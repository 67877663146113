import React from 'react'
import { Box, Text } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'

const ActionRequired = () => {
  return (
    <Box direction='row' align='end' gap='xxsmall' pad={{ top: 'xxsmall', right: 'xsmall' }}>
      <Text size='xsmall' weight={600} color={colors.AQUA_MARINE}>
        Action required
      </Text>
      <FontAwesomeIcon icon={faAngleDown} color={colors.AQUA_MARINE} fontSize={12} />
    </Box>
  )
}

export default React.memo(ActionRequired)
