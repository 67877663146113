import React, { useCallback, useRef, useState, useMemo } from 'react'
import _ from 'lodash'

import LayoutContext from 'src/contexts/LayoutContext'
import ProjectModal from 'modals/ProjectModal'
import TeammatesModal from 'modals/TeammatesModal'
import BidInviteModal from 'modals/BidInviteModal'
import Toast from 'shared/components/Toast'
import ConfirmationModal from 'modals/ConfirmationModal'
import BidPreviewModal from 'modals/BidPreviewModal'
import LevelingPanel from 'panels/LevelingPanel'
import InviteSubPanel from 'modals/InviteSubPanel'
import CommentsInbox from 'modals/CommentsInbox'
import ScheduledReminderModal from 'modals/ScheduledReminderModal'
import LeaveAccountModal from 'modals/LeaveAccountModal'
import ImportCsvModal from 'modals/ImportCsvModal'
import HubspotLiveChat from 'pages/HubspotLiveChat'
import ImportContacts from 'modals/ImportContacts'
import SaveFilterGroupModal from 'modals/SaveFilterGroupModal'
import RenameContactsGroupModal from 'modals/RenameContactsGroupModal'
import EditContactModal from 'modals/EditContactModal'

const LayoutDataProvider = ({ children }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState()

  const commentsInboxRef = useRef()
  const projectModalRef = useRef()
  const bidInviteModalRef = useRef()
  const confirmationModalRef = useRef()
  const bidPreviewModalRef = useRef()
  const sidePanelRef = useRef()
  const inviteSubPanelRef = useRef()
  const toastRef = useRef()
  const scheduledReminderRef = useRef()
  const leaveAccountModalRef = useRef()
  const importCsvModalRef = useRef()
  const teammatesModalRef = useRef()
  const importContactsRef = useRef()
  const saveFilterGroupModalRef = useRef()
  const renameContactsGroupModalRef = useRef()
  const editContactModalRef = useRef()

  const openProjectModal = useCallback((projectId, tabIndex = 0) => {
    projectModalRef.current.open(projectId, tabIndex)
  }, [])

  const openImportCsvModal = useCallback(projectId => {
    importCsvModalRef.current.open(projectId)
  }, [])

  const openSchedulerReminder = useCallback((workOrderId, invId, reminderId) => {
    scheduledReminderRef.current.open(workOrderId, invId, reminderId)
  }, [])

  const openLeaveAccountModal = useCallback(() => {
    console.log('open account leave confirmation modal', leaveAccountModalRef)
    if (_.has(leaveAccountModalRef, 'current.open')) leaveAccountModalRef.current.open()
  }, [])

  const openBidInviteModal = useCallback(projectId => {
    console.log('openBidInviteModal', projectId)
    bidInviteModalRef.current.open({ projectId })
  }, [])

  const openInviteSubPanel = useCallback((suggestion, workOrderId, value) => {
    inviteSubPanelRef.current.open(suggestion, workOrderId, value)
  }, [])

  const openConfirmationModal = useCallback(
    (title, onConfirm, onCancel = () => null, confirmButtonProps = {}, cancelButtonProps = {}) => {
      confirmationModalRef.current.open(title, onConfirm, onCancel, confirmButtonProps, cancelButtonProps)
    },
    []
  )

  const closeConfirmationModal = useCallback(() => {
    confirmationModalRef.current.close()
  }, [])

  const openBidPreviewModal = useCallback(params => {
    if (_.has(bidPreviewModalRef, 'current.open')) bidPreviewModalRef.current.open(params)
  }, [])

  const openSubInfo = useCallback(params => {
    sidePanelRef.current.openSubInfo(params)
  }, [])

  const openCellInfo = useCallback(params => {
    sidePanelRef.current.openCellInfo(params)
  }, [])

  const openLiInfo = useCallback(params => {
    sidePanelRef.current.openLiInfo(params)
  }, [])

  const openCommentsInbox = useCallback(() => {
    if (_.has(commentsInboxRef, 'current.open')) commentsInboxRef.current.open()
  }, [])

  const closeCommentsInbox = useCallback(() => {
    if (_.has(commentsInboxRef, 'current.close')) commentsInboxRef.current.close()
  }, [])

  const openToast = useCallback((content, withTimeout = true) => {
    if (_.has(toastRef, 'current.open')) toastRef.current.open(content, withTimeout)
  })

  const openTeammatesModal = useCallback(() => {
    teammatesModalRef.current.open()
  })

  const openImportContactsModal = useCallback(() => {
    console.log('openImportContactsModal')
    importContactsRef.current.open()
  })

  const openSaveFilterGroupModal = useCallback((filters, operator) => {
    saveFilterGroupModalRef.current.open(filters, operator)
  })

  const openRenameContactsGroupModal = useCallback((groupId, name) => {
    renameContactsGroupModalRef.current.open(groupId, name)
  })

  const openEditContactModal = useCallback(contactId => editContactModalRef.current.open(contactId))

  const providerValue = useMemo(
    () => ({
      openProjectModal,
      openBidInviteModal,
      openConfirmationModal,
      closeConfirmationModal,
      openBidPreviewModal,
      openSubInfo,
      openCellInfo,
      openCommentsInbox,
      closeCommentsInbox,
      openInviteSubPanel,
      openToast,
      openSchedulerReminder,
      openLeaveAccountModal,
      openImportCsvModal,
      openTeammatesModal,
      setSideMenuOpen,
      openLiInfo,
      openImportContactsModal,
      openSaveFilterGroupModal,
      openRenameContactsGroupModal,
      openEditContactModal
    }),
    []
  )

  return (
    <LayoutContext.Provider value={{ ...providerValue, sideMenuOpen }}>
      {children}
      <ProjectModal projectModalRef={projectModalRef} />
      <TeammatesModal teammatesModalRef={teammatesModalRef} />
      <BidInviteModal bidInviteModalRef={bidInviteModalRef} />
      <Toast toastRef={toastRef} />
      <ConfirmationModal modalRef={confirmationModalRef} />
      <LevelingPanel panelRef={sidePanelRef} />
      <ScheduledReminderModal ref={scheduledReminderRef} />
      <BidPreviewModal bidPreviewModalRef={bidPreviewModalRef} />
      <LeaveAccountModal ref={leaveAccountModalRef} />
      <CommentsInbox commentsInboxRef={commentsInboxRef} />
      <InviteSubPanel inviteSubPanelRef={inviteSubPanelRef} />
      <ImportCsvModal ref={importCsvModalRef} />
      <ImportContacts modalRef={importContactsRef} />
      <HubspotLiveChat />
      <SaveFilterGroupModal modalRef={saveFilterGroupModalRef} />
      <RenameContactsGroupModal modalRef={renameContactsGroupModalRef} />
      <EditContactModal modalRef={editContactModalRef} />
    </LayoutContext.Provider>
  )
}

export default React.memo(LayoutDataProvider)
