'use strict'
exports.apiKey = 'AIzaSyClQDEfJs9sLGImKpBhrY8pnUtgw8VLpWU'
exports.authDomain = 'mason-dev.firebaseapp.com'
exports.databaseURL = 'https://mason-dev.firebaseio.com'
exports.projectId = 'mason-dev'
exports.storageBucket = 'mason-dev.appspot.com'
exports.messagingSenderId = '150953587043'
exports.appId = '1:150953587043:web:078bd110f815ab77a06356'
exports.measurementId = 'G-3596YYJN0N'
exports.dynamicUrl = 'https://us-central1-mason-dev.cloudfunctions.net'
exports.supportEmail = 'support@usemason.com'
exports.proposalUrl = 'https://doc.dev.usemason.com'
exports.plaidPublicKey = '49365bace69030434658eb5148c16b'
exports.plaidEnv = 'sandbox'
exports.googleMapsApiKey = 'AIzaSyClQDEfJs9sLGImKpBhrY8pnUtgw8VLpWU'
exports.isDev = true
exports.internalVersion = 244
exports.stripeClientId = 'ca_F9VaSR3jfA6MzJMH4NGsojR43TXezjR4'
exports.stripeAuthorizeUri = 'https://connect.stripe.com/express/oauth/authorize'
exports.homeownerUrl = 'https://ho.dev.usemason.com'
exports.hellosignClientId = '28851dd965cf9ee7e110ed386f38afb2'
exports.workOrderUrl = 'https://wo.dev.usemason.com'
exports.sentryHICPublicDSN = 'https://3d74ee7136b94b4da6e38f5e4cf3f496@sentry.io/1808364'
exports.sentrySubsPublicDSN = 'https://c8c9e8314bb140fcb7afdaf70aa79a5a@sentry.io/1808374'
exports.sentryHICWebPublicDSN = 'https://0e09b24e2ee84d72a7161e61eb2c48c1@sentry.io/1808395'
exports.adminUrl = 'https://admin.dev.usemason.com'
exports.zapierStripeExpressConnectStart = 'https://hooks.zapier.com/hooks/catch/6329595/otsphnz/'
exports.zapierStripeExpressConnectExi = 'https://hooks.zapier.com/hooks/catch/6329595/ot8bszi/'
exports.stripeApiKey = 'pk_test_Mxno2vcPndB6feOHqZNFBn3l00Uyxk2tp0'
exports.zapierAppInitialized = 'https://hooks.zapier.com/hooks/catch/6329595/o11lkkd/'
exports.zapierOnboardingStarted = 'https://hooks.zapier.com/hooks/catch/6329595/o110ifr/'
exports.zapierOnboardingStep = 'https://hooks.zapier.com/hooks/catch/6329595/o11xbh1/'
exports.geojsonUrl =
  'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/geojson%2FgeoJsonSummary.json?alt=media&token=ef68d191-6dbb-49be-a49a-a469eb09d4a1'
exports.adobeClientIdMason = '3b305a80dd83450797cdbbc24fa58d71'
exports.adobeClientIdBidlevel = 'dbc37732495a42acaabc0a9b613b4d95'
exports.adobeClientIdReport = 'ceb881807061474d80ddccad156b2f7a'
exports.adobeClientIdInvitation = '7e7e2bc4128b43609481ff5cf2fff8de'
exports.adobeClientIdStorybook = '4e886e869c9647fa816a05256660de6f'
exports.branchKey = 'key_test_dfUNu8W122aaHeNgzXonwmhkstp3A4OS'
exports.gcHostname = 'bidlevel.co'
exports.courierToken = 'pk_test_JR7N4GMZX7M4QNQ09HB16QA4K0F3'
exports.algoliaAppId = 'HUF65C4EUT'
exports.algoliaApiKey = 'be870a34d441cc0363c98c438fec0d71'
exports.subHost = 'https://app.dev.usemason.com'
exports.gcHost = 'https://app.dev.bidlevel.co'
exports.reportUrl = 'https://rp.dev.bidlevel.co'
exports.calendlyUrl = 'https://calendly.com/bidlevel/precon-call'
exports.sentryToken = '775e7777c06d4c60bfbf834684dba1effbe2296191cf4281887e6ab4b8cd06d6'
exports.invitationUrl = 'https://inv.dev.usemason.com'
exports.retoolServicesPage =
  'https://masonco.retool.com/embedded/public/e8121d26-73a5-459b-a85e-3daf2a1d33fd?_environment=staging&_hideTimer=true&_hideNav=true'
exports.retoolPreconProjectsPage =
  'https://masonco.retool.com/apps/Precon%20-%20Live/Precon%20projects?_environment=staging&_hideTimer=true&_hideNav=true'
exports.takeoffTeamPage =
  'https://masonco.retool.com/embedded/public/8484f6f1-8141-4c2c-8268-a9e78cc0f7c6?_environment=staging&_hideTimer=true&_hideNav=true'
exports.retoolServicesAdminPage =
  'https://masonco.retool.com/embedded/public/2b8b51c0-8433-4f1e-994d-3df4845f2f54?_environment=staging&_hideTimer=true&_hideNav=true'
exports.retoolGCPage =
  'https://masonco.retool.com/apps/Precon%20-%20Live/General%20Contractors?_environment=staging&_hideTimer=true&_hideNav=true'
exports.hubspotChatSrc = 'https://js-na1.hs-scripts.com/20945779.js'
exports.retoolDealFilesPage =
  'https://masonco.retool.com/apps/00856d34-4b81-11ec-a917-2b58b0b34672/Precon%20-%20Live/HubSpot%20Deal%20files?_releaseVersion=latest&_environment=staging&_hideTimer=true&_hideNav=true'
