import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { db, auth } from 'controllers/db'
import { saveAs } from 'file-saver'
import {
  onSnapshot,
  collection,
  query,
  where,
  deleteField,
  updateDoc,
  doc,
  writeBatch,
  serverTimestamp,
  arrayRemove,
  deleteDoc,
  arrayUnion,
  setDoc
} from 'firebase/firestore'

import store from 'model/store'
import { receiveWorkOrders } from 'model/actions/workOrdersAC'
import { addListener } from 'controllers/listeners'
import { updateProjectAdmins } from 'controllers/project'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import sendTypes from 'shared/constants/inviteStatus'

export const fetchWorkOrders = (accountId, isGC) => {
  try {
    console.log('%c fetch workOrders, accountId:', 'color: lightgreen', accountId, 'isGC', isGC)

    const q = query(
      collection(db, 'workOrders'),
      where('accounts', 'array-contains', accountId),
      where('deleted', '==', 0)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const wo = doc.data()
          _.set(res, doc.id, wo)
        })
        console.log('work orders received', _.size(res))
        store.dispatch(receiveWorkOrders(res))
      },
      err => {
        console.log(`fetchWorkOrders error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('workOrders', unsubscribe)
  } catch (e) {
    console.log('fetchWorkOrders error', e)
    Sentry.captureException(e)
  }
}

export const downloadFile = async (path, name) => {
  try {
    const request = await window.fetch(path)
    const response = await request.blob()
    const status = _.get(request, 'status')
    if (status === 200) {
      saveAs(response, name)
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log('downloadFile error', error)
  }
}

export const updateWorkOrder = async (workOrderId, upd) => {
  console.log('updateWorkOrder async params', workOrderId, upd)
  upd = _.reduce(
    upd,
    (res, v, k) => {
      if (_.isNil(v)) {
        res[k] = deleteField()
        return res
      } else {
        res[k] = v
        return res
      }
    },
    {}
  )
  const ref = doc(db, 'workOrders', workOrderId)
  console.log('updateWorkOrder upd', upd)
  await updateDoc(ref, upd)
}

export const deleteWorkOrderAttachment = async (woId, file) => {
  const upd = { [`files.${file.id}`]: deleteField() }
  await updateWorkOrder(woId, upd)
}

export const saveFileToWorkOrder = async (woId, file) => {
  try {
    const upd = { [`files.${file.id}`]: file }
    await updateWorkOrder(woId, upd)
  } catch (error) {
    console.log(error)
  }
}

export const saveFilesToWorkOrder = async (projectId, woId, files) => {
  console.log('save files to workOrder', woId, files)
  const updWo = {}
  const updProject = {}
  _.forEach(files, file => {
    _.set(updWo, [`files.${file.id}`], file)
    _.set(updProject, [`attachments.${file.id}`], file)
  })
  console.log('updWo', updWo)
  console.log('updProject', updWo)
  try {
    const batch = writeBatch(db)
    batch.update(doc(db, 'workOrders', woId), updWo)
    batch.update(doc(db, 'projects', projectId), updProject)
    await batch.commit()
  } catch (error) {
    console.log(error)
  }
}

export const changeArchiveWorkOrderStatus = async (workOrderId, status) => {
  const state = store.getState()
  const deleted = status ? _.now() : 0
  const batch = writeBatch()
  console.log('changeArchiveWorkOrderStatus', workOrderId, deleted)
  const wo = _.get(state, ['workOrders', workOrderId], {})
  if (!_.isEmpty(wo)) {
    // optimistic redux update, this is needed when we archive a work order,
    // but it will never updated in redux by default, because onSnapshot listens deleted == 0
    store.dispatch(
      receiveWorkOrders({
        [workOrderId]: {
          ...wo,
          deleted
        }
      })
    )
  }
  batch.update(doc(db, 'workOrders', workOrderId), { deleted })
  for (const bidId in state.bids) {
    const bid = state.bids[bidId]
    if (bid.workOrderId === workOrderId) {
      batch.update(doc(db, 'bids', bid.id), { deleted })
    }
  }
  await batch.commit()
}

const sendInviteSubRequest = async (accountId, workOrderId, invId, sendType) => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    accountId,
    workOrderId,
    invId,
    sendType
  }
  const url = `${config.dynamicUrl}/proto/inviteSub`
  const response = await window.fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  console.log('inviteSub', answer)
}

export const sendDelayedInvite = async (accountId, workOrderId, invId) => {
  try {
    const upd = {
      [`invitations.${invId}.sendType`]: deleteField(),
      [`invitations.${invId}.timestamp`]: serverTimestamp()
    }
    await updateWorkOrder(workOrderId, upd)
    await sendInviteSubRequest(accountId, workOrderId, invId, sendTypes.IMMEDIATELY)
  } catch (error) {
    console.log('sendDelayedInvite error', error.message)
  }
}

export const saveScopeOfWork = async (workOrderId, scope, sections) => {
  console.log('saveScopeOfWork', workOrderId, scope, sections)
  const upd = {
    scope
  }
  if (!_.isNil(sections)) upd.sections = sections
  await updateWorkOrder(workOrderId, upd)
}

export const acceptOrDeclineInvite = async (accId, workOrderId, isAccepted) => {
  try {
    const userId = auth.currentUser.uid
    const update = {
      [`${isAccepted ? 'acceptedBy' : 'declinedBy'}.${accId}`]: {
        userId,
        timestamp: _.now()
      },
      [`${isAccepted ? 'declinedBy' : 'acceptedBy'}.${accId}`]: deleteField()
    }
    await updateWorkOrder(workOrderId, update)
  } catch (e) {
    Sentry.captureException(e)
    console.log('acceptOrDeclineInvite error:', e.message)
  }
}

export const inviteSub = async (subAccountId, companyId, sendTo, workOrderId, sendType = sendTypes.IMMEDIATELY) => {
  try {
    console.log('call inviteExistingSub', subAccountId, companyId, sendTo, workOrderId)
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const workOrder = _.get(state, ['workOrders', workOrderId])
    const subInvited = _.has(workOrder, ['invitatioins', subAccountId])
    const companyInvited = _.has(workOrder, ['invitatioins', companyId])
    if (subInvited || companyInvited) {
      console.log('inviteSub, SKIP: already invited, subInvited', subInvited, 'companyInvited', companyInvited)
      return false
    } else {
      const subAccountProfile = _.get(state, ['accountsProfiles', subAccountId])
      const contactCompany = _.get(state, ['contacts', 'companies', companyId])
      const invId = subAccountId ?? companyId
      const invParams = {
        companyName: _.get(subAccountProfile, 'name', _.get(contactCompany, 'name', null)),
        sendTo,
        id: invId,
        subAccountId,
        companyId,
        invitedBy: _.get(state, 'user.id'),
        timestamp: serverTimestamp(),
        sendType
      }
      const updWorkOrder = {
        [`invitations.${invId}`]: _.omitBy(invParams, 'isNil')
      }
      await updateWorkOrder(workOrderId, updWorkOrder)
      if (_.isEqual(sendType, sendTypes.IMMEDIATELY)) {
        sendInviteSubRequest(accountId, workOrderId, invId, sendType)
      }
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('inviteExistingSub error:', e.message)
  }
}

export const removeSubInvitation = async (accountId, workOrderId, invIds) => {
  console.log('remove sub invitation', accountId)
  const woUpdate = {
    accounts: arrayRemove(accountId),
    [`invitations.${accountId}`]: deleteField(),
    [`acceptedBy.${accountId}`]: deleteField(),
    [`declinedBy.${accountId}`]: deleteField()
  }
  await updateWorkOrder(workOrderId, woUpdate)
  _.forEach(invIds, async invId => {
    await deleteDoc(doc('subInvitations', invId))
  })
}

export const sendPlanFiles = async (subAccountId, workOrder, status) => {
  try {
    const workOrderId = _.get(workOrder, 'id')
    const functionUrl = `${config.dynamicUrl}/proto/sendPlanFiles`
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      subAccountId,
      workOrderId,
      status
    }
    const response = await window.fetch(functionUrl, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status !== 200) {
      console.log('sendPlanFiles response status', response.status)
    }
  } catch (error) {
    console.log(error.message)
  }
}

export const sendReminder = async (invId, workOrderId) => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      workOrderId,
      invId
    }
    const url = `${config.dynamicUrl}/proto/sendReminder`
    const response = await window.fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('sendReminder: ', answer)
    if (answer) {
      const upd = {
        [`invitations.${invId}.reminders`]: arrayUnion({
          timestamp: _.now(),
          createdBy: _.get(currentUser, 'uid')
        })
      }
      await updateWorkOrder(workOrderId, upd)
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('sendReminder error:', e.message)
  }
}

export const updateLineItem = async (workOrderId, itemId, item, altId) => {
  console.log('updateLineItem', workOrderId, itemId, item, altId)
  const key = altId ? `scope.${itemId}.alternates.${altId}` : `scope.${itemId}`
  const upd = {
    [key]: { ...item }
  }
  await updateWorkOrder(workOrderId, upd)
}

export const cancelReminder = async (workOrderId, invId, reminderId) => {
  console.log(workOrderId, invId, reminderId)
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    cancelationToken: `invite_sub/${reminderId}`
  }
  const url = `${config.dynamicUrl}/proto/cancelReminder`
  const response = await window.fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const upd = {
    [`invitations.${invId}.scheduledReminders.${reminderId}`]: deleteField()
  }
  await updateWorkOrder(workOrderId, upd)
  if (response.status === 200) {
    const res = await response.json()
    return res
  } else {
    return { error: `Server response status: ${response.status}` }
  }
}

export const saveBidInvite = async params => {
  try {
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const userId = _.get(state, 'user.id')
    const project = _.get(state, ['projects', params.projectId])
    let dbwo = {
      id: params.id,
      deleted: 0,
      accounts: [accountId],
      projectId: params.projectId,
      accountId: accountId,
      createdAt: serverTimestamp(),
      createdBy: userId,
      seenBy: {
        [userId]: _.now()
      },
      desc: _.get(params, 'description', null),
      projectAddress: _.get(project, 'address', null),
      projectApartment: _.get(project, 'apartment', null),
      tradeId: params.tradeId,
      requestType: params.requestType,
      bidsDueDate: params.bidsDueDate,
      jobWalkStartDate: params.jobWalkStartDate,
      jobWalkEndDate: params.jobWalkEndDate,
      startDate: params.startDate,
      endDate: params.endDate,
      teamLeadId: params.teamLeadId,
      jobWalkDateType: params.jobWalkDateType,
      files: _.get(params, 'files'),
      label: _.get(params, 'label', null)
    }
    dbwo = _.omitBy(dbwo, _.isNil)
    await setDoc(doc(db, 'workOrders', params.id), dbwo)
    store.dispatch(updateProjectAdmins(params.projectId, params.admins))
  } catch (e) {
    Sentry.captureException(e)
    console.log('saveBidInvite error:', e.message)
  }
}

export const updateBidInvite = async params => {
  try {
    const dbwo = _.mapValues(params, value => (_.isNil(value) ? deleteField() : value))
    await updateDoc(doc(db, 'workOrders', params.id), dbwo)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateBidInvite error:', e.message)
  }
}

export const scheduleReminder = async params => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = { authToken, ...params }
  const url = `${config.dynamicUrl}/proto/scheduleReminder`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    return res
  } else {
    return { error: `Server response status: ${response.status}` }
  }
}

export const getArchivedWorkOrders = () => null
