// import _ from 'lodash'
import workOptions from './workOptions'

export const groups = {
  structure: 'The Structure',
  functional: 'The Functional',
  finishes: 'The Finishes',
  additions: 'The Additions'
}

// export const zones = {
//   land: {
//     title: 'Land'
//   },
//   homeBuilding: {
//     title: 'Home building'
//   },
//   exterior: {
//     title: 'Exterior'
//   },
//   interior: {
//     title: 'Interior'
//   },
//   pool: {
//     title: 'Pool'
//   }
// }

export const zonesDict = {
  livingRoom: 'Living room',
  bedroom: 'Bedroom',
  masterBedroom: 'Master bedroom',
  porch: 'Porch',
  kitchen: 'Kitchen',
  greateRoom: 'Greate room',
  storage: 'Storage',
  dinningRoom: 'Dinning room',
  laundry: 'Laundry',
  bathroom: 'Bathroom',
  masterBathroom: 'Master bathroom',
  hall: 'Hall'
}

// export const houseConf = ['bedroom', 'masterBedroom', 'livingRoom', 'kitchen', 'bathroom', 'masterBathroom']

export const actvity = {
  wallsFinishing: {
    id: 'wallsFinishing',
    title: 'Walls finishing',
    options: workOptions.wallsFinishingOptions,
    group: 'Walls',
    suggestions: ['wallsLeveling']
  },
  wallsLeveling: {
    id: 'wallsLeveling',
    title: 'Walls surface leveling',
    group: 'Walls',
    options: workOptions.wallsLevingOptions
  }
}

// export const deafultTargetsByZone = {
//   bedroom: { wallsFinishing: 'wf13', wallsLeveling: 'wlo2' }
// }

export const components = {
  land: {
    group: 'structure',
    title: 'Land'
  },
  foundation: {
    group: 'structure',
    title: 'Foundation'
  },
  frame: {
    group: 'structure',
    title: 'Frame'
  },
  roof: {
    group: 'structure',
    title: 'Roof'
  },
  windows: {
    group: 'functional',
    title: 'Windows'
  },
  doors: {
    group: 'functional',
    title: 'Doors'
  },
  plumbing: {
    group: 'functional',
    title: 'Plumbing'
  },
  electrical: {
    group: 'functional',
    title: 'Electrical'
  },
  hvac: {
    group: 'functional',
    title: 'HVAC'
  },
  extFinishing: {
    group: 'finishes',
    title: 'Exterior'
  },
  walls: {
    group: 'finishes',
    title: 'Walls'
  },
  floors: {
    group: 'finishes',
    title: 'Floors'
  },
  ceils: {
    group: 'finishes',
    title: 'Ceils'
  },
  pool: {
    group: 'additions',
    title: 'Pool'
  }
}

export const defaultConf = {
  land: [],
  foundation: [],
  frame: [],
  roof: [],
  windows: [],
  doors: [],
  plumbing: [],
  electrical: [],
  hvac: [],
  extFinishing: [],
  walls: ['wf2', 'wlo1'],
  floors: ['wf1', 'wlo2'],
  ceils: ['wf13']
}

export default {
  groups,
  components,
  defaultConf
  // zonesDict,
  // houseConf
}
