import React, { useContext } from 'react'
import { Box, ResponsiveContext } from 'shared/components'

import { ReactComponent as Logo } from 'assets/bidlevel_logo_on_light.svg'
import HamburgerButton from 'components/navBar/HamburgerButton.js'
import CommentsInboxButton from 'components/navBar/CommentsInboxButton'
import AccountButton from 'components/navBar/AccountButton'

const NavBar = ({ children, fill, border, pad }) => {
  const size = useContext(ResponsiveContext)

  if (size === 'small') {
    return (
      <Box pad='medium'>
        <Logo height='26px' />
      </Box>
    )
  } else {
    return (
      <Box direction='row' align='center' justify='between' fixed fill={fill} border={border} pad={pad}>
        <Box align='center'>
          <HamburgerButton />
        </Box>
        <Box direction='row' fill={fill} align='center'>
          {children}
          <Box direction='row' gap='small' margin={{ left: 'auto' }} align='center'>
            <CommentsInboxButton />
            <AccountButton />
          </Box>
        </Box>
      </Box>
    )
  }
}

export default NavBar
