import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Select, ThemeContext, TextInput, Stack, Keyboard } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { css } from 'styled-components'
import _ from 'lodash'

import colors from 'shared/constants/colors'

export const InputWithSelect = ({
  value,
  onChange,
  onSelectChange,
  selectValue,
  onFocus,
  onBlur,
  options,
  placeholder,
  inputRef,
  keyboardListeners,
  alwaysShow,
  controlProps,
  textInputProps,
  showBorderOnFocus = true,
  highlighted = false,
  disabled,
  ...rest
}) => {
  const [opened, setOpened] = useState(false)

  const onOpen = () => {
    setOpened(true)
    onFocus()
  }

  const onClose = () => {
    setOpened(false)
    onBlur()
  }

  if (highlighted) {
    return (
      <ThemeContext.Extend
        value={{
          global: {
            control: { border: { color: 'transparent', radius: '0px' } }
          }
        }}
      >
        <Box flex direction='row' justify='center'>
          <Keyboard onEnter={onBlur} {...keyboardListeners}>
            <Box flex justify='center' pad={{ horizontal: '4px' }}>
              <ThemeContext.Extend
                value={{
                  textInput: {
                    extend: css`
                      text-align: center;
                      padding-top: 8px;
                      padding-bottom: 8px;
                      border-radius: 4px;
                      height: 100%;
                      ${disabled ? '' : `border: 1px ${colors.LINK} solid`};
                      :focus {
                        background-color: white;
                        border: 1px ${colors.AQUA_MARINE} solid;
                      }
                    `
                  }
                }}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  size='small'
                  alignSelf='center'
                  placeholder={placeholder}
                  ref={inputRef}
                  disabled={disabled}
                  {...rest}
                />
              </ThemeContext.Extend>
            </Box>
          </Keyboard>
          {!_.isEmpty(_.toString(value)) && (
            <Box justify='center'>
              <ThemeContext.Extend
                value={{
                  select: {
                    icons: {
                      margin: {
                        left: 'xxsmall'
                      }
                    },
                    control: {
                      extend: css`
                        input {
                          color: ${colors.LIGHT_NAVY};
                          width: 15px;
                          padding: 0px;
                          :focus {
                            border: none;
                          }
                        }
                      `
                    }
                  }
                }}
              >
                <Select
                  plain
                  placeholder='units'
                  options={options}
                  value={selectValue}
                  onChange={onSelectChange}
                  onOpen={onOpen}
                  onClose={onClose}
                  open={opened}
                  size='small'
                  icon={<FontAwesomeIcon icon={faChevronDown} color={colors.LIGHT_NAVY} fontSize={12} />}
                  disabled={disabled}
                  {...rest}
                />
              </ThemeContext.Extend>
            </Box>
          )}
        </Box>
      </ThemeContext.Extend>
    )
  }

  return (
    <ThemeContext.Extend
      value={{
        box: {
          extend: css`
            ${opened &&
              showBorderOnFocus &&
              `> div > div > div > input {background-color: white; border: 1px ${colors.AQUA_MARINE} solid;}`}
          `
        },
        textInput: textInputProps || {
          extend: css`
            text-align: start;
            height: 100%;
            border-radius: 4px;
            border: 1px ${colors.VERY_LIGHT_GREY_TWO} solid;
            color: ${colors.TEXT_PRIMARY};
            :focus {
              background-color: white;
              border: 1px ${colors.AQUA_MARINE} solid;
            }
          `,
          container: {
            extend: css`
              height: 100%;
            `
          }
        },
        global: {
          control: controlProps || { border: { color: 'transparent', radius: '0px' } }
        },
        select: {
          icons: {
            margin: {
              left: 'xxsmall'
            }
          },
          control: {
            extend: css`
              input {
                color: ${colors.LIGHT_NAVY};
                width: 25px;
                padding: 0px;
                border: none;
                :focus {
                  border: none;
                }
                text-align: center;
              }
            `
          }
        }
      }}
    >
      <Box fill>
        <Stack anchor='right' fill>
          <Keyboard onEnter={onBlur} {...keyboardListeners}>
            <Box fill align='center'>
              <TextInput
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                size='small'
                alignSelf='center'
                placeholder={placeholder}
                ref={inputRef}
                disabled={disabled}
                {...rest}
              />
            </Box>
          </Keyboard>
          {!_.isEmpty(_.toString(value)) && (
            <Box alignSelf='end'>
              <Select
                plain
                placeholder='units'
                options={options}
                value={selectValue}
                onChange={onSelectChange}
                onOpen={onOpen}
                onClose={onClose}
                open={opened}
                size='medium'
                icon={<FontAwesomeIcon icon={faChevronDown} color={colors.LIGHT_NAVY} fontSize={10} />}
                {...rest}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </ThemeContext.Extend>
  )
}

InputWithSelect.defaultProps = {
  options: []
}

InputWithSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  selectValue: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array
}

export default InputWithSelect
