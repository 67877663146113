import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, TextInput } from 'grommet'
import { Button } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment-timezone'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import RCDatePicker from 'shared/components/StyledDatePicker'

const CustomLabel = forwardRef(({
  timestampValue: value,
  onClick,
  disabled,
  type,
  placeholder,
  isClearable,
  clearButtonText,
  label: title,
  timeZone,
  onChange,
  onKeyDown,
  onDateSelected,
  isRequired
}, ref) => {
  const [active, setActive] = useState(false)
  const titleColor = _.isNil(value) ? isRequired ? colors.CORAL_TWO : (disabled ? colors.VERY_LIGHT_PINK : colors.ASANA_TEXT) : colors.TEXT

  const renderDate = () => {
    if (!_.isNil(value) && !_.isNaN(value)) {
      return (
        <Text size='small' alignSelf='start' color={colors.LIGHT_NAVY}>
          {moment.tz(value, timeZone).format(type === 'time' ? 'LT' : 'MMM DD')}
        </Text>
      )
    } else if (!_.isEmpty(placeholder)) {
      return <Text color={colors.VERY_LIGHT_PINK}>{placeholder}</Text>
    }
  }

  const resetDate = e => {
    e.stopPropagation()
    onDateSelected(null)
  }

  const renderIcon = () => {
    const color = _.isNil(value)
      ? isRequired
          ? colors.CORAL_TWO
          : disabled
            ? colors.VERY_LIGHT_PINK
            : colors.ASANA_GRAY_BORDER
      : colors.LIGHT_NAVY_DARK
    return (
      <Box
        id='icon_container'
        round='full'
        width='30px'
        height='30px'
        align='center'
        justify='center'
        margin={{ right: 'small' }}
        border={{ color: color, size: 'xsmall', style: 'dashed', side: 'all' }}
      >
        <FontAwesomeIcon icon={faCalendar} color={color} fontSize={12} />
      </Box>
    )
  }

  const handleKeyDown = e => {
    onKeyDown(e)
    if (e.key === 'Enter') setActive(false)
  }

  if (active) {
    return (
      <Box direction='row' align='center' pad={{ left: 'small' }} round='4px' border>
        <Box
          flex={{ shrink: 0 }}
          id='icon_container'
          round='full'
          width='30px'
          height='30px'
          align='center'
          justify='center'
          margin={{ right: 'small' }}
          border={{ color: colors.ANOTHER_GREY, size: 'xsmall', style: 'dashed', side: 'all' }}
        >
          <FontAwesomeIcon icon={faCalendar} color={colors.ANOTHER_GREY} fontSize={12} />
        </Box>
        <TextInput
          ref={ref}
          onFocus={onClick}
          onChange={onChange}
          onBlur={() => setActive(false)}
          autoFocus
          onKeyDown={handleKeyDown}
          plain
        />
      </Box>
    )
  }
  return (
    <Box
      ref={ref}
      onClick={() => setActive(true)}
      direction='row'
      alignSelf='start'
      justify='start'
      align='center'
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      flex={{ shrink: 0 }}
      hoverIndicator={{
        color: disabled ? '' : colors.ASANA_GRAY_BACKGROUND
      }}
      customStyle={`
        border-width: 1px;
        border-color: transparent;
        border-style: solid;
        cursor: ${disabled ? 'inherit' : 'pointer'};
        border-radius: 4px;
        ${
          disabled
            ? ''
            : `:hover {
              border-width: 1px;
              border-color: ${colors.ASANA_GRAY_BORDER};
              border-style: solid;

              svg {
                color: ${colors.LIGHT_NAVY_BRIGHT};
              };

              #title {
                color: ${colors.LIGHT_NAVY_DARK};
              };

              #icon_container {
                border-style: solid;
                border-color: ${colors.ASANA_GRAY_TEXT_HOVERED};
              }
            }`
        }
      `}
    >
      {renderIcon()}
      <Box>
        <Text size='small' id='title' color={titleColor}>
          {title}
        </Text>
        {renderDate()}
        {isClearable && !_.isNil(value) && <Button plain label={<Text size='small' color={colors.LINK}>{clearButtonText}</Text>} onClick={resetDate} />}
      </Box>
    </Box>
  )
})

const DatePicker = ({
  title,
  value,
  timeZone,
  onChange,
  disabled,
  placeholder,
  type,
  isClearable,
  clearButtonText,
  required
}) => {
  const [date, setDate] = useState(null)

  useEffect(() => {
    setDate(value)
  }, [value])

  const onDateSelected = (v) => {
    if (_.isNaN(v) || _.isNil(v)) {
      onChange(null)
      setDate(null)
    } else {
      const m = moment(v)
      const strDate = m.format('YYYY-MM-DD HH:mm')
      const mWithTimezone = moment.tz(strDate, timeZone)
      const timestamp = mWithTimezone.valueOf()
      onChange(timestamp)
      setDate(timestamp)
    }
  }

  const renderHeader = ({
    date,
    decreaseMonth,
    increaseMonth
  }) => (
    <Box background={colors.WHITE} pad='xsmall'>
      <Box direction='row' justify='end' align='center' gap='medium'>
        <Button
          plain
          icon={<FontAwesomeIcon icon={faAngleLeft} color={colors.AQUA_MARINE} fontSize={18} />}
          onClick={decreaseMonth}
        />
        <Button
          plain
          icon={<FontAwesomeIcon icon={faAngleRight} color={colors.AQUA_MARINE} fontSize={18} />}
          onClick={increaseMonth}
        />
      </Box>
      <Box align='start' justify='center'>
        <Text color={colors.TEXT} weight={700} size='small'>{moment.tz(date, timeZone).format('MMMM YYYY')}</Text>
      </Box>
    </Box>
  )

  const renderContainer = ({ children }) => (
    <Box pad={{ horizontal: 'small', top: 'small', bottom: 'large' }} elevation='small' background={colors.WHITE}>{children}</Box>
  )

  const renderDayContents = (_day, date) => {
    return (
      <Box pad='xxsmall'>
        <Text>{date.getDate()}</Text>
      </Box>
    )
  }

  return (
    <Box width='small' wrap background={{ color: colors.WHITE }}>
      <RCDatePicker
        onChange={onDateSelected}
        selected={!_.isNil(date) && !_.isNaN(date) ? moment.tz(date, timeZone).toDate() : null}
        customInput={(
          <CustomLabel
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            isClearable={isClearable}
            clearButtonText={clearButtonText}
            label={title}
            timestampValue={value}
            timeZone={timeZone}
            onDateSelected={onDateSelected}
            isRequired={required}
          />
        )}
        renderCustomHeader={renderHeader}
        calendarContainer={renderContainer}
        formatWeekDay={d => d.substr(0, 1)}
        renderDayContents={renderDayContents}
        showTimeSelect={type === 'time'}
        showTimeSelectOnly={type === 'time'}
        preventOpenOnFocus
      />
    </Box>
  )
}

DatePicker.defaultProps = {
  value: null,
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  timeZone: moment.tz.guess(),
  type: 'date',
  isClearable: false,
  clearButtonText: 'Clear',
  required: false
}

DatePicker.propTypes = {
  /**
  Function that will be called when the user changes the value. It will be passed an event object. The new input value will be available via 'event.target.value'.
  */
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.object,
    PropTypes.number
  ]),
  /**
  String to convert date from a standardized format to a certain timezone
  */
  timeZone: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  /**
  Bool value to show clear button
  */
  isClearable: PropTypes.bool,
  required: PropTypes.bool
}

export default DatePicker
