import React from 'react'
import { Box } from 'shared/components'

import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import colors from 'shared/constants/colors'

const CellContainer = ({ selected, onClick, children, ...rest }) => {
  let borderStyle = `border: 2px solid ${colors.PALE_GREY};`
  if (selected) borderStyle = `border: 2px solid ${colors.AQUA_MARINE};`
  return (
    <Box
      justify='between'
      fill
      hoverIndicator={!selected && colors.ALMOST_WHITE}
      className={IGNORE_OUTSIDE_CLICK_CLASS}
      onClick={onClick}
      customStyle={`${borderStyle}; position: relative;`}
      background={colors.WHITE}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default CellContainer
