import * as Sentry from '@sentry/react'
import _ from 'lodash'
import { updateDoc, doc, deleteField, writeBatch } from '@firebase/firestore'

import { auth, db } from 'controllers/db'
import { clearListeners } from 'controllers/listeners'
import store from 'model/store'

export const switchAccount = async (accId, navigate) => {
  console.log('SWITCH ACCOUNT', accId)
  if (_.isNil(accId)) {
    console.warn('cannot switch to account', accId)
    return null
  }
  try {
    const uid = _.get(auth, 'currentUser.uid')
    if (uid) {
      console.log(' ----> history replace ')
      navigate('/', { replace: true })
      clearListeners()
      await updateDoc(doc(db, 'users', uid), { currentAccountId: accId })
    } else {
      console.log('switchAccount, no uid')
      Sentry.captureMessage(`${accId} switchAccount, uid is undefined`, 'fatal')
    }
  } catch (e) {
    console.log('cant change account', e)
    Sentry.captureException(e)
  }
}

export const updateNotifications = async (kind, lst) => {
  const uid = _.get(auth, 'currentUser.uid')
  const ref = doc(db, 'users', uid)
  const upd = {}
  _.forEach(lst, (v, k) => {
    upd[`disabledNotifications.${kind}.${k}`] = v || deleteField()
  })
  console.log('updateNotifications', upd)
  await updateDoc(ref, upd)
}

export const updateQuoteDraft = async (draftId, draftPath, value) => {
  try {
    const authUser = auth.currentUser
    value = _.isObject(value) ? _.omitBy(value, _.isNil) : value
    value = _.isNil(value) ? deleteField() : value
    const upd = { [`quoteDrafts.${draftId}.${draftPath}`]: value }
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    const batch = writeBatch()
    batch.update(doc(db, 'users', authUser.uid), upd)
    batch.update(doc(db, 'workOrders', draftId), {
      [`invitations.${accountId}.draftCreated.${authUser.uid}`]: _.now()
    })
    await batch.commit()
  } catch (e) {
    console.log('updateQuoteDraft error:', e)
    Sentry.captureException(e)
  }
}
