import React, { useEffect, useState } from 'react'
import { Box, Keyboard, TextInput, Button, Text } from 'shared/components'
import _ from 'lodash'
import colors from 'shared/constants/colors'

const ProjectLabel = ({ onChange, projectLabel, addressName }) => {
  const [editLabelMode, setEditLabelMode] = useState(false)
  const [label, setLabel] = useState(projectLabel)

  useEffect(() => setLabel(projectLabel), [projectLabel])

  const handleLabelRemove = () => {
    onChange(null)
    setEditLabelMode(false)
  }

  const apply = () => {
    const l = _.isEmpty(label) ? null : label
    onChange(l)
    setEditLabelMode(false)
  }

  const onChangeLabel = e => {
    const l = e.target.value
    console.log('onChangeLabel', l)
    setLabel(l)
  }

  if (editLabelMode) {
    return (
      <Box
        direction='row'
        gap='medium'
        align='center'
        customStyle={`
        .submit-label {
          display: none;
        }
        :focus-within .submit-label {
          display: flex;
        }
      `}
        flex={{ grow: 1 }}
      >
        <Box width='medium' customStyle='input { padding: 4px; height: 28px; }'>
          <Keyboard onEnter={apply}>
            <TextInput autoFocus value={label || ''} onChange={onChangeLabel} />
          </Keyboard>
        </Box>
        <Button className='submit-label' plain label={<Text color={colors.CLEAR_BLUE}>update</Text>} onClick={apply} />
        <Button
          className='submit-label'
          plain
          label={<Text color={colors.CORAL_TWO}>remove label</Text>}
          onClick={handleLabelRemove}
        />
      </Box>
    )
  } else {
    return (
      <Box
        direction='row'
        gap='medium'
        align='center'
        customStyle={`
        .submit-label {
          display: none;
        }
        :hover .submit-label {
          display: flex;
        }
      `}
        flex={{ grow: 1 }}
      >
        <Box justify='center' width={{ max: 'medium' }}>
          <Text size='large' color={colors.LIGHT_NAVY_BRIGHT} weight={500} truncate>
            {_.isEmpty(label) ? addressName : label}
          </Text>
        </Box>
        <Button
          className='submit-label'
          plain
          label={<Text color={colors.CLEAR_BLUE}>rename</Text>}
          onClick={() => setEditLabelMode(true)}
        />
      </Box>
    )
  }
}

export default ProjectLabel
