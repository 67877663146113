import React from 'react'
import { Box, Text, Avatar } from 'shared/components'
import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'

import colors from 'shared/constants/colors'
import { toTimestamp } from 'shared/utils/date'
import { getInitials, getName, wrapMentions } from 'shared/utils/stringUtils'
import { getUsersProfiles } from 'model/selectors/profiles'

const Comment = ({ msg, index, prevMessage, workOrder }) => {
  const profiles = useSelector(getUsersProfiles)
  const renderReplacement = msgId => (name, index) => (
    <Text key={`${msgId}_${index}`} size='small' color={colors.AQUA_MARINE}>
      {name}
    </Text>
  )

  const profile = _.get(profiles, msg.userId)
  const prevMessageSameUser = _.get(prevMessage, 'userId') === msg.userId
  const prevMessageTime = toTimestamp(_.get(prevMessage, 'timestamp'))
  const messageTime = toTimestamp(_.get(msg, 'timestamp'))
  const shouldRenderHeader = index === 0 || !(prevMessageSameUser && messageTime - prevMessageTime < 360000000)
  let message = _.get(msg, 'text')
  const mentions = message.match(/@\[.*\]\(.*\)/g)
  const hasMentions = _.size(mentions) > 0
  if (hasMentions) {
    message = wrapMentions(message, /(@\[.*?\]\(.*?\))/, renderReplacement(msg.id))
  }
  message = (
    <Text newLines size='small' color={colors.TEXT}>
      {message}
    </Text>
  )

  const renderNewMessage = () => (
    <Box direction='row' flex={false} pad='small' key={index}>
      <Box flex={false} pad={{ left: '5px', right: 'xsmall' }}>
        <Avatar src={_.get(profile, 'avatarSmall')} size='32px' background={colors.LIGHT_NAVY_BRIGHT}>
          <Text color={colors.WHITE}>{getInitials(profile)}</Text>
        </Avatar>
      </Box>
      <Box>
        <Box direction='row' align='center' gap='small' pad={{ left: '10px', right: 'small', bottom: 'xxsmall' }}>
          <Box>
            <Text size='small' color={colors.LIGHT_NAVY_BRIGHT}>
              {getName(profile)}
            </Text>
          </Box>
          <Box>
            <Text size='xsmall' color={colors.ANOTHER_GREY}>
              {moment(messageTime).fromNow()}
            </Text>
          </Box>
        </Box>
        <Box pad={{ left: '10px', right: 'small' }}>{message}</Box>
      </Box>
    </Box>
  )

  const renderAdditionalMessage = () => (
    <Box direction='row' flex={false} key={index} pad={{ horizontal: 'small', bottom: 'small' }}>
      <Box pad={{ left: '53px', right: 'small' }}>{message}</Box>
    </Box>
  )

  return shouldRenderHeader ? renderNewMessage() : renderAdditionalMessage()
}

export default Comment
