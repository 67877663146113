import React from 'react'
import { Box, Text } from 'shared/components'
import PropTypes from 'prop-types'
import _ from 'lodash'

import newLineItemAction, { actionTitle } from 'shared/constants/newLineItemAction'
import colors from 'shared/constants/colors'

const NewLineItemDropContent = ({ onAction, companyName, dropContentProps }) => {
  return (
    <Box
      pad={{ top: 'xsmall', bottom: 'small' }} width={{ min: '300px' }} flex
      marginX={-6}
      {...dropContentProps}
    >
      <Box>
        <Text margin={{ vertical: 'small', left: 'small' }} color={colors.ANOTHER_GREY}>
          New line item:
        </Text>
      </Box>
      {_.map(newLineItemAction.actions, action => {
        return (
          <Box
            key={action}
            pad='small'
            onClick={e => {
              e.stopPropagation()
              onAction(action)
            }}
            hoverIndicator={{ color: colors.TABLE_HOVER }}
          >
            <Text margin={{ left: 'small' }} size='small'>
              {actionTitle(action, companyName)}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

NewLineItemDropContent.propTypes = {
  onAction: PropTypes.func.isRequired,
  companyName: PropTypes.string
}

export default NewLineItemDropContent
