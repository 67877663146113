import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { query, onSnapshot, collection, where, deleteField, updateDoc, doc } from '@firebase/firestore'

import { addListener } from 'controllers/listeners'
import { receiveProfiles, receiveMasonAdmins } from 'model/actions/profilesAC'
import { receiveAccountsProfiles } from 'model/actions/accountsProfilesAC'
import store from 'model/store'
import { setSentryUserProfile, setSentryCompanyProfile } from 'controllers/sentry'
import { db, auth } from 'controllers/db'
import config from 'shared/config'
import headers from 'shared/controllers/headers'

export const fetchUsersProfiles = (accountId, userId) => {
  try {
    console.log('fetchUsersProfiles, accountId:', accountId)
    const q = query(collection(db, 'usersProfiles'), where('accounts', 'array-contains', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const profiles = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.unset(p, 'accounts')
          if (doc.id === userId) setSentryUserProfile(p)
          _.set(profiles, doc.id, p)
        })
        console.log('fetchUsersProfiles:', _.size(profiles))
        store.dispatch(receiveProfiles(profiles))
      },
      err => {
        console.log(`fetch usersProfiles error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('usersProfiles', unsubscribe)
  } catch (e) {
    console.log('usersProfiles error', e)
    Sentry.captureException(e)
  }
}

export const fetchMasonAdmins = () => {
  try {
    const q = query(collection(db, 'usersProfiles'), where('isMasonAdmin', '==', true))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const profiles = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.unset(p, 'accounts')
          _.set(profiles, doc.id, p)
        })
        console.log('fetchMasonAdmins:', _.size(profiles))
        store.dispatch(receiveMasonAdmins(profiles))
      },
      err => {
        console.log(`fetchMasonAdmins error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('masonAdmins', unsubscribe)
  } catch (e) {
    console.log('fetchMasonAdmins error', e)
    Sentry.captureException(e)
  }
}

export const fetchAccountsProfiles = accountId => {
  try {
    console.log('fetchAccountsProfiles, accountId:', accountId)
    const q = query(collection(db, 'accountsProfiles'), where('accounts', 'array-contains', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const profiles = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.unset(p, 'accounts')
          if (doc.id === accountId) setSentryCompanyProfile(p)
          _.set(profiles, doc.id, p)
        })
        console.log('fetchAccountsProfiles:', _.size(profiles))
        store.dispatch(receiveAccountsProfiles(profiles))
      },
      err => {
        console.log(`fetchAccountsProfiles error: ${err}`)
      }
    )
    addListener('accountsProfiles', unsubscribe)
  } catch (e) {
    console.log('fetchAccountsProfiles error', e)
    Sentry.captureException(e)
  }
}

export const fetchOwnAccountsProfiles = user => {
  try {
    const accountsId = _.get(user, 'adminOfAccounts', [])
    console.log('fetchOwnAccountsProfiles, user.adminOfAccounts:', accountsId)
    if (!_.isEmpty(accountsId)) {
      const chunks = _.chunk(accountsId, 10)
      for (const i in chunks) {
        const ids = chunks[i]
        const q = query(collection(db, 'accountsProfiles'), where('id', 'in', ids))
        const unsubscribe = onSnapshot(
          q,
          sn => {
            const profiles = {}
            sn.forEach(doc => {
              const p = doc.data()
              _.unset(p, 'accounts')
              _.set(profiles, doc.id, p)
            })
            console.log('fetchOwnAccountsProfiles:', _.size(profiles))
            store.dispatch(receiveAccountsProfiles(profiles))
          },
          err => {
            console.log(`fetchOwnAccountsProfiles error: ${err}`)
          }
        )
        addListener(`ownAccountsProfiles${i}`, unsubscribe)
      }
    } else {
      console.warn('fetchOwnAccountsProfiles, the list is empty', user.id)
    }
  } catch (e) {
    console.log('fetchOwnAccountsProfiles error', e)
    Sentry.captureException(e)
  }
}

export const removeAvatarFromAccount = async () => {
  try {
    const state = store.getState()
    const id = _.get(state, 'account.id')
    const ref = doc(db, 'accountsProfiles', id)
    const upd = {
      avatar: deleteField(),
      avatarSmall: deleteField()
    }
    await updateDoc(ref, upd)
  } catch (error) {
    console.log('error removing avatar from account: ', error.message)
    Sentry.captureException(error)
  }
}

export const removeAvatarFromUser = async () => {
  try {
    const state = store.getState()
    const id = _.get(state, 'user.id')
    const ref = doc(db, 'usersProfiles', id)
    const upd = {
      avatar: deleteField(),
      avatarSmall: deleteField()
    }
    await updateDoc(ref, upd)
  } catch (error) {
    console.log('error removing avatar from account: ', error.message)
    Sentry.captureException(error)
  }
}

export const updateAccountProfile = async upd => {
  try {
    const state = store.getState()
    const id = _.get(state, 'account.id')
    const ref = doc(db, 'accountsProfiles', id)
    await updateDoc(ref, upd)
  } catch (error) {
    console.log('Error while updating account profile: ', error.message)
  }
}

export const saveUserProfile = async (profile, updateAuth = true) => {
  console.log('save user profile', profile, updateAuth)
}

export const updateUserProfile = async upd => {
  try {
    const state = store.getState()
    const userId = _.get(state, 'user.id')
    const ref = doc(db, 'usersProfiles', userId)
    await updateDoc(ref, upd)
  } catch (error) {
    console.log('updateUserProfile: ', error.message)
  }
}

export const getSubProfile = async subId => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    accountId: subId
  }
  console.log('body', body)
  const url = `${config.dynamicUrl}/proto/getSubProfile`
  console.log('post to url ', url, 'body', body)
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    // console.log('getSubsProfile response json', res)
    return res
  } else {
    console.log('getSubProfile response status', response.status)
    return { error: `Server response status: ${response.status}` }
  }
}
