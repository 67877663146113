import { css } from 'styled-components'

import colors from 'shared/constants/colors'

export const INBOX_MESSAGES_LIMIT = 20
export const timeZone = 'America/Los_Angeles'
export const defaultTimeZone = timeZone
export const PHONE_NUMBER_LENGTH = 14
export const IGNORE_OUTSIDE_CLICK_CLASS = 'ignore-outside-click-flag'
export const PRIVATE_CHAT_CHANNEL = 'private'
export const FIRST_PART_LENGTH = 7 // TODO: it's used in a phone input, make a universal phone input component

export const scrollBarStyleOnDark = css`
  scrollbar-width: thin;
  scrollbar-color: ${colors.AS_SCROLL_GREY} ${colors.LIGHT_NAVY_BRIGHT};
  /* Works on Chrome/Edge/Safari */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.LIGHT_NAVY_BRIGHT};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.AS_SCROLL_GREY};
    border-radius: 20px;
    border: 2px solid ${colors.LIGHT_NAVY_BRIGHT};
  }
`

export const scrollBarStyleOnWhite = css`
  scrollbar-width: thin;
  scrollbar-color: ${colors.LIGHT_NAVY_GREY} transparent;
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${colors.LIGHT_NAVY_GREY};
    border-radius: 20px;
    border: 2px solid ${colors.WHITE};
  }
`
