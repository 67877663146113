import React from 'react'
import { Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-thin-svg-icons'

import colors from 'shared/constants/colors'

const LoadingPage = () => {
  return (
    <Box fill align='center' justify='center'>
      <FontAwesomeIcon icon={faSpinner} size='2x' spin color={colors.LIGHT_NAVY_BRIGHT} />
    </Box>
  )
}

export default LoadingPage
