import React, { useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

import { DropContent, DropButton, DropContentContainer } from 'shared/components'
import { generateId } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'

const Menu = ({
  dropRef,
  children,
  dropContentProps,
  title,
  onOptionClick,
  options,
  selectedId,
  onOpen,
  multiple,
  backgroundColor,
  ...rest
}) => {
  const [opened, setOpened] = useState(false)
  const id = generateId()

  useImperativeHandle(dropRef, () => ({
    open: () => setOpened(true),
    close: () => setOpened(false)
  }))

  const onClick = (id, e, params) => {
    e.stopPropagation()
    setOpened(false)
    onOptionClick(id, params)
  }

  const dropContent = (
    <DropContent
      title={title}
      onOptionClick={onClick}
      options={options}
      selectedId={selectedId}
      multiple={multiple}
      {...dropContentProps}
    />
  )

  const _onOpen = e => {
    e.stopPropagation()
    setOpened(true)
    onOpen()
  }

  const _onClose = e => {
    e.stopPropagation()
    setOpened(false)
  }

  return (
    <DropButton
      plain
      label={children || title}
      dropAlign={{ top: 'bottom', left: 'left' }}
      // dropContent={<DropContentContainer dropId={id} backgroundColor={backgroundColor}>{dropContent}</DropContentContainer>}
      dropContent={dropContent}
      open={opened}
      onOpen={_onOpen}
      onClose={_onClose}
      {...rest}
    />
  )
}

const optionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string
})

Menu.defaultProps = {
  onOptionClick: () => null,
  onOpen: () => null,
  title: '',
  options: [],
  selectedId: '',
  backgroundColor: colors.WHITE,
  disabled: false
}

Menu.propTypes = {
  disabled: PropTypes.bool,
  /**
  Label text to place in the button.
  */
  title: PropTypes.string,
  /**
  Option click handler.
  */
  onOptionClick: PropTypes.func,
  /**
  Menu items to be placed inside the drop down.
  */
  options: PropTypes.arrayOf(optionType).isRequired,
  /**
  Currently selected item ID.
  */
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
  Function to run on menu open.
  */
  onOpen: PropTypes.func,
  /**
  Background color of the content and arrow (if presented)
   */
  backgroundColor: PropTypes.string
}

export default Menu

export { Menu }
