import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'shared/components'

import colors from 'shared/constants/colors'

const ButtonCard = ({ icon, title, description, onClick, premium }) => {
  const premiumBadge = () => {
    if (premium) {
      return (
        <Box
          background={colors.AQUA_MARINE}
          height={{ min: '26px' }}
          width='95px'
          round='20px'
          align='center'
          justify='center'
        >
          <Text color={colors.WHITE} weight={700}>
            Premium
          </Text>
        </Box>
      )
    }
  }
  return (
    <Box
      align='start'
      gap='small'
      // width='small'
      // height='small'
      customStyle={`
        height: 250px;
        width: 250px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
      `}
      pad='small'
      onClick={onClick}
      hoverIndicator={{ color: 'rgba(0, 64, 105, 0.14)' }}
    >
      <Box height='40px' alignSelf='end'>
        {premiumBadge()}
      </Box>
      <Box width='48px' height='48px' alignSelf='center' margin={{}}>
        {icon}
      </Box>
      <Box alignSelf='start' margin={{ top: 'small', horizontal: 'small' }}>
        <Text weight={700} size='medium' color={colors.LIGHT_NAVY_BRIGHT}>
          {title}
        </Text>
      </Box>
      <Box alignSelf='start' margin={{ top: 'xsmall', horizontal: 'small' }}>
        <Text weight={400} color={colors.COOL_GREY}>
          {description}
        </Text>
      </Box>
    </Box>
  )
}

ButtonCard.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.node,
  description: PropTypes.node,
  onClick: PropTypes.func,
  premium: PropTypes.bool
}

export default ButtonCard
