import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useClickAway } from 'react-use'
import { Box } from 'grommet'

import colors from 'shared/constants/colors'

const IGNORE_OUTSIDE_CLICK_CLASS = 'ignore-outside-click-flag'

const OutsideClickHandlerWrapper = ({ children, onClick, enabled, customStyle }) => {
  const containerRef = useRef()

  useClickAway(containerRef, evt => {
    if (enabled) {
      let ignore = false
      _.forEach(evt.path, pathElt => {
        if (_.indexOf(pathElt.classList, IGNORE_OUTSIDE_CLICK_CLASS) >= 0) {
          ignore = true
          return false
        }
      })
      if (!ignore) onClick(evt)
    }
  })

  return (
    <Box
      background={colors.WHITE}
      width='400px'
      height='100%'
      elevation='small'
      customStyle={customStyle}
      ref={containerRef}
    >
      {children}
    </Box>
  )
}

OutsideClickHandlerWrapper.propTypes = {
  /**
  Event that will be triggered when user click outside of container
  */
  onClick: PropTypes.func.isRequired,
  /**
  True / False
  */
  enabled: PropTypes.bool,
  /**
  Custom css string for container
  */
  customStyle: PropTypes.string
}

export default OutsideClickHandlerWrapper

export { OutsideClickHandlerWrapper }
