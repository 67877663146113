import React, { useMemo, useState, useContext } from 'react'
// import { useSelector } from 'react-redux'
import _ from 'lodash'
import Table, { Column, AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'

import colors from 'shared/constants/colors'
// import { formatNational } from 'shared/utils/phone'
import { Box, Text, CheckBox, ThemeContext } from 'shared/components'
import LayoutContext from 'contexts/LayoutContext'

const ContactsTable = ({ selectedRows, setSelectedRows, data, tableHeaders }) => {
  const [sortBy, setSortBy] = useState({})
  const { openEditContactModal } = useContext(LayoutContext)
  const sortedData = useMemo(() => _.orderBy(data, [sortBy.key], [sortBy.order]), [data, sortBy])

  const headers = _.keys(tableHeaders)
  const selectedRowsDict = useMemo(() => _.keyBy(selectedRows), [selectedRows])

  const renderCell = ({ rowData, cellData }) => {
    return (
      <Text size='small' color={colors.DARK_GRAY_TWO}>
        {cellData}
      </Text>
    )
  }

  const renderHeaderCell = ({ column }) => {
    return <Text size='small'>{column.title}</Text>
  }

  // const renderRow = ({ rowData, cells }) => {
  //   const selected = false
  //   return (
  //     <Box
  //       key={_.get(rowData, 'id')}
  //       background={selected ? colors.RIPTIDE10 : colors.WHITE}
  //       fill
  //       direction='row'
  //       onClick={() => onRowClick(rowData)}
  //       hoverIndicator
  //     >
  //       {cells}
  //     </Box>
  //   )
  // }

  const selectAll = () => {
    if (_.size(selectedRows) < _.size(data)) {
      setSelectedRows(_.map(data, v => v.id))
    } else {
      setSelectedRows([])
    }
  }

  const selectRow = i => {
    if (_.has(selectedRowsDict, i)) {
      setSelectedRows(_.without(selectedRows, i))
    } else {
      setSelectedRows([i, ...selectedRows])
    }
  }

  const onSort = sortBy => {
    console.log('onSort', sortBy)
    setSortBy(sortBy)
  }

  const onRowClick = ({ rowData, rowIndex, rowKey, event }) => {
    // console.log('onRowClick', rowData, event)
    const cName = _.get(event, 'target.className')
    if (cName === 'BaseTable__row-cell') {
      console.log('on contact row click', rowData)
      openEditContactModal(rowData.id)
    }
  }

  return (
    <ThemeContext.Extend
      value={{
        checkBox: {
          hover: {
            border: {
              color: colors.AQUA_MARINE
            }
          },
          size: '18px',
          color: colors.AQUA_MARINE
        }
      }}
    >
      <Box
        fill
        customStyle={`
          .BaseTable__row:hover, .BaseTable__row--hovered {
            background-color: ${colors.TABLE_HOVER};
          }
        `}
      >
        <AutoResizer key='autoresizer'>
          {({ width, height }) => (
            <Table
              key='contacts_table'
              data={sortedData}
              width={width}
              height={height}
              components={{ TableCell: renderCell, TableHeaderCell: renderHeaderCell }}
              ignoreFunctionInColumnCompare={false}
              // rowRenderer={renderRow}
              onColumnSort={onSort}
              sortBy={sortBy}
              rowEventHandlers={{
                onClick: onRowClick
              }}
              // fixed
            >
              <Column
                key='select'
                dataKey='select'
                headerRenderer={() => (
                  <Box width='50px' align='center'>
                    <CheckBox
                      primary
                      checked={_.size(selectedRows) > 0}
                      onChange={selectAll}
                      type={!_.isEqual(_.size(selectedRows), _.size(data)) ? 'plus' : 'minus'}
                    />
                  </Box>
                )}
                cellRenderer={({ rowData }) => (
                  <Box width='50px' align='center'>
                    <CheckBox
                      key={_.get(rowData, 'id')}
                      primary
                      checked={_.has(selectedRowsDict, `${_.get(rowData, 'id')}`)}
                      onChange={() => selectRow(`${_.get(rowData, 'id')}`)}
                      type='checkmark'
                    />
                  </Box>
                )}
                title='Select Line Items'
                fixed
                width={50}
                frozen={Column.FrozenDirection.LEFT}
              />
              {_.map(headers, h => (
                <Column
                  sortable
                  key={h}
                  dataKey={h}
                  title={_.get(tableHeaders, h)}
                  // fixed={false}
                  width={0}
                  flexGrow={2}
                  resizable
                />
              ))}
              {/* <Column
                key='tags'
                dataKey='tags'
                cellRenderer={({ rowData }) => (
                  <Box direction='row' gap='xsmall' wrap flex={{ shrink: 0 }}>
                    {_.map(rowData.tags, tag => {
                      return <Text>{tag}</Text>
                      // return <Tag key={tag} label={tag} noNeedRemoveTag neededMargin />
                    })}
                  </Box>
                )}
                title='Tags'
                width={0}
                flexGrow={5}
                resizable
              /> */}
            </Table>
          )}
        </AutoResizer>
      </Box>
    </ThemeContext.Extend>
  )
}

export default ContactsTable
