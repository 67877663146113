import _ from 'lodash'
import * as Sentry from '@sentry/react'
import {
  onSnapshot,
  collection,
  query,
  where,
  writeBatch,
  doc,
  deleteField,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { db, generateId } from 'controllers/db'

import { receiveProjects } from 'model/actions/projectsAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { receiveImportedLi } from 'model/actions/importedLiAC'
import { deleteFile } from 'controllers/storage'

export const fetchProjects = accountId => {
  try {
    console.log('fetch projects, accountId:', accountId)
    const q = query(collection(db, 'projects'), where('accountId', '==', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        console.log('projects received, amount', _.size(res))
        store.dispatch(receiveProjects(res))
      },
      err => {
        console.log(`fetchProjects error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('projects', unsubscribe)
  } catch (e) {
    console.log('fetchProjects error', e)
    Sentry.captureException(e)
  }
}

export const changeArchiveProjectStatus = async (projectId, isArchiving) => {
  const state = store.getState()
  const deleted = isArchiving ? _.now() : 0
  const batch = writeBatch()

  batch.update(doc(db, 'projects', projectId), { deleted })
  const workOrders = _.filter(_.get(state, 'workOrders'), wo => wo.projectId === projectId)
  for (const workOrder of workOrders) {
    batch.update(doc(db, 'workOrders', workOrder.id), { deleted })
    const bids = _.filter(_.get(state, 'bids'), bid => bid.workOrderId === workOrder.id)
    for (const bid of bids) {
      batch.update(doc(db, 'bids', bid.id), { deleted })
    }
  }
  await batch.commit()
}

export const saveProject = async (params, projectAdmins) => {
  const p = {
    ..._.omitBy(params, _.isNil),
    deleted: 0
  }
  if (_.isNil(_.get(params, 'label'))) {
    _.set(p, 'label', deleteField())
  }
  console.log('save project', p)
  const state = store.getState()
  const ref = doc(db, 'projects', p.id)
  if (_.has(state, ['projects', p.id])) {
    await updateDoc(ref, p)
  } else {
    await setDoc(ref, p, { merge: true })
  }
  if (!_.isEmpty(projectAdmins)) {
    const upd = { [`projectsAdmins.${p.id}`]: projectAdmins }
    const accRef = doc(db, 'accounts', p.accountId)
    await updateDoc(accRef, p.accountId, upd)
  }
  return p.id
}

export const fetchImportedLi = async projectId => {
  try {
    const ref = doc(db, 'importedLi', projectId)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const res = sn.data()
        store.dispatch(receiveImportedLi(res))
      },
      err => {
        console.log(`fetchImportedLi error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('importedLi', unsubscribe)
  } catch (e) {
    console.log('fetchImportedLi error', e)
    Sentry.captureException(e)
  }
}

export const updateProject = async (projectId, upd) => {
  try {
    const ref = doc(db, 'projects', projectId)
    await updateDoc(ref, upd)
  } catch (e) {
    console.log('updateProject error', e)
    Sentry.captureException(e)
  }
}

export async function updateProjectAdmins (projectId, admins = []) {
  try {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    const upd = { [`projectsAdmins.${projectId}`]: admins }
    await updateDoc(doc(db, 'accounts', accountId), upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateProjectAdmins error:', e.message)
  }
}

export const removeProjectFile = async (fileId, projectId) => {
  try {
    console.log('delete project file', fileId, projectId)
    const upd = {
      [`attachments.${fileId}`]: deleteField()
    }
    await updateProject(projectId, upd)
    const storagePath = `projects/${projectId}/${fileId}`
    deleteFile(storagePath)
  } catch (error) {
    console.log(error.message)
  }
}

export const updateProjectFiles = async (attachments, projectId) => {
  const updProject = {}
  _.forEach(attachments, file => {
    _.set(updProject, [`attachments.${file.id}`], file)
  })
  await updateProject(projectId, updProject)
}

export const importLineItems = async (projectId, lineItems, filename) => {
  try {
    const state = store.getState()
    const importedLi = state.importedLi
    const timestamp = _.now()
    const fileId = generateId()
    const upd = {
      [`files.${fileId}`]: {
        filename,
        id: fileId,
        timestamp
      }
    }
    _.forEach(lineItems, li => {
      upd[`lineItems.${li.id}`] = { ...li, fileId }
    })
    if (_.isNil(importedLi)) {
      await setDoc(doc(db, 'importedLi', projectId), { id: projectId })
    }
    await updateDoc(doc(db, 'importedLi', projectId), upd)
    return null
  } catch (e) {
    console.log('importLineItems error', e)
    return null
  }
}

export const updateImportedLi = async (importedLi, projectId) => {
  try {
    const ref = doc(db, 'importedLi', projectId)
    const update = { ...importedLi }
    _.forEach(importedLi, (value, id) => {
      if (_.isEmpty(_.get(value, 'lineItems', []))) {
        _.set(update, id, deleteField())
      }
    })
    await updateDoc(ref, update)
  } catch (e) {
    console.log('updateImportedLi error', e)
  }
}
