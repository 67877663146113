import React from 'react'
import { Box, Text } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import colors from 'shared/constants/colors'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

const RequestPrice = ({ onPriceRequestClick }) => {
  return (
    <Box
      background={colors.PALE_GREY}
      align='center'
      justify='center'
      onClick={onPriceRequestClick}
      customStyle={`
        > div {
          display: none;
        }
        :hover {
          > div {
            display: flex;
          }
          border: 1px solid ${colors.LIGHT_NAVY_BRIGHT}
        }
      `}
    >
      <Box direction='row' align='center' gap='xsmall'>
        <FontAwesomeIcon icon={faPlus} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />
        <Text color={colors.LIGHT_NAVY_BRIGHT}>Request Price</Text>
      </Box>
    </Box>
  )
}

RequestPrice.propTypes = {
  onPriceRequestClick: PropTypes.func
}

export default React.memo(RequestPrice)
