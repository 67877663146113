import React, { Component } from 'react'
import LevelingContext from 'contexts/LevelingContext'
import { getArchivedWorkOrders } from 'controllers/workOrder'
import ImportedLiModal from 'modals/ImportedLiModal'

class LevelingDataProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      archivedVisible: false,
      setArchivedVisible: this.setArchivedVisible,
      values: false,
      compact: false,
      references: false,
      quantities: false,
      declined: false,
      sections: false,
      handleViewChange: this.handleViewChange,
      openImportLiModal: this.openImportLiModal
    }

    this.importedLiModalRef = React.createRef()
  }

  setArchivedVisible = v => {
    const { projectId } = this.props
    this.setState({ archivedVisible: v })
    if (v) {
      getArchivedWorkOrders(projectId)
    }
  }

  handleViewChange = (key, value) => {
    this.setState({ [key]: value })
  }

  openImportLiModal = () => {
    this.importedLiModalRef.current.open()
  }

  render () {
    return (
      <LevelingContext.Provider value={{ ...this.state }}>
        <ImportedLiModal ref={this.importedLiModalRef} />
        {this.props.children}
      </LevelingContext.Provider>
    )
  }
}

LevelingDataProvider.contextType = LevelingContext

export default LevelingDataProvider
