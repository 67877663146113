import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import { Box, Text, Select, Button, DropButton } from 'shared/components'
import ConditionItem from 'shared/components/filter/ConditionItem'
import filterConditions from 'shared/constants/filterConditions'
import colors from 'shared/constants/colors'
import _ from 'lodash'

const FilterDropButton = ({ confDict, fieldKey, selectedCond, selectedCondValue, saveFilter, index, ...rest }) => {
  const [show, setShow] = useState(false)
  const [_fieldKey, _setFieldKey] = useState(fieldKey)
  const [_selectedCond, _setSelectedCond] = useState(selectedCond)
  const [_selectedCondValue, _setSelectedCondValue] = useState(selectedCondValue)

  const fieldConf = _.get(confDict, _fieldKey)
  // console.log('FilterDropButton rest', rest)

  const open = () => {
    _setFieldKey(fieldKey)
    _setSelectedCond(selectedCond)
    _setSelectedCondValue(selectedCondValue)
    setShow(true)
  }

  const changeCondition = t => {
    _setSelectedCond(t)
    _setSelectedCondValue(null)
  }

  const changeFieldKey = fk => {
    _setFieldKey(fk)
    _setSelectedCond(null)
    _setSelectedCondValue(null)
  }

  // console.log('selectedCond', _selectedCond)

  const applyCurrentFilter = () => {
    const f = {
      fieldKey: _fieldKey,
      cond: _selectedCond,
      value: _selectedCondValue
    }
    saveFilter(f, index)
    setShow(false)
  }

  const remove = () => {
    saveFilter(null, index)
    setShow(false)
  }

  const dropContent = useMemo(() => {
    if (_.isNil(_fieldKey)) {
      return (
        <Box width={{ min: '280px' }}>
          <Box pad={{ horizontal: 'small', vertical: 'xsmall' }}>
            <Text weight={700}>Where</Text>
          </Box>
          {_.map(confDict, (fieldConf, fkey) => {
            return (
              <Box
                key={fkey}
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
                onClick={() => changeFieldKey(fkey)}
                hoverIndicator
              >
                <Text>{_.get(fieldConf, 'fieldLabel', fieldKey)}</Text>
              </Box>
            )
          })}
        </Box>
      )
    } else {
      const condType = _.get(fieldConf, ['fieldType'])
      // console.log('condType', condType)
      const conds = _.get(filterConditions.conditionsByType, condType, [])
      // console.log('conds', conds)
      return (
        <Box width={{ min: '280px' }}>
          <Box pad={{ horizontal: 'small', top: 'small' }} direction='row' align='center' justify='between'>
            <Text weight={700}>Where</Text>
            {!_.isNil(index) && <Button label={<Text color={colors.LINK}>remove</Text>} plain onClick={remove} />}
          </Box>
          <Box pad='small' border={{ side: 'bottom', color: colors.VERY_LIGHT_GREY_TWO }}>
            <Select
              size='medium'
              options={_.keys(confDict)}
              children={(option, index, options, { active, disabled, selected }) => (
                <Box pad={{ horizontal: 'small', vertical: 'xsmall' }}>
                  <Text>{_.get(confDict, [option, 'fieldLabel'], option)}</Text>
                </Box>
              )}
              value={
                <Text margin={{ horizontal: 'small', vertical: 'xsmall' }}>
                  {_.get(confDict, [_fieldKey, 'fieldLabel'], _fieldKey)}
                </Text>
              }
              onChange={({ value }) => changeFieldKey(value)}
            />
          </Box>
          <Box pad={{ horizontal: 'small', vertical: 'xsmall' }}>
            {_.map(conds, c => (
              <ConditionItem
                key={c}
                condition={c}
                fieldConf={fieldConf}
                isSelected={_selectedCond === c}
                value={_selectedCondValue}
                onSelect={() => changeCondition(c)}
                onChange={v => _setSelectedCondValue(v)}
              />
            ))}
          </Box>
          <Box
            direction='row'
            align='center'
            justify='center'
            border={{ side: 'top', color: colors.VERY_LIGHT_GREY_TWO }}
          >
            <Button label='Done' color={colors.AQUA_MARINE} noHover onClick={applyCurrentFilter} />
          </Box>
        </Box>
      )
    }
  }, [_fieldKey, confDict, _selectedCond, _selectedCondValue])

  return (
    <DropButton
      label='Add Filter'
      icon={<FontAwesomeIcon icon={faPlus} />}
      color={colors.LIGHT_NAVY_BRIGHT}
      size='medium'
      open={show}
      onOpen={open}
      onClose={() => setShow(false)}
      dropContent={dropContent}
      gap='xsmall'
      dropAlign={{ top: 'bottom', left: 'left' }}
      {...rest}
    />
  )
}

FilterDropButton.propTypes = {
  confDict: PropTypes.object.isRequired,
  fieldKey: PropTypes.string,
  selectedCond: PropTypes.string,
  selectedCondValue: PropTypes.string,
  saveFilter: PropTypes.func,
  index: PropTypes.number
}

export default FilterDropButton
