import React, { useRef } from 'react'
import { Box, Text, AccordionPanel } from 'shared/components'
import { connect } from 'react-redux'
import _ from 'lodash'

import { generateId } from 'controllers/db'
import ChatInputField from 'components/chat/ChatInputField'
import { saveNewNote } from 'controllers/notes'
import colors from 'shared/constants/colors'
import Comment from 'panels/levelingPanel/Comment'

const Notes = ({ accId, workOrderId, descriptions, userId, account, profiles, notes }) => {
  const accountId = _.get(account, 'id')
  const containerRef = useRef()

  const renderMessages = () => {
    if (_.isEmpty(notes)) {
      return <Box pad={{ bottom: 'small' }} />
    } else {
      return (
        <Box pad={{ bottom: 'small' }} overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
          {_.map(notes, (note, i) => {
            return (
              <Comment key={note.id} msg={note} index={i} prevMessage={_.get(notes, [i - 1])} profiles={profiles} />
            )
          })}
        </Box>
      )
    }
  }

  const onNewMessage = text => {
    if (_.isEmpty(_.toString(text))) {
      console.log('the message is empty')
      return null
    }
    const id = generateId()
    // const adminsIds = _.keys(_.get(account, 'admins'))
    // const incomingForUsers = _.filter(adminsIds, adminId => adminId !== userId)

    const mentions = text.match(/(@\[.*?\]\(.*?\))/g)
    const incomingForUsers = _.uniq(_.map(mentions, m => _.replace(_.replace(m, /@\[.*?\]\(/, ''), /\)/, '')))
    const note = {
      id,
      text,
      userId,
      accountId,
      workOrderId,
      subId: accId,
      incomingForUsers,
      type: 'note',
      accounts: [_.get(account, 'id')]
    }
    saveNewNote(note)
  }

  const inputField = (
    <ChatInputField descriptions={descriptions} onNewMessage={onNewMessage} containerRef={containerRef} />
  )

  const label = (
    <Box direction='row' align='center' gap='small'>
      <Text margin={{ vertical: 'small', left: 'small' }} weight={600} size='large' color={colors.BROWN_GREY_TWO}>
        Notes
      </Text>
      {!_.isEmpty(notes) && (
        <Box background={colors.VERY_LIGHT_PINK} round='small' pad={{ horizontal: 'xsmall', vertical: '1px' }}>
          <Text color={colors.WHITE} size='small'>
            {_.size(notes)}
          </Text>
        </Box>
      )}
    </Box>
  )

  const renderNotes = () => {
    return (
      <Box pad={{ horizontal: 'small', bottom: 'small' }} height='100%' ref={containerRef} flex={{ shrink: 0 }}>
        {renderMessages()}
        {inputField}
      </Box>
    )
  }

  return <AccordionPanel label={label}>{renderNotes()}</AccordionPanel>
}

const mapStateToProps = state => ({
  userId: _.get(state, 'user.id'),
  account: _.get(state, 'account'),
  profiles: state.profiles
})

export default connect(mapStateToProps)(Notes)
