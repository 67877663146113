import React from 'react'

import { Box } from 'shared/components'
import ContactsSideMenu from 'pages/contacts/ContactsSideMenu'
import ContactsContent from 'pages/contacts/ContactsContent'

const Contacts = () => {
  return (
    <Box direction='row' fill>
      <ContactsSideMenu />
      <ContactsContent />
    </Box>
  )
}

export default Contacts
