import React from 'react'
import AppRouter from 'navigation/AppRouter'
import { Provider } from 'react-redux'
import { Grommet } from 'shared/components'

import lightNavyTheme from 'shared/themes/lightNavyTheme'
import store from 'model/store'

const App = () => {
  return (
    <Provider store={store}>
      <Grommet theme={lightNavyTheme} full>
        <AppRouter />
      </Grommet>
    </Provider>
  )
}

export default App
