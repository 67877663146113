import { createSelector } from 'reselect'
import _ from 'lodash'

import { getContacts } from 'model/selectors/base'
import { formatNational } from 'shared/utils/phone'
import { applyFiltersToContact } from 'shared/utils/filter'

export const getContactsTableInfo = createSelector([getContacts], accountContacts => {
  const res = []
  const tagsDict = {}
  const contacts = _.get(accountContacts, 'contacts', {})
  const companies = _.get(accountContacts, 'companies', {})
  const groups = { ..._.get(accountContacts, 'groups', {}) }
  const tableHeaders = {
    name: 'Full name',
    company: 'Company name',
    location: 'Location',
    trades: 'Trade(s)',
    divisions: 'Division(s)',
    emails: 'Emails',
    phones: 'Phones',
    tags: 'Tags'
  }

  _.forEach(contacts, c => {
    const row = {
      id: c.id,
      name: c.name,
      company: _.get(companies, [c.companyId, 'name']),
      location: _.get(companies, [c.companyId, 'location']),
      trades: _.get(companies, [c.companyId, 'trades']),
      divisions: _.get(companies, [c.companyId, 'divisions']),
      emails: _.join(c.emails, ', '),
      phones: _.join(_.map(c.phones, formatNational), ', '),
      tags: c.tags
    }
    res.push(row)
    _.forEach(c.tags, t => _.set(tagsDict, t, true))
    _.forEach(groups, (g, groupId) => {
      if (applyFiltersToContact(row, g.filters, g.operator)) {
        if (!_.has(groups, [groupId, 'rows'])) {
          _.set(groups, [groupId, 'rows'], [])
        }
        groups[groupId].rows.push(row)
      }
    })
  })

  const conf = [
    {
      fieldKey: 'name',
      fieldLabel: tableHeaders.name,
      fieldType: 'string',
      fieldOptions: _.map(contacts, c => c.name)
    },
    {
      fieldKey: 'company',
      fieldLabel: tableHeaders.company,
      fieldType: 'string',
      fieldOptions: _.map(companies, c => c.name)
    },
    {
      fieldKey: 'location',
      fieldLabel: 'Location',
      fieldType: 'string'
    },
    {
      fieldKey: 'trades',
      fieldLabel: 'Trade',
      fieldType: 'string'
    },
    {
      fieldKey: 'divisions',
      fieldLabel: 'Division',
      fieldType: 'string'
    },
    {
      fieldKey: 'tags',
      fieldLabel: 'Tag',
      fieldType: 'string',
      fieldOptions: _.keys(tagsDict)
    },
    {
      fieldKey: 'emails',
      fieldLabel: 'Email',
      fieldType: 'string'
    },
    {
      fieldKey: 'phones',
      fieldLabel: 'Phone',
      fieldType: 'string'
    }
  ]

  return { tableData: res, filterConf: conf, tableHeaders, tags: _.keys(tagsDict), groups }
})
