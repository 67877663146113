import React from 'react'
const Ctx = React.createContext({
  onCreateProject: () => null,
  /**
   * Open confirmation modal
   * @param {String} title - Confirmation message
   * @param {requestCallback} onConfirm
   * @param {requestCallback} onCancel
   * @param {Object} confirmButtonProps - override props of Confirm button
   * @param {Object} cancelButtonProps - override props of Cancel button
   */
  openConfirmationModal: () => null,
  closeConfirmationModal: () => null,
  openBidPreviewModal: () => null,
  commentsInboxRef: null,
  openCommentsInbox: () => null,
  closeCommentsInbox: () => null,
  sideMenuOpen: false,
  openInviteSubPanel: () => null,
  openBidInviteModal: () => null,
  openSubInfo: () => null,
  openInvoiceModal: () => null,
  openLeaveAccountModal: () => null,
  openTeammatesModal: () => null,
  setSideMenuOpen: () => null,
  openLiInfo: () => null,
  openImportContactsModal: () => null,
  openSaveFilterGroupModal: () => null,
  openRenameContactsGroupModal: () => null,
  /**
   * Open edit contact modal
   * @param {String} contactId
   */
  openEditContactModal: () => null
})

export default Ctx
