import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { Collapsible, Button } from 'shared/components'

import LayoutContext from 'src/contexts/LayoutContext'

const HamburgerButton = () => {
  const { sideMenuOpen, setSideMenuOpen } = useContext(LayoutContext)
  const onButtonClick = () => {
    setSideMenuOpen(!sideMenuOpen)
  }
  return (
    <Collapsible direction='horizontal' open={!sideMenuOpen} baseline={20}>
      <Button
        plain
        label={<FontAwesomeIcon icon={faBars} fontSize={16} />}
        margin={{ right: 'small' }}
        onClick={onButtonClick}
      />
    </Collapsible>
  )
}

export default React.memo(HamburgerButton)
