import React, { useContext } from 'react'
import { Box, Button } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faExternalLink, faSlidersHSquare, faPaperclip } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import LevelingCustomizePanel from 'panels/LevelingCustomizePanel'
import LayoutContext from 'contexts/LayoutContext'

const DashboardSubmenu = ({ addBidInvite, generateReport, settingsChanged, projectId }) => {
  const { openProjectModal, openImportCsvModal } = useContext(LayoutContext)
  const addBidInviteButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPlus} fontSize={16} />}
      label='Add Bid Invitation'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addBidInvite}
    />
  )

  const planRoomButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPaperclip} fontSize={16} />}
      label='Plan Room'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={() => openProjectModal(projectId)}
    />
  )

  const importCsvButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPaperclip} fontSize={16} />}
      label='Import CSV'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={() => openImportCsvModal(projectId)}
    />
  )

  const generateReportButton = (
    <Button
      icon={<FontAwesomeIcon icon={faExternalLink} fontSize={16} />}
      label='Generate Report'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={generateReport}
    />
  )

  const customizeLabel = () => {
    if (settingsChanged === 0) {
      return 'Views'
    } else {
      return `${settingsChanged} customization${settingsChanged > 1 ? 's' : ''}`
    }
  }

  const viewsButton = (
    <LevelingCustomizePanel
      dropButtonLabel={customizeLabel()}
      dropButtonProps={{
        icon: <FontAwesomeIcon icon={faSlidersHSquare} />
      }}
    />
  )

  return (
    <Box
      fill='horizontal'
      direction='row'
      height='40px'
      background={colors.WHITE_GREY}
      align='center'
      pad={{ horizontal: 'small' }}
      flex={false}
      justify='between'
    >
      <Box direction='row' align='center' gap='small'>
        {addBidInviteButton}
        {importCsvButton}
        {planRoomButton}
        {generateReportButton}
      </Box>
      <Box>{viewsButton}</Box>
    </Box>
  )
}

export default React.memo(DashboardSubmenu)
