import React from 'react'

import { Box, Button, TextInput, ThemeContext } from 'shared/components'
import CommentsInboxButton from 'components/navBar/CommentsInboxButton'
import AccountButton from 'components/navBar/AccountButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPlus } from '@fortawesome/pro-regular-svg-icons'
import colors from 'shared/constants/colors'

const ContactsNavBar = () => {
  return (
    <Box
      direction='row'
      align='center'
      justify='between'
      fixed
      height={{ min: '60px' }}
      fill='horizontal'
      pad={{ vertical: 'small', right: 'medium' }}
    >
      <Box align='center' pad={{ left: 'medium' }} gap='small' direction='row' fill='horizontal' justify='start'>
        <Box>
          <ThemeContext.Extend
            value={{ textInput: { extend: `background-color: ${colors.VERY_LIGHT_PINK_TWO}; width: 460px;` } }}
          >
            <TextInput
              value=''
              size='small'
              placeholder='Search for a person,  company, location...'
              onChange={() => null}
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} color={colors.VERY_LIGHT_PINK} />}
            />
          </ThemeContext.Extend>
        </Box>
        <Button primary label='Add contact' icon={<FontAwesomeIcon icon={faPlus} />} color={colors.AQUA_MARINE} />
      </Box>
      <Box direction='row' gap='small' margin={{ left: 'auto' }} align='center'>
        <CommentsInboxButton />
        <AccountButton />
      </Box>
    </Box>
  )
}

export default ContactsNavBar
