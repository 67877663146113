import React, { useState, useImperativeHandle, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Layer, ThemeContext, Text, Button } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'
import { useNavigate } from 'react-router'

import colors from 'shared/constants/colors'
import { getInboxMessages, hasMoreInbox } from 'model/selectors/inbox'
import InboxMessage from 'components/chat/InboxMessage'
import messageType from 'constants/messageType'
import { loadMoreInbox, fetchInboxNotes } from 'controllers/inbox'
import { getChannel } from 'model/selectors/base'
import { wrapMentions } from 'shared/utils/stringUtils'
import { toTimestamp } from 'shared/utils/date'
import { getWorkOrdersWithTitlesDict } from 'model/selectors/workOrdersSelector'

const Tab = ({ label, isActive, onClick }) => {
  return (
    <Box
      onClick={onClick}
      pad={{ bottom: 'xsmall', horizontal: 'xsmall' }}
      border={
        isActive
          ? {
              side: 'bottom',
              size: '2px',
              color: colors.AQUA_MARINE
            }
          : false
      }
    >
      <Text size='large'>{label}</Text>
    </Box>
  )
}

const CommentsInbox = ({ commentsInboxRef }) => {
  const [visible, setVisible] = useState(false)
  const [tab, setTab] = useState('all')
  const [notes, setNotes] = useState([])
  const [notesFetched, setNotesFetched] = useState(false)
  const inboxMessages = useSelector(getInboxMessages)
  const firstNoteId = useSelector(state => _.get(state, 'inbox.firstNote.id'))
  const workOrders = useSelector(getWorkOrdersWithTitlesDict)
  const hasMore = useSelector(hasMoreInbox)
  const userId = useSelector(state => _.get(state, 'user.id'))
  const readAt = useSelector(getChannel)
  const profiles = useSelector(state => _.get(state, 'profiles'))
  const navigate = useNavigate()
  const unsubscribeNotes = useRef({ unsubscribe: () => null })

  useImperativeHandle(commentsInboxRef, () => ({ open }))

  const loadMore = () => {
    if (tab === 'all') {
      loadMoreInbox()
    } else {
      unsubscribeNotes.current.unsubscribe()
      fetchNotes(true)
    }
  }

  const fetchNotes = loadMore => {
    if (loadMore) {
      const lastNote = _.last(notes)
      unsubscribeNotes.current.unsubscribe = fetchInboxNotes(
        userId,
        notesRaw => {
          const notes = _.map(notesRaw, formatNote)
          setNotes([...this.state.notes, ...notes])
          setNotesFetched(true)
        },
        lastNote
      )
    } else {
      unsubscribeNotes.current.unsubscribe = fetchInboxNotes(userId, notesRaw => {
        const notes = _.map(notesRaw, formatNote)
        setNotes(notes)
        setNotesFetched(true)
      })
    }
  }

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
    setTab('all')
  }

  const renderInboxMessage = (msg, index) => {
    const workOrderId = _.get(msg, 'workOrderId')
    const workOrder = _.get(workOrders, workOrderId)
    const projectId = _.get(workOrder, 'projectId')
    let onClick = () => {
      console.log('on message click', msg)
      if (_.isNil(projectId) || _.isNil(workOrderId)) {
        console.log('no project or workorder - skip')
        return null
      }
      close()
      switch (msg.type) {
        case messageType.ANNOTATION:
          console.log('annotation message, navigate to work order file')
          navigate(`/workorderfile/${workOrderId}/${_.get(msg, 'fileId')}`)
          break
        case messageType.ITEM:
          console.log('item comment, open leveling page')
          navigate(`/project/${projectId}/workOrderId/${workOrderId}/leveling`)
          break
        case messageType.SUB:
          console.log('sub message clicked')
          navigate(`/project/${projectId}/workOrderId/${workOrderId}/leveling`)
          break
        case messageType.NOTE:
          navigate(`/project/${projectId}/workOrderId/${workOrderId}/leveling`)
          break
        default:
          console.warn('cannot navigate to msg', msg)
      }
    }
    if (_.isNil(projectId)) {
      onClick = () => {
        console.warn('on message click', msg, 'no projectId')
      }
    }
    return (
      <InboxMessage
        key={msg.id}
        index={index}
        profile={msg.profile}
        strAction={msg.strAction}
        timestamp={msg.timestamp}
        text={msg.text}
        itemName={msg.itemName}
        title={msg.title}
        isNew={msg.unseen}
        onClick={onClick}
        type={msg.type}
      />
    )
  }

  const hasMoreNotes = () => {
    const notesIds = _.map(notes, n => n.id)
    return !_.isNil(firstNoteId) && !_.includes(notesIds, firstNoteId)
  }

  const renderLoadMoreButton = () => {
    const hasMoreVal = tab === 'all' ? hasMore : hasMoreNotes()
    if (hasMoreVal) {
      return (
        <Box
          direction='row'
          pad='small'
          fill='horizontal'
          margin={{ bottom: 'xlarge' }}
          justify='center'
          flex={{ shrink: 0 }}
        >
          <Button
            primary
            color={colors.VERY_LIGHT_PINK_TWO}
            label={
              <Box pad='none' align='start'>
                <Text color={colors.ANOTHER_GREY}>Load more</Text>
              </Box>
            }
            onClick={loadMore}
          />
        </Box>
      )
    }
  }

  const formatNote = m => {
    const workOrderId = _.get(m, 'workOrderId')
    const wo = _.get(workOrders, workOrderId)
    const channelKey = `${workOrderId}_${m.subId}`
    const readTime = toTimestamp(_.get(readAt, channelKey))
    const timestamp = toTimestamp(m.timestamp)
    const unseen = readTime < timestamp
    const strAction = 'mentioned you'
    const regex = /(@\[.*?\]\(.*?\))/
    const text = _.join(wrapMentions(_.get(m, 'text'), regex), '')
    const itemName = _.get(wo, ['invitations', m.subId, 'companyName'], _.get(wo, ['invitaitons', m.subId, 'name']))
    let title = _.get(wo, 'projectAddress.name', '')
    const woTitle = _.get(wo, 'title')
    if (!_.isEmpty(woTitle)) title = `${title} - ${woTitle}`
    return {
      id: m.id,
      type: m.type,
      profile: _.get(profiles, m.userId),
      strAction,
      timestamp,
      text,
      itemName,
      unseen,
      title,
      itemId: m.itemId,
      subId: m.subId,
      workOrderId: workOrderId
    }
  }

  const renderInboxMessages = () => {
    if (tab === 'mentions' && !notesFetched) fetchNotes(false)
    const messages = tab === 'all' ? inboxMessages : notes
    return (
      <Box overflow='scroll' align='start' height='100%'>
        {_.map(messages, renderInboxMessage)}
        {renderLoadMoreButton()}
      </Box>
    )
  }

  const handleNavigation = tab => {
    setTab(tab)
  }

  const renderHeader = () => (
    <Box pad='medium' direction='row' align='center' justify='between'>
      <Box justify='center' direction='row' gap='large'>
        <Tab label='All' isActive={tab === 'all'} onClick={() => handleNavigation('all')} />
        <Tab label='Mentions' isActive={tab === 'mentions'} onClick={() => handleNavigation('mentions')} />
      </Box>
      <Box onClick={close}>
        <FontAwesomeIcon icon={faArrowToRight} fontSize={24} color={colors.TEXT} />
      </Box>
    </Box>
  )

  if (visible) {
    return (
      <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
        <Layer position='right' full='vertical' modal onClickOutside={close} onEsc={close}>
          <Box
            width='400px'
            fill='vertical'
            border='left'
            customStyle='box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)'
          >
            <Box fill='vertical' height='100%' justify='start'>
              {renderHeader()}
              {renderInboxMessages()}
            </Box>
          </Box>
        </Layer>
      </ThemeContext.Extend>
    )
  } else {
    return null
  }
}

export default CommentsInbox
