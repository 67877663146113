import _ from 'lodash'
import filterOperators from 'shared/constants/filterOperators'
import { condition } from 'shared/constants/filterConditions'

const check = (elt, filter) => {
  const v = _.get(elt, filter.fieldKey)
  const value = filter.value
  switch (filter.cond) {
    case condition.IS: {
      if (_.isArray(v)) {
        return !_.isNil(_.find(v, e => e === value))
      } else {
        return _.toString(v) === value
      }
    }
    case condition.IS_NOT: {
      if (_.isArray(v)) {
        return _.isNil(_.find(v, e => e === value))
      } else {
        return _.toString(v) !== value
      }
    }
    case condition.STARTS_WITH: {
      if (_.isArray(v)) {
        return !_.isNil(_.find(v, e => _.startsWith(e, value)))
      } else {
        return _.startsWith(_.toString(v), value)
      }
    }
    case condition.ENDS_WITH: {
      if (_.isArray(v)) {
        return !_.isNil(_.find(v, e => _.endsWith(e, value)))
      } else {
        return _.endsWith(_.toString(v), value)
      }
    }
    case condition.CONTAINS: {
      const reg = new RegExp(_.escapeRegExp(value), 'gi')
      if (_.isArray(v)) {
        return !_.isNil(_.find(v, e => _.toString(e).match(reg)))
      } else {
        return _.toString(v).match(reg)
      }
    }
    case condition.DOES_NOT_CONTAIN: {
      const reg = new RegExp(_.escapeRegExp(value), 'gi')
      if (_.isArray(v)) {
        return _.isNil(_.find(v, e => _.toString(e).match(reg)))
      } else {
        return !_.toString(v).match(reg)
      }
    }
    default:
      return false
  }
}

export const applyFiltersToContact = (contact, filters, operator) => {
  let passed = false
  if (operator === filterOperators.OR) {
    const passedFilter = _.find(filters, filter => check(contact, filter))
    // console.log('passedFilter', passedFilter)
    if (!_.isEmpty(passedFilter)) passed = true
  } else {
    const failedFilter = _.find(filters, filter => !check(contact, filter))
    if (_.isEmpty(failedFilter)) passed = true
  }
  return passed
}

export const applyFilter = (data, filters, operator) => {
  if (_.isEmpty(filters)) {
    return data
  } else {
    return _.filter(data, elt => applyFiltersToContact(elt, filters, operator))
  }
}

export default {
  applyFilter,
  applyFiltersToContact
}
