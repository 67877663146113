import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox as GrommetCheckBox, ThemeContext } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faSquareXmark, faSquareMinus, faSquarePlus, faSquare } from '@fortawesome/pro-regular-svg-icons'
import {
  faSquareCheck as faSquareCheckSolid,
  faSquareXmark as faSquareXmarkSolid,
  faSquareMinus as faSquareMinusSolid,
  faSquarePlus as faSquarePlusSolid
} from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

export const CheckBox = ({ size, onChange, type, color, primary, uncheckedColor, checked, disabled }) => {
  const fontSizes = {
    small: 14,
    medium: 18,
    large: 24
  }

  const renderElement = ({ checked }) => {
    let icon = faSquare
    if (checked) {
      switch (type) {
        case 'checkmark': {
          icon = primary ? faSquareCheckSolid : faSquareCheck
          break
        }
        case 'x': {
          icon = primary ? faSquareXmarkSolid : faSquareXmark
          break
        }
        case 'minus': {
          icon = primary ? faSquareMinusSolid : faSquareMinus
          break
        }
        case 'plus': {
          icon = primary ? faSquarePlusSolid : faSquarePlus
          break
        }
      }
    }
    return (
      <FontAwesomeIcon
        icon={icon}
        color={checked ? color : uncheckedColor}
        fontSize={fontSizes[size]}
        opacity={disabled ? 0.75 : 1}
      />
    )
  }

  const onCheckboxClick = e => {
    // console.log('onCheckboxClick', e)
    e.stopPropagation()
    onChange(e.target.checked)
  }

  return (
    <GrommetCheckBox checked={checked} onChange={onCheckboxClick} disabled={disabled}>
      {renderElement}
    </GrommetCheckBox>
  )
}

CheckBox.defaultProps = {
  type: 'checkmark',
  size: 'medium',
  uncheckedColor: colors.LIGHT_BLUE_GREY,
  checked: false,
  onChange: () => null,
  disabled: false,
  primary: false,
  color: colors.AQUA_MARINE
}

CheckBox.propTypes = {
  /**
  True / False
  */
  checked: PropTypes.bool,
  /**
  Function that will be called when the user clicks the check box.
  */
  onChange: PropTypes.func,
  /**
  checkbox size
  */
  type: PropTypes.oneOf(['checkmark', 'x', 'minus', 'plus']),
  /**
  checkbox size
  */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
  When checked background color is set to button color
  */
  primary: PropTypes.bool,
  /**
  Disables the checkbox
  */
  disabled: PropTypes.bool,
  /**
  Color of the checkbox, when it's checked
  */
  color: PropTypes.string,
  /**
  Color of the checkbox border, when it's unchecked
  */
  uncheckedColor: PropTypes.string
}

export default CheckBox
