import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box, Text, Radio, TextInput } from 'shared/components'
import filterConditions from 'shared/constants/filterConditions'
import colors from 'shared/constants/colors'

const ConditionItem = ({ condition, isSelected, value, onSelect, onChange, fieldConf }) => {
  const onRadioClick = () => {
    // console.log('onRadioClick', condition)
    if (!isSelected) {
      onSelect()
    }
  }

  const onSuggestionSelect = e => {
    onChange(e.suggestion)
  }

  const suggestions = () => {
    if (_.includes([filterConditions.condition.IS, filterConditions.condition.IS_NOT], condition)) {
      const options = _.get(fieldConf, 'fieldOptions', [])
      let res
      if (!_.isEmpty(options)) {
        if (!_.isNil(value) && value.length > 0) {
          const reg = new RegExp(_.escapeRegExp(value), 'gi')
          res = _.filter(options, opt => opt && opt.match(reg))
        } else {
          res = options
        }
      } else {
        res = []
      }
      if (_.size(res) === 1 && res[0] === value) {
        return []
      } else {
        return res
      }
    } else {
      return []
    }
  }

  // console.log('ConditionItem', condition, 'isSelected', isSelected)
  return (
    <Box pad={{ vertical: 'xsmall' }} gap='xsmall'>
      <Box direction='row' gap='small' align='center'>
        <Radio
          value={isSelected}
          onChange={onRadioClick}
          colorUnchecked={colors.VERY_LIGHT_PINK}
          colorChecked={colors.AQUA_MARINE}
          size='small'
        />
        <Text>{filterConditions.conditionTitles[condition]}</Text>
      </Box>
      {isSelected && (
        <TextInput
          size='small'
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          suggestions={suggestions()}
          onSuggestionSelect={onSuggestionSelect}
        />
      )}
    </Box>
  )
}

ConditionItem.propTypes = {
  condition: PropTypes.oneOf(filterConditions.conditionsList),
  isSelected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  fieldConf: PropTypes.object
}

export default ConditionItem
