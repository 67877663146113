import React, { useState, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip, faTrashAlt, faTimes } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { removeBidFile, updateBidFiles } from 'controllers/bids'
import PdfPreview from 'shared/components/PdfPreview'
import { getName } from 'shared/utils/stringUtils'
import FilePreview from 'shared/components/FilePreview'
import FilesPickerButton from 'components/FilesPickerButton'
import { getFile } from 'shared/utils/files'
import LoadingPage from 'shared/pages/LoadingPage'
import { Layer, Text, Card, CardHeader, CardBody, Box, Button, Tab, Tabs, Tip } from 'shared/components'

const filePickerLabel = ({ onClick }) => (
  <Box
    direction='row'
    gap='xsmall'
    justify='start'
    align='center'
    onClick={onClick}
    pad={{ vertical: 'small', horizontal: 'medium' }}
  >
    <FontAwesomeIcon icon={faPaperclip} color={colors.VERY_LIGHT_PINK} fontSize={14} />
    <Text size='medium' color={colors.VERY_LIGHT_PINK}>
      Upload file
    </Text>
  </Box>
)

const BidPreviewModal = ({ bidPreviewModalRef }) => {
  const [visible, setVisible] = useState(false)
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [bidId, setBidId] = useState(null)
  const [pdf, setPdf] = useState(null)
  const [error, setError] = useState(false)
  // const [wrapperRef, wrapperParams] = useMeasure()

  const profiles = useSelector(state => state.profiles)
  const bids = useSelector(state => state.bids)

  useImperativeHandle(bidPreviewModalRef, () => ({ open }))

  const open = async bidId => {
    setActiveIndex(0)
    setVisible(true)
    setBidId(bidId)
    setLoading(true)
    const bid = _.get(bids, bidId)
    const pdfUrl = _.get(bid, 'pdfUrl', null)
    if (!_.isNil(pdfUrl)) {
      const pdf = await getFile(pdfUrl)
      if (!pdf) {
        setError(true)
      } else {
        setPdf(pdf)
      }
    }
    setLoading(false)
  }

  const close = () => {
    setVisible(false)
    setActiveIndex(0)
    setError(false)
    setIsGeneratingPdf(false)
    setActiveIndex(0)
    setLoading(false)
    setBidId(null)
    setPdf(null)
  }

  // const onDownloadClick = async () => {
  //   const bid = _.get(bids, bidId)
  //   setIsGeneratingPdf(true)
  //   await downloadEstimatePdf(bid)
  //   setIsGeneratingPdf(false)
  // }

  const handleNavigation = index => {
    setActiveIndex(index)
  }

  const handleRemoveFile = ({ id }, isActive) => e => {
    e.stopPropagation()
    const bid = _.get(bids, bidId)
    const workOrderId = _.get(bid, 'workOrderId')
    if (isActive) {
      setActiveIndex(0)
    }
    removeBidFile(workOrderId, bidId, id, true)
  }

  const renderBid = () => {
    const bid = _.get(bids, bidId)
    console.log('isGeneratingPdf: ', isGeneratingPdf)
    if (loading) {
      return (
        <Card height='100%' width='large' customStyle='border-radius: 0px'>
          <CardBody overflow='scroll' wrap={false} background={colors.PALE_GREY}>
            <LoadingPage />
          </CardBody>
        </Card>
      )
    }
    if (error) {
      return (
        <Card height='100%' width='large' customStyle='border-radius: 0px'>
          <CardHeader
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            border={{
              color: 'border',
              size: '1px',
              side: 'bottom'
            }}
          >
            <Text size='xlarge' color={colors.BLACK} weight={600}>
              Preview
            </Text>
          </CardHeader>
          <CardBody overflow='scroll' wrap={false} background={colors.PALE_GREY}>
            <Box fill justify='center' align='center'>
              <Text>Error while fetching PDF</Text>
            </Box>
          </CardBody>
        </Card>
      )
    } else if (pdf) {
      const subName = getName(bid.owner)
      const gcName = getName(bid.client)
      const address = _.get(bid, 'projectAddress.name')
      const filename = _.snakeCase(`${subName} bid for ${gcName} at ${address}`) + '.pdf'
      return (
        <Card height='100%' width='large' customStyle='border-radius: 0px'>
          <CardBody overflow='scroll' wrap={false} background={colors.PALE_GREY}>
            <PdfPreview
              id={bid.id}
              url={bid.pdfUrl}
              name={filename}
              fileConfig={{
                showPageControls: false,
                dockPageControls: false,
                showAnnotationTools: false,
                showLeftHandPanel: false,
                defaultViewMode: 'FIT_WIDTH',
                enableFormFilling: false,
                showDownloadPDF: true,
                showPrintPDF: false,
                enableAnnotationAPIs: true
              }}
            />
          </CardBody>
        </Card>
      )
    }
    return (
      // <Card height='100%' width='large' customStyle='border-radius: 0px'>
      //   <CardHeader
      //     pad={{ left: 'medium', right: 'small', vertical: 'small' }}
      //     border={{
      //       color: 'border',
      //       size: '1px',
      //       side: 'bottom'
      //     }}
      //   >
      //     <Text size='xlarge' color={colors.BLACK} weight={600}>
      //       Preview
      //     </Text>
      //     <Button
      //       color={colors.DEEP_SKY_BLUE}
      //       label='Download'
      //       onClick={onDownloadClick}
      //       icon={
      //         <FontAwesomeIcon icon={isGeneratingPdf ? faSpinner : faPaperclip} spin={isGeneratingPdf} fontSize={18} />
      //       }
      //       disabled={isGeneratingPdf}
      //     />
      //   </CardHeader>
      //   <CardBody overflow='scroll' wrap={false} background={colors.PALE_GREY}>
      //     <Box fill justify='center' align='center' ref={wrapperRef}>
      //       <DocPanelEstimatePreview
      //         viewport={{ width: wrapperParams.width, height: wrapperParams.width * 2 }}
      //         doc={bid}
      //       />
      //     </Box>
      //   </CardBody>
      // </Card>
      <Card height='100%' width='large' customStyle='border-radius: 0px'>
        <CardHeader
          pad={{ left: 'medium', right: 'small', vertical: 'small' }}
          border={{
            color: 'border',
            size: '1px',
            side: 'bottom'
          }}
        >
          <Text size='xlarge' color={colors.BLACK} weight={600}>
            Preview
          </Text>
        </CardHeader>
        <CardBody overflow='scroll' wrap={false} background={colors.PALE_GREY}>
          <Box fill justify='center' align='center'>
            <Text>Preview of not submitted bids is not yet implemented</Text>
          </Box>
        </CardBody>
      </Card>
    )
  }

  const renderElement = (el, index) => {
    const name = _.get(el, 'name')
    const timestamp = _.has(el, 'createdAt') ? moment(_.get(el, 'createdAt')).fromNow() : ''
    const createdBy = _.get(el, 'createdBy')
    const accountName = _.get(profiles, [createdBy, 'name'])
    const main = _.get(el, 'main', false)
    const itemIndex = main ? 0 : index + 1
    const isActive = _.isEqual(activeIndex, itemIndex)
    const color = isActive ? colors.RIPTIDE10 : colors.WHITE
    return (
      <Box
        key={itemIndex}
        hoverIndicator
        flex={{ shrink: 0 }}
        background={color}
        onClick={() => handleNavigation(itemIndex)}
        pad='small'
        gap='xsmall'
        border={main ? 'horizontal' : 'bottom'}
        customStyle={`
          position: relative;
          .delete-button {
            display: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            right: 10px;
          }
          .delete-button:hover * {
            fill: ${colors.LIGHT_NAVY_BRIGHT}
          }
          :hover .delete-button {
            display: flex;
          }
        `}
      >
        <Box>
          <Text color={colors.TEXT_PRIMARY}>{name}</Text>
        </Box>
        <Box direction='row' gap='xsmall'>
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            uploaded {timestamp}
          </Text>
          {createdBy && (
            <Text size='small' color={colors.DARK_GREAY_TWO}>
              by {accountName}
            </Text>
          )}
        </Box>
        {!main && (
          <Tip message='Remove file'>
            <Box className='delete-button'>
              <Button
                color={colors.VERY_LIGHT_PINK}
                icon={<FontAwesomeIcon icon={faTrashAlt} fontSize={18} />}
                plain
                onClick={handleRemoveFile(el, isActive)}
              />
            </Box>
          </Tip>
        )}
      </Box>
    )
  }

  const renderFile = file => {
    return (
      <Tab key={file.id}>
        <Card height='100%' width='large' customStyle='border-radius: 0px'>
          <FilePreview file={file} />
        </Card>
      </Tab>
    )
  }

  const onBidFilesPicked = files => {
    const bid = _.get(bids, bidId)
    const bidFiles = _.get(bid, 'files', {})
    const newFiles = { ...bidFiles }
    _.forEach(files, f => _.set(newFiles, f.id, f))
    updateBidFiles(_.get(bid, 'id'), newFiles)
  }

  const bid = _.get(bids, bidId)
  const bidElement = {
    name: 'Bid document',
    createdAt: !_.isNil(_.get(bid, 'timestamp')) ? _.get(bid, 'timestamp').toMillis() : '',
    createdBy: _.get(bid, 'createdBy'),
    main: true
  }
  const bidFiles = _.orderBy(_.values(_.get(bid, 'files')), 'createdAt', 'desc')
  const workOrderId = _.get(bid, 'workOrderId')
  if (visible) {
    return (
      <Layer onEsc={close} onClickOutside={close} full='vertical' margin={{ vertical: 'small' }} animate={false}>
        <Box direction='row' fill>
          {_.size(bidFiles) > 0 && (
            <Box fill='vertical' width='250px' background={colors.PALE_GREY} overflow='auto'>
              <Box flex={false} pad='small' width='xxsmall' align='center'>
                <Button
                  plain
                  icon={<FontAwesomeIcon icon={faTimes} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />}
                  onClick={close}
                />
              </Box>
              <Box flex={{ shrink: 0 }}>{renderElement(bidElement)}</Box>
              <Box pad='small' flex={{ shrink: 0 }}>
                <Text>Attached files</Text>
              </Box>
              {_.map(bidFiles, this.renderElement)}
              <FilesPickerButton
                ButtonComponent={filePickerLabel}
                onComplete={onBidFilesPicked}
                storagePath={`/workOrders/${workOrderId}/bid/${bidId}`}
              />
            </Box>
          )}
          <Box fill='vertical'>
            <Tabs flex activeIndex={activeIndex} customStyle='> div:first-child { display: none }'>
              <Tab title='Bid document'>{renderBid()}</Tab>
              {_.map(bidFiles, renderFile)}
            </Tabs>
          </Box>
        </Box>
      </Layer>
    )
  } else {
    return null
  }
}

export default BidPreviewModal
