import React from 'react'
import { Box, Menu } from 'shared/components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import CellContainer from 'components/leveling/CellContainer'
import MessagesIcon from 'components/leveling/MessagesIcon'
import StatusPending from 'components/leveling/cellElements/StatusPending'

const PriceRequested = ({ onClick, removePriceRequest, selected, hasNewMessage, hasMessages, compact }) => {
  if (compact) {
    return (
      <CellContainer selected={selected} onClick={onClick} fill justify='between' direction='row'>
        <Box align='start' justify='between' direction='row'>
          <StatusPending />
        </Box>
        <Box direction='row' justify='center' align='center' gap='small'>
          {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} size={14} customStyle={null} pad='none' />}
          <Menu
            options={[{ id: '0', label: 'Cancel price request' }]}
            dropContentProps={{ boxProps: { width: { min: '220px' } } }}
            dropAlign={{ top: 'bottom', right: 'right' }}
            onOptionClick={removePriceRequest}
          >
            <Box pad={{ right: 'xsmall', top: 'xxsmall' }}>
              <FontAwesomeIcon icon={faAngleDown} fontSize={18} color={colors.LIGHT_NAVY_BRIGHT} />
            </Box>
          </Menu>
        </Box>
      </CellContainer>
    )
  }
  return (
    <CellContainer selected={selected} onClick={onClick}>
      <Box fill align='start' justify='between' direction='row'>
        <StatusPending />
        <Menu
          options={[{ id: '0', label: 'Cancel price request' }]}
          dropContentProps={{ boxProps: { width: { min: '220px' } } }}
          dropAlign={{ top: 'bottom', right: 'right' }}
          onOptionClick={removePriceRequest}
        >
          <Box pad={{ right: 'xsmall', top: 'xxsmall' }}>
            <FontAwesomeIcon icon={faAngleDown} fontSize={12} color={colors.LIGHT_NAVY_BRIGHT} />
          </Box>
        </Menu>
      </Box>
      {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} />}
    </CellContainer>
  )
}

PriceRequested.propTypes = {
  onClick: PropTypes.func,
  plug: PropTypes.object,
  removePriceRequest: PropTypes.func,
  selected: PropTypes.bool
}

export default PriceRequested
