import React from 'react'
import { Box } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faProjectDiagram, faAngleDown } from '@fortawesome/pro-light-svg-icons'
// import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { faProjectDiagram as faProjectDiagramSolid } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const AlternatesIcon = ({ active, compact }) => {
  const color = active ? colors.LIGHT_NAVY_BRIGHT : colors.DARK_GRAY_TWO
  return (
    <Box
      direction='row'
      fill='vertical'
      align='center'
      justify='start'
      flex={false}
      pad={{ top: 'xxsmall', right: 'xxsmall' }}
      gap='xsmall'
    >
      <FontAwesomeIcon icon={active ? faProjectDiagramSolid : faProjectDiagram} color={color} fontSize={12} />
      <FontAwesomeIcon icon={faAngleDown} color={color} fontSize={12} />
    </Box>
  )
}

export default React.memo(AlternatesIcon)
