import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RadioButton, Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleEmpty } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

export const Radio = ({ disabled, value, onChange, size, colorChecked, colorUnchecked, color }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const handleChange = e => {
    setChecked(!checked)
    onChange(e)
  }

  const fontSize = size === 'medium' ? 24 : 16
  return (
    <RadioButton name='radio button' checked={checked} disabled={disabled} readOnly>
      {({ checked }) => {
        if (checked) {
          return (
            <Box
              onClick={disabled ? () => null : handleChange}
              align='center'
              justify='center'
              flex={{ shrink: 0, grow: 0 }}
              disabled={disabled}
              width={`${fontSize}px`}
              height={`${fontSize}px`}
              customStyle={`
                position: relative;
                svg {
                  position: absolute;
                }
              `}
            >
              <FontAwesomeIcon icon={faCircleEmpty} fontSize={fontSize} color={colorChecked || color} />
              <FontAwesomeIcon icon={faCircle} fontSize={fontSize} color={colorChecked || color} transform='shrink-7' />
            </Box>
          )
        } else {
          return (
            <Box
              onClick={disabled ? () => null : handleChange}
              align='center'
              justify='center'
              width={`${fontSize}px`}
              height={`${fontSize}px`}
              customStyle={`
                position: relative;
                svg {
                  position: absolute;
                }
              `}
            >
              <FontAwesomeIcon icon={faCircleEmpty} fontSize={fontSize} color={colorUnchecked || color} />
            </Box>
          )
        }
      }}
    </RadioButton>
  )
}

Radio.defaultProps = {
  disabled: false,
  size: 'medium',
  colorChecked: null,
  colorUnchecked: null,
  color: colors.LIGHT_NAVY_BRIGHT,
  checked: false,
  onChange: () => null
}

Radio.propTypes = {
  /**
  True / False
  */
  disabled: PropTypes.bool,
  /**
  Current value
  */
  checked: PropTypes.bool.isRequired,
  /**
  Function that will be called when the user clicks the radio button. It will be passed a React event object. The current state can be accessed via event.target.checked.
  */
  onChange: PropTypes.func.isRequired,
  /**
  Small / medium
  */
  size: PropTypes.string,
  /**
  If set will change color of checked icon
  */
  colorChecked: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  /**
  If set will change color of unchecked icon
  */
  colorUnchecked: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  /**
  Color of icons (both checked & unchecked)
  */
  color: PropTypes.string
}

export default Radio
