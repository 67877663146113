import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'
import { Anchor, Box, Text } from 'shared/components'
import validator from 'validator'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import signInMode from 'constants/signInMode'
import AuthLayout from 'pages/auth/AuthLayout'
import localStorage from 'controllers/localStorage'

const Footer = () => {
  const navigate = useNavigate()
  return (
    <Box
      alignSelf='start'
      pad={{ top: 'medium' }}
      border={{ side: 'top', color: colors.VERY_LIGHT_GREY_TWO }}
      margin={{ top: 'medium' }}
      width='medium'
      direction='row'
      gap='xsmall'
      align='center'
    >
      <Text size='medium' color={colors.TEXT}>
        Already have an account?
      </Text>
      <Anchor
        label='Sign in'
        color={colors.LINK}
        onClick={() => navigate('/auth', { state: { mode: signInMode.SIGN_IN } })}
      />
    </Box>
  )
}

const EnterEmail = ({ mode }) => {
  const [email, setEmail] = useState('')
  const [isValid, setValid] = useState(false)
  const [isPrecon, setIsPrecon] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const checkPrecon = async () => {
      const preconConfId = await localStorage.get('preconConfId')
      if (!_.isEmpty(preconConfId)) {
        setIsPrecon(true)
      }
    }
    checkPrecon()
  }, [])

  const onSubmit = async () => {
    navigate('/auth', { state: { email, mode: signInMode.SIGN_UP } })
  }

  const onEmailChange = event => {
    const email = event.target.value
    const isValid = validator.isEmail(email)
    setEmail(email)
    setValid(isValid)
  }

  return (
    <AuthLayout
      header={isPrecon ? 'Get started with BidLevel' : 'Try BidLevel for free'}
      error={null}
      isError={false}
      valueName='Email'
      value={email}
      placeholder='name@company.com'
      handleChange={onEmailChange}
      handleSubmit={onSubmit}
      isValid={isValid}
      footer={<Footer />}
      submitName={isPrecon ? 'Continue' : 'Try for free'}
      subheader={
        isPrecon
          ? 'Create your Mason account in a few easy steps'
          : 'We will text you a verification code to complete your registration.'
      }
    />
  )
}

EnterEmail.defaultProps = {
  mode: signInMode.SIGN_IN
}

EnterEmail.propTypes = {
  mode: PropTypes.string
}

export default EnterEmail
