import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import App from 'src/App'
import config from 'shared/config'
import 'assets/fonts/lato.css'

if (!config.isDev) {
  Sentry.init({
    dsn: config.sentryHICWebPublicDSN,
    debug: true,
    enableInExpoDevelopment: false,
    release: config.internalVersion,
    environment: config.isDev ? 'dev' : 'prod'
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
