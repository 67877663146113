import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'shared/components'

import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons'

const LevelingPanelHeaderContainer = ({ onClose, children, align, skipCloseRender, border, ...rest }) => {
  return (
    <Box
      pad='small'
      direction='row'
      align={align || 'center'}
      justify='between'
      height={{ min: '60px', max: '100%' }}
      border={border || 'bottom'}
      gap='xsmall'
      flex={{ grow: 0 }}
      {...rest}
    >
      {children}
      {!skipCloseRender && (
        <Box onClick={onClose} margin={{ left: 'auto' }}>
          <FontAwesomeIcon icon={faArrowToRight} color={colors.ANOTHER_GREY} fontSize={20} />
        </Box>
      )}
    </Box>
  )
}

LevelingPanelHeaderContainer.defaultProps = {
  onClose: () => null
}

LevelingPanelHeaderContainer.propTypes = {
  onClose: PropTypes.func
}

export default LevelingPanelHeaderContainer
