import React, { useRef, useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { Box } from 'shared/components'
import { connect, useSelector } from 'react-redux'
import _ from 'lodash'
import { useParams, useNavigate } from 'react-router'

import colors from 'shared/constants/colors'
import LevelingTable from 'pages/leveling/LevelingTable'
// import BidInviteModal from 'webPages/BidInviteModal'
import { getWorkOrderWithTitle } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import { toTimestamp } from 'shared/utils/date'
import { fetchMessagesWeb } from 'controllers/chat'
// import CreateBidManually from 'webPages/createBid/CreateBidManually'
import FilesPanel from 'panels/FilesPanel'
// import CalendarSidepanel from 'components/CalendarSidepanel'
import { navigateToHomePage } from 'controllers/init'
import LevelingSubmenu from 'pages/leveling/LevelingSubmenu'
import levelingTableMode from 'constants/levelingTableMode'
import sendTypes from 'shared/constants/inviteStatus'
import EmptyState from 'pages/leveling/EmptyState'
import levelingTableSettings from 'constants/levelingTableSettings'
import LayoutContext from 'contexts/LayoutContext'
import LevelingDataProvider from 'contexts/LevelingDataProvider'

// TODO: add CreateBidManually modal

const Leveling = ({ bidsByWorkOrderAccount, account, cellOpen, cellOpenTimestamp, settings }) => {
  const navigate = useNavigate()
  const { projectId, workOrderId } = useParams()
  const workOrder = useSelector(state => getWorkOrderWithTitle(state, { workOrderId }))
  const [mode, setMode] = useState(levelingTableMode.SCOPE_OF_WORK)
  const { openBidInviteModal } = useContext(LayoutContext)
  const { declined } = settings
  // const [calendarOpen, setCalendarOpen] = useState(false)
  // const bidInviteModalRef = useRef()
  // const createBidManuallyRef = useRef()
  const filesPanelRef = useRef()
  const bidsByAccount = _.get(bidsByWorkOrderAccount, workOrderId)
  const accountId = _.get(account, 'id')
  const panelRef = useRef()

  useEffect(() => {
    if (!_.isNil(workOrderId)) fetchMessagesWeb(workOrderId, accountId)
  }, [workOrderId, accountId])

  useEffect(() => {
    if (_.isNil(workOrder)) navigateToHomePage(navigate)
  }, [workOrder])

  const openWorkOrderDetails = useCallback(() => {
    console.log('view trade')
    filesPanelRef.current.close()
    openBidInviteModal(projectId)
    // bidInviteModalRef.current.open({ projectId, workOrderId })
  }, [projectId, workOrderId])

  const openFilesModal = useCallback(() => {
    filesPanelRef.current.open(workOrderId)
  }, [workOrderId])

  const scope = _.get(workOrder, 'scope', {})

  const invitations = _.get(workOrder, 'invitations', {})

  const { levelingInvitations, delayedInvitations } = useMemo(() => {
    const levelingInvitations = []
    const delayedInvitations = []
    _.forEach(invitations, (inv, accId) => {
      const bid = _.get(bidsByAccount, accId)
      const isDeclined = _.isEqual(_.get(bid, 'status.type'), 'declined')
      if (!declined && isDeclined) return null
      const info = {
        id: accId,
        invitedAt: toTimestamp(inv.timestamp),
        bid,
        ...inv
      }
      if (_.has(workOrder, ['acceptedBy', accId])) {
        info.acceptedAt = toTimestamp(_.get(workOrder, ['acceptedBy', accId, 'timestamp']))
      }
      if (_.has(workOrder, ['declinedBy', accId])) {
        info.declinedBy = toTimestamp(_.get(workOrder, ['declinedBy', accId, 'timestamp']))
      }
      levelingInvitations.push(info)
      if (_.isEqual(_.get(inv, 'sendType', sendTypes.IMMEDIATELY), sendTypes.LATER)) {
        delayedInvitations.push(inv)
      }
    })
    return { levelingInvitations, delayedInvitations }
  }, [bidsByAccount, invitations])

  const sortedInvitations = useMemo(
    () =>
      _.orderBy(levelingInvitations, [
        info => {
          if (_.has(info, 'bid.timestamp')) {
            return toTimestamp(info.bid.timestamp) / 100
          } else if (_.has(info, 'acceptedAt')) {
            return toTimestamp(info.acceptedAt / 10)
          } else {
            return toTimestamp(info.invitedAt)
          }
        },
        'id'
      ]),
    [levelingInvitations]
  )

  console.log('sortedInvitations', sortedInvitations)

  const openCreateBidManuallyModal = subId => {
    console.log('openCreateBidManuallyModal, subId', subId)
    if (!_.isEmpty(subId)) {
      // createBidManuallyRef.current.open({ subId, workOrderId })
    }
  }

  const invitationsWithNoBids = _.reduce(
    sortedInvitations,
    (res, inv) => (_.isEmpty(inv.bid) ? [...res, inv.id] : res),
    []
  )

  const defaultSections = [
    {
      id: 'scope_of_work',
      items: _.keys(scope),
      title: 'Scope of Work'
    }
  ]

  const sections = _.isEmpty(_.get(workOrder, 'sections')) ? defaultSections : _.get(workOrder, 'sections')

  const settingsChanged = _.size(
    _.filter(settings, (v, name) => {
      if (_.includes([levelingTableSettings.SHOW_SECTIONS, levelingTableSettings.SHOW_DECLINED], name)) {
        return !v
      }
      return v
    })
  )

  const isEmptyState = _.size(scope) === 0 && _.size(sortedInvitations) === 0

  const renderTable = () => {
    if (isEmptyState) {
      return <EmptyState projectId={projectId} workOrderId={workOrderId} openFilesModal={openFilesModal} />
    } else {
      return (
        <LevelingTable
          key={workOrder.id}
          scope={scope}
          scopeSections={sections}
          invitations={sortedInvitations}
          workOrder={workOrder}
          openCreateBidManuallyModal={openCreateBidManuallyModal}
          cellOpen={cellOpen}
          cellOpenTimestamp={cellOpenTimestamp}
          panelRef={panelRef}
          mode={mode}
          setMode={setMode}
        />
      )
    }
  }

  if (_.isNil(workOrder)) {
    return null
  } else {
    return (
      <LevelingDataProvider>
        <Box background={colors.WHITE} flex>
          <LevelingSubmenu
            workOrderId={workOrderId}
            projectId={projectId}
            openFilesModal={openFilesModal}
            panelRef={panelRef}
            mode={mode}
            setMode={setMode}
            openWorkOrderDetails={openWorkOrderDetails}
            isArchived={_.get(workOrder, 'deleted', 0) > 0}
            delayedInvitations={delayedInvitations}
            accountId={accountId}
            invitations={sortedInvitations}
            invitationsWithNoBids={invitationsWithNoBids}
            openCreateBidManuallyModal={openCreateBidManuallyModal}
            settingsChanged={settingsChanged}
            isEmptyState={isEmptyState}
          />
          {renderTable()}
          {/* <BidInviteModal ref={bidInviteModalRef} /> */}
          {/* <CreateBidManually ref={createBidManuallyRef} /> */}
          <FilesPanel ref={filesPanelRef} />
          {/* <CalendarSidepanel open={calendarOpen} setOpen={setCalendarOpen} type='gc' /> */}
        </Box>
      </LevelingDataProvider>
    )
  }
}

const mapStateToProps = (state, props) => ({
  workOrder: getWorkOrderWithTitle(state, props),
  bidsByWorkOrderAccount: getBidsByWorkOrdersAccounts(state),
  channelWeb: state.channelWeb,
  user: state.user,
  account: state.account,
  settings: state.customizeSettings
})

export default connect(mapStateToProps)(Leveling)
