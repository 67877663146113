const AND = 'and'
const OR = 'or'

const list = [AND, OR]

export default {
  AND,
  OR,
  list
}
