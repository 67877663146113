import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, DropButton, Button } from 'shared/components'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeMerge } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown, faCheck } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'
import numeral from 'numeral'

import LineItemPlug from 'components/leveling/LineItemPlug'
import colors from 'shared/constants/colors'
import ItemPriceRow from 'components/leveling/ItemPriceRow'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import CellContainer from 'components/leveling/CellContainer'
import StatusExcluded from 'components/leveling/cellElements/StatusExcluded'
import StatusIncluded from 'components/leveling/cellElements/StatusIncluded'
import StatusPending from 'components/leveling/cellElements/StatusPending'
import ItemTotal from 'components/leveling/cellElements/ItemTotal'
import MessagesIcon from 'components/leveling/MessagesIcon'
import AlternatesIcon from 'components/leveling/cellElements/AlternatesIcon'

const StyledText = styled(Text)`
  line-height: 1;
`
class LineItemRegularCell extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dropOpen: false
    }
  }

  openDrop = () => this.setState({ dropOpen: true })
  handleCloseDrop = () => this.setState({ dropOpen: false })

  onAlternateClick = (altId, e) => {
    e.stopPropagation()
    console.log('onAlternateSelected', altId)
    const { onAlternateSelected, inv, item } = this.props
    this.handleCloseDrop()
    onAlternateSelected(inv.id, item.id, altId)
  }

  handleUndoMergeClick = event => {
    event.stopPropagation()
    this.handleCloseDrop()
    const { item, lineItemBid } = this.props
    const mergedItem = _.get(lineItemBid, 'mergedItem', {})
    const itemId = _.get(item, 'id')
    this.props.undoMerge(itemId, mergedItem.id)
  }

  renderOption = (name, quantity, quantityType, cost, total, altId) => {
    const { selectedAlternate } = this.props
    const selected = altId === selectedAlternate

    const checkmarkInCircle = (
      <Box
        flex={false}
        align='center'
        justify='center'
        background={colors.AQUA_MARINE}
        round='full'
        width='20px'
        height='20px'
      >
        <FontAwesomeIcon icon={faCheck} color={colors.WHITE} fontSize={12} />
      </Box>
    )

    return (
      <Box
        key={altId}
        pad={{ horizontal: 'small', vertical: 'xsmall' }}
        round='none'
        onClick={e => this.onAlternateClick(altId, e)}
        hoverIndicator
        border={{ side: 'bottom' }}
      >
        <Box direction='row' align='center' gap='xsmall'>
          {selected && checkmarkInCircle}
          <StyledText size='small'>{name}</StyledText>
        </Box>
        <Box direction='row' align='center' justify='between' pad={{ top: 'xsmall' }}>
          <ItemPriceRow quantity={quantity} cost={cost} quantityType={quantityType} total={total} />
        </Box>
      </Box>
    )
  }

  renderDropContent = () => {
    const { lineItemBid, scope, item } = this.props
    const itemId = _.get(item, 'id')
    return (
      <Box
        pad={{ top: 'xsmall', bottom: 'small' }} width='240px' className={IGNORE_OUTSIDE_CLICK_CLASS}
      >
        <Box border={{ side: 'bottom' }}>
          <Text margin={{ vertical: 'small', left: 'small' }} color={colors.ANOTHER_GREY}>
            Select alternate:
          </Text>
        </Box>
        {this.renderOption(
          _.get(scope, [itemId, 'name']),
          _.get(lineItemBid, 'quantity', _.get(scope, [itemId, 'quantity'])),
          _.get(lineItemBid, 'quantityType', _.get(scope, [itemId, 'quantityType'])),
          _.get(lineItemBid, 'cost', 0),
          _.get(lineItemBid, 'total'),
          null
        )}
        {_.map(lineItemBid.alternates, (alternate, altId) => {
          const name = _.get(alternate, 'name')
          const quantity = _.get(alternate, 'quantity', 1)
          const quantityType = _.get(alternate, 'quantityType')
          const cost = _.get(alternate, 'cost', 1)
          const total = _.get(alternate, 'total')
          return this.renderOption(name, quantity, quantityType, cost, total, altId)
        })}
      </Box>
    )
  }

  renderMergeDropContent = () => {
    const { lineItemBid } = this.props
    const mergedItem = _.get(lineItemBid, 'mergedItem', {})
    const name = _.get(mergedItem, 'name')
    const quantity = _.get(mergedItem, 'quantity', 1)
    const quantityType = _.get(mergedItem, 'quantityType')
    const cost = _.get(mergedItem, 'cost', 1)
    const total = _.get(mergedItem, 'total')
    // console.log('drop content', mergedItem, 'total', total)
    return (
      <Box
        pad={{ top: 'xsmall', bottom: 'small' }} width='240px' className={IGNORE_OUTSIDE_CLICK_CLASS}
      >
        <Box>
          <Text margin={{ vertical: 'small', left: 'small' }} color={colors.ANOTHER_GREY}>
            Merged line item:
          </Text>
        </Box>
        <Box pad={{ horizontal: 'small', vertical: 'small' }} round='none'>
          <Box direction='row' align='center' gap='xsmall' pad={{ bottom: 'xsmall' }}>
            <StyledText size='small' color={colors.LIGHT_NAVY_BRIGHT}>
              {name}
            </StyledText>
          </Box>
          <Box direction='row' align='center' justify='between' pad={{ top: 'xsmall' }}>
            <ItemPriceRow quantity={quantity} cost={cost} quantityType={quantityType} total={total} />
          </Box>
        </Box>
        <Box direction='row' justify='center' pad={{ top: 'medium', bottom: 'small' }}>
          <Button
            primary
            label='Undo merge'
            color={colors.LIGHT_NAVY_BRIGHT}
            width='auto'
            onClick={this.handleUndoMergeClick}
          />
        </Box>
      </Box>
    )
  }

  handleDropButtonClick = event => {
    event.stopPropagation()
    this.openDrop()
  }

  renderItemAction = isValuesShown => {
    const { dropOpen } = this.state
    const { lineItemBid, inv, item, selectedAlternate, compact, id: idObject } = this.props
    const id = _.get(idObject, 'record')
    const mergedItem = _.get(lineItemBid, 'mergedItem', [])
    const alternates = _.get(lineItemBid, 'alternates')
    if (!_.isEmpty(mergedItem)) {
      const itemId = _.get(item, 'id')
      const altItem = _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate])
      const color = _.isNil(altItem) ? colors.DARK_GRAY_TWO : colors.LIGHT_NAVY_BRIGHT
      const label = (
        <Box direction='row' align='center'>
          <FontAwesomeIcon icon={faCodeMerge} color={color} fontSize={12} />
          {!isValuesShown && !compact && (
            <Text margin={{ horizontal: 'xsmall' }} size='small' color={color} weight={400}>
              Merged
            </Text>
          )}
          <FontAwesomeIcon icon={faChevronDown} color={color} fontSize={12} />
        </Box>
      )
      return (
        <DropButton
          id={`${id}_drop`}
          plain
          onClose={this.handleCloseDrop}
          open={dropOpen}
          label={label}
          dropAlign={{ top: 'bottom', left: 'right' }}
          dropProps={{ style: { overflow: 'visible' } }}
          dropContent={this.renderMergeDropContent()}
          onClick={this.handleDropButtonClick}
        />
      )
    } else if (!_.isEmpty(alternates)) {
      const itemId = _.get(item, 'id')
      const altItem = _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate])
      const label = <AlternatesIcon compact={compact} active={!_.isNil(altItem)} />
      return (
        <DropButton
          id={`${id}_drop`}
          plain
          onClose={this.handleCloseDrop}
          open={dropOpen}
          label={label}
          dropAlign={{ top: 'bottom', left: 'right' }}
          dropProps={{ style: { overflow: 'visible' } }}
          dropContent={this.renderDropContent()}
          onClick={this.handleDropButtonClick}
        />
      )
    }
  }

  renderTotal = () => {
    const { item, inv, selectedAlternate, lineItemBid } = this.props
    const mergedItem = _.get(lineItemBid, 'mergedItem', {})
    const itemId = _.get(item, 'id')
    const altItem = _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate])
    // console.log('altItem', altItem, inv.bid)
    // const altInfo = _.get(scope, [itemId, 'alternates', selectedAlternate])
    const total = _.get(mergedItem, 'total', _.get(altItem, 'total', _.get(lineItemBid, 'total', 0)))
    // console.log('renderTotal, mergedItem', mergedItem, 'total', total)
    const excluded = _.get(lineItemBid, 'excluded', false)
    if (excluded) {
      return <StatusExcluded />
    } else if (total > 0) {
      return <ItemTotal total={total} />
    } else if (_.isNil(lineItemBid)) {
      return <StatusPending />
    } else {
      return <StatusIncluded />
    }
  }

  renderTopLeft = () => {}

  renderAdditionalData = () => {
    const { item, inv, selectedAlternate, lineItemBid, showValues } = this.props
    const mergedItem = _.get(lineItemBid, 'mergedItem', {})
    const itemId = _.get(item, 'id')
    const altItem = _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate])
    const excluded = _.get(lineItemBid, 'excluded', false)
    const cost = _.get(mergedItem, 'cost', altItem ? _.get(altItem, 'cost', null) : _.get(lineItemBid, 'cost', null))
    const quantity = _.get(
      mergedItem,
      'quantity',
      altItem ? _.get(altItem, 'quantity', null) : _.get(lineItemBid, 'quantity', null)
    )
    const quantityType = _.get(
      mergedItem,
      'quantityType',
      altItem ? _.get(altItem, 'quantityType', null) : _.get(lineItemBid, 'quantityType', null)
    )
    if (excluded || !showValues || (_.isNil(cost) && _.isNil(quantity))) {
      return null
    } else {
      return (
        <Box
          justify='start'
          align='end'
          width='xsmall'
          direction='row'
          gap='small'
          fill
          pad={{ horizontal: 'xxsmall' }}
        >
          <Box direction='row' gap='xxsmall'>
            <Text size='small' color={colors.BROWN_GREY_TWO}>
              {!_.isNil(quantity) ? `${numeral(quantity).format('0,0.[00]')}` : ''}
            </Text>
            <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
              {!_.isNil(quantity) ? `${quantityType ? ` ${quantityType}` : ' units'}` : ''}
            </Text>
          </Box>
          <Box direction='row' gap='xxsmall'>
            <Text size='small' color={colors.BROWN_GREY_TWO}>
              {!_.isNil(cost) ? `${numeral(cost).format('$0,0.[00]')}` : ''}
            </Text>
            <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
              {!_.isNil(cost) ? `${quantityType ? ` / ${quantityType}` : ' / unit'}` : ''}
            </Text>
          </Box>
        </Box>
      )
    }
  }

  handleContainerClick = event => {
    const { onClick } = this.props
    console.log('--------- on container click')
    onClick()
  }

  render () {
    const {
      item,
      inv,
      selectedAlternate,
      plug,
      selected,
      lineItemBid,
      hasNewMessage,
      hasMessages,
      showValues,
      compact
    } = this.props
    const plugVisible = _.get(lineItemBid, 'excluded', false)
    const itemId = _.get(item, 'id')

    const altItem = _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate])
    const mergedItem = _.get(lineItemBid, 'mergedItem', {})
    const excluded = _.get(lineItemBid, 'excluded', false)
    const cost = _.get(mergedItem, 'total', _.get(altItem, 'total', _.get(lineItemBid, 'cost', null)))
    const quantity = _.get(mergedItem, 'total', _.get(altItem, 'total', _.get(lineItemBid, 'quantity', null)))
    const isValuesShown = showValues && !excluded && (!_.isNil(cost) || !_.isNil(quantity))
    if (compact) {
      return (
        <CellContainer selected={selected} onClick={this.handleContainerClick} direction='row' justify='between'>
          <Box flex={{ shrink: 0 }}>{this.renderTotal()}</Box>
          <Box direction='row' align='center' gap='xsmall'>
            {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} size={14} pad='none' customStyle={null} />}
            {plugVisible && !_.isNil(plug) ? <LineItemPlug plug={plug} compact={compact} /> : <Box />}
            {this.renderItemAction(isValuesShown)}
          </Box>
        </CellContainer>
      )
    }
    return (
      <CellContainer selected={selected} onClick={this.handleContainerClick}>
        <Box flex={{ shrink: 0 }} direction='row' justify='start'>
          {this.renderTotal()}
          <Box margin={{ left: 'auto' }}>{this.renderItemAction(isValuesShown)}</Box>
        </Box>
        {this.renderAdditionalData()}
        <Box direction='row' align='center' justify='between' pad={{ left: 'xxsmall', bottom: 'xxsmall' }}>
          {plugVisible ? <LineItemPlug plug={plug} /> : <Box />}
        </Box>
        {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} />}
      </CellContainer>
    )
  }
}

LineItemRegularCell.defaultProps = {
  selectedAlternate: null
}

LineItemRegularCell.propTypes = {
  lineItemBid: PropTypes.object,
  item: PropTypes.object,
  scope: PropTypes.object,
  activeCell: PropTypes.string,
  setActiveCell: PropTypes.func,
  inv: PropTypes.object,
  onAlternateSelected: PropTypes.func,
  selectedAlternate: PropTypes.string,
  selected: PropTypes.bool,
  undoMerge: PropTypes.func,
  workOrderId: PropTypes.string
}

const mapStateToProps = (state, props) => ({
  accountsProfiles: state.accountsProfiles
})

export default connect(mapStateToProps)(LineItemRegularCell)
