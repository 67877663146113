import React, { useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'shared/components'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import numeral from 'numeral'
import { css } from 'styled-components'

import colors from 'shared/constants/colors'
import { updatePlugs, removePlug } from 'controllers/privateWorkOrder'
import LevelingPanelHeaderContainer from 'panels/levelingPanel/LevelingPanelHeaderContainer'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import { getName } from 'shared/utils/stringUtils'
import CurrencyInput from 'shared/components/CurrencyInput'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import { updateSeenStatus } from 'controllers/chat'
import { MessagesList, renderHeader as renderCommentsHeader } from 'panels/levelingPanel/Comments'
import { getLevelingTableMessages } from 'model/selectors/channels'
import InputWithSelect from 'shared/components/InputWithSelect'
import CellInfoPanelFooter from '../cellInfoPanelContent/CellInfoPanelFooter'
import { useParams } from 'react-router'

const CellInfoPanelContent = ({ subId, itemId, onClose }) => {
  const messagesEndRef = useRef()
  const { workOrderId } = useParams()
  const workOrder = useSelector(state => getWorkOrder(state, { workOrderId }))
  const messages = useSelector(state => _.get(getLevelingTableMessages(state), ['cells', subId, itemId], []))
  const profiles = useSelector(state => state.profiles)
  const accountProfile = useSelector(state => _.get(state.accountsProfiles, subId, {}))
  const bid = useSelector(state => _.get(getBidsByWorkOrdersAccounts(state), [workOrderId, subId]))
  const [lastMessageId, setLastMessageId] = useState(_.get(_.last(messages), 'id'))
  const [cost, setCost] = useState(_.get(workOrder, ['plugs', itemId, subId, 'cost'], null))

  useEffect(() => {
    const lastMessage = _.last(messages)
    if (lastMessage && lastMessage.id !== lastMessageId) {
      const channelKey = `${workOrderId}_${subId}_${itemId}`
      updateSeenStatus(channelKey, lastMessage)
      setLastMessageId(lastMessage.id)
    }
  }, [_.last(messages)])

  const updatePlug = params => {
    const plug = _.get(workOrder, ['plugs', itemId, subId])
    const newPlug = _.omitBy({ ...plug, ...params }, _.isUndefined)
    console.log('updatePlug, newPlug', newPlug)
    if (_.isEmpty(_.omitBy(newPlug, _.isNil))) {
      console.log('the plug is empty, delete it')
      removePlug(workOrderId, itemId, subId)
    } else {
      updatePlugs(workOrderId, itemId, subId, newPlug)
    }
  }

  const onQuantityCostChange = (quantity, cost) => {
    if (!_.isNil(quantity) && !_.isNil(cost)) {
      const total = _.floor(quantity * cost, 2)
      updatePlug({ quantity, cost, total })
    } else {
      updatePlug({
        quantity,
        cost
      })
    }
  }

  const plug = useMemo(() => _.get(workOrder, ['plugs', itemId, subId]), [workOrder, itemId, subId])

  const handleQuantityChange = v => {
    onQuantityCostChange(v, _.get(plug, 'cost'))
  }

  const handleQuantityTypeChange = event => {
    updatePlug({ quantityType: event.value })
  }

  const handleCostChange = e => {
    const value = e.target.value
    const c = _.size(value) > 0 ? numeral(value).format('0,0.[00]') : null
    setCost(c)
  }

  const handleCostSubmit = () => {
    const value = _.size(cost) > 0 ? numeral(cost).value() : null
    onQuantityCostChange(_.get(plug, 'quantity'), value)
  }

  // const handleResetPlug = () => {
  //   removePlug(workOrderId, itemId, subId)
  // }

  const handleTotalChange = v => {
    console.log('handleTotalChange', v)
    const quantity = _.get(plug, 'quantity')
    console.log('handleTotalChange, quantity', quantity)
    if (!_.isNil(v)) {
      if (!_.isNil(quantity) && quantity > 0) {
        const newCost = _.ceil(v / quantity, 2)
        const newTotal = newCost * quantity
        updatePlug({ total: newTotal, cost: newCost })
      } else {
        updatePlug({ total: v, quantity: null, cost: null })
      }
    } else {
      updatePlug({ total: null, cost: null, quantity: null })
    }
  }

  const renderHeader = () => {
    // console.log('bid', bid, 'itemId', itemId)
    const itemName = _.get(bid, ['items', itemId, 'name'], _.get(workOrder, ['scope', itemId, 'name']))
    return (
      <LevelingPanelHeaderContainer onClose={onClose}>
        <Box gap='xxsmall' flex>
          <Text size='large' color={colors.TEXT_PRIMARY} weight={800}>
            {itemName}
          </Text>
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            {getName(accountProfile)}
          </Text>
        </Box>
      </LevelingPanelHeaderContainer>
    )
  }

  const renderTotalRow = () => {
    const total = _.get(plug, 'total')
    return (
      <Box direction='row' align='start' justify='center'>
        <CurrencyInput placeholder='Total' value={total} onChange={handleTotalChange} size='small' />
      </Box>
    )
  }

  const renderQuantityRow = () => {
    const value = _.get(plug, 'quantity')
    return (
      <Box direction='row' align='start' justify='center'>
        <CurrencyInput
          placeholder='Quantity'
          value={value}
          onChange={handleQuantityChange}
          size='small'
          formatString='0,0.[00]'
        />
      </Box>
    )
  }

  const getItem = () => {
    const selectedAlternates = _.get(workOrder, 'alternatesConf', {})
    const altItemId = _.get(selectedAlternates, [subId, itemId])
    const altInfo = _.get(bid, ['items', itemId, 'alternates', altItemId])
    let item = _.get(bid, ['items', itemId])
    if (!_.isEmpty(altInfo)) item = altInfo
    return item
  }

  const renderCostRow = () => {
    const item = getItem()
    const options = ['sf', 'cy', 'lf', 'ls', 'ea', 'cf', 'lbs', 'ft']
    const quantityType = _.get(plug, 'quantityType', _.get(item, 'quantityType'))
    // const value = _.get(plug, 'cost')
    return (
      <Box direction='row' align='start' justify='center' className={IGNORE_OUTSIDE_CLICK_CLASS}>
        <InputWithSelect
          onFocus={() => null}
          onChange={handleCostChange}
          onBlur={handleCostSubmit}
          onSelectChange={handleQuantityTypeChange}
          value={cost}
          selectValue={quantityType}
          options={options}
          placeholder='Cost / Unit ($)'
          inputRef={null}
          id='plug-cost'
          dropProps={{ className: IGNORE_OUTSIDE_CLICK_CLASS }}
          alwaysShow
          controlProps={{ border: { color: colors.VERY_LIGHT_GREY_TWO, radius: '4px' } }}
          textInputProps={{
            extend: css`
              text-align: start;
            `
          }}
        />
      </Box>
    )
  }

  const renderComments = () => {
    return (
      <Box
        justify='end'
        background={colors.PALE_GREY}
        className={IGNORE_OUTSIDE_CLICK_CLASS}
        fill
        overflow='auto'
        customStyle='{ scroll-behavior: smooth }'
      >
        <Box justify='end' flex>
          <MessagesList workOrder={workOrder} messages={messages} messagesEndRef={messagesEndRef} profiles={profiles} />
        </Box>
      </Box>
    )
  }

  const renderFooter = () => {
    return <CellInfoPanelFooter subId={subId} messagesEndRef={messagesEndRef} itemId={itemId} />
  }

  const renderItemDetails = () => {
    let item = _.get(bid, ['items', itemId], null)
    const isExcluded = _.get(item, 'excluded', false)
    if (isExcluded) {
      return (
        <Box pad='small' flex={{ shrink: 0 }} height='small'>
          <Box align='start' justify='center' pad={{ vertical: 'small' }}>
            <Text color={colors.MEDIUM_GREY} size='medium'>
              Add internal Plug
            </Text>
          </Box>
          <Box direction='row' gap='xxsmall'>
            {renderQuantityRow()}
            {renderCostRow()}
            {renderTotalRow()}
          </Box>
        </Box>
      )
    } else if (_.isNil(_.get(item, 'answer'))) {
      if (_.isNil(item)) {
        const merging = _.get(workOrder, 'merging')
        const mergingIndex = _.indexOf(_.values(merging), itemId)
        if (mergingIndex >= 0) {
          const mergingId = _.keys(merging)[mergingIndex]
          item = _.get(bid, ['items', mergingId], null)
        }
      }
      const cost = _.get(item, 'cost')
      const total = _.get(item, 'total')
      const quantity = _.get(item, 'quantity')
      const quantityType = _.get(item, 'quantityType')
      return (
        <Box
          pad={{ left: 'medium', right: 'large' }}
          gap='medium'
          flex={{ shrink: 0 }}
          margin={{ bottom: 'large', top: 'medium' }}
        >
          <Box direction='row' justify='between'>
            <Text color={colors.MEDIUM_GREY}>Total</Text>
            <Box direction='row' width='small'>
              <Box width='xsmall'>
                <Text textAlign='end' color={colors.TEXT_PRIMARY}>
                  {`${!_.isNil(total) ? numeral(total).format('$0,0.[00]') : ''}`}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box direction='row' justify='between'>
            <Text color={colors.MEDIUM_GREY}>Quantity</Text>
            <Box direction='row' width='small' justify='between'>
              <Box width='xsmall'>
                <Text textAlign='end' color={colors.TEXT_PRIMARY}>
                  {quantity}
                </Text>
              </Box>
              <Box>
                <Text color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
                  {quantityType}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box direction='row' justify='between'>
            <Text color={colors.MEDIUM_GREY}>Cost / unit</Text>
            <Box direction='row' width='small'>
              <Box width='xsmall'>
                <Text textAlign='end' color={colors.TEXT_PRIMARY}>
                  {`${!_.isNil(cost) ? numeral(cost).format('$0,0.[00]') : ''}`}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box fill height='100%' flex customStyle='* { transition: all 0s ease; }'>
      {renderHeader()}
      {renderItemDetails()}
      {renderCommentsHeader(getName(accountProfile), colors.PALE_GREY)}
      {renderComments()}
      {renderFooter()}
    </Box>
  )
}

CellInfoPanelContent.propTypes = {
  onClose: PropTypes.func,
  subId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired
}

export default CellInfoPanelContent
