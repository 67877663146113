import React from 'react'
import { Box, Text } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import colors from 'shared/constants/colors'

const AddProjectRow = ({ onAddProjectClick }) => {
  const onClick = () => onAddProjectClick()
  return (
    <Box direction='row' height='54px' align='center' onClick={onClick} hoverIndicator={colors.ALMOST_WHITE}>
      <Box width='50px' direction='row' align='center' justify='center' margin={{ right: 'xsmall' }}>
        <Box
          width='32px'
          height='32px'
          border={{ style: 'dashed', color: colors.ANOTHER_GREY }}
          align='center'
          justify='center'
          round='xxsmall'
        >
          <FontAwesomeIcon icon={faPlus} color={colors.ANOTHER_GREY} fontSize={12} />
        </Box>
      </Box>
      <Text color={colors.ANOTHER_GREY}>New Project</Text>
    </Box>
  )
}

export default AddProjectRow
