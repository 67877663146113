import _ from 'lodash'
import * as Sentry from '@sentry/react'
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  serverTimestamp,
  setDoc,
  getDocs,
  orderBy
} from '@firebase/firestore'

import { db } from 'controllers/db'

export const saveNewNote = async note => {
  console.log('saveNewNote', note)
  const text = _.get(note, 'text')
  if (_.isEmpty(_.toString(text))) {
    console.warn('the message is empty', note)
    return null
  }
  try {
    const ref = doc(db, `notes/${note.workOrderId}/messagesList/${note.id}`)
    setDoc(ref, { ...note, timestamp: serverTimestamp() })
    await db
      .collection('notes')
      .doc(note.workOrderId)
      .collection('messagesList')
      .doc(note.id)
      .set()
  } catch (e) {
    console.log('save note error', e)
    Sentry.captureException(e)
  }
}

export const fetchSubNotes = (accountId, workOrderId, subId, callback) => {
  // console.log('fetchSubNotes', accountId, workOrderId, subId)
  const q = query(
    collection(db, 'notes', workOrderId, 'messagesList'),
    orderBy('timestamp'),
    where('accountId', '==', accountId),
    where('subId', '==', subId)
  )
  const unsubscribe = onSnapshot(q, sn => {
    const notes = _.map(sn.docs, doc => doc.data())
    console.log('onNotes received', notes)
    callback(notes)
  })
  return unsubscribe
}

export const fetchSubsNotes = async (accountId, workOrderId, ids) => {
  // console.log('fetchSubsNotes', accountId, workOrderId, ids)
  const res = {}
  for (const subId of ids) {
    const q = query(
      collection(db, 'notes', workOrderId, 'messagesList'),
      where('accountId', '==', accountId),
      where('subId', '==', subId),
      orderBy('timestamp')
    )
    const sn = await getDocs(q)
    _.set(res, subId, !sn.empty)
  }
  return res
}
