import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import { Main, Box, Text, CheckBox } from 'shared/components'
import { defaultConf, components, groups } from 'pages/scopeBuilder/sampleData'
import linesOfWork from 'pages/scopeBuilder/linesOfWork'
// import { generateId } from 'controllers/db'
import colors from 'shared/constants/colors'
import { getTradesDict } from 'model/selectors/references'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus } from '@fortawesome/pro-regular-svg-icons'

const ScopeBuilder = () => {
  const [conf] = useState({ ...defaultConf })
  const [selectedCompId, setSelectedCompId] = useState()
  const contractorTypes = useSelector(getTradesDict)

  const menuConf = useMemo(() => {
    const res = {}
    _.forEach(conf, (activitiesIds, compId) => {
      const comp = components[compId]
      const gId = comp.group
      if (!_.has(res, gId)) res[gId] = []
      res[gId].push(compId)
    })
    return res
  }, [conf, components])

  const availableLinesOfWork = useMemo(() => {
    const res = []
    _.forEach(linesOfWork, (l, lId) => {
      if (_.includes(l.appliedTo, selectedCompId)) res.push(lId)
    })
    return res
  }, [selectedCompId])
  // const [ scope, setScope ] = useState({...})
  // const [currentCategories, setCurrentCategories] = useState(
  //   _.map(houseConf, c => ({ id: generateId(), kind: c, title: zonesDict[c] }))
  // )

  // const menuOptions = _.map(zonesDict, (title, cId) => ({ id: cId, label: title }))

  // const onMenuOptionClick = id => {
  //   console.log('onMenuOptionClick', id)
  //   const curAmount = _.size(_.filter(currentCategories, c => c.kind === id))
  //   const newCat = {
  //     id: generateId(),
  //     kind: id,
  //     title: zonesDict[id] + (curAmount > 0 ? ` ${curAmount + 1}` : '')
  //   }
  //   setCurrentCategories([...currentCategories, newCat])
  // }

  // const renderTypeOfWorkRow = (defaultOption, towId) => {
  //   console.log('renderTypeOfWorkRow', towId, defaultOption)
  //   const tow = _.get(typesOfWork, towId)
  //   return (
  //     <Box key={towId}>
  //       <Box direction='row' align='center' gap='xsmall'>
  //         <CheckBox checked size='small' />
  //         <Text>{tow.title}</Text>
  //       </Box>
  //     </Box>
  //   )
  // }

  const renderConstructionComponent = () => {
    const comp = components[selectedCompId]
    const activitiesIds = _.get(conf, selectedCompId)
    // const selectedCategory = _.find(currentCategories, c => c.id === selectedCategoryId)
    // console.log('selectedCategory', selectedCategory)
    // const targets = _.get(deafultTargetsByZone, _.get(selectedCategory, 'kind'))
    // console.log('targets', targets)
    return (
      <Box fill pad='medium'>
        <Text size='large' weight={800}>
          {_.get(comp, 'title')}
        </Text>
        <Text weight={800} color={colors.ANOTHER_GREY}>
          Lines of work
        </Text>
        <Box pad={{ top: 'medium' }} gap='small'>
          {_.map(availableLinesOfWork, actId => {
            const act = linesOfWork[actId]
            return (
              <Box key={actId} gap='xsmall' direction='row'>
                <CheckBox checked={_.includes(activitiesIds, actId)} size='small' />
                <Text weight={700}>
                  {act.title}{' '}
                  <Text weight={400} size='small'>
                    ({contractorTypes[act.trade].name})
                  </Text>
                </Text>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }

  return (
    <Main direction='row'>
      <Box pad='medium' gap='small' border={{ side: 'right', color: colors.ASANA_GRAY_BORDER }} flex={{ shrink: 0 }}>
        {_.map(menuConf, (comps, gId) => {
          return (
            <Box key={gId} flex={{ shrink: 0 }}>
              <Text weight={800} size='large'>
                {groups[gId]}
              </Text>
              {_.map(comps, cId => (
                <Box
                  key={cId}
                  onClick={() => setSelectedCompId(cId)}
                  hoverIndicator
                  background={{ color: colors.AQUA_MARINE, opacity: selectedCompId === cId ? 0.25 : 0 }}
                  pad='small'
                >
                  <Text>{components[cId].title}</Text>
                </Box>
              ))}
            </Box>
          )
        })}
        {/* <Menu
          options={menuOptions}
          dropContentProps={{ boxProps: { height: { max: '400px' } } }}
          onOptionClick={onMenuOptionClick}
        >
          <Box direction='row' align='center' gap='xsmall'>
            <FontAwesomeIcon icon={faPlus} />
            <Text>Add</Text>
          </Box>
        </Menu> */}
      </Box>
      {renderConstructionComponent()}
    </Main>
  )
}

export default ScopeBuilder
