import React, { useContext } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCloudDownload as faCloudDownloadAltLight } from '@fortawesome/pro-light-svg-icons'
import { faCloudDownloadAlt as faCloudDownloadAltSolid } from '@fortawesome/pro-solid-svg-icons'
import { List, Text, Box, Button } from 'shared/components'
import moment from 'moment'

import colors from 'shared/constants/colors'
import { toTimestamp, stringDiff } from 'shared/utils/date'
import FilesPickerButton from 'components/FilesPickerButton'
import { deleteFile } from 'controllers/storage'
import { downloadFile } from 'controllers/workOrder'
import LayoutContext from 'src/contexts/LayoutContext'

const ProjectFiles = ({ files, setFiles, storagePath, hideTime, hideDownload, pickerButtonProps }) => {
  const { openToast } = useContext(LayoutContext)

  const onDeleteFilePress = fId => () => {
    const p = `${storagePath}/${fId}`
    deleteFile(p)
    const newFiles = { ...files }
    _.unset(newFiles, fId)
    setFiles(newFiles)
  }

  const renderTrashButton = f => {
    return (
      <Button color={colors.VERY_LIGHT_PINK} hoverColor={colors.LIGHT_NAVY_BRIGHT} onClick={onDeleteFilePress(f.id)}>
        <FontAwesomeIcon icon={faTrashAlt} fontSize={16} />
      </Button>
    )
  }

  const handleDownload = async f => {
    const res = await downloadFile(f.url, f.name)
    if (!res) {
      openToast(
        <Box background={colors.CORAL_TWO} justify='center' align='center' pad='small' round='small'>
          <Text color={colors.TEXT}>Error: file not found</Text>
        </Box>
      )
    }
  }

  const renderDownloadButton = f => {
    return (
      <Box
        onClick={() => handleDownload(f)}
        customStyle={`
          > svg + svg,
          :hover > svg {
            display: none;
          }
          :hover > svg + svg {
            display: inherit;
          }
        `}
      >
        <FontAwesomeIcon icon={faCloudDownloadAltLight} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />
        <FontAwesomeIcon icon={faCloudDownloadAltSolid} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />
      </Box>
    )
  }

  const renderFileRow = (f, index) => {
    return (
      <Box direction='row' align='center' key={index}>
        <Box width='360px'>
          <Text size='small' color='#243659'>
            {f.name}
          </Text>
        </Box>
        {!hideTime && (
          <Box width='180px' margin={{ left: 'medium' }}>
            <Text size='small' color={colors.VERY_LIGHT_PINK}>
              {`uploaded ${stringDiff(moment(), moment(f.createdAt))}`}
            </Text>
          </Box>
        )}
        <Box width='80px' margin={{ left: 'medium' }}>
          <Text size='small'>{numeral(f.size).format('0.0 b')}</Text>
        </Box>
        {!hideDownload && <Box margin={{ left: 'medium' }}>{renderDownloadButton(f)}</Box>}
        <Box margin={{ left: 'medium' }}>{renderTrashButton(f)}</Box>
      </Box>
    )
  }

  const onFilesPicked = newFiles => {
    console.log('onFilesPicked', newFiles)
    setFiles({
      ...files,
      ..._.keyBy(newFiles, 'id')
    })
  }

  const data = _.values(files)
  const sortedData = _.sortBy(data, f => toTimestamp(_.get(f, 'createdAt')))

  return (
    <>
      <List margin={{ top: 'small' }} pad='small' data={sortedData}>
        {(f, index) => renderFileRow(f, index)}
      </List>
      <FilesPickerButton onComplete={onFilesPicked} storagePath={storagePath} {...pickerButtonProps} />
    </>
  )
}

ProjectFiles.propTypes = {
  projectId: PropTypes.string,
  setFiles: PropTypes.func,
  files: PropTypes.object,
  hideTime: PropTypes.bool,
  hideDownload: PropTypes.bool,
  pickerButtonProps: PropTypes.object
}

export default ProjectFiles
