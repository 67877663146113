import React from 'react'
import PropTypes from 'prop-types'

import { Menu, Text } from 'shared/components'
import filterOperators from 'shared/constants/filterOperators'

const OperatorButton = ({ operator, setOperator, ...rest }) => {
  const onMenuOptionClick = newOp => {
    if (newOp !== operator) {
      setOperator(newOp)
    }
  }

  return (
    <Menu
      options={_.map(filterOperators.list, op => ({ id: op, label: op }))}
      dropContentProps={{ boxProps: { width: { min: '160px' } } }}
      dropAlign={{ top: 'bottom', left: 'left' }}
      onOptionClick={onMenuOptionClick}
      selectedId={operator}
      {...rest}
    >
      <Text weight={600}>{operator}</Text>
    </Menu>
  )
}

OperatorButton.propTypes = {
  operator: PropTypes.oneOf(filterOperators.list),
  setOperator: PropTypes.func
}

export default OperatorButton
