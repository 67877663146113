import React, { useState, useImperativeHandle, useRef } from 'react'
import { Layer, Text, Button, Box, ModalHeader } from 'shared/components'
import _ from 'lodash'

import colors from 'shared/constants/colors'

const ConfirmationModal = ({ modalRef }) => {
  const [show, setShow] = useState(false)
  const funcRef = useRef()
  const [title, setTitle] = useState()
  const [buttonsProps, setButtonsProps] = useState({})

  const close = () => setShow(false)

  const open = (title, onConfirm, onCancel = () => null, confirmButtonProps = {}, cancelButtonProps = {}) => {
    setTitle(title)
    setButtonsProps({
      confirm: confirmButtonProps,
      cancel: cancelButtonProps
    })
    funcRef.current = {
      onConfirm,
      onCancel
    }
    setShow(true)
  }

  useImperativeHandle(modalRef, () => ({
    open,
    close
  }))

  const onConfirmClick = () => {
    funcRef.current.onConfirm()
    close()
  }

  const onCancelClick = () => {
    funcRef.current.onCancel()
    close()
  }

  if (show) {
    return (
      <Layer onClose={onCancelClick}>
        <Box width='medium'>
          <ModalHeader title='' onClose={onCancelClick} hideBorder />
          <Text
            size='large'
            margin={{ top: 'medium', bottom: 'medium', horizontal: 'medium' }}
            color={colors.LIGHT_NAVY_BRIGHT}
            textAlign='center'
          >
            {title}
          </Text>
          <Box direction='row' align='center' justify='between' pad='medium'>
            <Button label='Cancel' secondary onClick={onCancelClick} {..._.get(buttonsProps, 'cancel', {})} />
            <Button label='Confirm' primary onClick={onConfirmClick} {..._.get(buttonsProps, 'confirm', {})} />
          </Box>
        </Box>
      </Layer>
    )
  } else {
    return null
  }
}

export default ConfirmationModal
