import React, { useContext, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faFileImport, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Box, Button, Text } from 'shared/components'
import colors from 'shared/constants/colors'
import LayoutContext from 'contexts/LayoutContext'
import ContactsSideMenuItem from 'pages/contacts/contactsSideMenu/ContactsSideMenuItem'
import contactsPageMode from 'constants/contactsPageMode'
import { getContactsTableInfo } from 'model/selectors/contacts'
import { deleteContactsGroup } from 'controllers/contacts'

const ContactsSideMenu = () => {
  const navigate = useNavigate()
  const { openImportContactsModal, setSideMenuOpen, openRenameContactsGroupModal, openConfirmationModal } = useContext(
    LayoutContext
  )
  // const accountContacts = useSelector(state => state.contacts)
  const { tableData, groups } = useSelector(getContactsTableInfo)
  const [searchParams, setSearchParams] = useSearchParams()
  // const contacts = _.get(accountContacts, 'contacts')
  // const companies = _.get(accountContacts, 'companies')

  // console.log('groups amount', _.size(groups))
  const sortedGroups = _.orderBy(groups, ['timestamp'], ['asc'])

  useEffect(() => setSideMenuOpen(false), [])

  const mode = searchParams.get('mode') || contactsPageMode.CONTACTS
  const groupId = searchParams.get('groupId')

  const header = (
    <Box
      direction='row'
      align='center'
      gap='xsmall'
      pad={{ vertical: 'small' }}
      margin={{ vertical: 'xxsmall' }}
      flex={{ shrink: 0 }}
    >
      <Button
        icon={<FontAwesomeIcon icon={faChevronLeft} fixedWidth />}
        size='medium'
        onClick={() => navigate('/')}
        noHover
      />
      <Text weight={600} size='large'>
        Contacts
      </Text>
    </Box>
  )

  const footer = (
    <Box border={{ side: 'top' }} direction='row' pad='small' flex={{ shrink: 0 }}>
      <Button label='Import' icon={<FontAwesomeIcon icon={faFileImport} />} onClick={openImportContactsModal} />
    </Box>
  )

  const mainMenuItems = (
    <Box
      pad={{ top: 'medium', bottom: 'xxsmall' }}
      border={{ side: 'bottom', color: colors.VERY_LIGHT_GREY_TWO }}
      flex={{ shrink: 0 }}
    >
      <ContactsSideMenuItem
        title='All contacts'
        amount={_.toString(_.size(tableData))}
        isSelected={mode === contactsPageMode.CONTACTS}
        onClick={() => setSearchParams({ mode: contactsPageMode.CONTACTS })}
      />
    </Box>
  )

  const groupsItems = useMemo(() => {
    if (_.isEmpty(groups)) {
      return (
        <Text margin={{ top: 'small' }} color={colors.ANOTHER_GREY} textAlign='center'>
          no groups yet
        </Text>
      )
    } else {
      return _.map(sortedGroups, g => {
        const onConfirm = () => {
          deleteContactsGroup(g.id)
          setSearchParams({ mode: contactsPageMode.CONTACTS })
        }

        const onDelete = () => {
          openConfirmationModal(
            'Are you sure you want to remove this group of contacts? No contacts will be deleted.',
            onConfirm,
            () => null,
            { label: 'Remove' }
          )
        }
        return (
          <ContactsSideMenuItem
            key={g.id}
            title={g.name}
            amount={_.toString(_.size(g.rows))}
            isSelected={mode === contactsPageMode.GROUP && groupId === g.id}
            onClick={() => setSearchParams({ mode: contactsPageMode.GROUP, groupId: g.id })}
            canEdit
            onRename={() => openRenameContactsGroupModal(groupId, g.name)}
            onDelete={onDelete}
          />
        )
      })
    }
  }, [groups, groupId])

  return (
    <Box width='280px' background={{ color: colors.PALE_GREY, opacity: 0.28 }} fill='vertical' flex={{ shrink: 0 }}>
      {header}
      <Box direction='row' pad='small' flex={{ shrink: 0 }}>
        <Button primary label='Add contact' color={colors.AQUA_MARINE} icon={<FontAwesomeIcon icon={faPlus} />} />
      </Box>
      <Box flex={{ shrink: 0 }}>{mainMenuItems}</Box>
      <Box fill pad={{ vertical: 'xxsmall' }}>
        {groupsItems}
      </Box>
      {footer}
    </Box>
  )
}

export default ContactsSideMenu
