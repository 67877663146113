import React, { useEffect } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import config from 'shared/config'
import _ from 'lodash'

const divId = 'pdf-file-editor'
const urls = {
  bidLevel: ['https://app.dev.bidlevel.co', 'https://app.bidlevel.co'],
  useMason: ['https://app.dev.usemason.com', 'https://app.usemason.com'],
  storybook: ['https://storybook.dev.bidlevel.co']
}

const previewFileConf = {
  showPageControls: true,
  dockPageControls: true,
  showAnnotationTools: false,
  showLeftHandPanel: true,
  defaultViewMode: 'FIT_WIDTH',
  enableFormFilling: false,
  showDownloadPDF: true,
  showPrintPDF: false,
  enableAnnotationAPIs: true
}

const PdfPreview = ({ id, url, name, fileConfig }) => {
  const getClientId = () => {
    const url = window.location.origin
    if (_.includes(urls.bidLevel, url)) {
      return config.adobeClientIdBidlevel
    } else if (_.includes(urls.useMason, url)) {
      return config.adobeClientIdMason
    } else if (_.includes(urls.storybook, url)) {
      return config.adobeClientIdStorybook
    } else {
      return '8c0cd670273d451cbc9b351b11d22318'
    }
  }
  useEffect(() => {
    const run = async () => {
      const adobeDCView = new window.AdobeDC.View({
        clientId: getClientId(),
        divId
      })
      await adobeDCView.previewFile(
        {
          content: {
            location: {
              url
            }
          },
          metaData: {
            fileName: name,
            id
          }
        },
        fileConfig || previewFileConf
      )
    }
    run()
  }, [])
  return <Box fill background='lightgrey' id={divId} />
}

PdfPreview.propTypes = {
  /**
  File ID
  */
  id: PropTypes.string.isRequired,
  /**
  File url
  */
  url: PropTypes.string.isRequired,
  /**
  File name
  */
  name: PropTypes.string.isRequired,
  /**
  Config for PDF Embed API
  */
  fileConfig: PropTypes.object
}

export default PdfPreview

export { PdfPreview }
