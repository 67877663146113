import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TextInput, Keyboard } from 'grommet'
import numeral from 'numeral'

export const CurrencyInput = ({ value, onFocus, onBlur, onChange, placeholder, formatString, inputRef, ...rest }) => {
  const [v, setV] = useState(null)
  const _inputRef = useRef()

  const ref = _.isNil(inputRef) ? _inputRef : inputRef

  const handleOnChange = event => {
    const valueRaw = event.target.value.replace(/[^0-9.,$]/g, '')
    const newValue = valueRaw.replace(/[^0-9.,]/g, '')
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(v).value()
      if (newV === curV || _.isNil(v)) {
        setV(valueRaw)
      } else {
        setV(numeral(newValue).format(formatString))
      }
    } else {
      setV('')
    }
  }

  let curValue = value
  if (!_.isEmpty(_.toString(curValue))) {
    curValue = numeral(curValue).format(formatString)
  }
  if (!_.isNil(v)) curValue = v
  if (_.isNil(curValue)) curValue = ''

  const handleOnBlur = () => {
    onBlur()
    if (!_.isNil(v)) {
      const cost = _.size(v) > 0 ? numeral(v).value() : null
      const finalValue = onChange(cost)
      if (!_.isNil(finalValue)) {
        setV(numeral(finalValue).format(formatString))
      } else {
        setV(null)
      }
    }
  }

  const onEnter = () => {
    ref.current.blur()
  }

  return (
    <Keyboard onEnter={onEnter}>
      <TextInput
        value={curValue}
        alignSelf='center'
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    </Keyboard>
  )
}

CurrencyInput.defaultProps = {
  placeholder: '',
  onFocus: () => null,
  onBlur: () => null,
  onChange: () => null,
  formatString: '$0,0.[00]',
  disabled: false
}

CurrencyInput.propTypes = {
  /**
  True / False
  */
  disabled: PropTypes.bool,
  /**
  What number to put in the input.
  */
  value: PropTypes.number,
  /**
  Function that will be called when the user when the user sets focus on input.
  */
  onFocus: PropTypes.func,
  /**
  Function that will be called when input loses focus.
  */
  onBlur: PropTypes.func,
  /**
  Function that will be called when the user types in input.
  */
  onChange: PropTypes.func,
  /**
  Placeholder to use when no value is provided.
  */
  placeholder: PropTypes.string
}

export default CurrencyInput
