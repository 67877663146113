import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box, Text } from 'shared/components'
import filterConditions from 'shared/constants/filterConditions'
import filterOperators from 'shared/constants/filterOperators'
import FilterDropButton from 'shared/components/filter/FilterDropButton'
import colors from 'shared/constants/colors'
import OperatorButton from 'shared/components/filter/OperatorButton'

export const Filter = ({ conf, filters, setFilters, operator, setOperator }) => {
  const confDict = _.keyBy(conf, 'fieldKey')
  const filtersAmount = _.size(filters)

  const saveFilter = (f, i) => {
    console.log('saveFilter', f)
    const filterIsValid = f && !_.isNil(f.fieldKey) && !_.isNil(f.cond) && !_.isEmpty(_.toString(f.value))
    if (_.isNil(i) && filterIsValid) {
      const newFilters = [...filters, f]
      setFilters(newFilters)
    } else if (_.isNil(f)) {
      const newFilters = [...filters]
      newFilters.splice(i, 1)
      setFilters(newFilters)
    } else if (filterIsValid) {
      const newFilters = [...filters]
      newFilters[i] = f
      setFilters(newFilters)
    }
  }

  return (
    <Box direction='row' align='center' wrap flex={{ shrink: 0 }}>
      {_.map(filters, (f, i) => {
        const needOperator = filtersAmount > 1 && i < filtersAmount - 1
        const fieldKey = _.get(f, 'fieldKey')
        const cond = _.get(f, 'cond')
        const value = _.get(f, 'value')
        const label = (
          <Text color={colors.ASANA_GRAY_TEXT_HOVERED}>
            <Text weight={600}>{_.get(confDict, [fieldKey, 'fieldLabel'])}</Text>
            {` ${filterConditions.conditionTitles[cond]} ${value}`}
          </Text>
        )
        return (
          <Fragment key={i}>
            <FilterDropButton
              key={i}
              confDict={confDict}
              saveFilter={saveFilter}
              index={i}
              fieldKey={fieldKey}
              selectedCond={cond}
              selectedCondValue={value}
              label={label}
              primary
              color={colors.VERY_LIGHT_PINK_TWO}
              size='small'
              icon={undefined}
              margin={{ right: 'small', top: 'xsmall' }}
            />
            {needOperator && (
              <OperatorButton
                key={`op_${i}`}
                operator={operator}
                setOperator={setOperator}
                margin={{ right: 'small', top: 'xsmall' }}
              />
            )}
          </Fragment>
        )
      })}
      <FilterDropButton key='new' confDict={confDict} saveFilter={saveFilter} margin={{ top: 'xsmall' }} />
    </Box>
  )
}

const fieldConf = PropTypes.shape({
  fieldType: PropTypes.oneOf(['number', 'string', 'array', 'enum', 'bool', 'date']).isRequired,
  fieldKey: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldOptions: PropTypes.arrayOf(PropTypes.string)
})

const filter = PropTypes.shape({
  fieldKey: PropTypes.string.isRequired,
  cond: PropTypes.oneOf(filterConditions.conditionsList),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
})

Filter.defaultProps = {
  conf: [],
  filters: [],
  setFilters: () => null,
  operator: filterOperators.AND,
  setOperator: () => null
}

Filter.propTypes = {
  /** array if objects, each object describes a field */
  conf: PropTypes.arrayOf(fieldConf),
  filters: PropTypes.arrayOf(filter),
  setFiltes: PropTypes.func,
  operator: PropTypes.oneOf([filterOperators.AND, filterOperators.OR]),
  setOperator: PropTypes.func
}

export default Filter
