import React, { useState, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

// import colors from 'shared/constants/colors'
import { Layer, ModalHeader, Box, Text } from 'shared/components'

const EditContactModal = ({ modalRef }) => {
  const [show, setShow] = useState(false)
  const [contactId, setContactId] = useState()
  const contact = useSelector(state => _.get(state, ['contacts', 'contacts', contactId]))
  const close = () => setShow(false)

  const open = cId => {
    setContactId(cId)
    setShow(true)
  }

  useImperativeHandle(modalRef, () => ({
    open
  }))

  if (show) {
    return (
      <Layer>
        <Box width='medium'>
          <ModalHeader title='Edit Contact' onClose={close} />
          <Text>{_.get(contact, 'name')}</Text>
        </Box>
      </Layer>
    )
  } else {
    return null
  }
}

export default EditContactModal
