import { db } from 'controllers/db'
import { doc, onSnapshot, getDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'

import { receiveReferences, receiveContractorTypes } from 'model/actions/referencesAC'
import store from 'model/store'
import { addListener } from 'controllers/listeners'

export const fetchReferences = async () => {
  try {
    console.log('fetchReferences')

    const referencesUnsubscribe = onSnapshot(
      doc(db, 'appSettings', 'references'),
      referencesSN => {
        const references = referencesSN.data()
        // TODO: is this still needed?
        // if (isUserShouldUpdateApp(references)) {
        //   isOldVersion = true
        //   universalNavigation.navigate(screens.UPDATE_APP)
        // }
        store.dispatch(receiveReferences(references))
      },
      err => {
        console.log('fetchReferences error', err)
        Sentry.captureMessage('fetchReferences error')
        Sentry.captureException(err)
      }
    )
    addListener('references', referencesUnsubscribe)

    const contractorTypesUnsubscribe = onSnapshot(
      doc(db, 'appSettings', 'contractorTypes'),
      sn => {
        store.dispatch(receiveContractorTypes(sn.data()))
      },
      err => {
        console.log('fetch contractorTypes error', err)
        Sentry.captureMessage('fetch contractorTypes error')
        Sentry.captureException(err)
      }
    )
    addListener('contractorTypes', contractorTypesUnsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchReferences error:', e.message)
  }
}

export async function userIdByPhone (phone) {
  const ref = doc(db, 'uidByPhone', phone)
  const uidByPhoneSN = await getDoc(ref)
  if (uidByPhoneSN.exists) {
    const uidByPhone = uidByPhoneSN.data()
    return uidByPhone.uid
  } else {
    return null
  }
}
