import React from 'react'
import { Box } from 'shared/components'

const NavBarContainer = ({ children, ...props }) => (
  <Box
    flex={false}
    direction='row'
    justify='between'
    align='center'
    height={{ min: '60px' }}
    // pad={{ right: 'small', left: 'xxsmall', vertical: 'none' }}
    border={props.mobile ? '' : 'bottom'}
    {...props}
  >
    {children}
  </Box>
)

export default NavBarContainer
