import * as Sentry from '@sentry/react'
import { doc, onSnapshot, setDoc, updateDoc, deleteField } from '@firebase/firestore'
import _ from 'lodash'

import { addListener } from 'controllers/listeners'
import { receiveContacts } from 'model/actions/contactsAC'
import { db, generateId } from 'controllers/db'
import store from 'model/store'

export const fetchContacts = accountId => {
  const unsubscribe = onSnapshot(
    doc(db, 'accountsContacts', accountId),
    contactsSN => {
      store.dispatch(receiveContacts(contactsSN.data() || {}))
    },
    err => {
      console.log('fetchContacts onSnapshot error: ', err)
      Sentry.captureException(err)
    }
  )
  addListener('contacts', unsubscribe)
}

const initContacts = async accountId => {
  const ref = doc(db, 'accountsContacts', accountId)
  await setDoc(ref, { contacts: {}, companies: {} }, { merge: true })
}

export const updateAccountContact = async p => {
  const state = store.getState()
  const isSet = _.isEmpty(state.contacts)
  const accountId = _.get(state, 'account.id')
  if (isSet) {
    await initContacts(accountId)
  }
  const upd = {
    [`contacts.${p.id}`]: p
  }
  const ref = doc(db, 'accountsContacts', accountId)
  console.log('update account contacts', upd)
  await updateDoc(ref, upd)
}

export const deleteAccountContact = contactId => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`contacts.${contactId}`]: deleteField()
  }
  updateDoc(doc(db, 'accountsContacts', accountId), upd)
}

export const updateAccountContactCompany = async (companyId, params) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const isSet = _.isEmpty(state.contacts)
  params = _.omitBy(params, _.isNil)
  if (!_.isEmpty(params)) {
    if (isSet) {
      await initContacts(accountId)
    }
    const upd = {
      [`companies.${companyId}`]: params
    }
    updateDoc(doc(db, 'accountsContacts', accountId), upd)
  }
}

export const addNewContacts = async (companies, contacts) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    companies: { ..._.get(state, 'contacts.companies', {}), ...companies },
    contacts: { ..._.get(state, 'contacts.contacts', {}), ...contacts }
  }
  console.log('addNewContacts', upd)
  const ref = doc(db, 'accountsContacts', accountId)
  await setDoc(ref, upd)
}

export const createContactsGroup = async (name, filters, operator) => {
  const groupId = generateId()
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`groups.${groupId}`]: {
      id: groupId,
      name,
      filters,
      operator,
      timestamp: _.now()
    }
  }
  const ref = doc(db, 'accountsContacts', accountId)
  updateDoc(ref, upd)
  return groupId
}

export const updateContactsGroup = async (groupId, filters, operator) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`groups.${groupId}.filters`]: filters,
    [`groups.${groupId}.operator`]: operator
  }
  const ref = doc(db, 'accountsContacts', accountId)
  await updateDoc(ref, upd)
}

export const renameContactsGroup = async (groupId, name) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`groups.${groupId}.name`]: name
  }
  const ref = doc(db, 'accountsContacts', accountId)
  await updateDoc(ref, upd)
}

export const deleteContactsGroup = async groupId => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`groups.${groupId}`]: deleteField()
  }
  const ref = doc(db, 'accountsContacts', accountId)
  await updateDoc(ref, upd)
}
