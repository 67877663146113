import React, { useContext } from 'react'
import { Outlet } from 'react-router'
import { Box, Main, ResponsiveContext } from 'shared/components'

import SideBar from 'pages/layout/SideBar'
import LayoutDataProvider from 'src/contexts/LayoutDataProvider'
import SideBarWrapper from 'pages/layout/SideBarWrapper'
import { scrollBarStyleOnWhite } from 'constants/index'

const Layout = ({ params }) => {
  const windowSize = useContext(ResponsiveContext)
  const isMobile = windowSize === 'small'
  return (
    <LayoutDataProvider>
      <Main
        pad='none'
        direction='row'
        width={isMobile ? { min: 'small', max: 'full' } : { min: 'medium', max: 'full' }}
        customStyle={scrollBarStyleOnWhite}
      >
        <SideBarWrapper>
          <SideBar params={params} />
        </SideBarWrapper>
        <Box width={isMobile ? { min: 'small', max: 'full' } : { min: 'medium', max: 'full' }} alignSelf='stretch' flex>
          <Outlet />
        </Box>
      </Main>
    </LayoutDataProvider>
  )
}

export default Layout
