import { combineReducers } from 'redux'
import references from 'model/reducers/references'
import user from 'model/reducers/user'
import account from 'model/reducers/account'
import projects from 'model/reducers/projects'
import workOrders from 'model/reducers/workOrders'
import messages from 'model/reducers/messages'
import profiles from 'model/reducers/profiles'
import accountsProfiles from 'model/reducers/accountsProfiles'
import channelWeb from 'model/reducers/channelWeb'
import bids from 'model/reducers/bids'
import outgoingInvitations from 'model/reducers/outgoingInvitations'
import incomingInvitations from 'model/reducers/incomingInvitations'
import authData from 'model/reducers/authData'
import messagesWeb from 'model/reducers/messagesWeb'
import inbox from 'model/reducers/inbox'
import contacts from 'model/reducers/contacts'
import settings from 'model/reducers/settings'
import customizeSettings from 'model/reducers/customizeSettings'
import masonAdmins from 'model/reducers/masonAdmins'
import importedLi from 'model/reducers/importedLi'
import privateWorkOrders from 'model/reducers/privateWorkOrders'

export default combineReducers({
  projects,
  references,
  user,
  account,
  workOrders,
  messages,
  profiles,
  accountsProfiles,
  bids,
  outgoingInvitations,
  incomingInvitations,
  authData,
  channelWeb,
  messagesWeb,
  inbox,
  contacts,
  settings,
  customizeSettings,
  masonAdmins,
  importedLi,
  privateWorkOrders
})
