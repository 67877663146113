export const STATUS_BAR = 'rgb(0, 115, 247)'
export const BLACK = '#000000'
export const BLACK05 = 'rgba(0, 0, 0, 0.05)'
export const BLACK10 = 'rgba(0, 0, 0, 0.1)'
export const BLACK20 = 'rgba(0, 0, 0, 0.2)'
export const BLACK25 = 'rgba(0, 0, 0, 0.25)'
export const BLACK30 = 'rgba(0, 0, 0, 0.3)'
export const BLACK40 = 'rgba(0, 0, 0, 0.4)'
export const BLACK70 = 'rgba(0, 0, 0, 0.7)'
export const BLACK80 = 'rgba(0, 0, 0, 0.8)'
export const BLACK90 = 'rgba(0, 0, 0, 0.9)'

export const WHITE = '#ffffff'
export const WHITE25 = 'rgba(255, 255, 255, 0.25)'
export const WHITE10 = 'rgba(255, 255, 255, 0.1)'

// FRIDAY COLORS
export const AQUA_MARINE10 = '#3cd0b032'
export const AQUA_MARINE30 = '#3cd0b04c'
export const AQUA_MARINE50 = '#3cd0b07f'
export const LIGHT_NAVY = '#18446F'
export const LIGHT_NAVY20 = 'rgba(28, 64, 111, 0.2)'
export const MIDNIGHT_EXPRESS = '#212B36'
export const LIGHT_NAVY40 = '#18446f60'
export const LIGHT_NAVY50 = '#18446f50'
export const DUSK = '#535f7f'
export const DUSK_TWO = '#3b4664'
export const SEAFOAM_BLUE = '#83cbca'
export const PALE_GREY = '#ecf2fa'
export const ALMOST_BLACK = '#0d0e10'
export const MUSHROOM = '#a6a5a5'
export const NAVBAR_FONT = LIGHT_NAVY
export const BROWN_GREY = '#979797'
export const STRAWBERRY = '#ea2d3f'
export const SUNFLOWER_YELLOW = '#ffd700'
export const DARK_BLUE_GREY = '#1b1b4e'
export const LIGHT_GREY_BLUE = '#aeb3c0'
export const SEAFOAM_BLUE_TWO = '#61d1ab'
export const RED_PINK = '#f42850'

// MASON COLORS:
export const ICE_BLUE = '#ecf3f9'
export const BATTLESHIP_GREY = '#637381'
export const BLUEY_GREY = '#919eab'
export const CORAL = '#ff4d46'
export const CORAL_TWO = '#eb5757'
export const CORAL_TWO_HOVER = '#cf4949'
export const CORAL_TWO10 = '#fdeeed'
export const ORANGE = '#f49342'
export const STEEL = '#747e8d'
export const LIGHT_PERIWINKLE = '#d4dbe3'
export const DEEP_SKY_BLUE = '#0082FA'
export const AQUA_MARINE = '#3cd0b0'
export const AQUA_MARINE_HOVER = '#1BC29E'
export const DARK_SKY_BLUE = '#4a90e2'
export const NICE_BLUE = '#1259c4'
export const DARK = '#212b36'
export const DARK_GRAY = '#4d4d4d'
export const APPLE_GREEN = '#7ed321'
export const LIGHT_GREEN = '#50b83c'
export const LIGHT_BLUE_GREY = '#c4cdd5'
export const MANGO = '#f6a93b'
export const PALE_BLUE = '#dfe3e8'
export const SILVER_SAND = '#BBBBBB'
export const ANOTHER_GREY = '#AAAAAA'
export const PALE_LILAC = '#e5e6ea'
export const LIGHT_NAVY_BRIGHT = '#004069'
export const LIGHT_NAVY_HOVER = '#043C60'
export const LIGHT_NAVY_BRIGHT50 = '#0040697f'
export const LIGHT_NAVY_GREY = '#cdced2'
export const WHITE_GREY = '#f9f9f9'
export const GRAY80 = '#cccccc'
export const RED = '#d0021b'
export const COOL_GREY = '#a3a9af'
export const GREENBLUE = '#20cc82'
export const BROWN_GREY_TWO = '#828282'
export const VERY_LIGHT_PINK = '#bdbdbd'
export const TEALFISH = '#3cd0b0'
export const VERY_LIGHT_PINK_TWO = '#f2f2f2'
export const CLEAR_BLUE = '#2f80ed'
export const CLEAR_BLUE_HOVER = '#2364bb'
export const TEALISH = 'rgba(60, 208, 176, 0.17)'
export const VERY_LIGHT_GREY = '#CBD4DB'
export const VERY_LIGHT_GREY_TWO = '#EEEEEE'
export const VERY_LIGHT_GREY_THREE = '#E8ECEE'
export const VERY_LIGHT_GREY_FOUR = '#E0E0E0'
export const VERY_LIGHT_GREY_TWO50 = '#E9E9E97F'
export const LIGHT_NAVY_DARK = '#003456'
export const PINK = '#FFDCDC'
export const AS_SCROLL_GREY = '#9ca6af'
export const ASANA_GRAY_BACKGROUND = '#f6f6f6'
export const ASANA_GRAY_BORDER = '#cbd4db'
export const ASANA_GRAY_TEXT_HOVERED = '#6f7782'
export const ASANA_TEXT = '#9ca6af'
export const CONTACT_LABEL = '#444444'
export const HEADING = '#33475B'

// WEB COLORS:
export const TWILIGHT_BLUE = '#084e8a'
export const LIGHT_GREY = '#f9fafb'

export const LIGHT_NAVY_L2 = '#244e73' // polaris #6f7bcb
export const LIGHT_NAVY_L1 = '#1c4770' // polaris #6774c8
export const LIGHT_NAVY_D1 = '#123e6c' // polaris #5c6ac4
export const LIGHT_NAVY_D2 = '#103769' // polaris #5563c1
export const LIGHT_NAVY_D3 = '#0c2d65' // polaris #4959bd
export const LIGHT_NAVY_D4 = '#082356' // polaris #3f4eae
export const LIGHT_NAVY_D5 = '#061c4c' // polaris #38469b
export const LIGHT_NAVY_D6 = '#041238' // polaris #202e78

export const BLACK_TR = 'rgba(22, 29, 37, 0.05)'
export const PALE_GREEN = '#eff7ed'
export const PALE_RED = '#fdf3f0'

export const DISABLED = LIGHT_BLUE_GREY
export const TEXT = '#333333'
export const TEXT_PRIMARY = '#243659'
export const TABLE_HOVER = 'rgba(213, 214, 215, 0.29)'
// export const TABLE_HOVER_SOLID = '#e9ecee'
export const TABLE_HOVER_SOLID = '#f6f6f6'
export const AQUA_MARINE_LIGHT = 'rgba(132, 228, 208, 0.15)'
export const DARK_GRAY_TWO = '#4F4F4F'
export const LIGHT_BLUE = 'rgba(97, 180, 233, 0.3)'
export const LINK = '#1565D8'
export const RIPTIDE = '#84E4D0'
export const RIPTIDE10 = 'rgba(132, 228, 208, 0.1)'

export const MEDIUM_GREY = '#6F7782'
export const ALMOST_WHITE = '#F7F9FA'
export const BADGE_BG = '#D5D6D7'
export const BOULDER = '#797979'
export const MASON_NAVY_100 = '#E8F6FF'
export const MASON_NAVY_200 = '#B1CEE0'
export const MASON_NAVY_300 = '#8EB4CD'
export const MASON_NAVY_400 = '#719AB4'
export const MASON_NAVY_500 = '#55819D'
export const MASON_NAVY_600 = '#396582'
export const MASON_NAVY_700 = '#244E69'
export const MASON_NAVY_800 = '#1B415A'
export const MASON_NAVY_900 = '#0A283A'
export const RED_100 = 'rgba(235, 87, 87, 0.1)'
export const RED_200 = 'rgba(235, 87, 87, 0.2)'
export const RED_300 = 'rgba(235, 87, 87, 0.3)'
export const RED_400 = 'rgba(235, 87, 87, 0.4)'
export const RED_500 = 'rgba(235, 87, 87, 0.5)'
export const RED_600 = 'rgba(235, 87, 87, 0.6)'
export const RED_700 = 'rgba(235, 87, 87, 0.7)'
export const RED_800 = 'rgba(235, 87, 87, 0.8)'
export const RED_900 = 'rgba(235, 87, 87, 0.9)'
export const YELLOW = '#F2C94C'
export const BRIGHT_RED = '#ED1C24'

export default {
  STATUS_BAR,
  BLACK,
  BLACK05,
  BLACK10,
  BLACK20,
  BLACK25,
  BLACK30,
  BLACK40,
  BLACK70,
  BLACK80,
  BLACK90,
  WHITE,
  WHITE25,
  AQUA_MARINE10,
  AQUA_MARINE30,
  AQUA_MARINE50,
  LIGHT_NAVY,
  LIGHT_NAVY20,
  MIDNIGHT_EXPRESS,
  LIGHT_NAVY40,
  DUSK,
  DUSK_TWO,
  SEAFOAM_BLUE,
  PALE_GREY,
  ALMOST_BLACK,
  MUSHROOM,
  NAVBAR_FONT,
  BROWN_GREY,
  STRAWBERRY,
  SUNFLOWER_YELLOW,
  DARK_BLUE_GREY,
  LIGHT_GREY_BLUE,
  SEAFOAM_BLUE_TWO,
  ICE_BLUE,
  BATTLESHIP_GREY,
  BLUEY_GREY,
  CORAL,
  ORANGE,
  STEEL,
  LIGHT_PERIWINKLE,
  DEEP_SKY_BLUE,
  AQUA_MARINE,
  DARK_SKY_BLUE,
  NICE_BLUE,
  DARK,
  APPLE_GREEN,
  LIGHT_GREEN,
  LIGHT_BLUE_GREY,
  MANGO,
  PALE_BLUE,
  SILVER_SAND,
  PALE_LILAC,
  LIGHT_NAVY_BRIGHT,
  LIGHT_NAVY_BRIGHT50,

  LIGHT_NAVY_GREY,
  WHITE_GREY,
  GRAY80,
  LIGHT_NAVY_L2,
  LIGHT_NAVY_L1,
  LIGHT_NAVY_D1,
  LIGHT_NAVY_D2,
  LIGHT_NAVY_D3,
  LIGHT_NAVY_D4,
  LIGHT_NAVY_D5,
  LIGHT_NAVY_D6,
  BLACK_TR,
  COOL_GREY,
  LIGHT_NAVY50,
  RED_PINK,
  TWILIGHT_BLUE,
  LIGHT_GREY,
  GREENBLUE,
  PALE_RED,
  PALE_GREEN,
  BROWN_GREY_TWO,
  VERY_LIGHT_PINK,
  CORAL_TWO,
  CORAL_TWO10,
  TEALFISH,
  VERY_LIGHT_PINK_TWO,
  CLEAR_BLUE,
  DISABLED,
  TEALISH,
  RED,
  VERY_LIGHT_GREY,
  ANOTHER_GREY,
  VERY_LIGHT_GREY_TWO,
  VERY_LIGHT_GREY_THREE,
  VERY_LIGHT_GREY_TWO50,
  WHITE10,
  LIGHT_NAVY_DARK,
  TEXT,
  TEXT_PRIMARY,
  TABLE_HOVER,
  AQUA_MARINE_LIGHT,
  PINK,
  DARK_GRAY_TWO,
  LIGHT_BLUE,
  MEDIUM_GREY,
  ASANA_GRAY_BACKGROUND,
  ASANA_GRAY_BORDER,
  ASANA_GRAY_TEXT_HOVERED,
  ASANA_TEXT,
  LINK,
  ALMOST_WHITE,
  BADGE_BG,
  CONTACT_LABEL,
  TABLE_HOVER_SOLID,
  BOULDER,
  AS_SCROLL_GREY,
  MASON_NAVY_100,
  MASON_NAVY_200,
  MASON_NAVY_300,
  MASON_NAVY_400,
  MASON_NAVY_500,
  MASON_NAVY_600,
  MASON_NAVY_700,
  MASON_NAVY_800,
  MASON_NAVY_900,
  RED_100,
  RED_200,
  RED_300,
  RED_400,
  RED_500,
  RED_600,
  RED_700,
  RED_800,
  RED_900,
  RIPTIDE,
  RIPTIDE10,
  YELLOW,
  BRIGHT_RED,
  VERY_LIGHT_GREY_FOUR,
  HEADING,
  LIGHT_NAVY_HOVER,
  AQUA_MARINE_HOVER,
  CLEAR_BLUE_HOVER,
  CORAL_TWO_HOVER
}
