import React, { useState, useEffect } from 'react'
import { Box, Text, Tabs, Tab, ThemeContext } from 'shared/components'

import colors from 'shared/constants/colors'
import SettingsProfile from 'pages/settings/SettingsProfile'
import Account from 'pages/settings/SettingsAccount'
import SettingsTeammates from 'pages/settings/SettingsTeammates'
import SettingsNotifications from 'pages/settings/SettingsNotifications'
import NavBar from 'components/navBar/NavBar'
import NavBarContainer from 'components/navBar/NavBarContainer'
import config from 'shared/config'
import SettingsTabTitle from 'pages/settings/SettingsTabTitle'

const Settings = ({ redirectActiveIndex }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    setActiveIndex(redirectActiveIndex)
  }, [redirectActiveIndex])

  return (
    <Box pad='none'>
      <NavBarContainer border={false} pad='none'>
        <NavBar fill='horizontal' pad={{ vertical: 'xsmall', horizontal: 'medium' }} border={false} />
      </NavBarContainer>
      <Box margin={{ top: 'small' }} pad={{ top: 'medium' }}>
        <ThemeContext.Extend
          value={{
            tab: {
              border: { hover: { color: colors.ANOTHER_GREY } },
              active: { background: 'transparent' },
              hover: { background: 'transparent' }
            }
          }}
        >
          <Tabs
            justify='center'
            activeIndex={activeIndex}
            onActive={index => {
              setActiveIndex(index)
            }}
            gap='large'
            border={false}
          >
            <Tab title={<SettingsTabTitle label='Profile' active={activeIndex === 0} />}>
              <SettingsProfile />
            </Tab>
            <Tab title={<SettingsTabTitle label='Account' active={activeIndex === 1} />}>
              <Account />
            </Tab>
            <Tab title={<SettingsTabTitle label='Team' active={activeIndex === 2} />}>
              <SettingsTeammates />
            </Tab>
            <Tab title={<SettingsTabTitle label='Notifications' active={activeIndex === 3} />}>
              <SettingsNotifications />
            </Tab>
            {/* <Tab title={<SettingsTabTitle label='Subscription' active={activeIndex === 4} />}>
              <SettingsBilling />
              <Tmp />
            </Tab> */}
            <Tab title={<SettingsTabTitle label='Integrations' active={activeIndex === 5} disabled />} disabled>
              <Text>redirect to stripe todo</Text>
            </Tab>
          </Tabs>
        </ThemeContext.Extend>
      </Box>
      {!config.isDev && (
        <Box customStyle='position: fixed; bottom: 0px; right: 0px;' pad='xsmall'>
          <Text size='small' color={colors.ANOTHER_GREY}>
            {config.internalVersion}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default Settings
