import React, { useContext, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUsers, faInbox, faCommentAltLines } from '@fortawesome/pro-light-svg-icons'
import { faBars, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'
// import HamburgerBack from 'assets/icons/hamburgerBack.svg'
import { getAllProjectsInfoList } from 'model/selectors/projectsInfoSelector'
import LayoutContext from 'src/contexts/LayoutContext'
import AccountSelector from 'pages/layout/AccountSelector'
import { scrollBarStyleOnDark } from 'constants/index'
import { Box, Text, Tip, Button } from 'shared/components'

const SideBar = () => {
  const navigate = useNavigate()
  const params = useParams()

  const projects = useSelector(getAllProjectsInfoList)
  // console.log('SIDEBAR - GC')
  const selectedProjectId = _.get(params, 'projectId')
  const { openProjectModal, setSideMenuOpen, openTeammatesModal } = useContext(LayoutContext)

  const onProjectClick = useCallback(
    projectId => {
      if (projectId !== selectedProjectId) {
        navigate(`/project/${projectId}`)
      }
    },
    [selectedProjectId, navigate]
  )

  const onClose = () => setSideMenuOpen(false)

  const onContactsClick = () => {
    // universalNavigation.navigate(screens.SETTINGS, { activeIndex: 4 })
    navigate('/contacts')
  }

  const onLineItemsClick = () => {
    console.log('onLineItemsClick')
  }

  const onInviteTeammatesClick = () => {
    openTeammatesModal()
  }

  const onHelpAndSupportClick = () => {
    window.open('http://help.bidlevel.co/en/')
  }

  // const onSettingsClick = () => {
  //   // close()
  //   universalNavigation.navigate(screens.SETTINGS)
  // }

  const renderProjects = useMemo(() => {
    if (_.isEmpty(projects)) {
      return <Box flex />
    } else {
      const renderProjectLine = p => {
        const isSelected = p.id === selectedProjectId
        return (
          <Box
            direction='row'
            align='center'
            pad={{ left: 'xsmall' }}
            flex={{ shrink: 0 }}
            key={p.id}
            background={isSelected ? colors.WHITE25 : undefined}
            customStyle={`
              :hover {
                background-color: rgba(255, 255, 255, 0.1);
              }
            `}
          >
            <Box
              round='xxsmall'
              onClick={() => onProjectClick(p.id)}
              align='start'
              direction='row'
              flex
              height='100%'
              pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
              customStyle={`
                color: ${colors.VERY_LIGHT_GREY_THREE};
                :hover {
                  color: ${colors.WHITE};
                };
              `}
            >
              <Text>{_.get(p, 'label', _.get(p, 'address.name'))}</Text>
            </Box>
          </Box>
        )
      }

      return (
        <Box flex>
          <Box pad={{ left: 'small' }} flex={false}>
            <Text color={colors.BOULDER} size='small' margin={{ top: 'medium', right: 'xsmall', left: 'xsmall' }}>
              PROJECTS
            </Text>
          </Box>
          <Box flex overflow='auto' customStyle={scrollBarStyleOnDark}>
            {_.map(projects, renderProjectLine)}
          </Box>
        </Box>
      )
    }
  }, [projects, selectedProjectId, onProjectClick])

  const addProjectButton = useMemo(() => {
    const customTheme = {
      button: {
        secondary: {
          border: {
            color: colors.WHITE
          },
          color: colors.WHITE
        },
        hover: {
          secondary: {
            background: {
              color: colors.WHITE,
              opacity: 1
            }
          }
        }
      }
    }
    return (
      <Button
        gap='xsmall'
        margin={{ right: 'xsmall', left: 'xsmall' }}
        label='New Project'
        color={colors.WHITE}
        icon={<FontAwesomeIcon icon={faPlus} />}
        secondary
        onClick={() => openProjectModal()}
        customTheme={customTheme}
        justify='start'
      />
    )
  }, [openProjectModal])

  return (
    <Box direction='column' justify='between' flex>
      <Box>
        <Box pad={{ top: 'xxsmall' }} flex={false}>
          <Box
            // height='xxsmall'
            direction='row'
            align='center'
            justify='between'
            pad={{ left: 'small', right: 'xsmall', top: 'small' }}
          >
            <Box direction='row' align='center' gap='xsmall' flex>
              <AccountSelector />
            </Box>
            <Box height='27px' align='center' justify='center'>
              <Button
                onClick={onClose}
                plain
                color={colors.WHITE}
                hoverColor={colors.VERY_LIGHT_PINK_TWO}
                icon={
                  <Box direction='row' gap='xxsmall'>
                    <FontAwesomeIcon icon={faChevronLeft} color={colors.WHITE} fontSize={16} />
                    <FontAwesomeIcon icon={faBars} color={colors.WHITE} fontSize={16} />
                  </Box>
                }
              >
                {/* <HamburgerBack /> */}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box pad={{ right: 'medium', top: 'medium', left: 'small' }} flex={false}>
          {addProjectButton}
        </Box>
        <Box direction='row' pad={{ top: 'medium', left: 'xxsmall' }} flex={{ shrink: 0 }}>
          <Button
            gap='xsmall'
            label='Contacts'
            color={colors.WHITE}
            icon={<FontAwesomeIcon icon={faUsers} fixedWidth />}
            onClick={onContactsClick}
            customTheme={{ button: { hover: { default: { background: { color: colors.LIGHT_NAVY_BRIGHT } } } } }}
          />
        </Box>
        <Box direction='row' pad={{ top: 'xsmall', left: 'xxsmall' }} flex={{ shrink: 0 }}>
          <Tip message='Coming Soon - Manage your saved Line items'>
            <Button
              gap='xsmall'
              label='Line items'
              color={colors.WHITE}
              icon={<FontAwesomeIcon icon={faInbox} fixedWidth />}
              onClick={onLineItemsClick}
              customTheme={{ button: { hover: { default: { background: { color: colors.LIGHT_NAVY_BRIGHT } } } } }}
            />
          </Tip>
        </Box>
        {renderProjects}
      </Box>

      <Box flex={false} pad={{ right: 'xsmall', left: 'xsmall', top: 'small', bottom: 'small' }}>
        <Box direction='row'>
          <Button
            gap='xsmall'
            label='Invite Teammates'
            color={colors.WHITE}
            icon={<FontAwesomeIcon icon={faUsers} fixedWidth />}
            onClick={onInviteTeammatesClick}
            customTheme={{ button: { hover: { default: { background: { color: colors.LIGHT_NAVY_BRIGHT } } } } }}
          />
        </Box>
        <Box direction='row' pad={{ top: 'xsmall' }}>
          <Button
            gap='xsmall'
            label='Help & Feedback'
            color={colors.WHITE}
            icon={<FontAwesomeIcon icon={faCommentAltLines} fixedWidth />}
            onClick={onHelpAndSupportClick}
            customTheme={{ button: { hover: { default: { background: { color: colors.LIGHT_NAVY_BRIGHT } } } } }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default SideBar
