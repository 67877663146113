import Cookies from 'js-cookie'
import _ from 'lodash'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { signInWithCustomToken, signOut as fbSignOut } from 'firebase/auth'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Sentry from '@sentry/react'

import { db, auth } from 'controllers/db'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import localStorage from 'controllers/localStorage'
import { trackEvent } from 'utils/analytics'
import { clearUserListener, clearListeners } from 'controllers/listeners'
import store from 'model/store'

export const resetOpenPageOnAuth = () => {
  Cookies.remove('openPage')
}

export const getOpenPageOnAuth = () => {
  try {
    const str = Cookies.get('openPage')
    console.log('getOpenPageOnAuth', str)
    if (!_.isEmpty(str)) {
      const res = JSON.parse(str)
      console.log('getOpenPageOnAuth', res)
      return res
    } else {
      return null
    }
  } catch (e) {
    console.log(e)
    resetOpenPageOnAuth()
    return null
  }
}

export async function userIdByPhone (phone) {
  const docRef = doc(db, 'uidByPhone', phone)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const uidByPhone = docSnap.data()
    return uidByPhone.uid
  } else {
    return null
  }
}

export async function requestSmsCode (phone) {
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  const phoneNum = phoneNumber.number
  try {
    const body = {
      phone: phoneNum
    }
    const url = `${config.dynamicUrl}/proto/requestSmsCode`
    const response = await window.fetch(url, {
      method: 'post',
      headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('requestSmsCode', answer)
    return _.get(answer, 'sent', false)
  } catch (e) {
    console.log('requestSmsCode error:', e.message)
    return false
  }
}

export const resetInvId = async id => {
  await localStorage.remove('invId')
}

export const resetPreconConfId = async id => {
  await localStorage.remove('preconConfId', id)
}

export const resetSubProposalId = async id => {
  await localStorage.remove('subProposalId')
}

export const signIn = async authToken => {
  signInWithCustomToken(auth, authToken)
}

export async function checkVerificationCode (
  phone,
  code,
  email,
  name,
  teammateInvite = false,
  inviteId = null
) {
  const invId = inviteId || (await localStorage.get('invId'))
  const subProposalId = await localStorage.get('subProposalId')
  const preconConfId = await localStorage.get('preconConfId')
  const body = {
    phone,
    code,
    invId,
    email,
    subProposalId,
    name,
    teammateInvite,
    preconConfId
  }
  console.log('checkVerificationCode', body)
  const url = `${config.dynamicUrl}/proto/checkVerificationCode`
  const response = await window.fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  console.log('checkVerificationCode answer', answer)
  const authToken = _.get(answer, 'authToken')
  if (authToken) {
    await signIn(authToken)
  }
  await resetInvId()
  await resetSubProposalId()
  await resetPreconConfId()
  return answer
}

export async function signOut (navigate) {
  try {
    if (auth && auth.currentUser) {
      trackEvent('logout')
      clearUserListener()
      clearListeners()
      Sentry.setUser()
      await fbSignOut(auth)
    } else {
      navigate('/auth')
    }
  } catch (e) {
    console.log('signOut error:', e.message)
    Sentry.captureException(e)
  }
}

export async function updateAdditionalInfo (userName, accountName, address) {
  const state = store.getState()
  const user = _.get(state, 'user')
  const uid = _.get(user, 'id')
  const accountId = _.get(user, 'currentAccountId')
  try {
    if (userName !== '') {
      const docRef = doc(db, 'usersProfiles', uid)
      const update = { name: userName }
      updateDoc(docRef, update)
    }
    if (accountName !== '' || !_.isNil(address)) {
      const update = {}
      if (accountName !== '') {
        update.name = accountName
      }
      if (!_.isNil(address)) {
        update.address = { ...address }
      }
      const docRef = doc(db, 'accountsProfiles', accountId)
      updateDoc(docRef, update)
    }
    const update = {
      [`admins.${user.id}.onboardingComplete`]: true
    }
    const docRef = doc(db, 'accounts', accountId)
    await updateDoc(docRef, update)
  } catch (error) {
    console.log('updateAdditionalInfo error: ', error.message)
  }
}
