import React, { useState } from 'react'
import { Box, Text, Stack, DropButton, Button } from 'shared/components'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { faProjectDiagram, faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'

import colors from 'shared/constants/colors'
import { cutString, getName } from 'shared/utils/stringUtils'
import NewLineItemDropContent from 'components/leveling/NewLineItemDropContent'
import CancelRemovingRequestDropContent from 'components/leveling/CancelRemovingRequestDropContent'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import lineItemType from 'shared/constants/lineItemType'
import Tip from 'shared/components/Tip'

const StyledIcon = styled(FontAwesomeIcon)`
  display: flex;
`

const LineItemTitle = ({
  item,
  onNewLineItemAction,
  removalRequest,
  toggleItemSelected,
  isSelected,
  onClick,
  focused,
  num,
  accountsProfiles,
  compact
}) => {
  // console.log('num', num, 'item', item)
  const [dropOpen, setDropOpen] = useState(false)
  const onDropOpen = e => {
    e.stopPropagation()
    setDropOpen(true)
  }
  const onDropClose = () => {
    setDropOpen(false)
  }

  const itemId = _.get(item, 'id')

  const itemType = _.get(item, 'type', lineItemType.DEFAULT)
  const isQuestion = itemType === lineItemType.QUESTION || itemType === lineItemType.QUESTION_BOOL

  const isUnapproved = _.get(item, 'unapproved', false)
  // console.log('num', num, 'item', item, 'isUnapproved', isUnapproved)

  const removalDenied = _.get(removalRequest, 'declined', false)
  const hasRemovalRequest = !_.isEmpty(removalRequest)

  const numIcon = (
    <Box width='42px' align='center' justify='center' flex={{ shrink: 0 }}>
      <Text color={colors.VERY_LIGHT_PINK}>{num}</Text>
    </Box>
  )

  const unapprovedIconLabel = () => {
    if (removalDenied) {
      return (
        <Box align='center' gap='1px' width='42px'>
          <StyledIcon icon={faCircleSolid} color={colors.VERY_LIGHT_PINK} size='2x' />
        </Box>
      )
    } else {
      const color = hasRemovalRequest ? colors.VERY_LIGHT_PINK : colors.AQUA_MARINE
      return (
        <Box align='center' gap='1px' width='42px'>
          <Stack anchor='center'>
            <StyledIcon icon={isSelected ? faCircleSolid : faCircle} color={color} size='2x' />
            <Box margin={{ top: '2px' }}>
              <StyledIcon icon={faCheck} color={colors.WHITE} size='1x' />
            </Box>
          </Stack>
          {!compact && (
            <Text size='xsmall' color={color}>
              NEW
            </Text>
          )}
        </Box>
      )
    }
  }

  const onAction = action => {
    onDropClose()
    onNewLineItemAction(action, _.get(item, 'subId'), itemId)
  }

  const getTitle = name => {
    if (_.size(name) < 105) {
      return <Text color={colors.LIGHT_NAVY_BRIGHT}>{name}</Text>
    } else {
      return (
        <Tip message={name}>
          <Text color={colors.LIGHT_NAVY_BRIGHT}>{cutString(name, 105)}</Text>
        </Tip>
      )
    }
  }

  const unapprovedIcon = () => {
    if (hasRemovalRequest) {
      const subId = _.get(item, 'subId')
      const companyName = getName(_.get(accountsProfiles, subId))
      const dropContent = <CancelRemovingRequestDropContent onAction={onAction} companyName={companyName} />
      return (
        <DropButton
          plain
          onClose={onDropClose}
          onOpen={onDropOpen}
          open={dropOpen}
          label={unapprovedIconLabel()}
          dropAlign={{ top: 'bottom', left: 'right' }}
          dropProps={{ style: { overflow: 'visible' } }}
          dropContent={dropContent}
          disabled={removalDenied}
        />
      )
    } else {
      return (
        <Button plain onClick={() => toggleItemSelected(itemId, item.subId)}>
          {unapprovedIconLabel()}
        </Button>
      )
    }
  }

  const renderStatusText = () => {
    if (item.unapproved) {
      if (hasRemovalRequest && removalDenied) {
        return (
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            <i>Removal request denied</i>
          </Text>
        )
      } else if (hasRemovalRequest) {
        return (
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            <i>Removal requested</i>
          </Text>
        )
      } else {
        const subId = _.get(item, 'subId')
        const companyName = getName(_.get(accountsProfiles, subId))
        const label = (
          <Box direction='row' align='center'>
            <FontAwesomeIcon icon={faExclamationCircle} color={colors.AQUA_MARINE} fontSize={12} />
            <Text size='small' color={colors.AQUA_MARINE} alignSelf='end' margin={{ left: 'xsmall' }}>
              New line item - action required
            </Text>
          </Box>
        )
        return (
          <DropButton
            plain
            onClose={onDropClose}
            onOpen={onDropOpen}
            open={dropOpen}
            label={label}
            dropAlign={{ top: 'bottom', left: 'left' }}
            dropProps={{ style: { overflow: 'visible' } }}
            dropContent={<NewLineItemDropContent onAction={onAction} companyName={companyName} />}
            disabled={removalDenied}
          />
        )
      }
    }
  }

  const renderQuestonLabel = () => {
    if (isQuestion) {
      const qType = itemType === lineItemType.QUESTION ? 'Text question' : 'Yes / No'
      return (
        <Text size='small' italic color={colors.VERY_LIGHT_PINK}>
          {qType}
        </Text>
      )
    }
  }

  const renderAlternatesLabel = () => {
    if (_.size(_.get(item, 'alternates')) > 0 && !isQuestion) {
      return (
        <Box direction='row' align='center' pad={{ left: 'xsmall' }}>
          <FontAwesomeIcon icon={faProjectDiagram} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />
        </Box>
      )
    }
  }

  let borderStyle = `border: 2px solid ${colors.PALE_GREY};`
  if (focused) {
    borderStyle = `border: 2px solid ${colors.AQUA_MARINE};`
  }

  return (
    <Box
      direction='row'
      align='center'
      justify='start'
      fill
      pad={{ vertical: 'xxsmall' }}
      background={colors.WHITE}
      onClick={() => onClick(itemId)}
      className={IGNORE_OUTSIDE_CLICK_CLASS}
      customStyle={borderStyle}
    >
      <Box direction='row' align='center' fill>
        {isUnapproved ? unapprovedIcon() : numIcon}
        <Box
          direction={compact ? 'row' : 'column'}
          fill
          justify='between'
          pad={{ vertical: 'xxsmall', right: 'xxsmall' }}
        >
          <Box flex justify='center'>
            {getTitle(_.get(item, 'name', ''))}
          </Box>
          <Box
            direction='row'
            gap='xsmall'
            align='center'
            justify='end'
            pad={{ right: 'small' }}
            alignSelf={compact ? 'center' : 'end'}
          >
            {isUnapproved && !compact && renderStatusText()}
            {renderAlternatesLabel()}
            {renderQuestonLabel()}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

LineItemTitle.defaultProps = {
  toggleItemSelected: () => null
}

LineItemTitle.propTypes = {
  removalRequest: PropTypes.object,
  name: PropTypes.string,
  record: PropTypes.object,
  onNewLineItemAction: PropTypes.func
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles
})

export default connect(mapStateToProps)(LineItemTitle)
