import React from 'react'
import { Box } from 'shared/components'

import colors from 'shared/constants/colors'

const Row = ({ onClick, children, border }) => (
  <Box
    border={border || 'none'}
    pad='small'
    direction='row'
    align='center'
    hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
    onClick={onClick}
    height='52px'
  >
    {children}
  </Box>
)

export default Row
