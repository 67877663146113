export default {
  wf1: {
    title: 'Semi-gloss paint',
    appliedTo: ['walls', 'floors'],
    trade: 'ct11' // Painter
  },
  wf2: {
    title: 'Venetian plaster',
    appliedTo: ['walls', 'floors'],
    trade: 'ct13' // Lathing and Plastering Contractor
  },
  wf3: {
    title: 'Varnishes/Pearlescent coatings',
    appliedTo: ['walls', 'floors'],
    trade: 'ct11' // Painter
  },
  // wf4: {
  //   title: 'Metallic wallpaper',
  //   appliedTo: ['walls'],

  // }
  // wf5: 'Matte paint',
  // wf6: 'Thin wallpaper',
  // wf7: 'Decorative plaster',
  // wf8: 'Structural wallpaper',
  // wf9: 'Glass wallpaper',
  // wf10: 'Liquid wallpaper',
  // wf11: 'Heavy vinyl wallpaper',
  wf12: 'Texture/structure paint',
  wf13: {
    title: 'Ceramic Tile',
    appliedTo: ['walls', 'ceils'],
    trade: 'ct12' // Stone and Tile Contractor
  },
  // wf14: 'Facing with decorative elements on the glue',
  wlo1: {
    title: 'Walls leveling with sheet materials',
    appliedTo: ['walls'],
    trade: 'ct13' // drywall contractor
  },
  wlo2: {
    title: 'Plastering',
    appliedTo: ['walls', 'floors', 'extFinishing'],
    trade: 'ct13' // Lathing and Plastering Contractor
  }
}
