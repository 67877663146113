import React from 'react'
import { Box } from 'shared/components'

import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons'

const AddPlugArrow = () => {
  return (
    <Box pad={{ left: 'medium' }}>
      <FontAwesomeIcon icon={faArrowDown} color={colors.VERY_LIGHT_PINK} />
    </Box>
  )
}

export default AddPlugArrow
