import React, { useState } from 'react'
import { Text, Button, Box, Heading } from 'shared/components'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactCodeInput from 'react-verification-code-input'
import styled from 'styled-components'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { checkVerificationCode, requestSmsCode } from 'controllers/auth'
import colors from 'shared/constants/colors'
import { ReactComponent as Logo } from 'assets/bidlevel_logo_on_light.svg'
import { ReactComponent as SignInLogo } from 'assets/signin_logo.svg'

const SMS_CODE_LENGTH = 4

const StyledCodeInput = styled(ReactCodeInput)`
  input {
    color: ${colors.TEXT} !important;
    font-size: 24px !important;
    border-color: ${colors.ANOTHER_GREY} !important;
  }
  input:focus {
    border-color: ${colors.NICE_BLUE} !important;
  }
`

const EnterCode = () => {
  const [loading, setLoading] = useState(false)
  const [codeError, setCodeError] = useState(null)
  const [codeInputKey, setCodeInputKey] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const props = _.get(location, 'state')
  const { phone } = props
  const phoneNumber = parsePhoneNumberFromString(phone || '')

  const onVerificationRes = res => {
    const er = _.get(res, 'error')
    const resend = _.get(res, 'resend', false)
    console.log('onVerification res resend', resend)
    if (er) {
      setCodeError(er)
      setLoading(false)
      setCodeInputKey(_.now())
      if (resend) {
        goBack()
      }
    }
  }

  const handleSubmit = async code => {
    try {
      const { phone, email } = props
      setLoading(true)
      const res = await checkVerificationCode(phone, code, email)
      onVerificationRes(res)
    } catch (e) {
      console.log('EnterCode, onSubmit, error:', e)
      setLoading(false)
      setCodeInputKey(_.now())
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  const onResponse = () => {
    setLoading(false)
    setCodeInputKey(_.now())
  }

  const handleResendCode = async () => {
    try {
      const { phone } = props
      setCodeError(null)
      setLoading(true)
      await requestSmsCode(phone)
      onResponse()
    } catch (e) {
      onResponse()
      console.log('requestSmsCode error', e)
    }
  }

  const renderError = () => {
    if (!_.isEmpty(codeError)) {
      return (
        <Text margin={{ top: 'xsmall' }} color={colors.CORAL_TWO}>
          {codeError}
        </Text>
      )
    }
  }

  return (
    <Box pad='medium' align='start'>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box flex={false} pad='medium' alignSelf='center'>
        <Box align='center'>
          <SignInLogo />
          <Heading level={2} color={colors.LIGHT_NAVY} margin={{ top: 'small' }} textAlign='center'>
            Enter validation code
          </Heading>

          <Text margin={{ top: 'small' }} color={colors.ANOTHER_GREY} textAlign='center'>
            Validation code was sent to your number
          </Text>
          <Button
            plain
            icon={<FontAwesomeIcon icon={faPencil} color={colors.ANOTHER_GREY} fontSize={12} />}
            label={<Text color={colors.ANOTHER_GREY}>{phoneNumber.formatNational()}</Text>}
            onClick={goBack}
            margin={{ bottom: 'large' }}
          />
          <StyledCodeInput
            key={codeInputKey}
            fields={SMS_CODE_LENGTH}
            type='number'
            autoFocus
            onComplete={handleSubmit}
            disabled={loading}
          />

          {renderError()}

          <Button
            plain
            label={
              <Text color={colors.ANOTHER_GREY}>
                <u>Haven't received a code? Resend</u>
              </Text>
            }
            onClick={handleResendCode}
            margin={{ top: 'large' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EnterCode
