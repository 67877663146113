import React, { useMemo } from 'react'
import _ from 'lodash'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NavWrapper from 'navigation/NavWrapper'
import { getAppLoaded } from 'model/selectors/base'
import LoadingWithProgress from 'shared/pages/LoadingWithProgress.web'

// pages
// import Tmp from 'pages/Tmp'
import EnterPhone from 'pages/auth/EnterPhone'
import EnterCode from 'pages/auth/EnterCode'
import EnterEmail from 'pages/auth/EnterEmail'
import LoadingPage from 'shared/pages/LoadingPage'
import Layout from 'pages/layout/Layout'
import Onboarding from 'pages/auth/Onboarding'
import ProjectsList from 'pages/ProjectsList'
import Settings from 'pages/Settings'
import ProjectDashboard from 'pages/ProjectDashboard'
import ProjectWrapper from 'pages/layout/ProjectWrapper'
import Leveling from 'pages/Leveling'
import Contacts from 'pages/Contacts'
import ScopeBuilder from 'pages/ScopeBuilder'

const AppRouter = () => {
  const appLoaded = useSelector(getAppLoaded)
  const authData = useSelector(state => state.authData)

  const authRoutes = useMemo(() => {
    return (
      <>
        <Route path='/auth/phone' element={<EnterPhone />} />
        <Route path='/auth/enter-code' element={<EnterCode />} />
        <Route path='/auth/email' element={<EnterEmail />} />
        <Route path='/auth' element={<EnterPhone />} />
        <Route path='/onboarding' element={<Onboarding />} />
        <Route path='/' element={<Navigate replace to='/auth' />} />
      </>
    )
  }, [])

  const projectsRoutes = useMemo(() => {
    return (
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<ProjectsList />} />
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/project' element={<ProjectWrapper />}>
          <Route path=':projectId/:workOrderId' element={<Leveling />} />
          <Route path=':projectId' element={<ProjectDashboard />} />
        </Route>
      </Route>
    )
  }, [])

  const settingsRoutes = useMemo(() => {
    return (
      <Route path='/settings' element={<Layout />}>
        <Route path='/settings' element={<Settings />} />
      </Route>
    )
  })

  const signedInRoutes = useMemo(() => {
    if (_.isEmpty(authData)) {
      return <Routes>{authRoutes}</Routes>
    } else if (_.isNil(appLoaded)) {
      return (
        <Routes>
          {settingsRoutes}
          {projectsRoutes}
          <Route path='/scope_builder' element={<ScopeBuilder />} />
        </Routes>
      )
    } else {
      return <LoadingWithProgress progress={appLoaded} />
    }
  }, [appLoaded, authData])

  if (_.isNil(authData)) {
    return (
      <BrowserRouter>
        <NavWrapper>
          <LoadingPage />
        </NavWrapper>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <NavWrapper>{signedInRoutes}</NavWrapper>
      </BrowserRouter>
    )
  }
}

export default AppRouter
