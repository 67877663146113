import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'

import { Text } from 'shared/components'
import colors from 'shared/constants/colors'

export const FileInput = ({ onChange, label, disabled, ...rest }) => {
  const filePickerRef = useRef()
  const [inputKey, setInputKey] = useState(_.now())

  const openFilePicker = () => {
    filePickerRef.current.click()
  }

  const onFilesPicked = async event => {
    const files = _.values(_.get(event, 'target.files'))
    onChange(files)
    setInputKey(_.now())
  }

  const defaultLabel = (
    <Box direction='row' hoverIndicator onClick={() => {}} pad={{ horizontal: 'small', vertical: 'xsmall' }} gap='small' round='6px'>
      <FontAwesomeIcon icon={faPaperclip} fontSize={16} color={colors.LIGHT_NAVY_BRIGHT} />
      <Text size='medium' color={colors.LIGHT_NAVY_BRIGHT}>Upload file</Text>
    </Box>
  )

  return (
    <Box onClick={openFilePicker}>
      <input
        key={inputKey}
        ref={filePickerRef}
        id='file-input'
        onChange={onFilesPicked}
        type='file'
        style={{ display: 'none' }}
        multiple
        disabled={disabled}
        {...rest}
      />
      {label || defaultLabel}
    </Box>
  )
}

FileInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.element
}

export default FileInput
