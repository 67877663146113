import React, { useState } from 'react'
import { Avatar, Box, Text, DropButton } from 'shared/components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faPlus } from '@fortawesome/pro-regular-svg-icons'

import { ReactComponent as Logo } from 'assets/bidlevel_logo_on_dark.svg'
import colors from 'shared/constants/colors'
import { getInitials } from 'shared/utils/stringUtils'
import { switchAccount } from 'controllers/user'

const getProfileData = ({ name, avatar, email, active, id }) => {
  return { name: _.truncate(name, { length: 20 }), avatar, email, active, id }
}

const AccountSelector = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { accountsProfiles, currentAccount, isMasonAdmin } = useSelector(state => {
    const adminOfAccounts = _.get(state, 'user.adminOfAccounts')
    const currentAccountId = _.get(state, 'user.currentAccountId')
    const userId = _.get(state, 'user.id')
    const isMasonAdmin = _.get(state, ['profiles', userId, 'isMasonAdmin'], false)
    return {
      accountsProfiles: _.map(adminOfAccounts, id => {
        const profile = _.get(state, ['accountsProfiles', id], {})
        return getProfileData({ ...profile, active: _.isEqual(id, currentAccountId), id })
      }),
      currentAccount: getProfileData(_.get(state, ['accountsProfiles', currentAccountId], {})),
      isMasonAdmin
    }
  })

  if (_.size(accountsProfiles) > 1 || isMasonAdmin) {
    const label = (
      <Box direction='row' justify='between' align='center' gap='small'>
        <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={_.get(currentAccount, 'avatar')}>
          <Text color={colors.WHITE}>{getInitials(_.get(currentAccount, 'name', '-'), true)}</Text>
        </Avatar>
        <Text color={colors.WHITE}>{_.get(currentAccount, 'name', '-')}</Text>
        <FontAwesomeIcon icon={faAngleDown} color={colors.WHITE} fontSize={12} />
      </Box>
    )

    const handleClick = () => {
      setOpen(!open)
    }

    const onAccountSwitchClick = (accountId, active) => () => {
      if (!active) {
        switchAccount(accountId, navigate)
      }
    }

    const renderAccount = ({ name, avatar, email, active, id }) => {
      return (
        <Box
          direction='row'
          key={id}
          pad='small'
          gap='small'
          background={active ? colors.AQUA_MARINE10 : colors.WHITE}
          onClick={onAccountSwitchClick(id, active)}
          hoverIndicator
          width='260px'
          flex={false}
        >
          <Box width='32px' height='32px' align='center' justify='center' flex={false}>
            <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={avatar}>
              <Text color={colors.WHITE}>{getInitials(name, true)}</Text>
            </Avatar>
          </Box>
          <Box justify='center'>
            <Text color={colors.TEXT} size='medium'>
              {name}
            </Text>
            <Text color={colors.ANOTHER_GREY} size='small'>
              {email}
            </Text>
          </Box>
        </Box>
      )
    }

    const accountsList = []
    if (isMasonAdmin) {
      const url =
        'https://masonco.retool.com/apps/41363060-1f5e-11ec-a516-b74228f4cbff/Precon%20-%20Live/General%20Contractors'
      accountsList.push(
        <Box
          direction='row'
          key='create_company_button'
          pad='small'
          gap='small'
          background={colors.WHITE}
          onClick={() => window.open(url, '_blank')}
          hoverIndicator
          width='260px'
          flex={false}
        >
          <Box width='32px' height='32px' align='center' justify='center' flex={false}>
            <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px'>
              <FontAwesomeIcon icon={faPlus} color={colors.WHITE} fontSize={12} />
            </Avatar>
          </Box>
          <Box justify='center'>
            <Text color={colors.TEXT} size='medium'>
              Create company
            </Text>
          </Box>
        </Box>
      )
    }
    const dropContent = <Box>{[accountsList, ..._.map(accountsProfiles, renderAccount)]}</Box>
    return (
      <Box align='center' onClick={handleClick}>
        <DropButton
          plain
          onClose={handleClick}
          open={open}
          label={label}
          dropProps={{ style: { overflow: 'auto' }, elevation: 'none' }}
          dropContent={
            <Box overflow='auto' background={colors.WHITE} round={false}>
              {dropContent}
            </Box>
          }
          dropAlign={{ top: 'bottom', left: 'left' }}
        />
      </Box>
    )
  }

  const onLogoClick = () => {
    navigate('/')
  }
  return (
    <Box onClick={onLogoClick} width='140px' height='auto'>
      <Logo />
    </Box>
  )
}

export default AccountSelector
