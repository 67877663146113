import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Box, Text, Button, Image } from 'grommet'

import PdfPreview from 'shared/components/PdfPreview'
import colors from 'shared/constants/colors'
import LoadingPage from 'shared/pages/LoadingPage'
import { downloadFile } from 'controllers/workOrder'
import LayoutContext from 'contexts/LayoutContext'

const types = {
  'application/pdf': 'application/pdf',
  image: 'image'
}

const FilePreview = ({ file }) => {
  const [loading, setLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [loadedFile, setLoadedFile] = useState(null)
  const { openToast } = useContext(LayoutContext)

  useEffect(() => {
    setLoading(false)
    setLoadedFile(null)
  }, [file])

  if (loading) {
    return <LoadingPage />
  }

  const handleDownload = async () => {
    setFileLoading(true)
    const res = await downloadFile(file.url, file.name)
    if (!res) {
      openToast(
        <Box background={colors.CORAL_TWO} justify='center' align='center' pad='small' margin='small'>
          <Text color={colors.TEXT}>Error: file not found</Text>
        </Box>
      )
    }
    setFileLoading(false)
  }

  const fetchFile = async () => {
    setLoading(true)
    const request = await window.fetch(file.url)
    const response = await request.blob()
    const fileUrl = URL.createObjectURL(response)
    setLoadedFile(fileUrl)
    setLoading(false)
  }

  const contentType = _.get(file, 'contentType', '')
  if (_.isEqual(contentType, types['application/pdf'])) {
    return (
      <PdfPreview
        id={_.get(file, 'id')}
        url={file.url}
        name={file.name}
        fileConfig={{
          showPageControls: false,
          dockPageControls: false,
          showAnnotationTools: false,
          showLeftHandPanel: false,
          defaultViewMode: 'FIT_WIDTH',
          enableFormFilling: false,
          showDownloadPDF: true,
          showPrintPDF: false,
          enableAnnotationAPIs: true
        }}
      />
    )
  } else if (_.isEqual(_.get(_.split(contentType, '/'), 0), types.image)) {
    if (!loading && _.isNil(loadedFile)) {
      fetchFile()
    }
    return <Image src={loadedFile} fit='contain' fill={false} />
  }

  return (
    <Box fill align='center' justify='center' gap='medium'>
      <Text size='large'>This file type is not supported for preview</Text>
      <Button disabled={fileLoading} primary label='Download' color={colors.AQUA_MARINE} onClick={handleDownload} />
    </Box>
  )
}

export default FilePreview
