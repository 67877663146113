import React, { useContext, useCallback } from 'react'
// import { connect } from 'react-redux'
import { Box, Text } from 'shared/components'
import _ from 'lodash'

// import { fetchMessagesWebDashboard } from 'controllers/chat'
// import { getWorkOrdersWithTitles } from 'model/selectors/workOrdersSelector'
// import { getProject } from 'model/selectors/projectsInfoSelector'
// import { getBidsByProject, getBidsByWorkOrders } from 'model/selectors/bids'
import DashboardSubmenu from 'pages/projectDashboard/DashboardSubmenu'
// import DashboardTable from 'webPages/dashboard/DashboardTable'
import LayoutContext from 'contexts/LayoutContext'
import { useNavigate, useParams } from 'react-router'
// import LevelingContext from 'webPages/leveling/LevelingContext'
// import { getLevelingTableMessages } from 'model/selectors/channels'
// import { offListener } from 'controllers/listeners'
// import SubInfoPanel from 'webPages/dashboard/SubInfoPanel'
// import CreateBidManually from 'webPages/createBid/CreateBidManually'
// import { getAccountsProfiles } from 'model/selectors/profiles'
// import screens from 'constants/screens'
// import universalNavigation from 'utils/universalNavigation'
import reportType from 'shared/constants/reportType'
import { useSelector } from 'react-redux'
import levelingTableSettings from 'constants/levelingTableSettings'

const ProjectDashboard = () => {
  const { openBidInviteModal } = useContext(LayoutContext)
  const settings = useSelector(state => state.customizeSettings)
  // const { archivedVisible } = useContext(LevelingContext)
  // const panelRef = useRef(null)
  // const createBidManuallyRef = useRef()
  const { projectId } = useParams()
  const navigate = useNavigate()

  // const projectId = _.get(project, 'id')

  // useEffect(() => {
  //   if (!_.isNil(projectId)) fetchMessagesWebDashboard(projectId, accountId)
  //   return () => offListener('messagesDashboard')
  // }, [accountId, projectId])

  const handleAddBidInvite = () => {
    openBidInviteModal(projectId)
  }

  // const openCreateBidManuallyModal = (subId, workOrderId) => {
  //   console.log('openCreateBidManuallyModal, subId', subId, workOrderId)
  //   if (!_.isEmpty(subId)) {
  //     createBidManuallyRef.current.open({ subId, workOrderId })
  //   }
  // }

  const generateReport = useCallback(() => {
    navigate(`/project/${projectId}/share/${reportType.SELECTED_BIDS}`)
  }, [projectId])

  const settingsChanged = _.size(
    _.filter(settings, (v, name) => {
      if (_.includes([levelingTableSettings.SHOW_SECTIONS, levelingTableSettings.SHOW_DECLINED], name)) {
        return !v
      }
      return v
    })
  )

  return (
    <Box fill>
      <DashboardSubmenu
        addBidInvite={handleAddBidInvite}
        generateReport={generateReport}
        settingsChanged={settingsChanged}
        projectId={projectId}
      />
      <Box fill>
        <Box height='100%' wrap={false}>
          <Box overflow='auto' height='100%' flex pad='medium'>
            <Text>A new project dashboard will be here</Text>
            {/* <DashboardTable
              addBidInvite={handleAddBidInvite}
              openSubDetails={openSubDetails}
              workOrders={workOrdersList}
              project={project}
              bids={bids}
              accountsProfiles={accountsProfiles}
              channel={channel}
              messages={messages}
              openCreateBidManuallyModal={openCreateBidManuallyModal}
            /> */}
          </Box>
        </Box>
      </Box>
      {/* <SubInfoPanel ref={panelRef} /> */}
      {/* <CreateBidManually ref={createBidManuallyRef} /> */}
    </Box>
  )
}

// const mapStateToProps = (state, props) => ({
//   project: getProject(state, props),
//   workOrders: getWorkOrdersWithTitles(state, props),
//   bids: getBidsByProject(state, props),
//   bidsByWorkOrder: getBidsByWorkOrders(state),
//   accountsProfiles: getAccountsProfiles(state),
//   channel: state.channelWeb,
//   messages: _.get(getLevelingTableMessages(state, props), 'subs'),
//   accountId: _.get(state, 'account.id'),
//   settings: state.customizeSettings
// })

export default ProjectDashboard
