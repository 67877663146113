import React from 'react'
import { Box, Text, Menu } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router'

import colors from 'shared/constants/colors'

const EDIT_SCOPE = 'EDIT_SCOPE'

const SectionTitle = ({ title, projectId, workOrderId }) => {
  const navigate = useNavigate()
  const customStyle = `
   > button {
      display: none;
   }
   :hover {
    > button {
        display: flex;
      }
   }
  `

  const toEditScope = () => {
    navigate(`/project/${projectId}/${workOrderId}/scope`)
    // universalNavigation.push(screens.GC_PROJECT_WORKORDER, { projectId, workOrderId })
  }

  const onMenuOptionClick = id => {
    switch (id) {
      case EDIT_SCOPE:
        toEditScope()
        break
      default:
        break
    }
  }

  return (
    <Box
      direction='row'
      align='center'
      pad={{ horizontal: 'medium' }}
      background={colors.PALE_GREY}
      customStyle={customStyle}
    >
      <Text size='large' color={colors.TEXT_PRIMARY}>
        {title}
      </Text>
      <Menu
        id={`${title}-dropmenu`}
        options={[{ id: EDIT_SCOPE, label: 'Edit Scope' }]}
        dropContentProps={{ boxProps: { width: { min: '160px' } } }}
        dropAlign={{ top: 'bottom', left: 'left' }}
        onOptionClick={onMenuOptionClick}
      >
        <Box
          direction='row'
          align='center'
          round='xsmall'
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
          gap='small'
        >
          <FontAwesomeIcon icon={faEllipsisH} fontSize={18} color={colors.TEXT} />
        </Box>
      </Menu>
    </Box>
  )
}

export default SectionTitle
