import React, { useState, useMemo, useLayoutEffect, useContext, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { DropButton, Box, Text } from 'shared/components'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import colors from 'shared/constants/colors'
import { getName } from 'shared/utils/stringUtils'
import { getUsersProfilesByAccountId } from 'model/selectors/profiles'
import LayoutContext from 'contexts/LayoutContext'
import SubNameInput from 'modals/inviteSubDrop/SubNameInput'

const SUGGESTIONS_LIMIT = 12

const InviteSubDrop = ({
  dropButtonLabel,
  dropButtonProps,
  workOrderId,
  account,
  accountsProfiles,
  usersProfilesByAccountId,
  contacts,
  companies,
  openLayerCallback,
  dropRef,
  fromManual = false,
  onSelectCallback
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [value, setValue] = useState('')
  const [selectedOption] = useState()
  const { openInviteSubPanel } = useContext(LayoutContext)

  useImperativeHandle(dropRef, () => ({
    open: () => setIsOpened(true),
    close: () => setIsOpened(false)
  }))

  const open = () => {
    setIsOpened(true)
  }

  useLayoutEffect(() => {
    if (isOpened) {
      setValue('')
    }
  }, [isOpened])

  const close = () => setIsOpened(false)

  const accountId = _.get(account, 'id')

  const renderHeader = () => {
    return (
      <Box direction='row' align='center' justify='between' pad={{ horizontal: 'medium' }}>
        <Text size='large' weight={600}>
          Invite sub to bid
        </Text>
      </Box>
    )
  }

  const onSuggestionSelect = event => {
    if (!_.isNil(openLayerCallback)) {
      openLayerCallback()
    }
    setIsOpened(false)
    openInviteSubPanel(event, workOrderId, value)
    if (!_.isNil(onSelectCallback)) {
      onSelectCallback()
    }
  }

  const renderCompanyOptionLabel = (accName, userName, isActive) => {
    return (
      <Box
        // border='top'
        direction='row'
        align='center'
        justify='between'
        flex
        pad={{ vertical: 'small', horizontal: 'medium' }}
        gap='small'
        width={{ min: '450px' }}
      >
        <Box direction='row' align='end' gap='xsmall'>
          <Text weight={500} color={colors.TEXT}>
            {accName}
          </Text>
          {isActive && (
            <Text size='small' color={colors.ANOTHER_GREY}>
              <i>Active</i>
            </Text>
          )}
        </Box>
        <Text weight={500} color={colors.LIGHT_NAVY_BRIGHT} textAlign='end' truncate>
          {userName}
        </Text>
      </Box>
    )
  }

  const createCompanyButton = (
    <Box
      border='top'
      direction='row'
      align='center'
      flex
      pad={{ vertical: 'small', horizontal: 'medium' }}
      gap='xsmall'
    >
      <FontAwesomeIcon icon={faPlusCircle} fontSize={24} color={colors.CLEAR_BLUE} />
      <Text weight={500} color={colors.CLEAR_BLUE}>
        Create a Company <Text color={colors.ALMOST_BLACK}>{value}</Text>
      </Text>
    </Box>
  )

  const suggestions = useMemo(() => {
    if (_.isEmpty(value)) return []
    let res = []
    const reg = new RegExp(_.escapeRegExp(_.toLower(value)), 'gi')

    console.log('contacts', contacts)
    _.forEach(accountsProfiles, (accountProfile, accId) => {
      const accName = _.toLower(getName(accountProfile))
      const profiles = _.get(usersProfilesByAccountId, accId)
      _.forEach(profiles, p => {
        const userName = _.toLower(getName(p))
        if (accId !== accountId && (accName.match(reg) || userName.match(reg))) {
          res.push({
            label: renderCompanyOptionLabel(getName(accountProfile), getName(p), true),
            value: { accId, userId: p.id, accountProfile }
          })
        }
      })
    })
    if (_.size(res) < SUGGESTIONS_LIMIT) {
      _.forEach(contacts, c => {
        const companyName = _.get(companies, [c.companyId, 'name'])
        const companyNameLower = _.toLower(companyName)
        const phones = _.get(c, 'phones', [])
        const emails = _.get(c, 'emails', [])
        const str = [_.toLower(c.name), companyNameLower, ...phones, ...emails].join(', ')
        if (str.match(reg) && !_.isEmpty(companyName) && !_.isEmpty(c.name)) {
          res.push({
            label: renderCompanyOptionLabel(companyName, c.name, false),
            value: { contactId: c.id }
          })
        }
      })
    }
    res = _.take(res, SUGGESTIONS_LIMIT)
    res.push({
      label: createCompanyButton,
      value: null
    })
    return res
  }, [accountId, contacts, value, accountsProfiles, usersProfilesByAccountId, selectedOption, companies])

  const onChangeValue = e => {
    const v = e.target.value
    setValue(v)
  }

  const renderInput = () => {
    return (
      <SubNameInput
        value={value}
        onChangeValue={onChangeValue}
        suggestions={suggestions}
        onSuggestionSelect={onSuggestionSelect}
        isOpened={isOpened}
      />
    )
  }

  const dropContent = (
    <Box width={{ min: '400px' }} height='400px' pad={{ top: 'medium' }} responsive={false} flex={{ shrink: 0 }}>
      <Box customStyle='flex: 1'>
        {renderHeader()}
        {renderInput()}
      </Box>
    </Box>
  )

  if (fromManual) {
    return dropContent
  }

  return (
    <DropButton
      label={dropButtonLabel}
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropContent={dropContent}
      open={isOpened}
      color={colors.LIGHT_NAVY}
      onOpen={open}
      onClose={close}
      backgroundColor={colors.WHITE}
      {...dropButtonProps}
    />
  )
}

InviteSubDrop.defaultProps = {}

InviteSubDrop.propTypes = {
  dropButtonLabel: PropTypes.node,
  workOrderId: PropTypes.string,
  dropButtonProps: PropTypes.object
}

const mapsStateToProps = state => ({
  account: state.account,
  accountsProfiles: state.accountsProfiles,
  usersProfilesByAccountId: getUsersProfilesByAccountId(state),
  profiles: state.profiles,
  contacts: _.get(state, 'contacts.contacts'),
  companies: _.get(state, 'contacts.companies')
})

export default connect(mapsStateToProps)(InviteSubDrop)
