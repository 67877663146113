import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Layer } from 'grommet'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from 'shared/components'
import colors from 'shared/constants/colors'

export const Toast = ({ toastRef }) => {
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [withTimeout, setWithTimeout] = useState(false)
  const [props, setProps] = useState({})

  useImperativeHandle(toastRef, () => ({
    open: (content, withTimeout, props) => handleOpen(content, withTimeout, props),
    close: close
  }))

  useEffect(() => {
    if (withTimeout) {
      const timer = setTimeout(close, 5000)

      return () => clearTimeout(timer)
    }
  }, [withTimeout])

  const handleOpen = (content, withTimeout = true, props = {}) => {
    if (typeof content === 'string') {
      content = (
        <Box
          fill
          pad='medium'
          margin={{ bottom: 'small' }}
          background={colors.PALE_GREY}
          round='4px'
          elevation='small'
          customStyle='position: relative'
        >
          <Text size='medium' colors={colors.TEXT}>{content}</Text>
          <Box customStyle='position: absolute; top: 7px; right: 7px' onClick={close}>
            <FontAwesomeIcon icon={faTimes} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />
          </Box>
        </Box>
      )
    }
    setOpen(true)
    setContent(content)
    setWithTimeout(withTimeout)
    setProps(props)
  }

  const close = () => {
    setOpen(false)
    setContent(null)
    setWithTimeout(false)
    setProps({})
  }

  if (open) {
    return (
      <Layer
        position='bottom-right'
        plain
        modal={false}
        margin={{ horizontal: 'xxsmall', vertical: 'xlarge' }}
        {...props}
      >
        {content}
      </Layer>
    )
  }
  return null
}

Toast.defaultProps = {
  toastRef: null
}

Toast.propTypes = {
  toastRef: PropTypes.object.isRequired
}

export default Toast
