import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, FormField, TextInput, ThemeContext, Text, Button } from 'shared/components'
import { useNavigate } from 'react-router'
import _ from 'lodash'

import AddressAutocomplete from 'shared/components/AddressAutocomplete'
import { ReactComponent as Logo } from 'assets/bidlevel_logo_on_light.svg'
import { updateAdditionalInfo } from 'controllers/auth'
import { trackOnboardingStarted } from 'controllers/zapier'
import { getCurrentAccountProfile, getCurrentUserProfile } from 'model/selectors/profiles'
import { getCanEditCompanySettings } from 'model/selectors/permissionsSelector'
import colors from 'shared/constants/colors'
import { getAddress } from 'shared/utils/stringUtils'

const Onboarding = () => {
  const profile = useSelector(getCurrentUserProfile)
  const accountProfile = useSelector(getCurrentAccountProfile)
  const canEditCompanySettings = useSelector(getCanEditCompanySettings)
  const [errors, setErrors] = useState({})
  const [userName, setUserName] = useState('')
  const [accountName, setAccountName] = useState('')
  const [address, setAddress] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    trackOnboardingStarted()
  }, [])

  useEffect(() => {
    setAccountName(_.get(accountProfile, 'name', ''))
    setAddress(_.get(accountProfile, 'address'))
  }, [accountProfile])

  useEffect(() => {
    setUserName(_.get(profile, 'name', ''))
  }, [profile])

  const resetError = k => () => {
    setErrors({ ...errors, [k]: undefined })
  }

  const onFocusAddress = () => {
    resetError('address')()
  }

  const handleSubmit = async () => {
    const curErrors = {}
    if (_.isEmpty(userName)) curErrors.userName = 'Enter your name'
    if (_.isEmpty(accountName)) curErrors.accountName = 'Enter company name'
    if (!_.has(address, 'timeZone')) curErrors.address = 'Enter a valid company address'
    if (_.isEmpty(curErrors)) {
      setLoading(true)
      await updateAdditionalInfo(userName, accountName, address)
      navigate('/')
      setLoading(false)
    } else {
      setErrors(curErrors)
    }
  }

  return (
    <Box pad='medium' align='start' fill>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box fill='horizontal' align='center' margin={{ top: 'small' }} flex={false} pad='medium'>
        <Box flex={false} width='500px'>
          <Box align='start'>
            <Text textAlign='start' size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT}>
              Complete your profile
            </Text>
          </Box>

          <ThemeContext.Extend
            value={{
              formField: {
                round: '5px'
              }
            }}
          >
            <Box margin={{ vertical: 'medium' }} flex={{ shrink: 0 }}>
              <FormField label={<Text>Your name</Text>} flex={{ shrink: 0 }} error={_.get(errors, 'userName')}>
                <TextInput
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                  onFocus={resetError('userName')}
                />
              </FormField>
              <FormField label={<Text>Company name</Text>} error={_.get(errors, 'accountName')} flex={{ shrink: 0 }}>
                <TextInput
                  value={accountName}
                  onChange={e => setAccountName(e.target.value)}
                  disabled={!canEditCompanySettings}
                  onFocus={resetError('accountName')}
                />
              </FormField>
              <FormField label='Company address' flex={{ shrink: 0 }} error={_.get(errors, 'address')}>
                <AddressAutocomplete
                  onSelect={setAddress}
                  value={getAddress(address)}
                  onFocus={onFocusAddress}
                  disabled={!canEditCompanySettings}
                />
              </FormField>
            </Box>
          </ThemeContext.Extend>
          <Box>
            <Box fill='horizontal' height='45px'>
              <Button
                fill
                primary
                color={colors.AQUA_MARINE}
                label='Next'
                onClick={handleSubmit}
                size='large'
                disabled={loading}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Onboarding
