import _ from 'lodash'

import config from 'shared/config'
import { getName } from 'shared/utils/stringUtils'
import store from 'model/store'
import { getCurrentAccountProfile, getCurrentUserProfile } from 'model/selectors/profiles'

const sendForDev = false
const onboardingId = _.now()

const sendEvent = async (url, body) => {
  if (!config.isDev || sendForDev) {
    const response = await window.fetch(url, {
      method: 'post',
      headers: {},
      body: JSON.stringify(body)
    })
    await response.json()
  } else {
    console.log('skip sending for dev')
  }
}

export const trackOnboardingStarted = async () => {
  const state = store.getState()
  const profile = getCurrentUserProfile(state)
  const accountProfile = getCurrentAccountProfile(state)
  const account = state.account
  const accountId = _.get(account, 'id')
  try {
    const body = {
      id: onboardingId,
      userId: profile.id,
      userName: getName(profile),
      userPhone: _.get(profile, 'phone'),
      companyName: _.get(accountProfile, 'name'),
      accountId: accountId,
      isDev: config.isDev
    }
    await sendEvent(config.zapierOnboardingStarted, body)
  } catch (e) {
    console.log('trackOnboardingStarted error', e)
  }
}
