import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text, Button } from 'shared/components'
import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrashCan } from '@fortawesome/pro-regular-svg-icons'

const ContactsSideMenuItem = ({ title, amount, isSelected, onClick, canEdit, onRename, onDelete }) => {
  const rightPart = () => {
    if (canEdit) {
      return (
        <Box direction='row' align='center'>
          <Button
            icon={<FontAwesomeIcon icon={faPen} />}
            color={colors.BROWN_GREY_TWO}
            size='small'
            onClick={onRename}
          />
          <Button
            margin={{ right: 'xsmall' }}
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            color={colors.BROWN_GREY_TWO}
            size='small'
            onClick={onDelete}
          />
          <Text margin={{ right: 'small' }} color={colors.BROWN_GREY_TWO}>
            {amount}
          </Text>
        </Box>
      )
    } else {
      return (
        <Box pad={{ right: 'small' }}>
          <Text color={colors.BROWN_GREY_TWO}>{amount}</Text>
        </Box>
      )
    }
  }

  const containerCustomStyle =
    canEdit &&
    `
      div button {
        display: none;
      }
      :hover {
        div button {
          display: block;
        }
        div span {
          display: none;
        }
      }
    `

  return (
    <Box
      background={isSelected ? { color: colors.AQUA_MARINE, opacity: 0.25 } : 'transparent'}
      onClick={onClick}
      height='40px'
      pad={{ left: 'small' }}
      direction='row'
      justify='between'
      align='center'
      hoverIndicator={isSelected ? undefined : colors.TABLE_HOVER}
      customStyle={containerCustomStyle}
    >
      <Text weight={500} color={colors.TEXT_PRIMARY}>
        {title}
      </Text>
      {rightPart()}
    </Box>
  )
}

ContactsSideMenuItem.defaultProps = {
  onClick: () => null,
  onRename: () => null,
  onDelete: () => null
}

ContactsSideMenuItem.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  canEdit: PropTypes.bool,
  onRename: PropTypes.func,
  onDelete: PropTypes.func
}

export default ContactsSideMenuItem
