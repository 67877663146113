import React, { useState, useImperativeHandle } from 'react'
import { Layer, Button, Box, Text, Card, CardHeader, CardBody, CardFooter } from 'shared/components'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { useNavigate } from 'react-router'

import colors from 'shared/constants/colors'
import { saveProject, changeArchiveProjectStatus } from 'controllers/project'
import { navigateToHomePage } from 'controllers/init'
import CreateProjectForm from 'modals/projectModal/CreateProjectForm'
import { generateId } from 'controllers/db'
import ProjectLabel from 'modals/projectModal/ProjectLabel'
import Tip from 'shared/components/Tip'

const ProjectModal = ({ projectModalRef }) => {
  const navigate = useNavigate()

  const projects = useSelector(state => state.projects)
  const user = useSelector(state => state.user)
  const account = useSelector(state => state.account)

  const [project, setProject] = useState()
  const [visible, setVisible] = useState(false)
  const [isNew, setIsNew] = useState()

  useImperativeHandle(projectModalRef, () => ({
    open: (projectId, activeTab = 0) => {
      console.log('open', projectId, 'activeTab', activeTab)
      const id = projectId || generateId()
      const existingProject = _.get(projects, id, {})
      setVisible(true)
      setIsNew(_.isEmpty(existingProject))
      setProject({
        ...existingProject,
        id
      })
    }
  }))

  const close = () => {
    setVisible(false)
  }

  const onChange = kind => v => {
    console.log('onChange', kind, v)
    setProject({
      ...project,
      [kind]: v
    })
  }

  const submit = async () => {
    console.log('save project')
    const params = {
      ...project,
      accountId: _.get(account, 'id'),
      createdBy: _.get(user, 'id')
    }
    if (isNew) {
      _.set(params, 'createdAt', _.now())
    } else {
      _.set(params, 'updatedAt', _.now())
    }
    console.log('save project', params)
    saveProject(params, [])
    close()
    navigate(`project/${project.id}`, { state: { projectId: project.id } })
  }

  const handleArchiveClick = () => {
    changeArchiveProjectStatus(project.id, project.deleted === 0)
    close()
    navigateToHomePage(navigate)
  }

  const renderSubmitButton = () => {
    const disabled = !_.has(project, 'address.timeZone')
    const buttonName = isNew ? 'Create' : 'Save'
    const submitButton = (
      <Box direction='row' margin='medium'>
        <Button primary label={buttonName} onClick={submit} disabled={disabled} color={colors.LIGHT_NAVY_BRIGHT} />
      </Box>
    )
    if (!_.has(project, 'address.timeZone')) {
      return (
        <Tip message='Enter a valid project address to continue' appearance='above'>
          {submitButton}
        </Tip>
      )
    } else {
      return submitButton
    }
  }

  const renderFooter = () => {
    return (
      <CardFooter align='center' justify='end'>
        <Box direction='row' justify='between' fill='horizontal' pad={{ right: 'medium', left: 'large' }}>
          <Box justify='center'>
            {!isNew && (
              <Button
                color={colors.CORAL_TWO}
                label={project.deleted > 0 ? 'Unarchive' : 'Archive'}
                onClick={handleArchiveClick}
              />
            )}
          </Box>
          <Box>{renderSubmitButton()}</Box>
        </Box>
      </CardFooter>
    )
  }

  const renderCardBody = () => {
    return (
      <Box fill='horizontal' align='center'>
        <Box alignSelf='center' width='large'>
          <CreateProjectForm isNew={isNew} projectId={project.id} params={project} onChange={onChange} />
        </Box>
      </Box>
    )
  }

  if (visible) {
    return (
      <Layer onEsc={close} onClickOutside={close}>
        <Card width='xlarge'>
          <CardHeader
            pad='medium'
            border={{
              color: 'border',
              size: '1px',
              side: 'bottom'
            }}
          >
            <Box
              direction='row'
              gap='medium'
              align='center'
              customStyle='* span { white-space: nowrap }'
              flex={{ grow: 1 }}
            >
              <Text size='xxlarge' color={colors.BLACK} weight={600}>
                {isNew ? 'Create Project' : 'Edit Project'}
              </Text>
              <ProjectLabel
                onChange={onChange('label')}
                projectLabel={project.label}
                addressName={_.get(project, 'address.name')}
              />
            </Box>
            <Button plain icon={<FontAwesomeIcon icon={faTimes} fontSize={24} />} onClick={close} />
          </CardHeader>
          <CardBody pad={{ horizontal: 'medium' }} overflow='auto' wrap={false} width='xlarge'>
            {renderCardBody()}
          </CardBody>
          {renderFooter()}
        </Card>
      </Layer>
    )
  } else {
    return null
  }
}

export default React.memo(ProjectModal)
