import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { collection, query, where, onSnapshot, doc, updateDoc, setDoc, deleteField } from '@firebase/firestore'

import { receivePrivateWorkOrders } from 'model/actions/privateWorkOrdersAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { db } from 'controllers/db'

export const fetchPrivateWorkOrders = accountId => {
  try {
    console.log('fetch fetchPrivateWorkOrders, accountId:', accountId)
    const q = query(collection(db, 'privateWorkOrders'), where('accountId', '==', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const wo = doc.data()
          _.set(res, doc.id, wo)
        })
        console.log('fetchPrivateWorkOrders received', _.size(res))
        store.dispatch(receivePrivateWorkOrders(res))
      },
      err => {
        console.log(`fetchPrivateWorkOrders error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('privateWorkOrders', unsubscribe)
  } catch (e) {
    console.log('fetchWorkOrders error', e)
    Sentry.captureException(e)
  }
}

const updatePrivateWorkOrder = async (workOrderId, upd) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const privateWorkOrderExists = _.has(state, ['privateWorkOrders', workOrderId])
  // console.log('privateWorkOrderExists', privateWorkOrderExists)
  const ref = doc(db, 'privateWorkOrders', workOrderId)
  if (!privateWorkOrderExists) {
    await setDoc(ref, { accountId }, { merge: true })
  }
  await updateDoc(ref, upd)
}

export const updateAlternatesConf = async (workOrderId, alternatesConf) => {
  await updatePrivateWorkOrder({ alternatesConf })
}

export const updateMerging = async (workOrderId, merging) => {
  console.log('update merging', workOrderId, merging)
  await updatePrivateWorkOrder({ merging })
}

export const updatePlugs = async (workOrderId, itemId, subId, plug) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`plugs.${accountId}.${itemId}.${subId}`]: plug
  }
  await updatePrivateWorkOrder(workOrderId, upd)
}

export const removePlug = async (workOrderId, itemId, subId) => {
  const accountId = _.get(store.getState(), 'account.id')
  const upd = {
    [`plugs.${accountId}.${itemId}.${subId}`]: deleteField()
  }
  await updatePrivateWorkOrder(workOrderId, upd)
}
