import React, { useMemo, useEffect, useState } from 'react'
import { Box, Text } from 'shared/components'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import _ from 'lodash'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import HeaderFirstCell from 'pages/leveling/levelingTable/HeaderFirstCell'
import levelingTableMode from 'constants/levelingTableMode'
import CellTitle from 'components/leveling/CellTitle'
import InviteSubDrop from 'modals/InviteSubDrop'
import lineItemSection from 'shared/constants/lineItemSection'
import LineItemTitle from 'components/leveling/LineItemTitle'
import { getLevelingTableMessages } from 'model/selectors/channels'
import { toTimestamp } from 'shared/utils/date'
import BidCell from 'components/leveling/BidCell'
import SectionTotal from 'components/leveling/SectionTotal'
import SectionTitle from 'components/leveling/SectionTitle'
import { fetchSubsNotes } from 'controllers/notes'

const TableContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  overflow: auto;
  div {
    z-index: 1;
  }
  > div:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
  }
  ${props =>
    props.quantities
      ? `> div:nth-child(2) {
        position: sticky;
        left: 400px;
        z-index: 5;
        background-color: ${colors.PALE_GREY}
      }`
      : ''}
`
const TableColumn = styled.div`
  display: grid;
  ${props => props.customStyle};
  grid-template-rows: ${props => props.gridTemplateRows};
  div {
    z-index: 1;
    /* background-color: ${colors.WHITE}; */
  }
  > div:first-child {
    position: sticky;
    top: 0;
    z-index: 3;
  }
`

const ScopeOfWorkTable = ({
  scope,
  scopeSections,
  invitations,
  workOrder,
  selectedAlternates,
  accountsProfiles,
  selectedCell,
  openSubDetails,
  merging,
  onNewLineItemAction,
  toggleItemSelected,
  selectedLineItems,
  openLineItemDetails,
  messages,
  channel,
  onAlternateSelected,
  requestPrice,
  openCellDetails,
  removePriceRequest,
  undoMerge,
  userId,
  settings
}) => {
  const { quantities, sections, compact } = settings
  const [notes, setNotes] = useState(null)

  const workOrderId = _.get(workOrder, 'id')
  const accountId = _.get(workOrder, 'accountId')
  // console.log('invitations', invitations)

  useEffect(() => {
    const ids = _.compact(_.map(invitations, ({ subAccountId, id }) => subAccountId || id))
    const fetchNotes = async () => {
      const res = await fetchSubsNotes(accountId, workOrderId, ids)
      setNotes(res)
    }
    try {
      fetchNotes()
    } catch (e) {
      console.log('fetch notes error', e.message)
    }
  }, [invitations, accountId, workOrderId])

  const gridTemplateColumns = useMemo(() => {
    const bidsArray = _.fill(Array(_.size(invitations) + 1), '224px')
    const firstRows = quantities ? '400px 70px' : '400px'
    return `${firstRows} ${bidsArray.join(' ')}`
  }, [invitations, quantities])

  const scopeItems = useMemo(() => {
    if (_.isEmpty(scopeSections)) {
      return _.values(scope)
    } else {
      const itemsInSections = _.keyBy(_.flatten(_.map(scopeSections, s => s.items)))
      const items = _.reduce(
        scopeSections,
        (res, s) => {
          if (s.id === lineItemSection.GENERAL_EXCLUSIONS_ID) {
            return res
          } else {
            const sectionItems = _.filter(_.uniq(s.items), i => _.has(scope, i))
            const items = _.map(sectionItems, itemId => _.get(scope, itemId))
            return [...res, ..._.compact(items)]
          }
        },
        []
      )
      _.forEach(scope, (item, itemId) => {
        if (!_.has(itemsInSections, itemId)) {
          items.push(item)
        }
      })
      return items
    }
  }, [scope, scopeSections])

  const unapprovedItems = useMemo(() => {
    const unapprovedLineItems = []
    _.forEach(invitations, inv => {
      const invLineItems = _.get(inv, 'bid.items', {})
      const invSections = _.get(inv, 'bid.sections', {})
      const geSectionItems = _.get(
        _.find(invSections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID),
        'items',
        []
      )
      _.forOwn(invLineItems, (item, itemId) => {
        if (
          _.has(item, 'id') &&
          !_.has(scope, itemId) &&
          !item.declined &&
          !_.has(merging, itemId) &&
          !_.includes(geSectionItems, itemId)
        ) {
          unapprovedLineItems.push({
            unapproved: true,
            subId: inv.id,
            ...item
          })
        }
      })
    })
    return unapprovedLineItems
  }, [invitations, scope, merging])

  const items = useMemo(() => {
    return [...scopeItems, ...unapprovedItems]
  }, [unapprovedItems, scopeItems])

  const itemsNotInSections = useMemo(() => {
    const itemsInSections = _.flatten(_.map(scopeSections, s => s.items))
    const scopeItemsIds = _.map(scopeItems, item => _.toString(_.get(item, 'id')))
    return _.difference(scopeItemsIds, itemsInSections)
  }, [scopeItems, scopeSections])

  const gridTemplateRows = useMemo(() => {
    const rowHeight = compact ? '40px' : '69px'
    const acc = (res, s) => {
      if (s.id === lineItemSection.GENERAL_EXCLUSIONS_ID) {
        return res
      } else {
        let scopeItems = []
        if (s.id === lineItemSection.SCOPE_OF_WORK_ID) {
          const items = _.filter(_.get(s, 'items', []), i => _.has(scope, i))
          scopeItems = _.uniq(_.concat(items, unapprovedItems, _.keys(itemsNotInSections)))
        } else {
          scopeItems = _.uniq(s.items)
        }
        if (_.size(scopeItems) === 0) return res
        const sectionTemplate = _.flatten([...res, '40px', _.fill(Array(_.size(scopeItems)), rowHeight)])
        return sectionTemplate
      }
    }
    const result = sections
      ? _.reduce(scopeSections, acc, ['69px'])
      : _.flatten(['69px', _.fill(Array(_.size(items)), rowHeight)])
    return result.join(' ')
  }, [unapprovedItems, itemsNotInSections, scopeSections, sections, items, compact, scope])

  const removalRequests = useMemo(() => {
    let res = {}
    _.forEach(invitations, inv => {
      const rr = _.get(inv, ['bid', 'removalRequests'], {})
      res = _.assign(res, rr)
      // console.log('removal requests computation')
    })
    return res
  }, [invitations])

  const renderLineItemTitle = (item, index, sectionItemIndex) => {
    const itemId = _.get(item, 'id')
    const removalRequest = _.get(removalRequests, itemId)
    const selected = `${itemId}` === selectedCell
    // const item = _.get(invitationsDict, [invId, 'bid', 'items', itemId])
    return (
      <LineItemTitle
        key={`${itemId}_${sectionItemIndex}_title`}
        item={item}
        num={index + 1}
        onNewLineItemAction={onNewLineItemAction}
        removalRequest={removalRequest}
        toggleItemSelected={toggleItemSelected}
        isSelected={_.get(selectedLineItems, itemId, false)}
        focused={selected}
        onClick={() => openLineItemDetails(itemId)}
        compact={compact}
      />
    )
  }

  const renderSectionTitle = ({ title, id }) => (
    <SectionTitle
      key={id}
      id={id}
      title={title}
      workOrderId={_.get(workOrder, 'id')}
      projectId={_.get(workOrder, 'projectId')}
    />
  )

  const firstColumnRows = () => {
    let res = []
    if (sections) {
      let itemIndex = 0
      _.forEach(scopeSections, section => {
        const sectionItems = _.get(section, 'items', [])
        let sectionItemIndex = 0
        if (section.id !== lineItemSection.GENERAL_EXCLUSIONS_ID) {
          _.forEach(sectionItems, itemId => {
            const item = _.get(scope, itemId, null)
            if (!_.isNil(item)) {
              res.push(renderLineItemTitle(item, itemIndex, sectionItemIndex))
              itemIndex++
              sectionItemIndex++
            }
          })
          if (section.id === lineItemSection.SCOPE_OF_WORK_ID) {
            _.forEach(itemsNotInSections, itemId => {
              const item = _.get(scope, itemId, null)
              if (!_.isNil(item)) {
                res.push(renderLineItemTitle(item, itemIndex, sectionItemIndex))
                itemIndex++
                sectionItemIndex++
              }
            })
            _.forEach(unapprovedItems, item => {
              if (!_.isNil(item)) {
                res.push(renderLineItemTitle(item, itemIndex, sectionItemIndex))
                sectionItemIndex++
              }
            })
          }
          if (sectionItemIndex > 0) {
            const position = _.size(res) - sectionItemIndex
            res.splice(position, 0, renderSectionTitle(section))
          }
        }
      })
    } else {
      res = _.map(items, renderLineItemTitle)
    }
    return res
  }

  const firstColumn = () => {
    return (
      <TableColumn gridTemplateRows={gridTemplateRows}>
        <Box>
          <HeaderFirstCell mode={levelingTableMode.SCOPE_OF_WORK} tradeName={_.get(workOrder, 'title')} />
        </Box>
        {firstColumnRows()}
      </TableColumn>
    )
  }

  const renderLineItemQuantity = (item, index) => {
    const itemId = _.get(item, 'id')
    const quantity = _.get(item, 'quantity', '')
    let quantityType = _.get(item, 'quantityType', 'units')
    if (quantityType === '') quantityType = 'units'
    return (
      <Box key={`${itemId}_${index}`} align='center' justify='center'>
        <Text size='small' color={colors.TEXT}>
          {quantity ? numeral(quantity).format('0,0.[00]') : ''}
        </Text>
        {quantity && (
          <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
            {quantityType}
          </Text>
        )}
      </Box>
    )
  }

  const prepareItems = () => {
    let res = []
    if (sections) {
      _.forEach(scopeSections, section => {
        if (section.id !== lineItemSection.GENERAL_EXCLUSIONS_ID) {
          let sectionItemIndex = 0
          const sectionItems = _.get(section, 'items', [])
          _.forEach(sectionItems, itemId => {
            const item = _.get(scope, itemId, null)
            if (!_.isNil(item)) {
              res.push(item)
              sectionItemIndex++
            }
          })
          if (section.id === lineItemSection.SCOPE_OF_WORK_ID) {
            _.forEach(itemsNotInSections, itemId => {
              const item = _.get(scope, itemId, null)
              if (!_.isNil(item)) {
                res.push(item)
                sectionItemIndex++
              }
            })
            _.forEach(unapprovedItems, item => {
              if (!_.isNil(item)) {
                sectionItemIndex++
                res.push(item)
              }
            })
          }
          if (sectionItemIndex > 0) {
            const position = _.size(res) - sectionItemIndex
            res.splice(position, 0, { id: section.id })
          }
        }
      })
    } else {
      res = items
    }
    return res
  }

  const renderQuantities = (
    <TableColumn gridTemplateRows={gridTemplateRows}>
      <Box align='center' justify='center' background={colors.PALE_GREY}>
        <Text size='xsmall' color={colors.ANOTHER_GREY}>
          Quantities
        </Text>
      </Box>
      {_.map(prepareItems(), renderLineItemQuantity)}
    </TableColumn>
  )

  const renderBidCell = (item, inv, index) => {
    const itemId = _.get(item, 'id')
    const subId = _.get(inv, 'id')
    const bid = _.get(inv, 'bid')
    const plug = _.get(workOrder, ['plugs', itemId, subId])
    const key = `${inv.id}_${itemId}_${index}`
    const selected = key === selectedCell
    const cellMessages = _.get(messages, ['cells', subId, itemId])
    const lastMessage = _.last(cellMessages)
    const lastMessageUserId = _.get(lastMessage, 'userId')
    const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp'))
    const lastReadTime = toTimestamp(_.get(channel, `${workOrderId}_${subId}_${itemId}`))
    if (itemId === 'pdThRkBEqdVQloBo2OlB') {
      console.log('=======> inv', inv, 'lineItemBid', _.get(bid, ['items', itemId]))
    }
    return (
      <BidCell
        key={key}
        lineItemBid={_.get(bid, ['items', itemId])}
        item={item}
        index={index}
        scope={scope}
        inv={inv}
        activeCell={null}
        onAlternateSelected={onAlternateSelected}
        selectedAlternate={_.get(selectedAlternates, [subId, itemId])}
        onNewLineItemAction={onNewLineItemAction}
        requestPrice={requestPrice}
        removalRequests={removalRequests}
        selectCell={openCellDetails}
        plug={plug}
        removePriceRequest={removePriceRequest}
        selected={selected}
        undoMerge={undoMerge}
        hasMessages={!_.isEmpty(cellMessages)}
        hasNewMessage={lastReadTime < lastMessageTime && lastMessageUserId !== userId}
        workOrderId={workOrderId}
      />
    )
  }

  const renderSectionTotal = (total, inv, index) => <SectionTotal key={`${inv.id}_${index}`} total={total} />

  const renderSubRows = inv => {
    if (sections) {
      const bid = _.get(inv, 'bid')
      const res = []
      let sectionIndex = 0
      _.forEach(scopeSections, ({ id, items }) => {
        if (id !== lineItemSection.GENERAL_EXCLUSIONS_ID) {
          let sectionTotal = 0
          let sectionItemIndex = 0
          _.forEach(items, (itemId, index) => {
            const item = _.get(scope, itemId, _.get(bid, ['items', itemId]))
            if (!_.isNil(item)) {
              const bid = _.get(inv, 'bid')
              if (!_.isNil(bid)) {
                const selectedAlternate = _.get(selectedAlternates, [inv.id, itemId])
                if (!_.isNil(selectedAlternate)) {
                  const itemTotal = Number(
                    _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate, 'total'], 0)
                  )
                  sectionTotal = _.isNaN(itemTotal) ? sectionTotal : sectionTotal + itemTotal
                } else {
                  const itemTotal = Number(_.get(bid, ['items', itemId, 'total'], 0))
                  sectionTotal = _.isNaN(itemTotal) ? sectionTotal : sectionTotal + itemTotal
                }
              }
              sectionItemIndex++
              res.push(renderBidCell(item, inv, index))
            }
          })
          if (id === lineItemSection.SCOPE_OF_WORK_ID) {
            const bid = _.get(inv, 'bid')
            _.forEach(itemsNotInSections, (itemId, index) => {
              const item = _.get(scope, itemId)
              if (!_.isNil(bid)) {
                const selectedAlternate = _.get(selectedAlternates, [inv.id, itemId], null)
                if (!_.isNil(selectedAlternate)) {
                  const itemTotal = Number(
                    _.get(inv, ['bid', 'items', itemId, 'alternates', selectedAlternate, 'total'], 0)
                  )
                  sectionTotal = _.isNaN(itemTotal) ? sectionTotal : sectionTotal + itemTotal
                } else {
                  const itemTotal = Number(_.get(bid, ['items', itemId, 'total'], 0))
                  sectionTotal = _.isNaN(itemTotal) ? sectionTotal : sectionTotal + itemTotal
                }
              }
              sectionItemIndex++
              res.push(renderBidCell(item, inv, index))
            })
            _.forEach(unapprovedItems, (item, index) => {
              if (
                _.includes(_.keys(_.get(bid, 'items')), _.get(item, 'id')) &&
                !_.has(removalRequests, _.get(item, 'id'))
              ) {
                sectionTotal += Number(_.get(item, 'total', 0))
              }
              sectionItemIndex++
              res.push(renderBidCell(item, inv, index))
            })
          }
          if (id === lineItemSection.QUESTIONS_ID) sectionTotal = null
          if (!_.isNil(_.get(inv, 'bid')) && sectionItemIndex > 0) {
            const position = _.size(res) - sectionItemIndex
            res.splice(position, 0, renderSectionTotal(sectionTotal, inv, sectionIndex))
          }
          sectionIndex++
        }
      })
      return res
    } else {
      return _.map(items, (item, index) => renderBidCell(item, inv, index))
    }
  }

  const renderSubColumn = inv => {
    let accountId = _.get(inv, 'subAccountId')
    if (_.isNil(accountId)) {
      accountId = _.get(inv, 'id')
    }
    const cellMessages = _.get(messages, ['subs', accountId])
    const cellNotes = _.get(notes, accountId)
    return (
      <TableColumn key={`column_${inv.id}`} gridTemplateRows={gridTemplateRows}>
        <CellTitle
          inv={inv}
          workOrder={workOrder}
          scope={scope}
          selectedAlternates={selectedAlternates}
          accountsProfiles={accountsProfiles}
          selectedCell={selectedCell}
          openSubDetails={openSubDetails}
          cellMessages={cellMessages}
          cellNotes={cellNotes}
        />
        {renderSubRows(inv)}
      </TableColumn>
    )
  }

  const inviteSubCell = (
    <TableColumn gridTemplateRows={gridTemplateRows}>
      <Box
        background={colors.WHITE}
        customStyle={`border: 2px solid ${colors.PALE_GREY}; border-top-color: ${colors.ALMOST_WHITE}; :hover { background-color: ${colors.ALMOST_WHITE}`}
      >
        <InviteSubDrop
          key='inviteSubDrop'
          dropButtonLabel={
            <Box fill pad='none' align='center' justify='center'>
              <FontAwesomeIcon icon={faPlus} color={colors.LIGHT_NAVY} fontSize={16} />
            </Box>
          }
          invitations={invitations}
          workOrderId={workOrderId}
          dropButtonProps={{ fill: true, pad: 'none', round: 'none', plain: true }}
        />
      </Box>
    </TableColumn>
  )

  return (
    <Box flex>
      <TableContainer gridTemplateColumns={gridTemplateColumns} quantities={quantities}>
        {firstColumn()}
        {quantities && renderQuantities}
        {_.map(invitations, renderSubColumn)}
        {inviteSubCell}
      </TableContainer>
    </Box>
  )
}

const mapStateToProps = state => ({
  messages: getLevelingTableMessages(state),
  accountsProfiles: state.accountsProfiles,
  channel: state.channelWeb,
  userId: _.get(state, 'user.id'),
  settings: state.customizeSettings
})

export default connect(mapStateToProps)(ScopeOfWorkTable)
