import _ from 'lodash'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { collectionGroup, query, where, orderBy, limit, getDocs, onSnapshot, startAfter } from 'firebase/firestore'

import { receiveInboxFirstMessage, receiveMessagesInbox, receiveInboxFirstNote } from 'model/actions/messagesAC'
import { addListener } from 'controllers/listeners'
import { INBOX_MESSAGES_LIMIT } from 'constants/index'
import { toTimestamp } from 'shared/utils/date'
import messageType from 'constants/messageType'
import { db } from 'controllers/db'
import store from 'model/store'

export const fetchInbox = async (userId, accountId) => {
  console.log('--------------------- fetch inbox:', userId)
  try {
    const firstMessageQuery = query(
      collectionGroup(db, 'messagesList'),
      where('incomingForUsers', 'array-contains', userId),
      orderBy('timestamp', 'asc'),
      limit(1)
    )
    const firstMsgSN = await getDocs(firstMessageQuery)
    const firstMessage = _.get(
      _.map(firstMsgSN.docs, doc => doc.data()),
      0
    )
    console.log('first message', firstMessage)
    store.dispatch(receiveInboxFirstMessage(firstMessage))
    if (firstMessage.type === messageType.NOTE) {
      store.dispatch(receiveInboxFirstNote(firstMessage))
    } else {
      const firstNoteQuery = query(
        collectionGroup(db, 'messagesList'),
        where('incomingForUsers', 'array-contains', userId),
        where('type', '==', messageType.NOTE),
        orderBy('timestamp', 'asc'),
        limit(1)
      )
      const firstNoteSN = await getDocs(firstNoteQuery)
      const firstNote = _.get(
        _.map(firstNoteSN.docs, doc => doc.data()),
        0
      )
      store.dispatch(receiveInboxFirstNote(firstNote))
    }
    const q = query(
      collectionGroup(db, 'messagesList'),
      where('incomingForUsers', 'array-contains', userId),
      orderBy('timestamp', 'desc'),
      limit(INBOX_MESSAGES_LIMIT)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const annotations = _.map(sn.docs, doc => doc.data())
        console.log('inbox messages', annotations)
        store.dispatch(receiveMessagesInbox(_.keyBy(annotations, 'id')))
      },
      error => {
        Sentry.captureException(error)
        console.log(`fetchAnnotationsInbox  onSnapshot error: ${error}`)
      }
    )
    addListener('annotationsInbox', unsubscribe)
  } catch (e) {
    console.log('fetchInbox error')
    console.warn(e)
  }
}

export const fetchInboxNotes = (userId, callback, lastMessage = null) => {
  try {
    const qWithLastMessage = query(
      collectionGroup(db, 'messagesList'),
      where('incomingForUsers', 'array-contains', userId),
      where('type', '==', messageType.NOTE),
      orderBy('timestamp', 'desc'),
      startAfter(moment(_.get(lastMessage, 'timestamp')).toDate()),
      limit(INBOX_MESSAGES_LIMIT)
    )
    const qWithoutLastMessage = query(
      collectionGroup(db, 'messagesList'),
      where('incomingForUsers', 'array-contains', userId),
      where('type', '==', messageType.NOTE),
      orderBy('timestamp', 'desc'),
      limit(INBOX_MESSAGES_LIMIT)
    )
    const q = !_.isNil(lastMessage) ? qWithLastMessage : qWithoutLastMessage
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const notes = _.map(sn.docs, m => m.data())
        callback(notes)
      },
      error => {
        Sentry.captureException(error)
        console.log(`fetchInboxNotes onSnapshot error: ${error}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('fetchInboxNotes error')
    console.warn(e)
  }
}

export const loadMoreInbox = async () => {
  try {
    const state = store.getState()
    const userId = _.get(state, 'user.id')
    // const accountId = _.get(state, 'account.id')
    const inbox = _.get(state, 'inbox')
    console.log(inbox)
    const sortedMessages = _.sortBy(inbox.messages, m => toTimestamp(m.timestamp))
    const lastMessage = _.get(sortedMessages, 0)
    console.log('lastInboxMessage', lastMessage)
    if (!_.isNil(lastMessage)) {
      const newMessagesRef = query(
        collectionGroup(db, 'messagesList'),
        where('incomingForUsers', 'array-contains', userId),
        orderBy('timestamp', 'asc'),
        startAfter(lastMessage.timestamp),
        limit(INBOX_MESSAGES_LIMIT)
      )
      const newMessagesSN = await getDocs(newMessagesRef)
      const newMessages = _.keyBy(
        _.map(newMessagesSN.docs, doc => ({ ...doc.data(), id: doc.id })),
        'id'
      )
      console.log('newMessages', newMessages)
      store.dispatch(receiveMessagesInbox(newMessages))
    }
  } catch (e) {
    console.log('load more inbox error', e)
    Sentry.captureException(e)
  }
}
