import React, { useImperativeHandle, useState } from 'react'
import _ from 'lodash'
import { Importer, ImporterField } from 'react-csv-importer'

import { Layer, Box, ModalHeader } from 'shared/components'
import colors from 'shared/constants/colors'
import { formatPhone } from 'shared/utils/phone'
import { isEmail } from 'validator'
import { addNewContacts } from 'controllers/contacts'

const ImportContacts = ({ modalRef }) => {
  const [show, setShow] = useState(false)
  const [importedCompanies, setImportedCompanies] = useState({})
  const [importedContacts, setImportedContacts] = useState({})

  const close = () => setShow(false)
  const open = () => {
    console.log('open import contacts')
    setShow(true)
  }

  useImperativeHandle(modalRef, () => ({
    open
  }))

  const stringToId = s => {
    return _.snakeCase(_.trim(s))
  }

  const parsePhones = s => {
    const ar = _.split(s, /(?:,|\/)+/)
    const phonesRaw = _.map(ar, formatPhone)
    return _.compact(phonesRaw)
  }

  const parseEmails = s => {
    const ar = _.split(s, /(?:,|\/)+/)
    const emailsRaw = _.filter(ar, emailRaw => isEmail(_.trim(emailRaw)))
    return _.compact(_.map(emailsRaw, _.trim))
  }

  const parseList = s => _.compact(_.map(_.split(s, /(?:,|\/)+/), _.trim))

  const processRows = async rows => {
    console.log('rows', rows)
    const companies = {}
    const contacts = {}
    _.forEach(rows, row => {
      const company = {
        id: stringToId(_.get(row, 'companyName')),
        name: _.get(row, 'companyName'),
        phones: parsePhones(_.get(row, 'phone')),
        emails: parseEmails(_.get(row, 'email')),
        location: _.get(row, 'location'),
        trades: parseList(_.get(row, 'trades')),
        divisions: parseList(_.get(row, 'divisions'))
      }
      const contact = {
        companyId: company.id,
        id: stringToId(_.get(row, 'contactName')),
        name: _.get(row, 'contactName'),
        phones: parsePhones(_.get(row, 'phone')),
        emails: parseEmails(_.get(row, 'email')),
        tags: parseList(_.get(row, 'tag'))
      }
      if (!_.isEmpty(company.id)) {
        _.set(companies, company.id, company)
      }
      if (!_.isEmpty(contact.id)) {
        _.set(contacts, contact.id, contact)
      }
    })
    setImportedCompanies(importedCompanies => ({ ...importedCompanies, ...companies }))
    setImportedContacts(importedContacts => ({ ...importedContacts, ...contacts }))
  }

  const applyImport = async () => {
    console.log('imported companies', importedCompanies)
    console.log('imported contacts', importedContacts)
    await addNewContacts(importedCompanies, importedContacts)
    close()
  }

  const importer = (
    <Importer
      chunkSize={10000}
      assumeNoHeaders={false}
      restartable={false}
      processChunk={processRows}
      onComplete={async ({ file, fields }) => {
        await applyImport()
      }}
      onClose={close}
    >
      <ImporterField name='contactName' label='Contact Name' />
      <ImporterField name='companyName' label='Company Name' optional />
      <ImporterField name='email' label='Emails' optional />
      <ImporterField name='phone' label='Phones' optional />
      <ImporterField name='tag' label='Tags' optional />
      <ImporterField name='location' label='Location' optional />
      <ImporterField name='trades' label='Trade(s)' optional />
      <ImporterField name='divisions' label='Dividion(s)' optional />
    </Importer>
  )

  if (show) {
    return (
      <Layer
        position='center'
        onClickOutside={close}
        onEsc={close}
        margin={{ horizontal: 'xlarge', vertical: 'medium' }}
      >
        <Box
          width='large'
          customStyle={`
            .CSVImporter_FileSelector {
              border: 0.25em dashed ${colors.VERY_LIGHT_PINK};
              background: ${colors.WHITE};
              margin: 7em 4em;
              border-radius: 0;
            };
            .CSVImporter_ImporterFrame {
              border-radius: 0;
              border: none;
            };
            form {
              width: 100%;
            };
          `}
        >
          <ModalHeader title='Import contacts' onClose={close} />
          <Box overflow={{ vertical: 'auto' }}>{importer}</Box>
        </Box>
      </Layer>
    )
  } else {
    return null
  }
}

export default ImportContacts
