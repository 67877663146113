import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { Button } from 'shared/components'
import _ from 'lodash'

import ProgressBar from 'shared/components/ProgressBar'
import { saveFileObject } from 'controllers/storage'
import colors from 'shared/constants/colors'
import { generateId } from 'controllers/db'

const FilesPickerButton = ({ onComplete, storagePath, ButtonComponent, disabled, inputProps }) => {
  const filePickerRef = useRef()
  const user = useSelector(state => state.user)
  const [progress, setProgress] = useState(null)
  const [inputKey, setInputKey] = useState(_.now())
  const [cancelled, setCancelled] = useState(false)

  const renderUploadFileButtonSecondary = () => {
    if (!_.isNil(ButtonComponent)) {
      return <ButtonComponent onClick={openFilePicker} />
    } else {
      return (
        // <ThemeContext.Extend
        //   value={{ button: { hover: { default: { extend: `svg > path {fill: ${colors.BLACK80};}` } } } }}
        // >
        <Button
          alignSelf='start'
          icon={<FontAwesomeIcon icon={faPaperclip} />}
          // icon={<FontAwesomeIcon icon={faPaperclip} color={colors.VERY_LIGHT_PINK} fontSize={12} />}
          // label={<Text size='small'>Upload file</Text>}
          label='Upload file'
          size='small'
          color={colors.VERY_LIGHT_PINK}
          // gap='xsmall'
          onClick={openFilePicker}
          disabled={disabled}
        />
        // </ThemeContext.Extend>
      )
    }
  }

  const openFilePicker = () => {
    setCancelled(false)
    filePickerRef.current.click()
  }

  const deleteFiles = files => {
    // _.forEach(files, f => {
    //   const p = `${storagePath}/${f.id}`
    //   deleteFile(p)
    // })
  }

  const onFilesPicked = async event => {
    const upl = _.values(_.get(event, 'target.files'))
    console.log('onFilesPicked', upl)
    const count = _.size(upl)
    const res = []
    for (const i in upl) {
      if (cancelled) break
      const fileRaw = upl[i]
      console.log(i, 'fileRaw ===>', fileRaw)
      const id = generateId()
      const p = `${storagePath}/${id}`

      const onProgress = v => {
        console.log('onProgress', v)
        setProgress(_.ceil(v / count + (i / count) * 100))
      }
      const fileInfo = await saveFileObject(fileRaw, p, onProgress)
      res.push({
        id,
        createdAt: _.now(),
        name: _.get(fileRaw, 'name'),
        createdBy: user.id,
        ...fileInfo
      })
    }

    console.log('res', res)
    setInputKey(_.now())

    setProgress(null)
    if (cancelled) {
      deleteFiles(res)
    } else {
      onComplete(res)
    }
  }

  const renderProgress = () => {
    if (!_.isNaN(progress)) {
      return <ProgressBar progress={progress} onCancel={() => setCancelled(true)} />
    } else {
      return null
    }
  }

  return (
    <>
      {renderUploadFileButtonSecondary()}
      <input
        key={inputKey}
        ref={filePickerRef}
        id='file-input'
        onChange={onFilesPicked}
        type='file'
        style={{ display: 'none' }}
        multiple
        {...inputProps}
      />
      {renderProgress()}
    </>
  )
}

FilesPickerButton.defaultProps = {
  inputProps: {}
}

FilesPickerButton.propTypes = {
  storagePath: PropTypes.string,
  ButtonComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  inputProps: PropTypes.object
}

export default FilesPickerButton
