import React, { useState, useImperativeHandle } from 'react'
import {
  Layer,
  Button,
  Box,
  Text,
  FormField,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ThemeContext
} from 'shared/components'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import colors from 'shared/constants/colors'
import { getAddress } from 'shared/utils/stringUtils'
import TradesSelect from 'components/bidInviteModal/TradeSelect'
import BidInviteFiles from 'components/bidInviteModal/BidInviteFiles'
import DatePicker from 'shared/components/DatePicker'
import { saveBidInvite, updateBidInvite, changeArchiveWorkOrderStatus } from 'controllers/workOrder'
import { getTradesDict } from 'model/selectors/references'
import { generateId } from 'controllers/db'

const BidInviteModal = ({ bidInviteModalRef }) => {
  const [visible, setVisible] = useState(false)
  const [projectId, setProjectId] = useState(null)
  const [workOrderId, setWorkOrderId] = useState(null)
  const [tradeId, setTradeId] = useState(null)
  const [files, setFiles] = useState({})
  const [bidsDueDate, setBidsDueDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [rfiDueDate, setRfiDueDate] = useState(null)
  const [jobWalkStartDate, setJobWalkStartDate] = useState(null)
  const [label, setLabel] = useState('')
  const [update, setUpdate] = useState(false)
  const [deleted, setDeleted] = useState(0)

  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const projects = useSelector(state => state.projects)
  const workOrders = useSelector(state => state.workOrders)
  const contractorTypes = useSelector(getTradesDict)
  const project = _.get(projects, projectId)
  const address = _.get(project, 'address')
  const timeZone = _.get(address, 'timeZone')
  let tradeName = _.has(contractorTypes, tradeId) ? ` - ${_.get(contractorTypes, [tradeId, 'name'])}` : ''
  if (!_.isEmpty(label)) tradeName = ` - ${label}`

  useImperativeHandle(bidInviteModalRef, () => ({ open }))

  const open = ({ projectId, workOrderId }) => {
    const wo = _.get(workOrders, workOrderId, {})
    const deleted = _.get(wo, 'deleted')
    const update = !!workOrderId
    setVisible(true)
    setProjectId(projectId)
    setWorkOrderId(workOrderId || generateId(_.now()))
    setUpdate(update)
    setDeleted(deleted)
  }
  const close = () => setVisible(false)
  const updateFiles = files => {
    setFiles(files)
  }
  const onSelectTradeId = tradeId => setTradeId(tradeId)
  const saveWorkOrder = async () => {
    const params = {
      id: workOrderId,
      projectId,
      tradeId,
      files,
      bidsDueDate,
      startDate,
      rfiDueDate,
      teamLeadId: _.get(user, 'id', null)
    }
    params.label = label === '' ? null : label
    close()
    if (update) {
      await updateBidInvite(params)
    } else {
      await saveBidInvite(params)
      navigate(`/project/${projectId}/workorder/${workOrderId}/leveling`)
    }
  }
  const handleArchiveClick = () => {
    changeArchiveWorkOrderStatus(workOrderId, deleted === 0)
    close()
  }

  const labelInfo = (
    <Text size='small' color={colors.ANOTHER_GREY}>
      Maximum 50 character
    </Text>
  )

  if (!visible) return null

  return (
    <Layer onEsc={close} onClickOutside={close} background={colors.WHITE}>
      <Card>
        <CardHeader
          pad='medium'
          border={{
            color: 'border',
            size: '1px',
            side: 'bottom'
          }}
        >
          <Text size='xlarge' color={colors.BLACK} weight={600}>
            {!update && 'Add bid invitation - '}
            {`${getAddress(address)}`}
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: 'medium' }} overflow='auto' wrap={false}>
          <Box flex={false} direction='row' margin={{ top: 'medium' }}>
            <TradesSelect tradeId={tradeId} onSelect={onSelectTradeId} disabled={update} />
            <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
              <FormField
                label='Label'
                margin={{ left: 'small' }}
                width='medium'
                round='small'
                info={_.size(label) >= 50 && labelInfo}
              >
                <TextInput
                  placeholder='Label this bid invite (optional)'
                  color='dark-1'
                  maxLength={50}
                  value={label}
                  onChange={e => setLabel(e.target.value)}
                />
              </FormField>
            </ThemeContext.Extend>
          </Box>

          <Text size='small' margin={{ top: 'medium' }} color={colors.BROWN_GREY_TWO}>
            {`Files${tradeName}`}
          </Text>

          <BidInviteFiles project={project} files={files} setFiles={updateFiles} workOrderId={workOrderId} />

          <Text size='small' margin={{ top: 'large' }} color={colors.BROWN_GREY_TWO}>
            {`Important dates${tradeName}`}
          </Text>

          <Box direction='row' margin={{ vertical: 'small' }} height={{ min: 'xxsmall' }}>
            <Box flex>
              <DatePicker
                title='Job Walk Date'
                value={jobWalkStartDate}
                timeZone={timeZone}
                onChange={setJobWalkStartDate}
                disabled
              />
            </Box>
            <Box flex>
              <DatePicker title='RFI Due Date' value={rfiDueDate} timeZone={timeZone} onChange={setRfiDueDate} />
            </Box>
          </Box>
          <Box direction='row' margin={{ bottom: 'medium' }} height={{ min: 'xxsmall' }}>
            <Box flex>
              <DatePicker title='Bids Due Date' value={bidsDueDate} timeZone={timeZone} onChange={setBidsDueDate} />
            </Box>
            <Box flex>
              <DatePicker title='Expected Start Date' value={startDate} timeZone={timeZone} onChange={setStartDate} />
            </Box>
          </Box>
        </CardBody>
        <CardFooter align='center'>
          <Box direction='row' justify='between' fill='horizontal' pad={{ right: 'medium', left: 'large' }}>
            <Box justify='center'>
              {update && (
                <Button
                  color={colors.CORAL_TWO}
                  label={deleted > 0 ? 'Unarchive' : 'Archive'}
                  onClick={handleArchiveClick}
                />
              )}
            </Box>
            <Box>
              <Button
                primary
                margin='medium'
                label={update ? 'Save' : 'Create'}
                onClick={saveWorkOrder}
                customStyle='border-radius: 4px'
                disabled={_.isNil(tradeId)}
              />
            </Box>
          </Box>
        </CardFooter>
      </Card>
    </Layer>
  )
}

export default BidInviteModal
