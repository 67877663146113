import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Button as GrommetButton, ThemeContext } from 'grommet'
import { deepMerge } from 'grommet/utils'

import colors from 'shared/constants/colors'

const DEFAULT = 'DEFAULT'
const PRIMARY = 'PRIMARY'
const SECONDARY = 'SECONDARY'

export const createButtonTheme = props => {
  const getKind = () => {
    if (props.primary) {
      return PRIMARY
    } else if (props.secondary) {
      return SECONDARY
    } else {
      return DEFAULT
    }
  }

  const kind = getKind()

  const iconOnly = _.isEmpty(props.label) && !_.isEmpty(props.icon)

  // The color of the text label.
  const labelColor = () => {
    switch (kind) {
      case PRIMARY:
        return colors.WHITE
      default:
        return props.color
    }
  }

  const borderColor = () => {
    switch (kind) {
      case DEFAULT:
        return colors.WHITE
      default:
        return props.color
    }
  }

  const primaryHoverBackgroundColor = () => {
    switch (props.color) {
      case colors.LIGHT_NAVY_BRIGHT:
        return colors.LIGHT_NAVY_HOVER
      case colors.AQUA_MARINE:
        return colors.AQUA_MARINE_HOVER
      case colors.CLEAR_BLUE:
        return colors.CLEAR_BLUE_HOVER
      case colors.CORAL_TWO:
        return colors.CORAL_TWO_HOVER
      default:
        return props.color
    }
  }

  const defaultHoverColor = () => {
    switch (props.color) {
      case colors.LIGHT_NAVY_BRIGHT:
        return colors.LIGHT_NAVY_HOVER
      case colors.AQUA_MARINE:
        return colors.AQUA_MARINE_HOVER
      case colors.CLEAR_BLUE:
        return colors.CLEAR_BLUE_HOVER
      case colors.CORAL_TWO:
        return colors.CORAL_TWO_HOVER
      case colors.VERY_LIGHT_PINK:
      case colors.ASANA_GRAY_TEXT_HOVERED:
        return colors.LIGHT_NAVY_BRIGHT
      default:
        return props.color
    }
  }

  const themeValue = {
    button: {
      extend: 'line-height: 100%;',
      color: labelColor(),
      transition: {
        properties: ['background-color'],
        duration: 0.2
      },
      border: {
        width: kind === SECONDARY ? '1.56px' : '0px',
        color: borderColor()
      },
      disabled: {
        primary: {
          background: {
            color: primaryHoverBackgroundColor()
          },
          color: colors.WHITE
        },
        opacity: 0.5
      },
      hover: {
        default: {
          background: {
            color: props.noHover ? 'transparent' : colors.VERY_LIGHT_GREY_THREE
          },
          color: defaultHoverColor()
        },
        primary: {
          background: {
            color: primaryHoverBackgroundColor()
          },
          color: colors.WHITE
        },
        secondary: {
          background: {
            color: props.color,
            opacity: 0.12
          }
        }
      },
      size: {
        small: {
          border: {
            radius: '4px'
          },
          pad: {
            vertical: '8px',
            horizontal: iconOnly ? '8px' : '12px'
          }
        },
        medium: {
          border: {
            radius: '5px'
          },
          pad: {
            vertical: '10px',
            horizontal: iconOnly ? '10px' : '18px'
          }
        },
        large: {
          border: {
            radius: '5px'
          },
          pad: {
            vertical: '13px',
            horizontal: iconOnly ? '13px' : '24px'
          }
        }
      },
      default: {
        color: props.color,
        border: undefined,
        font: {
          weight: 500
        }
      },
      primary: {
        background: {
          color: props.color
        },
        border: undefined,
        color: colors.WHITE,
        font: {
          weight: 500
        }
      },
      secondary: {
        border: {
          color: props.color,
          width: '1.56px'
        },
        color: props.color,
        font: {
          weight: 500
        }
      }
    }
  }

  return themeValue
}

export const Button = React.forwardRef(({ customTheme, ...props }, ref) => {
  const themeValue = createButtonTheme(props)
  return (
    <ThemeContext.Extend value={deepMerge(themeValue, customTheme)}>
      <GrommetButton ref={ref} {...props} flex={{ grow: 0 }} />
    </ThemeContext.Extend>
  )
})

Button.defaultProps = {
  size: 'medium',
  color: colors.LIGHT_NAVY_BRIGHT,
  reverse: false,
  primary: false,
  secondary: false,
  icon: undefined,
  gap: 'xsmall',
  customTheme: {},
  noHover: false
}

Button.propTypes = {
  /**
  button size
  */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
  button color
  */
  color: PropTypes.oneOf([
    colors.LIGHT_NAVY_BRIGHT,
    colors.AQUA_MARINE,
    colors.CLEAR_BLUE,
    colors.CORAL_TWO,
    colors.VERY_LIGHT_PINK,
    colors.WHITE,
    colors.ASANA_GRAY_TEXT_HOVERED,
    colors.BROWN_GREY_TWO
  ]),
  /**
  Whether this is a primary button. There should be at most one per page or screen.
  */
  primary: PropTypes.bool,
  /**
  Whether this is a secondary button.
  */
  secondary: PropTypes.bool,
  /** Whether an icon and label should be reversed so that the icon is at the end of the anchor. */
  reverse: PropTypes.bool,
  /** Icon element to place in the button. */
  icon: PropTypes.element,
  /** The amount of spacing between icon and label in the button. */
  gap: PropTypes.oneOfType([
    PropTypes.oneOf(['none', 'xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
    PropTypes.string
  ]),
  /** Custom theme object that will be merged with the current one and override it */
  customTheme: PropTypes.object,
  /** Disable hover indication of default button */
  noHover: PropTypes.bool
}

export default Button
