import _ from 'lodash'
import { doc, updateDoc } from '@firebase/firestore'

import store from 'model/store'
import { db } from 'controllers/db'

export const editUserRole = async (userId, roleId) => {
  const state = store.getState()
  const accountId = _.get(state, 'user.currentAccountId')
  const ref = doc(db, 'accounts', accountId)
  await updateDoc(ref, { [`admins.${userId}.role`]: roleId })
}
