import React, { useCallback } from 'react'
import { Box, Text, Button, Menu } from 'shared/components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faExternalLink,
  faPaperclip,
  faSlidersHSquare,
  faUserPlus
  // faFilePlus
} from '@fortawesome/pro-light-svg-icons'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'
import { useNavigate } from 'react-router'

import colors from 'shared/constants/colors'
import InviteSubDrop from 'modals/InviteSubDrop'
// import levelingTableMode from 'constants/levelingTableMode'
import { changeArchiveWorkOrderStatus, sendDelayedInvite } from 'controllers/workOrder'
import sendTypes from 'shared/constants/inviteStatus'
// import CreateBidManuallyDrop from 'components/leveling/CreateBidManuallyDrop'
import reportType from 'shared/constants/reportType'
import LevelingCustomizePanel from 'panels/LevelingCustomizePanel'
import Tip from 'shared/components/Tip'

const ARCHIVE = 'ARCHIVE'
const EDIT = 'EDIT'
const ADD_INTERNAL_BID = 'ADD_INTERNAL_BID'

const LevelingSubmenu = ({
  projectId,
  workOrderId,
  openFilesModal,
  setMode,
  mode,
  openWorkOrderDetails,
  isArchived,
  delayedInvitations,
  accountId,
  invitations,
  invitationsWithNoBids,
  openCreateBidManuallyModal,
  settingsChanged,
  isEmptyState
}) => {
  const navigate = useNavigate()

  const toEditScope = () => {
    navigate(`/project/${projectId}/${workOrderId}/scope`)
    // universalNavigation.push(screens.GC_PROJECT_WORKORDER, { projectId, workOrderId })
  }

  const generateReport = useCallback(() => {
    navigate(`/project/${projectId}/share/${reportType.SELECTED_BIDS}`)
    // universalNavigation.push(screens.GC_PROJECT_SHARE, { projectId, reportType: reportType.SELECTED_BIDS })
  }, [projectId])

  const onMenuOptionClick = id => {
    console.log('onMenuOptionClick', id)
    switch (id) {
      case EDIT:
        openWorkOrderDetails()
        break
      case ARCHIVE: {
        changeArchiveWorkOrderStatus(workOrderId, !isArchived)
        if (!isArchived) {
          navigate(`/project/${projectId}`)
          // universalNavigation.push(screens.GC_PROJECT, { projectId })
        }
        break
      }
      case ADD_INTERNAL_BID: {
        openCreateBidManuallyModal(accountId)
        break
      }
      default:
        break
    }
  }

  const inviteSubDropLabel = (
    <Box direction='row' gap='small'>
      <Box direction='row'>
        <FontAwesomeIcon icon={faUserPlus} fontSize={16} />
      </Box>
      <Text>Invite Subs</Text>
    </Box>
  )

  const inviteSubButton = (
    <Box pad={{ left: 'xsmall' }}>
      <InviteSubDrop
        key='inviteSubDrop'
        dropButtonProps={{ color: colors.ASANA_GRAY_TEXT_HOVERED, size: 'small' }}
        dropButtonLabel={inviteSubDropLabel}
        // invitations={sortedInvitations}
        workOrderId={workOrderId}
      />
    </Box>
  )

  const editScopeButton = (
    <Button
      icon={<FontAwesomeIcon icon={faEdit} fontSize={16} />}
      label='Edit scope'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={toEditScope}
    />
  )

  const openFilesButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPaperclip} fontSize={16} />}
      label='Plan room'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={openFilesModal}
    />
  )

  const boxButtonCustomStyle = `
    :hover {
      background-color: ${colors.VERY_LIGHT_GREY_THREE};
      span {
        color: ${colors.BLACK80}
      }
      svg path {
        color: ${colors.BLACK80};
        fill: ${colors.BLACK80};
      }
    }
  `

  // const addBidManuallyLabel = (
  //   <Box direction='row' justify='center' gap='small'>
  //     <FontAwesomeIcon icon={faFilePlus} color={colors.ASANA_GRAY_TEXT_HOVERED} />
  //     <Text>Add bid manually</Text>
  //   </Box>
  // )

  // const addBidManuallyButton = (
  //   <CreateBidManuallyDrop
  //     workOrderId={workOrderId}
  //     invitationsWithNoBids={invitationsWithNoBids}
  //     invitations={invitations}
  //     dropButtonLabel={addBidManuallyLabel}
  //     onOpen={openCreateBidManuallyModal}
  //     dropButtonProps={{ color: colors.ASANA_GRAY_TEXT_HOVERED }}
  //   />
  // )

  // const addInternalBidButton = (
  //   <Button
  //     icon={<FontAwesomeIcon icon={faFileImport} />}
  //     label='Add internal bid'
  //     color={colors.ASANA_GRAY_TEXT_HOVERED}
  //     disabled
  //     onClick={() => null}
  //   />
  // )

  // const customizeLabel = () => {
  //   if (settingsChanged === 0) {
  //     return (
  //       <Box direction='row' gap='xsmall' align='center'>
  //         <FontAwesomeIcon icon={faSlidersHSquare} fontSize={16} />
  //         <Text>Views</Text>
  //       </Box>
  //     )
  //   } else {
  //     return (
  //       <Box direction='row' gap='xsmall' align='center'>
  //         <FontAwesomeIcon icon={faSlidersHSquare} fontSize={16} />
  //         <Text>
  //           {settingsChanged} customization{settingsChanged > 1 ? 's' : ''}
  //         </Text>
  //       </Box>
  //     )
  //   }
  // }

  const customizeLabel = () => {
    if (settingsChanged === 0) {
      return 'Views'
    } else {
      return `${settingsChanged} customization${settingsChanged > 1 ? 's' : ''}`
    }
  }

  const viewsButton = (
    <LevelingCustomizePanel
      dropButtonLabel={customizeLabel()}
      dropButtonProps={{
        color: colors.VERY_LIGHT_PINK,
        icon: <FontAwesomeIcon icon={faSlidersHSquare} />
      }}
      onChangeMode={setMode}
      mode={mode}
    />
  )

  const shareViewButton = (
    <Tip message='Generate detailed reports to share with anyone'>
      <Button
        icon={<FontAwesomeIcon icon={faExternalLink} fontSize={16} />}
        label='Share view'
        color={colors.ASANA_GRAY_TEXT_HOVERED}
        onClick={generateReport}
      />
    </Tip>
  )

  // const backToScopeOfWork = (
  //   <Box direction='row' align='center' gap='medium' pad={{ right: 'medium' }}>
  //     <Text color={colors.BROWN_GREY_TWO}>Return to Leveling Table view</Text>
  //     <Button primary label='Close' onClick={() => setMode(levelingTableMode.SCOPE_OF_WORK)} />
  //   </Box>
  // )

  const moreButton = (
    <Menu
      options={[
        { id: EDIT, label: 'Edit Bid invitation' },
        { id: ADD_INTERNAL_BID, label: 'Add internal bid' },
        { id: ARCHIVE, label: isArchived ? 'Unarchive' : 'Archive', color: colors.CORAL_TWO }
      ]}
      dropContentProps={{ boxProps: { width: { min: '160px' } } }}
      dropAlign={{ top: 'bottom', left: 'left' }}
      onOptionClick={onMenuOptionClick}
    >
      <Box
        direction='row'
        align='center'
        round='xsmall'
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        gap='small'
        customStyle={boxButtonCustomStyle}
      >
        <FontAwesomeIcon icon={faEllipsisH} fontSize={16} color={colors.ASANA_GRAY_TEXT_HOVERED} />
      </Box>
    </Menu>
  )

  const sendDelayedInvites = () => {
    Promise.all(
      _.map(delayedInvitations, inv => {
        return sendDelayedInvite(accountId, workOrderId, inv.id, sendTypes.IMMEDIATELY)
      })
    )
  }

  const delayedMessages = _.size(delayedInvitations) > 0 && (
    <Box direction='row' gap='medium' align='center' margin={{ horizontal: 'small' }}>
      <Text size='medium' color={colors.AQUA_MARINE}>
        {`${_.size(delayedInvitations)} unsent invitations`}
      </Text>
      <Button primary color={colors.AQUA_MARINE} label='Send All' onClick={sendDelayedInvites} />
    </Box>
  )

  return (
    <Box
      fill='horizontal'
      direction='row'
      height='40px'
      background={colors.WHITE_GREY}
      align='center'
      justify='between'
      pad={{ left: 'small', right: 'small' }}
    >
      <Box direction='row' align='center' gap='small'>
        {inviteSubButton}
        {editScopeButton}
        {openFilesButton}
        {/* {addBidManuallyButton} */}
        {moreButton}
      </Box>
      <Box direction='row' align='center' gap='small'>
        {!isEmptyState && viewsButton}
        {shareViewButton}
        <Box
          border={{ side: 'left', size: 'xsmall', color: `${colors.VERY_LIGHT_GREY_THREE}` }}
          height={{ min: '20px' }}
        />
        {delayedMessages}
      </Box>
    </Box>
  )
}

LevelingSubmenu.defaultProps = {
  setMode: () => null
}

LevelingSubmenu.propTypes = {
  projectId: PropTypes.string,
  workOrderId: PropTypes.string,
  openFilesModal: PropTypes.func,
  panelRef: PropTypes.any,
  setMode: PropTypes.func,
  mode: PropTypes.string,
  openWorkOrderDetails: PropTypes.func,
  isArchived: PropTypes.bool
}

export default React.memo(LevelingSubmenu)
