import React, { useState, useImperativeHandle, useRef, useMemo } from 'react'

import SlidingLeftPanel from 'shared/components/SlidingLeftPanel'
import SubInfoPanelContent from 'panels/levelingPanel/SubInfoPanelContent'
import CellInfoPanelContent from 'panels/levelingPanel/CellInfoPanelContent'
import LineItemPanelContent from 'panels/levelingPanel/LineItemPanelContent'
import { useParams } from 'react-router'

const SUB = 'SUB'
const CELL = 'CELL'
const LINE_ITEM = 'LINE_ITEM'

const LevelingPanel = ({ panelRef }) => {
  const { projectId, workOrderId } = useParams()
  const pRef = useRef()
  const [mode, setMode] = useState()
  const [panelProps, setPanelProps] = useState({ close: () => null })

  useImperativeHandle(panelRef, () => ({
    openSubInfo,
    openCellInfo,
    openLiInfo,
    close
  }))

  const _open = (mode, params) => {
    setMode(mode)
    setPanelProps(params)
    pRef.current.open()
    // this.setState({ mode, panelProps: params }, pRef.current.open)
  }

  const openSubInfo = params => _open(SUB, params)

  const openCellInfo = params => _open(CELL, params)

  const openLiInfo = params => _open(LINE_ITEM, params)

  const close = () => pRef.current.close()

  const ContentComponent = useMemo(() => {
    switch (mode) {
      case SUB:
        return SubInfoPanelContent
      case CELL:
        return CellInfoPanelContent
      case LINE_ITEM:
        return LineItemPanelContent
      default:
        return null
    }
  }, [mode])

  return (
    <SlidingLeftPanel ref={pRef} onClose={() => (panelProps.onClose ? panelProps.onClose() : () => null)}>
      {ContentComponent && (
        <ContentComponent onClose={close} {...panelProps} projectId={projectId} workOrderId={workOrderId} />
      )}
    </SlidingLeftPanel>
  )
}

export default LevelingPanel
