import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { Text, Box, Button, ThemeContext, Avatar, TextArea, Keyboard } from 'shared/components'
import { css } from 'styled-components'
import { useMeasure } from 'react-use'

import { sendMessage } from 'controllers/chat'
import messageType from 'constants/messageType'
import { getInitials, getName } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'
import { useParams } from 'react-router'

const CellInfoPanelFooter = ({ subId, messagesEndRef, itemId }) => {
  const userProfile = useSelector(getCurrentUserProfile)
  const accountId = useSelector(state => state.account.id)
  const accountsProfiles = useSelector(state => state.accountsProfiles)
  const [message, setMessage] = useState()
  const [focused, setFocused] = useState(false)
  const [textAreaRef, wrapperParams] = useMeasure()
  const { workOrderId, projectId } = useParams()

  const profile = _.get(accountsProfiles, subId)

  const scrollToBottom = () => {
    if (_.has(messagesEndRef, 'current') && focused) messagesEndRef.current.scrollIntoView(false)
  }

  useEffect(() => {
    scrollToBottom()
  }, [wrapperParams.height])

  const handleBlur = e => {
    const { relatedTarget } = e
    if (_.get(relatedTarget, 'id') === 'send_message') {
      updateMessages()
      setFocused(false)
    } else {
      setFocused(false)
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      updateMessages()
    }
  }

  const updateMessages = () => {
    if (message !== '') {
      const params = _.omitBy(
        {
          text: message,
          accountId,
          itemId,
          subId,
          userId: userProfile.id,
          accounts: [accountId, subId],
          workOrderId,
          type: messageType.ITEM,
          projectId
        },
        _.isNil
      )
      sendMessage(params)
      setMessage('')
      scrollToBottom()
    }
  }

  const textInputThemeValue = {
    textArea: {
      extend: css`
        background: ${colors.WHITE};
      `
    }
  }

  return (
    <Box
      background={colors.PALE_GREY}
      direction='row'
      align='center'
      height={focused ? { min: '115px' } : { min: '60px' }}
      pad={focused ? { bottom: 'small' } : 'none'}
    >
      <Box fill='vertical' justify='start' pad='small' width={{ min: '55px' }}>
        <Avatar size='32px' src={_.get(userProfile, 'avatar')}>
          {getInitials(userProfile)}
        </Avatar>
      </Box>
      <Box
        fill
        margin={{ right: 'small' }}
        background={colors.WHITE}
        customStyle={`
          height: ${focused ? '90px' : '40px'};
          border-radius: 5px;
          padding-top: ${focused ? '6px' : '0'};
        `}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
      >
        <ThemeContext.Extend value={textInputThemeValue}>
          <Keyboard onKeyDown={handleKeyDown}>
            <TextArea
              ref={textAreaRef}
              plain
              onChange={event => setMessage(event.target.value)}
              value={message}
              size='small'
              resize={false}
              placeholder='Ask a question about this line item or post an update...'
            />
          </Keyboard>
        </ThemeContext.Extend>
        {focused && (
          <Box
            direction='row'
            gap='small'
            align='center'
            customStyle={`
              position: absolute;
              bottom: 20px;
              right: 15px;
              animation: slide-up 0.5s ease;
              @keyframes slide-up {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
              }
            `}
          >
            <Text color={colors.ANOTHER_GREY} size='xsmall'>
              {getName(profile)} will be notified
            </Text>
            <Box width='xsmall'>
              <Button
                disabled={!focused}
                id='send_message'
                fill
                size='small'
                primary
                label='Comment'
                color={colors.AQUA_MARINE}
                onClick={updateMessages}
                tabIndex={-1}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CellInfoPanelFooter
