import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Text, Button, DropButton } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faTimes } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import DropContent from 'shared/components/DropContent'
import colors from 'shared/constants/colors'
import { removeInvite } from 'controllers/invitations'
import { getManageUsersPermission } from 'model/selectors/permissionsSelector'
import roles from 'shared/constants/roles'

const menuActionId = {
  REMOVE_USER: 'REMOVE_USER',
  CHANGE_ROLE: 'CHANGE_ROLE'
}

const ActionButton = ({ user, onSetRemovingUser, onEditUserRole }) => {
  const currentUser = useSelector(state => state.user)
  const canManageUsers = useSelector(getManageUsersPermission)
  const [open, setOpen] = useState(false)

  const handleAction = actionId => {
    setOpen(false)
    switch (actionId) {
      case menuActionId.REMOVE_USER: {
        onSetRemovingUser(user)
        break
      }
      case menuActionId.CHANGE_ROLE: {
        onEditUserRole(user.id, roles.OWNER)
        break
      }
      default:
        break
    }
  }

  if (!canManageUsers) {
    return null
  } else if (user.invited) {
    return (
      <Button
        plain
        label={<FontAwesomeIcon icon={faTimes} fontSize={16} color={colors.MEDIUM_GREY} />}
        onClick={() => removeInvite(user.inviteId)}
      />
    )
  } else {
    return (
      <DropButton
        plain
        label={<FontAwesomeIcon icon={faEllipsisV} fontSize={16} color={colors.MEDIUM_GREY} />}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        dropContent={
          <DropContent
            onOptionClick={actionId => handleAction(actionId)}
            options={[
              {
                id: menuActionId.CHANGE_ROLE,
                label: 'Change role to Owner',
                disabled: _.get(user, 'role') === 'owner' || !canManageUsers
              },
              {
                id: menuActionId.REMOVE_USER,
                label: <Text color={colors.CORAL_TWO}>Remove user</Text>,
                disabled: _.isEqual(user.id, currentUser.id) || !canManageUsers
              }
            ]}
            boxProps={{ width: '200px' }}
            optionLabelProps={{ textAlign: 'start' }}
          />
        }
      />
    )
  }
}

ActionButton.propTypes = {
  user: PropTypes.object.isRequired,
  onSetRemovingUser: PropTypes.func.isRequired,
  onEditUserRole: PropTypes.func.isRequired
}

export default ActionButton
