import React, { useState, useImperativeHandle, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Layer, Text, ThemeContext, MaskedInput, TextArea, Button, Avatar } from 'shared/components'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import validator from 'validator'

import colors from 'shared/constants/colors'
import { getAccountAdmins } from 'model/selectors/base'
import { sendInvite, resendInvite } from 'controllers/invitations'
import { getInitials, getName } from 'shared/utils/stringUtils'
import { emailMask, phoneMask } from 'shared/utils/inputMask'
import { getManageUsersPermission } from 'model/selectors/permissionsSelector'
import RemoveTeammateModal from 'modals/RemoveTeammateModal'
import ActionButton from 'pages/settings/settingsTeammates/ActionButton'
import Row from 'components/settings/Row'
import roles from 'shared/constants/roles'
import { editUserRole } from 'controllers/account'

const PHONE_NUMBER_LENGTH = 14

const borderBottom = {
  color: colors.VERY_LIGHT_GREY_TWO,
  size: 'xsmall',
  side: 'bottom'
}

const TeammatesModal = ({ teammatesModalRef }) => {
  const [open, setOpen] = useState(false)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const removeUserModalRef = useRef()
  const currentUser = useSelector(state => _.get(state, 'user'))
  const canManageUsers = useSelector(getManageUsersPermission)
  const profiles = useSelector(state => _.get(state, 'profiles'))
  const admins = _.map(useSelector(getAccountAdmins), admin => ({ ...admin, ..._.get(profiles, admin.id) }))
  const invitations = _.map(
    _.values(
      useSelector(
        state => _.get(state, 'outgoingInvitations'),
        inv => ({
          ...inv,
          ..._.get(profiles, inv.userId),
          invited: true,
          inviteId: inv.id
        })
      )
    )
  )

  const handleClose = () => setOpen(false)
  const handleInviteModalOpen = () => setInviteModalOpen(!inviteModalOpen)
  const handleSubmit = () => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    sendInvite(phoneNumber.number, roles.OWNER, null, null, email, message)
    setEmail('')
    setPhone('')
    setMessage('')
    setInviteModalOpen(false)
  }
  const onSetRemovingUser = user => {
    removeUserModalRef.current.open(user)
  }

  useImperativeHandle(teammatesModalRef, () => ({
    open: () => setOpen(true)
  }))

  const renderRole = user => {
    if (_.get(user, 'invited')) {
      return (
        <Button
          primary
          color={colors.AQUA_MARINE}
          label='Resend'
          onClick={() => resendInvite(_.get(user, 'inviteId'))}
        />
      )
    } else {
      return (
        <Text size='small' color={colors.VERY_LIGHT_PINK}>
          {_.upperFirst(_.get(user, 'role'))}
        </Text>
      )
    }
  }

  const renderUser = user => {
    const email = _.get(user, 'email')
    const phone = _.get(user, 'phone') || ''
    const phoneNumber = phone ? parsePhoneNumberFromString(phone) : null
    const contacts = email ? `${email}${phoneNumber ? `, ${phoneNumber.formatNational()}` : ''}` : phone
    return (
      <Box
        flex={false}
        direction='row'
        align='center'
        key={_.get(user, 'id')}
        pad='small'
        border={borderBottom}
        hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
        height='52px'
        onClick={() => null}
      >
        <Box width='32px' height='32px' align='center' justify='center' flex={false}>
          <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={_.get(user, 'avatarSmall')}>
            <Text color={colors.WHITE}>{getInitials(user)}</Text>
          </Avatar>
        </Box>
        <Box fill margin={{ left: 'medium' }} align='start' justify='center' gap='xsmall'>
          <Text>{getName(user)}</Text>
          <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
            {contacts}
          </Text>
        </Box>
        <Box align='end' width='small' pad={{ horizontal: 'small' }}>
          {renderRole(user)}
        </Box>
        <Box width='xsmall' align='center'>
          <ActionButton
            user={user}
            onSetRemovingUser={onSetRemovingUser}
            onEditUserRole={editUserRole}
            currentUser={currentUser}
            canManageUsers={canManageUsers}
          />
        </Box>
      </Box>
    )
  }

  if (!open) return null
  return (
    <Layer onEsc={handleClose} onClickOutside={handleClose} animate={false} background={colors.WHITE}>
      <Box fill flex={false} overflow='scroll'>
        <Box alignSelf='center' width='large' direction='column' flex={false}>
          <Box
            direction='row'
            align='center'
            border={borderBottom}
            justify='between'
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <Text size='xlarge' color={colors.BLACK} weight={500} margin='small'>
              Teammates
            </Text>
            <Button
              plain
              label={<FontAwesomeIcon icon={faTimes} fontSize={24} color={colors.BLACK} />}
              onClick={handleClose}
            />
          </Box>
          <Box flex={false} fill='vertical' direction='column' pad={{ horizontal: 'medium', bottom: 'medium' }}>
            <Row onClick={handleInviteModalOpen} border={borderBottom}>
              <Box
                width='32px'
                height='32px'
                border={{ color: colors.ANOTHER_GREY, style: 'dashed' }}
                round='xxsmall'
                align='center'
                justify='center'
                responsive={false}
              >
                <FontAwesomeIcon icon={faPlus} fontSize={18} color={colors.ANOTHER_GREY} />
              </Box>
              <Text margin={{ left: 'medium' }} color={colors.ANOTHER_GREY}>
                Invite Teammate
              </Text>
            </Row>
            <Box overflow='auto' height='medium'>
              {_.map(_.concat(admins, invitations), renderUser)}
            </Box>
          </Box>
        </Box>
        <RemoveTeammateModal modalRef={removeUserModalRef} />
        {inviteModalOpen && (
          <ThemeContext.Extend value={{ layer: { border: { radius: 'xsmall' } } }}>
            <Layer
              position='top'
              onClickOutside={handleInviteModalOpen}
              onEsc={handleInviteModalOpen}
              modal
              margin={{ top: 'large' }}
            >
              <Box width='medium' height='500px' gap='small' flex={false}>
                <Box pad={{ top: 'medium', horizontal: 'medium' }} flex={false}>
                  <Text color={colors.TEXT} size='large'>
                    Invite teammate
                  </Text>
                </Box>
                <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
                  <Text color={colors.TEXT}>Email</Text>
                  <MaskedInput mask={emailMask} value={email} onChange={({ target }) => setEmail(target.value)} />
                </Box>
                <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
                  <Text color={colors.TEXT}>Phone number:</Text>
                  <MaskedInput mask={phoneMask} value={phone} onChange={({ target }) => setPhone(target.value)} />
                </Box>
                <Box pad={{ horizontal: 'medium' }} height='100px' flex={false}>
                  <TextArea
                    resize={false}
                    fill
                    placeholder='Add message (optional)'
                    value={message}
                    onChange={({ target }) => setMessage(target.value)}
                  />
                </Box>
                <Box
                  fill='horizontal'
                  margin={{ top: 'auto' }}
                  pad={{ vertical: 'small', horizontal: 'medium' }}
                  align='end'
                  border='top'
                  flex={false}
                >
                  <Box width='xsmall'>
                    <Button
                      disabled={!validator.isEmail(email) || phone.length < PHONE_NUMBER_LENGTH}
                      primary
                      color={colors.AQUA_MARINE}
                      label='Send'
                      onClick={handleSubmit}
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          </ThemeContext.Extend>
        )}
      </Box>
    </Layer>
  )
}

export default TeammatesModal
