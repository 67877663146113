import React from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Menu, Box, Avatar, Text } from 'shared/components'
import colors from 'shared/constants/colors'
import { signOut } from 'controllers/auth'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { getInitials } from 'shared/utils/stringUtils'

const menuActionId = {
  SETTINGS: 'SETTINGS',
  INVITE_TEAMMATES: 'INVITE_TEAMMATES',
  HELP: 'HELP',
  SIGN_OUT: 'SIGN_OUT'
}
const menuActions = [
  { id: menuActionId.SETTINGS, label: 'Settings' },
  { id: menuActionId.INVITE_TEAMMATES, label: 'Invite Teammates' },
  { id: menuActionId.HELP, label: 'Help & Support' },
  { id: menuActionId.SIGN_OUT, label: 'Sign out' }
]

const AccountButton = () => {
  const navigate = useNavigate()
  const userProfile = useSelector(getCurrentUserProfile)
  const avatar = _.get(userProfile, 'avatarSmall')

  const onMenuAction = actionId => {
    console.log('onMenuAction', actionId)
    switch (actionId) {
      case menuActionId.SETTINGS:
        navigate('/settings')
        break
      case menuActionId.INVITE_TEAMMATES:
        navigate('/settings', { state: { activeIndex: 2 } })
        break
      case menuActionId.SIGN_OUT:
        signOut()
        break
      case menuActionId.HELP:
        window.open('http://help.bidlevel.co/en/')
        break
      default:
        return null
    }
  }

  return (
    <Menu
      options={menuActions}
      dropContentProps={{ boxProps: { width: { min: '200px' } }, optionLabelProps: { textAlign: 'center' } }}
      dropAlign={{ top: 'bottom', right: 'right' }}
      onOptionClick={onMenuAction}
      dropProps={{ margin: { top: 'small' } }}
    >
      <Box direction='row' align='center'>
        <Avatar size='29px' src={avatar} background={colors.LIGHT_NAVY_BRIGHT}>
          <Text color={colors.WHITE}>{getInitials(userProfile)}</Text>
        </Avatar>
      </Box>
    </Menu>
  )
}

export default AccountButton
