import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { appInitialized } from 'controllers/init'
import { Main } from 'shared/components'

const NavWrapper = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log('call appInitialized')
    appInitialized(navigate)
  }, [])

  return <Main>{children}</Main>
}

export default NavWrapper
