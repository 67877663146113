import { createSelector } from 'reselect'
import _ from 'lodash'
import { getRolePermissions, getRolePermissionsDict } from 'model/selectors/rolesSelectors'
import permissions from 'shared/constants/permissions'

const getUser = state => _.get(state, 'user', {})
const getAccountAdmins = state => _.get(state, 'account.admins', {})

const getMyRole = createSelector([getUser, getAccountAdmins], (user, admins) => {
  const roleId = _.get(admins, [_.get(user, 'id'), 'role'])
  return roleId
})

const getMyPermissions = state => {
  const roleId = getMyRole(state)
  return getRolePermissions(state, roleId)
}

export const getCreateProposalPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.CREATE_ROPOSALS, false)
})

export const getPaymentRequestsPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.PAYMENT_REQUESTS, false)
})

export const getViewAllProjectPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.VIEW_ALL_PROJECTS, false)
})

export const getEditApproversPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.APPROVERS, false)
})

export const getEditRolesPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.ROLES, false)
})

export const getManageUsersPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.MANAGE_USERS, false)
})

export const getCanAcceptAppointmentPermission = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.CAN_ACCEPT_APPOINTMENT, false)
})

export const getCanEditCompanySettings = createSelector([getMyPermissions], myPermissions => {
  return _.get(myPermissions, permissions.EDIT_COMPANY_SETTINGS, false)
})

export const getAdminsWhoCanViewAllProjects = createSelector(
  [getAccountAdmins, getRolePermissionsDict],
  (admins, rolesDict) => {
    const res = []
    for (const uid in admins) {
      const roleId = _.get(admins, [uid, 'role'])
      if (_.get(rolesDict, [roleId, permissions.VIEW_ALL_PROJECTS], false)) {
        res.push(uid)
      }
    }
    return res
  }
)
