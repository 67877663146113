import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { collection, query, where, onSnapshot, doc, updateDoc, serverTimestamp, deleteField } from '@firebase/firestore'

import { addListener } from 'controllers/listeners'
import { receiveBids } from 'model/actions/bidsAC'
import store from 'model/store'
import { db, auth } from 'controllers/db'
import bidStatus from 'shared/constants/bidStatus'
import { updateWorkOrder } from 'controllers/workOrder'
import config from 'shared/config'
import headers from 'shared/controllers/headers'

export const fetchBids = accountId => {
  try {
    console.log('fetchBids, accountId:', accountId)
    const state = store.getState()
    const showArchived = _.get(state, 'settings.showArchived', false)
    const allBidsQuery = query(collection(db, 'bids'), where('accounts', 'array-contains', accountId))
    const activeBidsQuery = query(
      collection(db, 'bids'),
      where('accounts', 'array-contains', accountId),
      where('deleted', '==', 0)
    )
    const q = showArchived ? allBidsQuery : activeBidsQuery
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const bid = doc.data()
          _.set(res, doc.id, bid)
        })
        console.log('fetchBids:', _.size(res))
        store.dispatch(receiveBids(res))
      },
      err => {
        Sentry.captureException(err)
        console.log(`fetchBids error: ${err}`)
      }
    )
    addListener('bids', unsubscribe)
  } catch (e) {
    console.log('fetchBids error', e)
    Sentry.captureException(e)
  }
}

export const updateBid = async (bidId, upd) => {
  try {
    const ref = doc(db, 'bids', bidId)
    await updateDoc(ref, upd)
  } catch (e) {
    console.log('updateBid error', e)
    Sentry.captureException(e)
  }
}

export const approveBid = async bid => {
  try {
    const uid = auth.currentUser.uid
    const status = {
      type: bidStatus.ACCEPTED,
      userId: uid,
      timestamp: serverTimestamp()
    }
    const upd = {
      status,
      seenBy: { [uid]: _.now() }
    }
    await updateBid(bid.id, upd)
    await updateWorkOrder(bid.workOrderId, { approvedBidId: bid.id })
  } catch (e) {
    Sentry.captureException(e)
    console.log('approveBid error:', e.message)
  }
}

export const declineBid = async bid => {
  try {
    console.log('decline bid', bid)
    const uid = auth.currentUser.uid
    const status = {
      type: bidStatus.DECLINED,
      userId: uid,
      timestamp: serverTimestamp()
    }
    const upd = {
      status,
      seenBy: { uid: _.now() }
    }
    await updateBid(bid.id, upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('declineBid error:', e.message)
  }
}

export const hideBid = async bid => {
  try {
    const upd = {
      'status.hidden': true
    }
    await updateBid(bid.id, upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('hideBid error:', e.message)
  }
}

export const removeApprovedBid = async bid => {
  try {
    await updateWorkOrder(bid.workOrderId, { approvedBidId: deleteField() })
    await updateBid(bid.id, { status: deleteField() })
  } catch (e) {
    console.log('removeApprovedBid', e.message)
  }
}

export const removeBid = async bidId => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const functionUrl = `${config.dynamicUrl}/proto/removeBid`
    const body = {
      authToken,
      bidId
    }
    const response = await window.fetch(functionUrl, {
      method: 'post',
      headers,
      body: JSON.stringify(body)
    })
    if (response.status !== 200) {
      console.log('removeBid response status', response.status)
    }
  } catch (e) {
    console.log('removeBid error', e.message)
  }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const downloadEstimatePdf = async bid => {
  try {
    const url = `${config.dynamicUrl}/proto/downloadEstimatePdf`
    const response = await window.fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ bid })
    })
    const res = await response.json()
    const base64String = _.get(res, 'base64')
    if (!_.isNil(base64String)) {
      const blob = b64toBlob(base64String, 'application/pdf')
      const fileUrl = URL.createObjectURL(blob)
      window.open(fileUrl)
      return null
    } else {
      return null
    }
  } catch (e) {
    console.log('downloadEstimatePdf error:', e)
    Sentry.captureException(e)
    return null
  }
}

export const removeBidFile = async (workOrderId, bidId, fileId, needToRemoveFromStorage = true) => {
  try {
    await updateDoc(doc(db, 'bids', bidId), { [`files.${fileId}`]: deleteField() })
  } catch (error) {
    console.log(error.message)
  }
}

export const updateBidFiles = async (bidId, files) => {
  await updateDoc(doc(db, 'bids', bidId), { files })
}
