import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TextInput } from 'grommet'
import * as Sentry from '@sentry/react'
import { getDomesticAddress } from 'shared/utils/stringUtils'
import * as googleApis from 'shared/utils/googleApis'

const MIN_SEARCH_LENGTH = 2
const MAX_LENGTH = 100

class AddressAutocomplete extends Component {
  constructor (props) {
    super(props)
    this.state = {
      text: '',
      selected: [],
      options: []
    }
    this.searchingRequests = []
    this.search = _.debounce(this.search, 500)
  }

  static getDerivedStateFromProps (props, prevState) {
    if (prevState.prevValue !== props.value) {
      // console.log('GDSFP', props.value)
      return {
        text: props.value || '',
        options: [],
        prevValue: props.value
      }
    }
    return null
  }

  componentWillUnmount () {
    this.abortSearchingRequests()
    this.onDetailsReceived = () => null
  }

  clearPredictions = () => this.setState({ options: [], selected: [] })

  abortSearchingRequests = () => {
    this.searchingRequests.forEach(request => {
      request.abort()
    })
    this.searchingRequests = []
  }

  getTextForSearchResult = searchResult =>
    searchResult.description || searchResult.formatted_address || searchResult.name

  updateSearchResults = searchResults => {
    const options = searchResults.map((item, index) => ({
      value: index,
      // label: this.getTextForSearchResult(item),
      label: getDomesticAddress(item),
      item: item
    }))
    this.setState({ options })
  }

  search = text => {
    const { sessionToken } = this.state
    if (text.length >= MIN_SEARCH_LENGTH) {
      this.abortSearchingRequests()
      const request = googleApis.autocomplete(text, sessionToken, responseJSON => {
        const results = _.get(responseJSON, 'predictions', [])
        if (!_.isEmpty(results)) {
          this.updateSearchResults(results)
        } else {
          this.updateSearchResults([])
        }
      })
      this.searchingRequests.push(request)
    } else {
      this.updateSearchResults([])
    }
  }

  onChange = event => {
    const text = event.target.value
    // console.log('address autocompletion onChange', text)
    this.setState({
      text: text.substring(0, MAX_LENGTH)
    })
    this.search(text)
  }

  onDetailsReceived = addressInfo => {
    // console.log('addressInfo', addressInfo)
    this.props.onSelect(addressInfo)
  }

  onSelect = async event => {
    const item = _.get(event, 'suggestion.item')
    // console.log('onPressSearchResult', item, 'event', event)
    const placeId = item.place_id
    const structured = {
      main: _.get(item, 'structured_formatting.main_text', null),
      secondary: _.get(item, 'structured_formatting.secondary_text', null)
    }
    try {
      const res = await googleApis.getPlaceDetailsFormatted(placeId, structured)
      this.onDetailsReceived({ ...res, placeId })
    } catch (e) {
      Sentry.captureException(e)
      console.log('get details error', e)
    }
  }

  onBlur = () => {
    this.setState({ focused: false, text: this.props.value })
    this.props.onBlur()
  }

  render () {
    const { text, options } = this.state
    const { placeholder, error, onFocus, disabled, textInputProps } = this.props
    // console.log('Address autocomplete render, options', options)
    return (
      <TextInput
        suggestions={options}
        value={text}
        onSuggestionSelect={this.onSelect}
        preferredPosition='below'
        dropAlign={{ left: 'left', top: 'bottom' }}
        error={error}
        onFocus={onFocus}
        onBlur={this.onBlur}
        disabled={disabled}
        onChange={this.onChange}
        placeholder={placeholder}
        plain
        {...textInputProps}
      />
    )
  }
}

AddressAutocomplete.defaultProps = {
  textInputProps: {},
  onBlur: () => null,
  onFocus: () => null
}

AddressAutocomplete.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.any,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  textInputProps: PropTypes.object,
  onBlur: PropTypes.func
}

export default AddressAutocomplete
