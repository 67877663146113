import React, { useEffect, useRef } from 'react'
import { Box, Text, TextInput } from 'shared/components'
import _ from 'lodash'

import colors from 'shared/constants/colors'

const SubNameInput = ({ value, onChangeValue, suggestions, onSuggestionSelect, isOpened }) => {
  const inputRef = useRef(null)

  useEffect(() => {
    // kasim: it does not work without timeout, looks like this is a grommet bug
    setTimeout(() => {
      if (!_.isNil(inputRef.current)) {
        inputRef.current.focus()
      }
    }, 1)
  }, [])

  const inputLabel = 'To'
  return (
    <Box margin={{ horizontal: 'medium', top: 'medium' }} flex={{ shrink: 0 }}>
      <Text margin={{ bottom: 'xsmall' }}>{inputLabel}</Text>
      <TextInput
        value={value}
        onChange={onChangeValue}
        suggestions={suggestions}
        onSuggestionSelect={onSuggestionSelect}
        focusIndicator
        ref={inputRef}
      />
      <Text margin={{ top: 'xsmall' }} size='xsmall' color={colors.ASANA_GRAY_TEXT_HOVERED}>
        Search by company or user name or enter a phone number or email
      </Text>
    </Box>
  )
}

export default SubNameInput
