import React, { useState, useEffect, useMemo } from 'react'
import { Box, TextInput, FormField, ThemeContext, Text, Avatar, Keyboard } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import validator from 'validator'
import { readAndCompressImage } from 'browser-image-resizer'

import colors from 'shared/constants/colors'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { updateUserProfile } from 'controllers/profiles'
import { emailMask, phoneMask } from 'shared/utils/inputMask'
import InputsList from 'shared/components/InputsList'
import { formatPhoneInput, formatNational, formatPhone } from 'shared/utils/phone'
import FileInput from 'shared/components/FileInput'
import { deleteField } from '@firebase/firestore'
import { saveFileObject } from 'controllers/storage'

const Profile = () => {
  const profile = useSelector(getCurrentUserProfile)
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')

  const emails = useMemo(() => {
    const email = _.get(profile, 'email')
    const emails = _.get(profile, 'emails', [])
    return _.compact(_.uniq([email, ...emails]))
  }, [profile.email, profile.emails])

  const phones = useMemo(() => {
    const phone = _.get(profile, 'phone')
    const phones = _.get(profile, 'phones', [])
    return _.map(_.compact(_.uniq([phone, ...phones])), formatNational)
  }, [profile.phone, profile.phones])

  useEffect(() => {
    if (!_.isNil(profile)) {
      setName(_.get(profile, 'name', ''))
      setTitle(_.get(profile, 'title', ''))
    }
  }, [profile])

  const removeAvatar = () => {
    const upd = {
      avatar: deleteField(),
      avatarSmall: deleteField()
    }
    updateUserProfile(upd)
  }

  const onAvatarFilesPicked = async files => {
    console.log('onAvatarFilesPicked', files)
    const f = _.get(files, 0)
    if (f) {
      const resizeConfig = {
        quality: 0.8,
        maxWidth: 200,
        maxHeight: 200,
        autoRotate: false,
        debug: true
      }
      const avatarSmallBlob = await readAndCompressImage(f, resizeConfig)
      const promises = [
        saveFileObject(f, `user/${profile.id}/avatar`),
        saveFileObject(avatarSmallBlob, `user/${profile.id}/avatarSmall`)
      ]
      const [avatar, avatarSmall] = await Promise.all(promises)
      console.log('avatarSmall', avatarSmall)
      const upd = {
        avatar: avatar.url,
        avatarSmall: avatarSmall.url
      }
      await updateUserProfile(upd)
    }
  }

  const renderAvatar = () => {
    const avatarUrl = _.get(profile, 'avatar')
    if (_.isNil(avatarUrl)) {
      const label = (
        <Box flex={false} direction='row' align='center' width='small'>
          <Box
            round='full'
            width='30px'
            height='30px'
            align='center'
            justify='center'
            margin={{ right: 'small' }}
            border={{ color: colors.VERY_LIGHT_PINK, size: 'xsmall', style: 'dashed', side: 'all' }}
          >
            <FontAwesomeIcon icon={faUserCircle} color={colors.VERY_LIGHT_PINK} fontSize={12} />
          </Box>
          <Text color={colors.VERY_LIGHT_PINK}>Upload logo</Text>
        </Box>
      )
      return (
        <FileInput onChange={onAvatarFilesPicked} accept='image/jpeg,image/png' multiple={false} label={label} />
      )
    } else {
      return (
        <Box flex={false} direction='row' align='center' onClick={removeAvatar} width='small'>
          <Box round='full' width='30px' height='30px' align='center' justify='center' margin={{ right: 'small' }}>
            <Avatar src={avatarUrl} />
          </Box>
          <Text color={colors.LIGHT_NAVY_BRIGHT}>Remove photo</Text>
        </Box>
      )
    }
  }

  const removeFocus = () => {
    document.activeElement.blur()
  }

  const submitField = async type => {
    switch (type) {
      case 'title': {
        updateUserProfile({ title })
        break
      }
      case 'name': {
        updateUserProfile({ name })
        break
      }
      default:
        break
    }
  }

  const isValidPhone = phone => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    if (phoneNumber) {
      return phoneNumber.isValid()
    } else {
      return false
    }
  }

  const updateEmails = emails => {
    const uniqValues = _.uniq(emails)
    let validEmails = _.filter(uniqValues, v => validator.isEmail(v))
    if (_.isEmpty(validEmails) && _.has(profile, 'email')) {
      validEmails = [_.get(profile, 'email')]
    }
    const upd = {
      email: _.get(validEmails, 0, deleteField()),
      emails: validEmails
    }
    updateUserProfile(upd)
  }

  const updatePhones = phones => {
    console.log('update phones', phones)
    const uniqValues = _.uniq(phones)
    let validPhones = _.filter(uniqValues, v => isValidPhone(v))
    validPhones = _.map(validPhones, formatPhone)
    if (_.isEmpty(validPhones) && _.has(profile, 'phone')) {
      validPhones = [_.get(profile, 'phone')]
    }
    const upd = {
      phone: _.get(validPhones, 0, deleteField()),
      phones: validPhones
    }
    updateUserProfile(upd)
  }

  return (
    <ThemeContext.Extend value={{ formField: { round: '5px' } }}>
      <Keyboard onEnter={removeFocus}>
        <Box
          fill
          flex={false}
          gap='medium'
          overflow='scroll'
          pad={{ horizontal: 'xlarge', top: 'xxsmall', bottom: 'medium' }}
        >
          <Box gap='xsmall' flex={false}>
            <Text size='medium'>Your photo</Text>
            {renderAvatar()}
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={false}>
            <Box fill>
              <FormField label='Name'>
                <TextInput value={name} onChange={e => setName(e.target.value)} onBlur={() => submitField('name')} />
              </FormField>
            </Box>
            <Box fill>
              <FormField label='Job title'>
                <TextInput value={title} onChange={e => setTitle(e.target.value)} onBlur={() => submitField('title')} />
              </FormField>
            </Box>
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={false}>
            <Box fill gap='xsmall'>
              <Box gap='xsmall'>
                <Text>Emails</Text>
                <InputsList
                  values={emails}
                  setValues={updateEmails}
                  mask={emailMask}
                  isValid={validator.isEmail}
                  buttonLabel='Add email'
                />
              </Box>
            </Box>
            <Box fill gap='xsmall'>
              <Box gap='xsmall'>
                <Text>Phones</Text>
                <InputsList
                  values={phones}
                  setValues={updatePhones}
                  formatOnChange={formatPhoneInput}
                  isValid={isValidPhone}
                  mask={phoneMask}
                  buttonLabel='Add phone'
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Keyboard>
    </ThemeContext.Extend>
  )
}

export default Profile
