import React, { useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-light-svg-icons'
import { useSearchParams } from 'react-router-dom'
import numeral from 'numeral'

import { Box, Filter, Button, Text } from 'shared/components'
import ContactsNavBar from 'pages/contacts/contactsContent/ContactsNavBar'
import ContactsTable from 'pages/contacts/contactsContent/ContactsTable'
import filterOperators from 'shared/constants/filterOperators'
import { applyFilter } from 'shared/utils/filter'
import LayoutContext from 'contexts/LayoutContext'
import { getContactsTableInfo } from 'model/selectors/contacts'
import contactsPageMode from 'constants/contactsPageMode'
import { updateContactsGroup } from 'controllers/contacts'
import colors from 'shared/constants/colors'

const ContactsContent = () => {
  const { tableData, filterConf, tableHeaders, groups } = useSelector(getContactsTableInfo)
  const [filters, setFilters] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [hasChanges, setHasChanges] = useState(false)
  const [operator, setOperator] = useState(filterOperators.AND)
  const [searchParams] = useSearchParams()
  const { openSaveFilterGroupModal } = useContext(LayoutContext)

  const mode = searchParams.get('mode') || contactsPageMode.CONTACTS
  const groupId = searchParams.get('groupId')

  useEffect(() => {
    if (mode === contactsPageMode.GROUP) {
      setHasChanges(false)
      setFilters(_.get(groups, [groupId, 'filters']))
      setOperator(_.get(groups, [groupId, 'operator']))
    } else {
      setHasChanges(false)
      setFilters([])
      setOperator(filterOperators.AND)
    }
  }, [mode, groupId, groups])

  const data = useMemo(() => {
    return applyFilter(tableData, filters, operator)
  }, [tableData, filters, operator])

  const saveGroup = () => {
    console.log('save group')
    openSaveFilterGroupModal(filters, operator)
  }

  const updateGroup = () => {
    console.log('update group')
    updateContactsGroup(groupId, filters, operator)
  }

  const renderSaveGroupButton = () => {
    if (!_.isNil(groupId)) {
      if (hasChanges) {
        return (
          <Button
            label='Update Group'
            onClick={updateGroup}
            icon={<FontAwesomeIcon icon={faTag} />}
            margin={{ top: 'xsmall' }}
          />
        )
      }
    } else if (!_.isEmpty(filters)) {
      return (
        <Button
          label='Save Group'
          onClick={saveGroup}
          icon={<FontAwesomeIcon icon={faTag} />}
          margin={{ top: 'xsmall' }}
        />
      )
    }
  }

  const editFilters = v => {
    if (groupId) {
      setHasChanges(
        !_.isEqual(v, _.get(groups, [groupId, 'filters'])) || operator !== _.get(groups, [groupId, 'operator'])
      )
    }
    setFilters(v)
  }

  const editOperator = v => {
    if (groupId) {
      setHasChanges(
        !_.isEqual(filters, _.get(groups, [groupId, 'filters'])) || v !== _.get(groups, [groupId, 'operator'])
      )
    }
    setOperator(v)
  }

  return (
    <Box fill gap='small'>
      <ContactsNavBar />
      <Box direction='row' align='center' justify='between' pad={{ right: 'medium' }}>
        <Box pad={{ horizontal: 'medium' }} flex={{ shrink: 0 }} direction='row' align='center'>
          <Filter
            conf={filterConf}
            filters={filters}
            setFilters={editFilters}
            operator={operator}
            setOperator={editOperator}
          />
          {renderSaveGroupButton()}
        </Box>
        <Text size='small' color={colors.ASANA_GRAY_TEXT_HOVERED}>
          {`${numeral(_.size(data)).format('0,0')} contacts`}
        </Text>
      </Box>
      <Box pad={{ horizontal: 'medium' }} fill>
        <ContactsTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          data={data}
          tableHeaders={tableHeaders}
        />
      </Box>
    </Box>
  )
}

export default ContactsContent
