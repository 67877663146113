import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import { Button, Box, Text } from 'shared/components'
import colors from 'shared/constants/colors'

export const ModalHeader = ({ title, onClose, children, hideBorder, ...rest }) => {
  return (
    <Box
      direction='row'
      pad={{ left: 'medium', vertical: 'xsmall', right: 'xsmall' }}
      align='center'
      fill='horizontal'
      justify='between'
      border={hideBorder ? undefined : { side: 'bottom', size: '1px', color: colors.VERY_LIGHT_GREY_TWO }}
      flex={{ shrink: 0 }}
      {...rest}
    >
      <Box direction='row' gap='medium' align='center'>
        <Text size='large' weight={700}>
          {title}
        </Text>
        {children}
      </Box>
      <Button onClick={onClose} icon={<FontAwesomeIcon icon={faTimes} />} size='large' noHover />
    </Box>
  )
}

ModalHeader.defaultProps = {
  title: '',
  onClose: () => null,
  hideBorder: false
}

ModalHeader.propTypes = {
  /** Modal title */
  title: PropTypes.string,
  /** function that will be called on X button click */
  onClick: PropTypes.func,
  /** hide bottom border */
  hideBorder: PropTypes.bool
}

export default ModalHeader
