import { storage } from 'controllers/db'
import { ref, getDownloadURL, getMetadata, uploadBytesResumable } from 'firebase/storage'
import * as Sentry from '@sentry/react'

// the method receives local url
export async function saveFile (storagePath, fileUrl, onProgress = () => null) {
  try {
    console.log('saveFile', storagePath, fileUrl)
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        console.log('saveFile error', e)
        Sentry.captureException(e)
        reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', fileUrl, true)
      xhr.send(null)
    })
    // TODO: test it
    const storageRef = ref(storage, storagePath)
    const uploadTask = uploadBytesResumable(storageRef, blob)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.metadata.size)
    const fileData = await Promise.all([getDownloadURL(uploadTask.snapshot.ref), getMetadata(storageRef)])
    const url = fileData[0]
    const { contentType } = fileData[1]
    return {
      url,
      size: snapshot.metadata.size,
      contentType
    }
  } catch (error) {
    console.log(error)
  }
}

export async function saveImageBase64DataUrl (storagePath, base64Data, onProgress = () => null) {
  const url = await saveFile(storagePath, base64Data, onProgress)
  return url
}

export async function getContentType (url) {
  const storagePath = url
    .split('o/')[1]
    .split('?alt')[0]
    .split('%2F')
    .join('/')
  const storageRef = ref(storage, storagePath)
  const { contentType } = await getMetadata(storageRef)
  return contentType
}

export const saveFileObject = async (fileObject, storagePath, onProgress = () => null) => {
  try {
    console.log('saveFileObject', fileObject, storagePath)
    const storageRef = ref(storage, storagePath)
    console.log('storageRef', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, fileObject)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.metadata.size)
    const fileData = await Promise.all([getDownloadURL(uploadTask.snapshot.ref), getMetadata(storageRef)])
    const url = fileData[0]
    const { contentType } = fileData[1]
    return {
      url,
      size: snapshot.metadata.size,
      contentType
    }
  } catch (e) {
    console.log('saveFileObject error: ', e)
  }
}

export const deleteFile = async () => {
  console.log('deleteFile')
}

export default {
  saveFile,
  saveImageBase64DataUrl,
  getContentType,
  saveFileObject,
  deleteFile
}
