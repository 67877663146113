import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Text, DropButton, Avatar } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { getName, getInitials } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'
import { switchAccount } from 'controllers/user'

const AccountSwitch = () => {
  const accountProfile = useSelector(getCurrentAccountProfile)
  const accountsProfiles = useSelector(state => state.accountsProfiles)
  const user = useSelector(state => state.user)

  const [open, setOpen] = useState(false)

  const adminOfAccounts = _.get(user, 'adminOfAccounts')
  const currentAccountId = _.get(user, 'currentAccountId')

  const label = (
    <Box>
      <Text>{getName(accountProfile)}</Text>
      <Text color={colors.LIGHT_NAVY_BRIGHT}>Switch which account you are logged into</Text>
    </Box>
  )

  const handleClick = () => {
    setOpen(!open)
  }

  const onAccountSwitchClick = accountId => () => {
    if (accountId !== currentAccountId) {
      switchAccount(accountId)
    }
  }

  const renderAccount = accountId => {
    const profile = _.get(accountsProfiles, accountId)
    const isCurrentAccount = _.isEqual(_.get(user, 'currentAccountId'), accountId)
    return (
      <Box
        direction='row'
        key={accountId}
        pad='small'
        gap='small'
        background={isCurrentAccount ? colors.AQUA_MARINE10 : colors.WHITE}
        onClick={onAccountSwitchClick(accountId)}
        hoverIndicator
        flex={false}
      >
        <Box width='32px' height='32px' align='center' justify='center' flex={false}>
          <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={_.get(profile, 'avatarSmall')}>
            <Text color={colors.WHITE}>{getInitials(profile)}</Text>
          </Avatar>
        </Box>
        <Box justify='center'>
          <Text color={colors.TEXT} size='medium'>
            {getName(profile)}
          </Text>
          <Text color={colors.ANOTHER_GREY} size='small'>
            {_.get(profile, 'email')}
          </Text>
        </Box>
      </Box>
    )
  }

  const dropContent = <Box>{_.map(adminOfAccounts, renderAccount)}</Box>

  return (
    <Box gap='small' flex={{ shrink: 0 }}>
      <Text>Switch company</Text>
      <Box direction='row' align='center' onClick={handleClick}>
        <Box
          round='full'
          width='30px'
          height='30px'
          align='center'
          justify='center'
          margin={{ right: 'small' }}
          border={{ color: colors.LIGHT_NAVY_BRIGHT, size: 'xsmall', style: 'dashed', side: 'all' }}
        >
          <FontAwesomeIcon icon={faBuilding} color={colors.LIGHT_NAVY_BRIGHT} fontSize={12} />
        </Box>
        <DropButton
          plain
          onClose={handleClick}
          open={open}
          label={label}
          dropProps={{ style: { overflow: 'visible' } }}
          dropContent={
            <Box overflow='auto' height='medium'>
              {dropContent}
            </Box>
          }
        />
      </Box>
    </Box>
  )
}

export default AccountSwitch
