import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'
import { getUnseenAmount } from 'model/selectors/inbox'
import LayoutContext from 'src/contexts/LayoutContext'
import { Button, Tip } from 'shared/components'

const CommentsInboxButton = () => {
  const { openCommentsInbox } = useContext(LayoutContext)
  const unseenAmount = useSelector(getUnseenAmount)
  let tip = 'Notifications'
  let color = colors.LIGHT_NAVY_BRIGHT
  if (unseenAmount > 0) {
    tip = unseenAmount === 1 ? '1 new notification' : `${unseenAmount} new notifications`
    color = colors.AQUA_MARINE
  }

  // console.log('tip', tip, 'color', color)

  return (
    <Tip message={tip}>
      <Button
        icon={<FontAwesomeIcon icon={unseenAmount > 0 ? faBellSolid : faBell} />}
        color={color}
        size='large'
        onClick={openCommentsInbox}
        customTheme={{ button: { hover: { default: { background: { color: 'transparent' } } } } }}
      />
    </Tip>
  )
}

export default CommentsInboxButton
