import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from 'controllers/db'
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import _ from 'lodash'
import qs from 'qs'

import {
  addListener,
  clearListeners,
  clearUserListener,
  setUserUnsubscribe,
  existingUser,
  setExistingUser
} from 'controllers/listeners'
import { fetchReferences } from 'controllers/data'
import { clear, logoutUser, receiveUser } from 'model/actions/userAC'
import store from 'model/store'
import { receiveAccount } from 'model/actions/accountAC'
// import screens from 'constants/screens'
import { fetchChannelWeb } from 'controllers/chat'
// import { fetchPaymentRequests } from 'controllers/paymentRequests'
// import { fetchSupplyOrders } from 'controllers/supplyOrders'
// import { fetchAppointments, fetchAppointmentsOffers } from 'controllers/appointments'
// import { fetchSuppliers } from 'controllers/suppliers'
// import { fetchReimbursementRequests } from 'controllers/reimbursementRequests'
// import { registerForPushNotificationsAsync } from 'shared/controllers/notifications'
// import { isWeb } from 'shared/constants/index'
// import history from 'shared/utils/history'
import { fetchProjects } from 'controllers/project'
// import { fetchProposals } from 'controllers/proposals'
import { fetchWorkOrders } from 'controllers/workOrder'
import { fetchPrivateWorkOrders } from 'controllers/privateWorkOrder'
import {
  fetchAccountsProfiles,
  fetchUsersProfiles,
  fetchOwnAccountsProfiles,
  fetchMasonAdmins
} from 'controllers/profiles'
import { fetchBids } from 'controllers/bids'
// import universalNavigation from 'utils/universalNavigation'
// // import locationTracking from 'controllers/locationTracking'
// import { trackAppInitialization } from 'controllers/zapier'
import { fetchOutgoingInvitations } from 'controllers/invitations'
// import { fetchGCAppointments } from 'controllers/gcAppointments'
import analytics from 'utils/analytics'
import { receiveAuthData } from 'model/actions/authDataAC'
import { resetOpenPageOnAuth, getOpenPageOnAuth } from 'controllers/auth'
import { fetchContacts } from 'controllers/contacts'
import { fetchInbox } from 'controllers/inbox'
// import { fetchTakeoffsQuotes } from 'controllers/takeoffs'

export const navigateToHomePage = async navigate => {
  console.log('%cnavigateToHomePage', 'color: orange;')
  const openPage = await getOpenPageOnAuth()
  if (_.isEmpty(openPage)) {
    navigate('/')
  } else {
    console.log('navigate to open page on auth')
    resetOpenPageOnAuth()
    navigate(openPage.screen, { state: openPage.params })
  }
}

const fetchAccount = accountId => {
  try {
    const accountUnsubscribe = onSnapshot(
      doc(db, 'accounts', accountId),
      docSN => {
        const account = docSN.data()
        store.dispatch(receiveAccount(account))
      },
      err => {
        console.log(`fetch account error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('account', accountUnsubscribe)
  } catch (e) {
    console.log('fetch account error', e)
    Sentry.captureException(e)
  }
}

const getAccount = async accountId => {
  const accSN = await getDoc(doc(db, 'accounts', accountId))
  return accSN.data()
}

async function initAccountData (user) {
  console.log('initAccountData, userId', user.id)
  console.log('user: ', user)
  const currentAccountId = _.get(user, 'currentAccountId')
  console.log('initAccountData: currentAccountId', currentAccountId)
  if (currentAccountId) {
    const account = await getAccount(currentAccountId)
    fetchAccount(currentAccountId)
    fetchProjects(currentAccountId)
    fetchWorkOrders(currentAccountId, account.isGC)
    fetchChannelWeb(user.id)
    fetchUsersProfiles(currentAccountId, user.id)
    fetchMasonAdmins()
    fetchAccountsProfiles(currentAccountId)
    fetchOwnAccountsProfiles(user)
    fetchBids(currentAccountId)
    fetchOutgoingInvitations(currentAccountId)
    fetchContacts(currentAccountId)
    fetchInbox(user.id, currentAccountId)
    fetchPrivateWorkOrders(currentAccountId)
  } else {
    const e = new Error(`User ${user.id} has no currentAccountId`)
    Sentry.captureException(e)
  }
}

async function init () {
  console.log('---- init ----')
  fetchReferences()
  // fetchIncomingInvitations()
}

const userChanged = async (user, isLocalChange, navigate) => {
  try {
    const currentAccountNow = _.get(existingUser, 'currentAccountId')
    console.log('%cuser changed, currentAccountNow', 'color: lightgreen', currentAccountNow)
    console.log('%cuser changed, newAccountId', 'color: lightgreen', _.get(user, 'currentAccountId'))
    const queryParams = qs.parse(_.get(window, 'location.search', ''), { ignoreQueryPrefix: true })
    const toAccountId = _.get(queryParams, 'accountId')
    console.log('%ctoAccountId', 'color: yellow', toAccountId)
    if (!_.isNil(toAccountId)) {
      navigate(window.location.pathname, { replace: true })
      if (_.includes(user.adminOfAccounts, toAccountId)) {
        console.log('is account admin true')
        const dbUserAccountId = _.get(user, 'currentAccountId')
        console.log('dbUserAccountId', dbUserAccountId, 'toAccountId', toAccountId)
        if (toAccountId !== dbUserAccountId) {
          console.log('need to switch acount true')
          await updateDoc(doc(db, 'users', user.id), { currentAccountId: toAccountId })
          return null
        }
      }
    }
    if (!currentAccountNow && _.has(user, 'currentAccountId') && !isLocalChange) {
      // console.log('userChanged 1')
      setExistingUser(user)
      store.dispatch(receiveUser(user))
      await initAccountData(user)
    } else if (currentAccountNow !== user.currentAccountId && !isLocalChange) {
      // console.log('userChanged 2')
      setExistingUser(user)
      store.dispatch(clear())
      clearListeners()
      store.dispatch(receiveUser(user))
      const screenName = await getDestinationScreen(user.id)
      console.log('currentAccountNow', currentAccountNow)
      console.log('screenName', screenName)
      await navigateToStart(screenName, navigate)
      await initAccountData(user)
    } else if (!isLocalChange) {
      // console.log('userChanged 3')
      setExistingUser(user)
      store.dispatch(receiveUser(user))
    } else {
      // console.log('userChanged 4')
      store.dispatch(receiveUser(user))
    }
  } catch (e) {
    console.log('userChanged error', e)
  }
}

const fetchUser = async (authData, navigate) => {
  console.log('fetch user', authData.uid)
  try {
    clearUserListener()
    const userId = authData.uid
    const unsubscribe = onSnapshot(
      doc(db, 'users', userId),
      { includeMetadataChanges: true },
      userSN => {
        const isLocalChange = userSN.metadata.hasPendingWrites
        // console.log('isLocalChange', isLocalChange)
        const user = userSN.data() || {}
        // console.log('user received', user)
        userChanged(user, isLocalChange, navigate)
      },
      err => {
        console.log('fetch user error', err)
        Sentry.captureMessage('fetch user error')
        Sentry.captureException(err)
      }
    )
    setUserUnsubscribe(unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch user error', e)
  }
}

// const unsubscribeFromNotifications = () => null

// const processDeppLinkParams = params => {
//   const p = _.get(params, '$deeplink_path')
//   const paramsId = _.get(params, 'params.id')
//   if (p === 'appointmentOffer') {
//     universalNavigation.navigate(screens.APPOINTMENT_OFFER, { appointmentId: paramsId })
//   } else if (p === 'bidInvite') {
//     universalNavigation.navigate(screens.VIEW_WORK_ORDER, { workOrderId: paramsId })
//   }
// }

// export const processDeepLinkBundle = bundle => {
//   // const tmpid = `${generate(_.now())}_listener`
//   // db.collection('tmp').doc(tmpid).set(bundle)
//   processDeppLinkParams(_.get(bundle, 'params'))
// }

// let branchUnsubscribe = null

// const initBranch = async () => {
//   console.log('initBranch', Constants.appOwnership)
//   if (Constants.appOwnership === 'standalone') {
//     const ExpoBranch = await import('expo-branch')
//     const Branch = ExpoBranch.default
//     // Alert.alert(`branch ${typeof ExpoBranch} | ${typeof Branch}`)
//     branchUnsubscribe = Branch.subscribe(bundle => {
//       if (bundle && bundle.params && !bundle.error) {
//         processDeepLinkBundle(bundle)
//       }
//     })
//   }
// }

const getDestinationScreen = async uid => {
  try {
    const userSN = await getDoc(doc(db, 'users', uid))
    const user = userSN.data()
    // console.log('getDestinationScreen user', user)
    const accountId = _.get(user, 'currentAccountId')
    // console.log('getDestinationScreen accountId', accountId)
    // console.log('getDestinationScreen accountProfile', accountProfile)
    const accountSN = await getDoc(doc(db, 'accounts', accountId))
    const account = accountSN.data()
    // console.log('getDestinationScreen account', account)
    // const isAccountCreator = accountId === user.id
    // console.log('is account creator:', isAccountCreator)
    // TODO: add tracking if needed
    // store.dispatch(trackAppInitialization(profile, accountId, accountProfile))
    const onboardingComplete = _.get(account, ['admins', user.id, 'onboardingComplete'], false)
    console.log('onboardingComplete', onboardingComplete)

    if (onboardingComplete) {
      if (_.get(account, 'isGC', false)) {
        return '/'
      }
    } else {
      return '/onboarding'
    }
  } catch (e) {
    console.log('getDestinationScreen error', e)
    Sentry.captureException(e)
    return '/'
  }
}

const navigateToStart = async (screenName, navigate) => {
  console.log('destination screen is', screenName)
  let needToRedirect = true
  if (screenName !== '/onboarding') {
    const openPage = getOpenPageOnAuth()
    if (_.isEmpty(openPage)) {
      const pathname = window.location.pathname
      console.log('pathName', pathname)
      if (screenName === '/' && pathname === '/') needToRedirect = false
      if (_.startsWith(pathname, '/acceptinvite')) needToRedirect = false
      if (_.startsWith(pathname, '/invitation')) needToRedirect = false
      if (_.startsWith(pathname, '/subproposal')) needToRedirect = false
      if (_.startsWith(pathname, '/workorderfile')) needToRedirect = false
      if (_.startsWith(pathname, '/projectfile')) needToRedirect = false
      if (_.startsWith(pathname, '/settings')) needToRedirect = false
      if (_.startsWith(pathname, '/scope_builder')) needToRedirect = false
    } else {
      console.log('navigate to open page on auth')
      resetOpenPageOnAuth()
      needToRedirect = false
      navigate(openPage.screen, { state: openPage.params })
    }
  }
  console.log('navigateToStart. need to redirect', needToRedirect)
  if (needToRedirect) navigate(screenName)
}

// async function onAuthStateChanged (authData, handleNotification) {
//   if (authData) {
//     console.log('authData', authData)
//     store.dispatch(receiveAuthData(authData))
//     analytics.trackUserId(authData.uid)
//     init()
//     const screenName = await getDestinationScreen(authData.uid)
//     await navigateToStart(screenName)
//     await fetchUser(authData)
//     if (handleNotification) {
//       setTimeout(handleNotification, 100)
//     }
//     if (!isWeb) {
//       await registerForPushNotificationsAsync(authData.uid)
//     }
//     await initBranch()
//   } else {
//     store.dispatch(receiveAuthData({}))
//     clearUserListener()
//     clearListeners()
//     unsubscribeFromNotifications()
//     let needToRedirect = true
//     let isSignIn = true
//     if (isWeb) {
//       const preconConf = await getPreconConf()
//       console.log('preconConf no auth', preconConf)
//       if (!_.isEmpty(preconConf)) {
//         await setPreconConfId(preconConf.id)
//         await setOpenPageOnAuth(screens.PRECON_PROJECT, { preconConfId: preconConf.id, projectId: generateId() })
//         isSignIn = false
//       }
//       const pathname = history.location.pathname
//       if (_.startsWith(pathname, '/acceptinvite')) needToRedirect = false
//       if (_.startsWith(pathname, '/invitation')) needToRedirect = false
//       if (_.startsWith(pathname, '/subproposal')) needToRedirect = false
//       if (_.startsWith(pathname, '/auth/enter-email')) needToRedirect = false
//     }
//     if (needToRedirect) {
//       universalNavigation.navigate(isSignIn ? screens.AUTH : screens.ENTER_EMAIL)
//     }
//     store.dispatch(logoutUser())
//     if (branchUnsubscribe) branchUnsubscribe()
//   }
// }

const onAuth = async (authData, navigate) => {
  console.log('onAuthStateChanged', authData)
  if (authData) {
    console.log('authData', authData)
    store.dispatch(receiveAuthData(authData))
    analytics.trackUserId(authData.uid)
    init()
    const screenName = await getDestinationScreen(authData.uid)
    console.log('destination screen', screenName)
    await navigateToStart(screenName, navigate)
    await fetchUser(authData, navigate)
  } else {
    store.dispatch(receiveAuthData({}))
    clearUserListener()
    clearListeners()
    let needToRedirect = true
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/acceptinvite')) needToRedirect = false
    if (_.startsWith(pathname, '/invitation')) needToRedirect = false
    if (_.startsWith(pathname, '/subproposal')) needToRedirect = false
    if (_.startsWith(pathname, '/auth/enter-email')) needToRedirect = false
    if (needToRedirect) {
      navigate('/auth')
      // navigate('/')
    }
    store.dispatch(logoutUser())
  }
}

export const appInitialized = navigate => {
  try {
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    // Sentry.captureException(e)
    console.log('app initialization error', e)
  }
}
