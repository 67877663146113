import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import _ from 'lodash'

import { Box } from 'shared/components'
import colors from 'shared/constants/colors'

const DropContentContainer = ({ children, dropId, backgroundColor, ...rest }) => {
  const [down, setDown] = useState(null)
  useEffect(() => {
    if (!_.isNil(dropId) && dropId) {
      const observer = new window.MutationObserver(() => {
        const target = document.getElementById(dropId)
        const style = _.get(target, 'parentElement.style')
        const isDown = !_.isEqual(_.get(style, 'bottom'), '')
        if (!_.isEqual(isDown, down)) {
          setDown(isDown)
        }
      })
      const target = document.getElementById(dropId)
      if (_.get(target, 'parentElement'))
        observer.observe(target.parentElement, { attributes: true, attributeFilter: ['style'] })

      return () => observer.disconnect()
    }
  }, [])
  return (
    <Box
      direction={down ? 'column-reverse' : 'column'}
      justify='start'
      align='start'
      background={backgroundColor}
      flex={{ shrink: 0 }}
      {...rest}
      id={dropId}
      customStyle={`
        > div {
          width: 100%;
          border-radius: 4px;
          box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
        }
      `}
    >
      {!_.isNil(down) && (
        <Box
          pad={{ left: '25px' }}
          customStyle={`
            * path { filter: drop-shadow(0px ${down ? '10px' : '-10px'} 0px rgb(0 0 0 / 10%)) };
          `}
        >
          <FontAwesomeIcon
            icon={down ? faCaretDown : faCaretUp}
            fontSize={30}
            style={{ margin: '-15px', zIndex: 10 }}
            color={backgroundColor}
          />
        </Box>
      )}
      {children}
    </Box>
  )
}

DropContentContainer.defaultProps = {
  children: null,
  dropId: null,
  backgroundColor: colors.WHITE
}

DropContentContainer.propTypes = {
  /**
  Content to put inside the Drop.
  */
  children: PropTypes.element.isRequired,
  /**
  Background color of the content and arrow (if presented)
   */
  backgroundColor: PropTypes.string,
  /**
  ID for drop container. Helps to change arrow position if needed.
   */
  dropId: PropTypes.string
}

export default DropContentContainer

export { DropContentContainer }
