import React, { useState, useEffect } from 'react'
import { Box, TextInput, FormField, ThemeContext, Text, MaskedInput, Keyboard, Avatar } from 'shared/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { readAndCompressImage } from 'browser-image-resizer'

import colors from 'shared/constants/colors'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { removeAvatarFromAccount, updateAccountProfile } from 'controllers/profiles'
import { emailMask, phoneMask } from 'shared/utils/inputMask'
import AccountSwitch from 'pages/settings/settingsAccount/AccountSwitch'
import FileInput from 'shared/components/FileInput'
import { saveFileObject } from 'controllers/storage'
import { formatPhone, formatNational } from 'shared/utils/phone'
import AddressAutocomplete from 'shared/components/AddressAutocomplete'
import { getAddress } from 'shared/utils/stringUtils'

const SettingsAccount = () => {
  const profile = useSelector(getCurrentAccountProfile)
  const [name, setName] = useState('')
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')

  useEffect(() => {
    if (!_.isNil(profile)) {
      setName(_.get(profile, 'name', ''))
      setAvatarUrl(_.get(profile, 'avatarSmall', _.get(profile, 'avatar', null)))
      setPhone(formatNational(_.get(profile, 'phone', '')))
      setEmail(_.get(profile, 'email', ''))
      setWebsite(_.get(profile, 'website', ''))
    }
  }, [profile])

  const onAvatarFilesPicked = async files => {
    console.log('onAvatarFilesPicked', files)
    const f = _.get(files, 0)
    if (f) {
      const resizeConfig = {
        quality: 0.8,
        maxWidth: 200,
        maxHeight: 200,
        autoRotate: false,
        debug: true
      }
      const avatarSmallBlob = await readAndCompressImage(f, resizeConfig)
      const promises = [
        saveFileObject(f, `account/${profile.id}/avatar`),
        saveFileObject(avatarSmallBlob, `account/${profile.id}/avatarSmall`)
      ]
      const [avatar, avatarSmall] = await Promise.all(promises)
      console.log('avatarSmall', avatarSmall)
      const upd = {
        avatar: avatar.url,
        avatarSmall: avatarSmall.url
      }
      await updateAccountProfile(upd)
    }
  }

  const renderAvatar = () => {
    const customLabel = (
      <Box flex={false} direction='row' align='center' width='small'>
        <Box
          round='full'
          width='30px'
          height='30px'
          align='center'
          justify='center'
          margin={{ right: 'small' }}
          border={{ color: colors.VERY_LIGHT_PINK, size: 'xsmall', style: 'dashed', side: 'all' }}
        >
          <FontAwesomeIcon icon={faUserCircle} color={colors.VERY_LIGHT_PINK} fontSize={12} />
        </Box>
        <Text color={colors.VERY_LIGHT_PINK}>Upload logo</Text>
      </Box>
    )
    if (_.isNil(avatarUrl)) {
      return (
        <FileInput onChange={onAvatarFilesPicked} accept='image/jpeg,image/png' multiple={false} label={customLabel} />
      )
    } else {
      return (
        <Box flex={false} direction='row' align='center' onClick={removeAvatarFromAccount} width='small'>
          <Box round='full' width='30px' height='30px' align='center' justify='center' margin={{ right: 'small' }}>
            <Avatar src={avatarUrl} />
          </Box>
          <Text color={colors.LIGHT_NAVY_BRIGHT}>Remove logo</Text>
        </Box>
      )
    }
  }

  const removeFocus = () => {
    document.activeElement.blur()
  }

  const submitField = async type => {
    switch (type) {
      case 'name': {
        updateAccountProfile({ name })
        break
      }
      case 'email': {
        updateAccountProfile({ email })
        break
      }
      case 'phone': {
        const ph = formatPhone(phone)
        updateAccountProfile({ phone: ph })
        break
      }
      case 'website': {
        updateAccountProfile({ website })
        break
      }
      default:
        break
    }
  }

  return (
    <ThemeContext.Extend value={{ formField: { round: '5px' } }}>
      <Keyboard onEnter={removeFocus}>
        <Box
          fill
          flex={{ shrink: 0 }}
          gap='medium'
          overflow='scroll'
          pad={{ horizontal: 'xlarge', top: 'xxsmall', bottom: 'medium' }}
        >
          <Box gap='xsmall' flex={false}>
            <Text size='medium'>Company logo</Text>
            {renderAvatar()}
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={{ shrink: 0 }}>
            <Box fill>
              <FormField label='Company name'>
                <TextInput value={name} onChange={e => setName(e.target.value)} onBlur={() => submitField('name')} />
              </FormField>
            </Box>

            <Box fill>
              <FormField label='Company address'>
                <AddressAutocomplete
                  onSelect={address => updateAccountProfile({ address })}
                  value={getAddress(_.get(profile, 'address'))}
                  placeholder='Type an address...'
                />
              </FormField>
            </Box>
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={{ shrink: 0 }}>
            <Box fill gap='xsmall'>
              <Box>
                <FormField label='Company email' flex={false}>
                  <MaskedInput
                    mask={emailMask}
                    value={email || ''}
                    onChange={e => setEmail(e.target.value)}
                    onBlur={() => submitField('email')}
                  />
                </FormField>
              </Box>
            </Box>
            <Box fill gap='xsmall'>
              <Box>
                <FormField label='Company phone' flex={false}>
                  <MaskedInput
                    mask={phoneMask}
                    value={phone || ''}
                    onChange={e => setPhone(e.target.value)}
                    onBlur={() => submitField('phone')}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={{ shrink: 0 }}>
            <Box fill gap='xsmall' basis='1/2' pad={{ right: 'large' }}>
              <Box>
                <FormField label='Company website' flex={false}>
                  <TextInput
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                    onBlur={() => submitField('website')}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
          <Box direction='row' flex={{ shrink: 0 }}>
            <AccountSwitch />
          </Box>
        </Box>
      </Keyboard>
    </ThemeContext.Extend>
  )
}

export default SettingsAccount
