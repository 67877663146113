import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'

import { Layer, ModalHeader, Box, Button, TextInput, FormField } from 'shared/components'
import { renameContactsGroup } from 'controllers/contacts'

const RenameContactsGroupModal = ({ modalRef }) => {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [groupId, setGroupId] = useState()

  const close = () => setShow(false)

  const open = (groupId, name) => {
    setName(name)
    setGroupId(groupId)
    setShow(true)
  }

  useImperativeHandle(modalRef, () => ({
    open
  }))

  const saveGroup = () => {
    renameContactsGroup(groupId, name)
    close()
  }

  if (show) {
    return (
      <Layer>
        <Box width='medium'>
          <ModalHeader title='Rename Group' onClose={close} />
          <Box pad={{ horizontal: 'medium', top: 'large', bottom: 'medium' }}>
            <FormField label='Group name'>
              <TextInput value={name} onChange={e => setName(e.target.value)} />
            </FormField>
          </Box>
          <Box direction='row' align='center' justify='between' pad='medium'>
            <Button label='Cancel' secondary onClick={close} />
            <Button label='Save' primary onClick={saveGroup} disabled={_.isEmpty(name)} />
          </Box>
        </Box>
      </Layer>
    )
  } else {
    return null
  }
}

export default RenameContactsGroupModal
