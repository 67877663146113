import React from 'react'
import { Box, Text } from 'shared/components'

import levelingTableMode from 'constants/levelingTableMode'
import colors from 'shared/constants/colors'

const HeaderFirstCell = ({ mode, tradeName }) => {
  const isSOW = mode === levelingTableMode.SCOPE_OF_WORK
  const title = isSOW ? `Scope of Work - ${tradeName}` : 'General Exclusions'
  return (
    <Box
      flex
      pad={{ horizontal: 'small' }}
      align='center'
      justify='between'
      direction='row'
      background={colors.WHITE}
      customStyle={`border: 2px solid ${colors.PALE_GREY}; border-top-color: ${colors.ALMOST_WHITE}; border-left: 0;`}
    >
      <Text weight={600} color={isSOW ? colors.TEXT : colors.CORAL_TWO}>
        {title}
      </Text>
    </Box>
  )
}

export default React.memo(HeaderFirstCell)
