import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

import colors from 'shared/constants/colors'

const StyledWrapper = styled.div`
  .react-datepicker__header {
    background: ${colors.WHITE};
  }
  .react-datepicker__day * {
    color: ${colors.CONTACT_LABEL};
    font-size: 8pt;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: ${colors.AQUA_MARINE};
  }
  .react-datepicker__header {
    border-bottom: none;
  }
  .react-datepicker__day-name {
    color: ${colors.VERY_LIGHT_PINK};
    font-size: 8pt;
    font-style: normal;
    font-weight: 400;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__time-container {
    border: none;
  }
`

const StyledDatePicker = ({ ...props }) => (
  <StyledWrapper>
    <DatePicker {...props} />
  </StyledWrapper>
)

export default StyledDatePicker
