import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { onSnapshot, doc, query, collection, where, serverTimestamp, setDoc } from 'firebase/firestore'
import { receiveMessagesWeb } from 'model/actions/messagesAC'
import { db, generateId, auth } from 'controllers/db'

import { receiveChannel } from 'model/actions/channelsAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { toTimestamp } from 'shared/utils/date'

export const fetchChannelWeb = userId => {
  const unsubscribe = onSnapshot(
    doc(db, 'messagesStatus', userId),
    sn => store.dispatch(receiveChannel(sn.data() || null)),
    err => {
      console.log('fetchChannelWeb onSnapshot error: ', err)
      Sentry.captureException(err)
    }
  )
  addListener('channelWeb', unsubscribe)
  return unsubscribe
}

export const fetchMessagesWeb = (workOrderId, accountId) => {
  const q = query(
    collection(db, 'messages', workOrderId, 'messagesList'),
    where('accounts', 'array-contains', accountId)
  )
  const unsubscribe = onSnapshot(
    q,
    sn => {
      const messages = _.map(sn.docs, doc => ({ ...doc.data(), id: doc.id }))
      store.dispatch(receiveMessagesWeb(messages))
    },
    err => {
      console.log('workOrderMessages onSnapshot error: ', err)
      Sentry.captureException(err)
    }
  )
  addListener('workOrderMessages', unsubscribe)
}

export const sendMessage = params => {
  const workOrderId = _.get(params, 'workOrderId')
  if (_.isNil(workOrderId)) {
    console.warn('message does not contain workOrderId', params)
    return null
  }
  const id = generateId()
  const m = { ...params, timestamp: serverTimestamp(), id }
  const ref = doc(db, `messages/${workOrderId}/messagesList/${id}`)
  setDoc(ref, m)
}

export const updateSeenStatus = async (channelKey, lastMessage) => {
  const state = store.getState()
  const curValue = toTimestamp(_.get(state, ['channelWeb', channelKey]))
  const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp'))
  const lastMessageUserId = _.get(lastMessage, 'userId')
  const userId = _.get(auth, 'currentUser.uid')
  if (_.isEmpty(lastMessage)) {
    console.log('SKIP updateSeenStatus, last message is not passed')
  } else if (userId !== lastMessageUserId && curValue < lastMessageTime) {
    console.log('update seen status', channelKey)
    console.log(
      'lastMessageUserId',
      lastMessageUserId,
      'last message seen',
      curValue < lastMessageTime,
      'curValue',
      curValue,
      'lastMessageTime',
      lastMessageTime,
      'lastMessage',
      lastMessage
    )
    const ref = doc(db, 'messagesStatus', userId)
    await setDoc(ref, { [channelKey]: serverTimestamp() }, { merge: true })
  } else {
    console.log(
      'SKIP updateSeenStatus, lastMessage is mine',
      userId === lastMessageUserId,
      'last message seen',
      curValue < lastMessageTime,
      'curValue',
      curValue,
      'lastMessageTime',
      lastMessageTime,
      'lastMessage',
      lastMessage
    )
  }
}
