import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { collection, query, where, onSnapshot, doc, deleteDoc, getDocs, writeBatch, setDoc } from '@firebase/firestore'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import store from 'model/store'
import { receiveOutgoingInvitations } from 'model/actions/invitationsAC'
import { addListener } from 'controllers/listeners'
import { db, auth } from 'controllers/db'
import config from 'shared/config'
import headers from 'shared/controllers/headers'

export const fetchOutgoingInvitations = accountId => {
  try {
    console.log('fetchOutgoingInvitations, accountId:', accountId)
    const q = query(collection(db, 'invitations'), where('accountId', '==', accountId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => _.set(res, doc.id, doc.data()))
        console.log('fetchOutgoingInvitations, amount', _.size(res))
        store.dispatch(receiveOutgoingInvitations(res))
      },
      err => {
        console.log(`fetchOutgoingInvitations error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('outgoingInvitations', unsubscribe)
  } catch (e) {
    console.log('fetchOutgoingInvitations error', e)
    Sentry.captureException(e)
  }
}

export const inviteMasonAdmins = async (ids, accountId) => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      ids,
      accountId
    }
    const url = `${config.dynamicUrl}/proto/inviteMasonAdmins`
    const response = await window.fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const res = await response.json()
    console.log('inviteMasonAdmins response', res)
    return true
  } catch (e) {
    console.log('inviteMasonAdmins error', e.message)
    return false
  }
}

export const resendInvite = async inviteId => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      inviteId
    }
    const url = `${config.dynamicUrl}/proto/resendInviteMember`
    const response = await window.fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('resendInviteMember answer', answer)
  } catch (e) {
    Sentry.captureException(e)
    console.log('resendInviteMember e:', e.message)
  }
}

export async function sendInvite (phone, role = 'employee', name = null, title = null, email = null, message = null) {
  try {
    const lookBy = {
      label: 'email',
      value: email
    }
    if (!_.isNil(phone)) {
      const phoneNumber = parsePhoneNumberFromString(phone, 'US')
      const phoneNum = phoneNumber.number
      _.set(lookBy, 'label', 'phone')
      _.set(lookBy, 'value', phoneNum)
    }
    const currentUser = auth.currentUser
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const invRef = query(
      collection(db, 'invitations'),
      where('accountId', '==', accountId),
      where(lookBy.label, '==', lookBy.value)
    )
    const invitationsSN = await getDocs(invRef)
    const batch = writeBatch(db)
    invitationsSN.forEach(doc => {
      batch.delete(doc.ref)
    })
    await batch.commit()
    console.log('remove invites done')

    if (accountId) {
      const inviteId = doc(collection(db, 'invitations')).id
      const invite = _.omitBy(
        {
          id: inviteId,
          accountId,
          role,
          name,
          phone: lookBy.label === 'phone' ? lookBy.value : null,
          email
        },
        _.isNil
      )
      await setDoc(doc(db, 'invitations', inviteId), invite)
      console.log('add invite to db done')
      const authToken = await currentUser.getIdToken()
      const body = _.omitBy(
        {
          authToken,
          phone: lookBy.label === 'phone' ? lookBy.value : null,
          role,
          name,
          title,
          inviteId,
          email,
          message
        },
        _.isNil
      )
      const url = `${config.dynamicUrl}/proto/inviteMember`
      const response = await window.fetch(url, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      const answer = await response.json()
      console.log('sendInvite answer', answer)
    } else {
      console.log('current account is not set')
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('sendInvite e:', e.message)
  }
}

export const removeInvite = async invId => {
  try {
    const ref = doc(db, 'invitations', invId)
    await deleteDoc(ref)
  } catch (error) {
    console.log('remove invite error: ', error.message)
  }
}

export const removeUserFromAccount = async uid => {
  try {
    const currentUser = auth.currentUser
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      uid,
      accountId
    }
    const url = `${config.dynamicUrl}/proto/removeUserFromAccount`
    const response = await window.fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('answer', answer)
  } catch (error) {
    console.log('removeUserFromAccount error,', error.message)
  }
}
