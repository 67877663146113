import React, { useState, useMemo, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import { Box, Text, Button, ThemeContext, DropButton } from 'shared/components'
import _ from 'lodash'
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTrophy, faUserCheck, faUserTimes } from '@fortawesome/pro-solid-svg-icons'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import {
  faEye,
  faEnvelope,
  faEnvelopeOpen,
  faEdit,
  faMouseAlt,
  faFile,
  faClock
} from '@fortawesome/pro-light-svg-icons'

import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import colors from 'shared/constants/colors'
import bidStatuses from 'shared/constants/bidStatus'
import DropContent from 'shared/components/DropContent'
import LayoutContext from 'contexts/LayoutContext'
import { cancelReminder } from 'controllers/workOrder'
import { getUsersProfiles } from 'model/selectors/profiles'
import { toTimestamp } from 'shared/utils/date'
import Tip from 'shared/components/Tip'

const ACTIVITIES_TYPES = {
  INVITED: 'INVITED',
  SUB_VIEW_INVITE: 'SUB_VIEW_INVITE',
  SUB_EMAIL_OPEN: 'SUB_EMAIL_OPEN',
  SUB_LINK_CLICKED: 'SUB_LINK_CLICKED',
  SUB_ACCEPTED: 'SUB_ACCEPTED',
  GC_ACCEPTED: 'GC_ACCEPTED',
  SUB_DECLINED: 'SUB_DECLINED',
  GC_DECLINED: 'GC_DECLINED',
  BID_CREATED: 'BID_CREATED',
  BID_EDITED: 'BID_EDITED',
  FILE_OPENED: 'FILE_OPENED',
  SCHEDULED_REMINDER: 'SCHEDULED_REMINDER'
}

const ACTIVITIES_PER_STEP = 4
const MENU_ACTIONS = {
  EDIT: 'EDIT',
  DELETE: 'DELETE'
}

const Activity = ({ workOrder, bid, userId, profiles, subId }) => {
  const [step, setStep] = useState(1)
  const { openSchedulerReminder } = useContext(LayoutContext)

  const formatPhone = phone => parsePhoneNumberFromString(phone, 'US').formatNational()

  const getActivityObject = useCallback(
    ({ timestamp, createdBy, ...rest }, type, nameAlreadyFormatted) => {
      if (!nameAlreadyFormatted) {
        createdBy = _.isEqual(userId, createdBy) ? 'You' : _.get(profiles, [createdBy, 'name'])
      }
      const res = {
        timestamp: toTimestamp(timestamp),
        createdBy,
        type
      }

      console.log('activity object', res)

      switch (type) {
        case ACTIVITIES_TYPES.INVITED: {
          const sendTo = _.values(_.get(rest, 'sendTo'))
          const sendToSize = _.size(sendTo)
          const sendToFormatted = _.map(sendTo, (user, index) => {
            const isLast = _.isEqual(index, sendToSize - 1)
            const isPrevToLast = _.isEqual(index, sendToSize - 2)
            const emails = _.get(user, 'emails', [])
            const phones = _.map(_.get(user, 'phones', []), formatPhone)
            const contacts = (
              <Box gap='xxsmall'>
                {_.map(emails, (email, i) => (
                  <Text key={`${_.get(user, 'id')}_${index}_${i}_email`}>{email}</Text>
                ))}
                {_.map(phones, (phone, i) => (
                  <Text key={`${_.get(user, 'id')}_${index}_${i}_phone`}>{phone}</Text>
                ))}
              </Box>
            )
            const name = _.get(user, 'name')
            if (sendToSize === 1 || isPrevToLast) {
              return (
                <Tip key={`${_.get(user, 'id')}_${index}_tip`} message={contacts}>
                  <Text size='medium' weight={600} color={colors.BROWN_GREY_TWO}>
                    {name}
                  </Text>
                </Tip>
              )
            } else if (isLast) {
              return [
                <Text key={`${_.get(user, 'id')}_${index}_and`} size='medium' color={colors.BROWN_GREY_TWO}>
                  and
                </Text>,
                <Tip key={`${_.get(user, 'id')}_${index}_name`} message={contacts}>
                  <Text size='medium' weight={600} color={colors.BROWN_GREY_TWO}>
                    {name}
                  </Text>
                </Tip>
              ]
            } else {
              return (
                <Tip key={`${_.get(user, 'id')}_${index}_tip`} message={contacts}>
                  <Text size='medium' weight={600} color={colors.BROWN_GREY_TWO}>
                    {name},
                  </Text>
                </Tip>
              )
            }
          })
          let desc = res.createdBy ? 'send this invitation' : 'Invitation sent'
          if (res.createdBy && _.size(sendToFormatted) > 0) desc = 'sent this invitation to'
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              {_.get(res, 'createdBy') && (
                <Text color={colors.TEXT_PRIMARY} size='medium'>
                  {res.createdBy}
                </Text>
              )}
              <Text color={colors.BROWN_GREY_TWO} size='medium'>
                {desc}
              </Text>
              {sendToFormatted}
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.SUB_VIEW_INVITE: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.BROWN_GREY_TWO} size='medium'>
                viewed this invitation
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.SUB_EMAIL_OPEN: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                opened invitation email
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.SUB_LINK_CLICKED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                clicked invitation link
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.SUB_ACCEPTED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                accepted
              </Text>
              <Text color={colors.BROWN_GREY_TWO} size='medium'>
                this invitation
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.GC_ACCEPTED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                marked this invitation as
              </Text>
              <Text color={colors.AQUA_MARINE} size='medium'>
                won
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.BID_CREATED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                Bid submitted by
              </Text>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.BID_EDITED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                Bid edited by
              </Text>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.GC_DECLINED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.CORAL_TWO} size='medium'>
                declined
              </Text>
              <Text color={colors.TEXT_PRIMARY} size='medium'>
                this bid
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.SUB_DECLINED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.CORAL_TWO} size='medium'>
                declined
              </Text>
              <Text color={colors.BROWN_GREY_TWO} size='medium'>
                this invitation
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.FILE_OPENED: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.BROWN_GREY_TWO} size='medium'>
                viewed
              </Text>
              <Text truncate weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {_.get(rest, 'fileName')}
              </Text>
            </Box>
          )
          break
        }
        case ACTIVITIES_TYPES.SCHEDULED_REMINDER: {
          _.set(
            res,
            'title',
            <Box direction='row' align='center' customStyle='display: inline-block; * { padding-right: 3px }'>
              <Text weight={600} color={colors.BROWN_GREY_TWO} size='medium'>
                {res.createdBy}
              </Text>
              <Text color={colors.YELLOW} size='medium'>
                scheduled
              </Text>
              <Text color={colors.BROWN_GREY_TWO} size='medium'>
                a bid reminder notification
              </Text>
            </Box>
          )
          _.set(res, 'date', _.get(rest, 'date'))
          _.set(res, 'message', _.get(rest, 'message'))
          _.set(res, 'sendTo', _.get(rest, 'sendTo'))
          _.set(res, 'id', _.get(rest, 'id'))
          break
        }
        default:
          break
      }
      return res
    },
    [profiles, userId]
  )

  const activities = useMemo(() => {
    const res = []
    const invitations = _.get(workOrder, 'invitations')
    const inv = _.get(invitations, subId)
    if (!_.isNil(inv)) {
      const createdBy = _.get(inv, 'invitedBy')
      const timestamp = toTimestamp(_.get(inv, 'timestamp'))
      const sendTo = _.get(inv, 'sendTo')
      res.push(getActivityObject({ createdBy, timestamp, sendTo }, ACTIVITIES_TYPES.INVITED))
      const seen = _.get(inv, 'seen')
      if (_.size(seen) > 0) {
        _.forOwn(seen, (timestamp, createdBy) => {
          res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.SUB_VIEW_INVITE))
        })
      }
      const scheduledReminders = _.get(inv, 'scheduledReminders')
      if (_.size(scheduledReminders) > 0) {
        _.forEach(scheduledReminders, ({ createdBy, date, timestamp, message, sendTo, id }) => {
          res.push(
            getActivityObject({ createdBy, timestamp, date, message, sendTo, id }, ACTIVITIES_TYPES.SCHEDULED_REMINDER)
          )
        })
      }
      _.forOwn(sendTo, sendToData => {
        const createdBy = _.get(sendToData, 'name')
        const opened = _.get(sendToData, 'opened')
        if (_.size(opened) > 0) {
          _.forEach(opened, timestamp => {
            res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.SUB_EMAIL_OPEN, true))
          })
        }
        const clicked = _.get(sendToData, 'clicked')
        if (_.size(clicked) > 0) {
          _.forEach(clicked, timestamp => {
            res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.SUB_LINK_CLICKED, true))
          })
        }
      })
      const reminders = _.get(inv, 'reminders')
      if (_.size(reminders) > 0) {
        _.forEach(reminders, r => res.push(getActivityObject({ ...r, sendTo }, ACTIVITIES_TYPES.INVITED)))
      }

      const draftCreated = _.get(inv, 'draftCreated', {})
      if (_.size(draftCreated) > 0) {
        _.forOwn(draftCreated, (timestamp, uid) => {
          if (_.has(profiles, uid)) {
            res.push(getActivityObject({ timestamp, createdBy: uid }, ACTIVITIES_TYPES.BID_EDITED))
          }
        })
      }

      const filesSeen = _.get(inv, 'filesSeen', {})
      const files = _.get(workOrder, 'files')
      if (_.size(filesSeen) > 0) {
        _.forOwn(filesSeen, (userData, fileId) => {
          _.forOwn(userData, (timestamp, uid) => {
            const fileName = _.get(files, [fileId, 'name'])
            if (_.has(profiles, uid) && fileName) {
              res.push(getActivityObject({ timestamp, createdBy: uid, fileName }, ACTIVITIES_TYPES.FILE_OPENED))
            }
          })
        })
      }
    }
    const isAcceptedBySub = _.includes(_.keys(_.get(workOrder, 'acceptedBy')), subId)
    if (isAcceptedBySub) {
      const createdBy = _.get(workOrder, ['acceptedBy', subId, 'userId'])
      const timestamp = _.get(workOrder, ['acceptedBy', subId, 'timestamp'])
      res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.SUB_ACCEPTED))
    }
    const isDeclinedBySub = _.includes(_.keys(_.get(workOrder, 'declinedBy')), subId)
    if (isDeclinedBySub) {
      const createdBy = _.get(workOrder, ['declinedBy', subId, 'userId'])
      const timestamp = toTimestamp(_.get(workOrder, ['declinedBy', subId, 'timestamp']))
      res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.SUB_DECLINED))
    }
    const bidTimestamp = _.get(bid, 'timestamp')
    if (bidTimestamp) {
      const createdBy = _.get(bid, 'createdBy')
      res.push(getActivityObject({ createdBy, timestamp: bidTimestamp }, ACTIVITIES_TYPES.BID_CREATED))
      const bidStatus = _.get(bid, 'status')
      if (_.has(bidStatus, 'type')) {
        switch (bidStatus.type) {
          case bidStatuses.ACCEPTED: {
            const createdBy = _.get(bid, 'status.userId')
            const timestamp = toTimestamp(_.get(bid, 'status.timestamp'))
            res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.GC_ACCEPTED))
            break
          }
          case bidStatuses.DECLINED: {
            const createdBy = _.get(bid, 'status.userId')
            const timestamp = toTimestamp(_.get(bid, 'status.timestamp'))
            res.push(getActivityObject({ createdBy, timestamp }, ACTIVITIES_TYPES.GC_DECLINED))
            break
          }
          default:
            break
        }
      }
      const bidUpdates = _.get(bid, 'updates', [])
      if (_.size(bidUpdates) > 0) {
        _.forEach(bidUpdates, upd => res.push(getActivityObject(upd, ACTIVITIES_TYPES.BID_EDITED)))
      }
    }
    return res
  }, [workOrder, bid, subId, getActivityObject, profiles])

  const bubbleStyle = { border: 'none', width: 16, height: 16, background: 'none' }
  const contentStyle = { marginLeft: -10, marginBottom: 10 }

  const renderTimeLineIcon = type => {
    switch (type) {
      case ACTIVITIES_TYPES.INVITED: {
        return <FontAwesomeIcon icon={faEnvelope} color={colors.LIGHT_NAVY_BRIGHT} fontSize={14} />
      }
      case ACTIVITIES_TYPES.SUB_VIEW_INVITE: {
        return <FontAwesomeIcon icon={faEye} color={colors.MEDIUM_GREY} fontSize={14} />
      }
      case ACTIVITIES_TYPES.SUB_EMAIL_OPEN: {
        return <FontAwesomeIcon icon={faEnvelopeOpen} color={colors.MEDIUM_GREY} fontSize={14} />
      }
      case ACTIVITIES_TYPES.SUB_LINK_CLICKED: {
        return <FontAwesomeIcon icon={faMouseAlt} color={colors.MEDIUM_GREY} fontSize={14} />
      }
      case ACTIVITIES_TYPES.SUB_ACCEPTED: {
        return <FontAwesomeIcon icon={faUserCheck} color={colors.AQUA_MARINE} fontSize={14} />
      }
      case ACTIVITIES_TYPES.GC_ACCEPTED: {
        return <FontAwesomeIcon icon={faTrophy} color={colors.AQUA_MARINE} fontSize={14} />
      }
      case ACTIVITIES_TYPES.BID_CREATED: {
        return <FontAwesomeIcon icon={faCheckCircle} color={colors.AQUA_MARINE} fontSize={14} />
      }
      case ACTIVITIES_TYPES.BID_EDITED: {
        return <FontAwesomeIcon icon={faEdit} color={colors.MEDIUM_GREY} fontSize={14} />
      }
      case ACTIVITIES_TYPES.GC_DECLINED: {
        return <FontAwesomeIcon icon={faUserTimes} color={colors.CORAL_TWO} fontSize={14} />
      }
      case ACTIVITIES_TYPES.SUB_DECLINED: {
        return <FontAwesomeIcon icon={faUserTimes} color={colors.CORAL_TWO} fontSize={14} />
      }
      case ACTIVITIES_TYPES.FILE_OPENED: {
        return <FontAwesomeIcon icon={faFile} color={colors.MEDIUM_GREY} fontSize={14} />
      }
      case ACTIVITIES_TYPES.SCHEDULED_REMINDER: {
        return <FontAwesomeIcon icon={faClock} color={colors.YELLOW} fontSize={14} />
      }
      default:
        break
    }
  }

  const handleDropDownClick = (id, e, { params }) => {
    const workOrderId = _.get(workOrder, 'id')
    const reminderId = _.get(params, 'id')
    switch (id) {
      case MENU_ACTIONS.EDIT: {
        openSchedulerReminder(workOrderId, subId, reminderId)
        break
      }
      case MENU_ACTIONS.DELETE: {
        cancelReminder(workOrderId, subId, reminderId)
        break
      }
      default:
        return null
    }
  }

  const renderActivity = ({ title, timestamp, type, date, ...rest }) => {
    // const timeZone = _.get(workOrder, 'projectAddress.timeZone')
    if (type === ACTIVITIES_TYPES.SCHEDULED_REMINDER) {
      const disabled = _.now() > date
      const menuOptions = [
        { id: MENU_ACTIONS.EDIT, label: 'Edit', disabled, params: { ...rest, date } },
        {
          id: MENU_ACTIONS.DELETE,
          label: <Text color={colors.CORAL_TWO}>Cancel</Text>,
          disabled,
          params: { ...rest, date }
        }
      ]
      return (
        <Box
          flex
          direction='row'
          customStyle={`
            .dropdown-menu {
              display: none
            }
            :hover .dropdown-menu {
              display: flex
            }
          `}
        >
          <Box gap='xsmall' flex>
            {title}
            <Text italic size='small' color={colors.YELLOW}>
              scheduled for {moment(date).format('dddd M/D, h:mm a')}
            </Text>
          </Box>
          <Box className='dropdown-menu'>
            <DropButton
              plain
              secondary
              color={colors.DARK_GRAY_TWO}
              hoverTextColor={colors.WHITE}
              customStyle={`
                > div {
                  justify-content: center;
                }
              `}
              label={<FontAwesomeIcon icon={faEllipsisH} fontSize={20} color={colors.TEXT} />}
              reverse
              size='large'
              dropContent={
                <DropContent boxProps={{ width: 'xsmall' }} options={menuOptions} onOptionClick={handleDropDownClick} />
              }
              dropProps={{ align: { top: 'bottom', right: 'left' } }}
            />
          </Box>
        </Box>
      )
    }
    return (
      <Box gap='xsmall'>
        {title}
        <Text italic size='small' color={colors.BROWN_GREY_TWO}>
          {moment(timestamp).format('dddd M/D, h:mm a')}
        </Text>
      </Box>
    )
  }

  return (
    <ThemeContext.Extend value={{ tip: { drop: { background: colors.LIGHT_NAVY_BRIGHT } } }}>
      <Timeline lineColor={colors.PALE_GREY} lineStyle={{ width: 0.5, left: 8, height: 'unset', bottom: 60 }}>
        {_.map(_.slice(_.orderBy(activities, 'timestamp', 'asc'), 0, step * ACTIVITIES_PER_STEP), (ev, i) => {
          return (
            <TimelineEvent
              key={i}
              title={renderActivity(ev)}
              bubbleStyle={bubbleStyle}
              icon={<Box background={colors.WHITE}>{renderTimeLineIcon(_.get(ev, 'type'))}</Box>}
              style={contentStyle}
            />
          )
        })}
      </Timeline>
      {_.size(activities) > step * ACTIVITIES_PER_STEP && (
        <Box pad={{ horizontal: 'small', bottom: 'xsmall' }}>
          <Button
            plain
            label={
              <Text weight={600} color={colors.AQUA_MARINE}>
                Show more
              </Text>
            }
            onClick={() => setStep(step + 1)}
          />
        </Box>
      )}
    </ThemeContext.Extend>
  )
}

const mapStateToProps = (state, props) => ({
  workOrder: getWorkOrder(state, props),
  bid: _.get(getBidsByWorkOrdersAccounts(state), [props.workOrderId, props.subId]),
  userId: _.get(state, 'user.id'),
  profiles: getUsersProfiles(state)
})

export default connect(mapStateToProps)(Activity)
