import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'
import { Anchor, Box, Text } from 'shared/components'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'

import { userIdByPhone, requestSmsCode } from 'controllers/auth'
import colors from 'shared/constants/colors'
import signInMode from 'constants/signInMode'
import AuthLayout from 'pages/auth/AuthLayout'

const FIRST_PART_LENGTH = 7
const PHONE_NUMBER_LENGTH = 14

const EnterPhone = ({ mode, email }) => {
  const [phone, setPhone] = useState('')
  const [isValid, setValid] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [prevMode, setPrevMode] = useState(null)
  const navigate = useNavigate()

  if (prevMode !== mode) {
    setPrevMode(mode)
    setErrorMessage(null)
    setLoading(false)
  }

  const onResponse = (phone, isSent) => {
    console.log('onResponse', phone, isSent)
    if (isSent) {
      navigate('/auth/enter-code', { state: { phone, mode, email } })
    } else {
      setErrorMessage('We could not sent SMS to the phone number')
    }
  }

  const onSubmit = async () => {
    try {
      console.log('onSubmit', phone)
      setLoading(true)
      const phoneNumber = parsePhoneNumberFromString(phone, 'US')
      const phoneNum = phoneNumber.number
      let error
      if (mode === signInMode.SIGN_IN || mode === signInMode.SIGN_UP) {
        const existingUid = await userIdByPhone(phoneNum)
        if (!existingUid && mode === signInMode.SIGN_IN) {
          error =
            'User does not exist - contact your company admin to invite you to this account or signup to create a new account'
        } else if (existingUid && mode === signInMode.SIGN_UP) {
          error = 'User alredy exists - sign in to continue'
        }
      }
      if (!_.isEmpty(error)) {
        setErrorMessage(error)
        setLoading(false)
      } else {
        const isSent = await requestSmsCode(phoneNum)
        setLoading(false)
        onResponse(phoneNum, isSent)
      }
    } catch (e) {
      setLoading(false)
      console.log('requestSmsCode error', e)
    }
  }

  const onPhoneChange = async event => {
    const v = event.target.value
    if (v && v.length <= FIRST_PART_LENGTH) {
      setPhone(v)
      if (!_.isNil(errorMessage)) {
        setErrorMessage(null)
      }
    } else if (v.length <= PHONE_NUMBER_LENGTH) {
      const aYT = new AsYouType('US')
      const formattedPhone = aYT.input(v)
      const phoneNumber = aYT.getNumber()
      const valid = !_.isNil(phoneNumber) && phoneNumber.isValid()
      setPhone(formattedPhone)
      setValid(valid)
      setErrorMessage(null)
    }
  }

  const renderError = () => {
    return (
      <Box width='medium' customStyle={`visibility: ${!_.isNil(errorMessage) ? 'visible' : 'hidden'}`}>
        <Text margin={{ top: 'xsmall', bottom: 'small' }} color={colors.CORAL_TWO}>
          {errorMessage}
        </Text>
      </Box>
    )
  }

  const header = () => {
    switch (mode) {
      case signInMode.SIGN_IN:
        return 'Sign in to your BidLevel account'
      case signInMode.SIGN_UP:
        return 'Try BidLevel for free'
      default:
        return 'Sign in to your BidLevel account'
    }
  }

  const renderFooter = (msg, label, onClick) => {
    return (
      <Box
        alignSelf='start'
        pad={{ top: 'medium' }}
        border={{ side: 'top', color: colors.VERY_LIGHT_GREY_TWO }}
        margin={{ top: 'medium' }}
        width='medium'
        direction='row'
        gap='xsmall'
        align='center'
      >
        <Text size='medium' color={colors.TEXT}>
          {msg}
        </Text>
        <Anchor label={label} color={colors.LINK} onClick={onClick} />
      </Box>
    )
  }

  const signInFooter = () =>
    renderFooter('Don’t have an account?', 'Setup company account', () =>
      navigate('/auth/email', { state: { mode: signInMode.SIGN_UP } })
    )

  const signUpFooter = () =>
    renderFooter('Already have an account?', 'Sign in', () =>
      navigate('/auth/phone', { state: { mode: signInMode.SIGN_IN } })
    )

  const footer = () => {
    switch (mode) {
      case signInMode.SIGN_IN:
        return signInFooter()
      case signInMode.SIGN_UP:
        return signUpFooter()
      default:
        return null
    }
  }

  const submitButtonName = () => {
    switch (mode) {
      case signInMode.SIGN_IN:
        return 'Log in'
      case signInMode.SIGN_UP:
        return 'Try for free'
      default:
        return 'Log in'
    }
  }

  const buttonDisabled = !isValid || isLoading
  return (
    <AuthLayout
      header={header()}
      subheader='We will text you a verification code to complete your registration'
      error={renderError()}
      isError={!isValid && !_.isEqual(phone, '')}
      valueName='Phone number'
      value={phone}
      placeholder='(500) 555 0000'
      handleChange={onPhoneChange}
      handleSubmit={onSubmit}
      isValid={!buttonDisabled}
      footer={footer()}
      submitName={submitButtonName()}
    />
  )
}

EnterPhone.defaultProps = {
  mode: signInMode.SIGN_IN
}

EnterPhone.propTypes = {
  mode: PropTypes.string
}

export default EnterPhone
