import React from 'react'
import PropTypes from 'prop-types'
import { Tip as GTip, Box, Text, ThemeContext } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const TipContent = ({ message, down }) => (
  <Box direction={down ? 'column-reverse' : 'column'} justify='start' align='center' background='transparent'>
    <Box background='yellow'>
      <FontAwesomeIcon
        icon={down ? faCaretDown : faCaretUp}
        fontSize={24}
        style={{ margin: down ? '-10px' : '-10px' }}
        color={colors.LIGHT_NAVY_BRIGHT}
      />
    </Box>
    <Box
      direction='column'
      justify='center'
      align='center'
      pad='small'
      round='xsmall'
      background={{ color: colors.LIGHT_NAVY_BRIGHT }}
    >
      <Text size='medium' textAlign='center' color={colors.VERY_LIGHT_PINK_TWO}>
        {message}
      </Text>
    </Box>
  </Box>
)

export const Tip = ({ message, appearance, children }) => {
  const down = appearance !== 'below'
  return (
    <ThemeContext.Extend
      value={{
        tip: {
          drop: {
            background: 'none',
            elevation: 'none',
            margin: 'none'
          }
        }
      }}
    >
      <GTip
        plain
        content={<TipContent message={message} down={down} />}
        dropProps={{ align: down ? { bottom: 'top' } : { top: 'bottom' }, margin: { vertical: 'small' } }}
      >
        {children}
      </GTip>
    </ThemeContext.Extend>
  )
}

Tip.defaultProps = {
  message: 'tooltip message',
  appearance: 'below'
}

Tip.propTypes = {
  /**
  Text inside the tooltip
  */
  message: PropTypes.string,
  /**
  Where the tooltip appears, above or below
  */
  appearance: PropTypes.oneOf(['below', 'above'])
}

export default Tip
