import React from 'react'
import { Box, Text } from 'shared/components'
import colors from 'shared/constants/colors'

const StatusIncluded = () => {
  return (
    <Box background={colors.MASON_NAVY_700} width='63px' align='center' justify='center'>
      <Text color={colors.WHITE} size='xsmall'>
        Included
      </Text>
    </Box>
  )
}

export default React.memo(StatusIncluded)
