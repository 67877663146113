import React from 'react'
import PropTypes from 'prop-types'
import { DropButton as GrommetDropButton, ThemeContext } from 'grommet'
import { deepMerge } from 'grommet/utils'
import _ from 'lodash'

import { createButtonTheme } from './Button'
import { DropContentContainer } from 'shared/components'
import { generateId } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'

const DropButton = ({ customTheme, dropContent, label, backgroundColor, ...props }) => {
  const themeValue = createButtonTheme(props)
  const id = generateId()
  return (
    <ThemeContext.Extend value={deepMerge(themeValue, customTheme)}>
      <GrommetDropButton
        label={label}
        dropContent={(
          <DropContentContainer dropId={id} backgroundColor={backgroundColor}>{dropContent}</DropContentContainer>
        )}
        dropProps={{
          ..._.get(props, 'dropProps', {}),
          margin: { vertical: 'small' }
        }}
        {...props}
      />
    </ThemeContext.Extend>
  )
}

DropButton.defaultProps = {
  disabled: false,
  backgroundColor: colors.WHITE,
  dropAlign: { top: 'bottom', left: 'left' },
  dropContent: null
}

DropButton.propTypes = {
  disabled: PropTypes.bool,
  /**
  How to align the drop with respect to the button.

  {
    top: "top" | "bottom",
    bottom: "top" | "bottom",
    right: "left" | "right",
    left: "left" | "right"
  }
  */
  dropAlign: PropTypes.object,
  /**
  Background color of the content and arrow (if presented)
   */
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
  Label text to place in the button.
   */
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
  /**
  Content to put inside the Drop.
  */
  dropContent: PropTypes.element.isRequired
}

export default DropButton

export { DropButton }
