import React from 'react'
import { connect } from 'react-redux'
import { Box, Text, Layer, Button } from 'shared/components'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { removeUserFromAccount } from 'controllers/invitations'

class LeaveAccountModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      loading: false
    }
  }

  open = () => {
    this.setState({ open: true, loading: false })
  }

  close = () => {
    this.setState({ open: false, loading: false })
  }

  handleConfirm = () => {
    this.setState({ loading: true }, () => {
      const { userId } = this.props
      removeUserFromAccount(userId)
    })
  }

  render () {
    const { open, loading } = this.state
    if (!open) return null
    const { accountProfile } = this.props
    const accountName = _.get(accountProfile, 'name')
    return (
      <Layer margin='large' onEsc={this.close} onClickOutside={this.close}>
        <Box width='medium' height='small'>
          <Box pad='small' fill='horizontal' align='center' direction='row' border='bottom' flex={false}>
            <Text color={colors.BLACK} size='medium'>
              Leave account
            </Text>
          </Box>
          <Box pad='small' fill='horizontal' align='center' direction='row' gap='small'>
            <Text size='medium' color={colors.BLACK}>
              Are you sure you want to leave {accountName}?
            </Text>
          </Box>
          <Box direction='row' justify='end' gap='small' margin={{ top: 'auto' }} pad='small'>
            <Box>
              <Button
                disabled={loading}
                primary
                color={colors.AQUA_MARINE}
                onClick={this.handleConfirm}
                label='Confirm'
              />
            </Box>
            <Box>
              <Button disabled={loading} primary color={colors.CORAL_TWO} onClick={this.close} label='Cancel' />
            </Box>
          </Box>
        </Box>
      </Layer>
    )
  }
}

const mapStateToProps = state => ({
  userId: _.get(state, 'user.id'),
  accountProfile: _.get(state, ['accountsProfiles', _.get(state, 'user.currentAccountId')])
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(LeaveAccountModal)
