import colors, { WHITE } from 'shared/constants/colors'
import { css } from 'styled-components'
import _ from 'lodash'

const tableCellBorder = props => {
  if (_.isArray(props.border)) {
    const borders = _.map(props.border, side => {
      switch (side) {
        case 'left':
          return 'border-left: solid 1px #eeeeee;'
        case 'right':
          return 'border-right: solid 1px #eeeeee;'
        case 'top':
          return 'border-top: solid 1px #eeeeee;'
        case 'bottom':
          return 'border-bottom: solid 1px #eeeeee;'
      }
    })
    return `border: none; ${borders.join(' ')}`
  }
}

const theme = {
  global: {
    font: {
      family: 'Lato',
      size: '14px'
    },
    colors: {
      text: colors.TEXT,
      brand: colors.LIGHT_NAVY_BRIGHT,
      border: colors.VERY_LIGHT_GREY_TWO,
      placeholder: colors.VERY_LIGHT_PINK
    },
    focus: {
      border: {
        width: '0px',
        radius: '0px',
        color: '#00000000'
      }
    },
    input: {
      font: {
        height: 'inherit',
        weight: 400
      }
    },
    drop: {
      border: {
        radius: '4px'
      },
      extend: ({ alignProp, theme }) => {
        return `
        overflow: visible;
        // box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.25)
      `
      }
    }
  },
  collapsible: {
    extend: css`
      ${props => `
        font-weight: 600;
        border-radius: 5px;
        padding: 6px 22px;
        display: contents;
      `}
    `,
    minSpeed: 200
  },
  accordion: {
    border: {
      color: 'transparent'
    }
  },
  text: {
    xsmall: {
      size: '10px',
      height: '14px'
    },
    small: {
      size: '12px',
      height: '16px'
    },
    medium: {
      size: '14px',
      height: '18px'
    },
    large: {
      size: '18px',
      height: '24px'
    },
    xlarge: {
      size: '22px',
      height: '28px'
    },
    extend: props => `
      white-space: ${props.newLines ? 'pre-line' : 'normal'};
      ${props.italic ? 'font-style: italic;' : ''}
      ${props.customStyle || ''};
    `
  },
  drop: {
    background: 'background-front',
    border: {
      width: '3px',
      color: 'red',
      radius: '4px'
    },
    extend: ({ alignProp, theme }) => `
      margin-top: ${alignProp.top !== 'top' && theme.global.edgeSize.xsmall};
      margin-bottom: ${alignProp.bottom !== 'bottom' && theme.global.edgeSize.xsmall}`,
    shadowSize: 'medium'
  },
  tabs: {
    header: {
      border: {
        width: '1px',
        side: 'bottom',
        color: colors.VERY_LIGHT_GREY_TWO
      },
      extend: 'width: 60%; margin-left: auto; margin-right: auto;'
    },
    panel: {
      extend: 'display: flex;'
    }
  },
  tab: {
    color: colors.ANOTHER_GREY,
    active: {
      background: 'background-contrast',
      color: colors.AQUA_MARINE
    },
    hover: {
      background: 'background-contrast',
      color: 'text'
    },
    border: {
      width: '1.5px',
      side: 'bottom',
      color: 'transparent',
      active: {
        // color: 'border-strong'
        color: colors.AQUA_MARINE
      },
      disabled: {
        color: 'transparent'
      },
      hover: {
        color: 'text'
      }
    },
    disabled: {
      color: 'text-weak'
    },
    pad: {
      // verital: 'small'
      // horizontal: 'xsmall'
    },
    margin: {
      // bring the overall tabs border behind invidual tab borders
      vertical: '0px',
      horizontal: 'small'
    },
    extend: ({ theme }) => css`
      border-top-left-radius: ${theme.global.control.border.radius}; // should use radius property of border
      border-top-right-radius: ${theme.global.control.border.radius}; // should use radius property of border
      /* font-weight: bold; */
    `
  },
  textInput: {
    extend: ({ theme }) =>
      css`
        color: #243659;
        ${props => props.customStyle};
      `
  },
  box: {
    extend: ({ theme }) =>
      css`
        ${props => props.customStyle};
      `
  },
  tip: {
    content: {
      background: 'transparent',
      elevation: 'none',
      // margin: 'xsmall',
      pad: {
        vertical: 'xsmall',
        horizontal: 'small'
      },
      round: 'none',
      customStyle: 'color: #FFF;text-align:center;'
    },
    drop: {
      plain: true,
      margin: 'none',
      background: colors.BLACK,
      width: 'small',
      round: 'xsmall',
      // elevation: 'xlarge',
      align: { top: 'bottom', left: 'left' }
    }
  },
  formField: {
    border: {
      color: 'border',
      position: 'inner',
      side: 'all'
    },
    extend: ({ theme }) =>
      css`
        > div {
          /* border-radius: 4px; */
          :focus-within {
            border-width: 1px;
            border-color: ${colors.VERY_LIGHT_GREY};
          }
        }
      `,
    label: {
      margin: { horizontal: '0px' }
    }
  },
  radioButton: {
    extend: css`
      font-size: 22px;
    `
  },
  // button: {
  //   border: {
  //     radius: '4px'
  //   },
  //   size: {
  //     small: {
  //       border: {
  //         radius: '4px'
  //       }
  //     },
  //     large: {
  //       border: {
  //         radius: '8px'
  //       },
  //       pad: {
  //         horizontal: '24px',
  //         vertical: '6px'
  //       }
  //     }
  //   },
  //   default: {
  //     border: undefined,
  //     font: {
  //       weight: 400
  //     },
  //     padding: {
  //       horizontal: 'small',
  //       vertical: 'xsmall'
  //     },
  //     extend: ({ theme }) => css`
  //       color: ${props => props.colorValue || colors.VERY_LIGHT_PINK};
  //       svg path {
  //         color: ${props => props.colorValue || colors.VERY_LIGHT_PINK};
  //         fill: ${props => props.colorValue || colors.VERY_LIGHT_PINK};
  //       }
  //     `
  //   },
  //   secondary: {
  //     border: {
  //       width: '1px'
  //     },
  //     font: {
  //       weight: 400
  //     },
  //     padding: {
  //       horizontal: 'small',
  //       vertical: 'xsmall'
  //     },
  //     extend: ({ theme }) => css`
  //       ${props => (props.hideBorder ? 'border-width: 0px;' : '')};
  //       border-color: ${props => props.hoverColor || props.colorValue || colors.LIGHT_NAVY_BRIGHT};
  //       color: ${props => props.colorValue || colors.LIGHT_NAVY_BRIGHT};
  //       > div {
  //         justify-content: flex-start;
  //       }
  //     `
  //   },
  //   primary: {
  //     border: {
  //       width: '1px'
  //     },
  //     color: colors.WHITE,
  //     font: {
  //       weight: 400
  //     },
  //     background: colors.LIGHT_NAVY_BRIGHT,
  //     padding: {
  //       horizontal: 'medium',
  //       vertical: 'xsmall'
  //     },
  //     extend: ({ theme }) => css`
  //       border-color: ${props => props.hoverColor || props.colorValue || colors.LIGHT_NAVY_BRIGHT};
  //     `
  //   },
  //   selected: {
  //     background: colors.VERY_LIGHT_GREY
  //   },
  //   hover: {
  //     default: {
  //       color: colors.BLACK80,
  //       extend: props => css`
  //         color: ${props.hoverTextColor || colors.BLACK80};
  //         background-color: ${props.hoverColor || colors.VERY_LIGHT_GREY_THREE};
  //         div {
  //           color: ${props.hoverTextColor || colors.BLACK80};
  //           border-color: ${props.hoverTextColor || colors.BLACK80};
  //         }
  //         div svg path {
  //           color: ${props.hoverTextColor || colors.BLACK80};
  //           fill: ${props.hoverTextColor || colors.BLACK80};
  //         }
  //       `
  //     },
  //     secondary: {
  //       extend: ({ theme }) => css`
  //         ${props => {
  //           if (props.inverse) {
  //             return `
  //               border-color: ${props.hoverColor || props.colorValue || colors.LIGHT_NAVY_BRIGHT};
  //               background-color: ${props.hoverColor || props.colorValue || colors.LIGHT_NAVY_BRIGHT};
  //               color: ${props.hoverTextColor || colors.LIGHT_NAVY_BRIGHT};
  //               svg path {
  //                 color: ${props.hoverTextColor || colors.LIGHT_NAVY_BRIGHT};
  //                 fill: ${props.hoverTextColor || colors.LIGHT_NAVY_BRIGHT};
  //               }
  //               :active {
  //                 opacity: 0.9;
  //               }
  //             `
  //           } else {
  //             return 'box-shadow: 0px 1px 4px 1px #c6c6c6;'
  //           }
  //         }}
  //       `
  //     },
  //     primary: {
  //       color: colors.WHITE,
  //       font: {
  //         weight: 400
  //       },
  //       // background: colors.LIGHT_NAVY_BRIGHT,
  //       extend: css`
  //         ${props => 'box-shadow: 0px 1px 4px 1px #c6c6c6;'};
  //       `
  //     }
  //   },
  //   active: {
  //     secondary: {
  //       border: {
  //         width: '5px'
  //       }
  //     }
  //   },
  //   disabled: {
  //     primary: {
  //       color: colors.WHITE,
  //       background: { color: colors.DISABLED },
  //       border: { color: colors.DISABLED }
  //     },
  //     secondary: {
  //       border: {
  //         color: 'text-weak'
  //       }
  //     },
  //     opacity: 0.8
  //   },
  //   extend: css`
  //     ${props => (props.menu ? ':hover {background-color: rgba(255, 255, 255, 0.1)};' : '')}
  //     > svg {
  //       display: flex;
  //       vertical-align: middle;
  //       path {
  //         fill: ${props => props.colorValue || colors.LIGHT_NAVY_BRIGHT};
  //       }
  //     }
  //     :hover {
  //       svg path {
  //         ${props => (props.kind === 'default' && _.has(props, 'hoverColor') ? `fill: ${props.hoverColor};` : '')}
  //       }
  //     }
  //     ${props =>
  //       props.white
  //         ? `
  //       border-radius: 3px;
  //       border-width: 1.5px;
  //       padding-left: 10px;
  //       border-color: #FFF;
  //       > div {
  //         justify-content: flex-start;
  //       };
  //       :hover {
  //         box-shadow: none;
  //         border-width: 1.5px;
  //         border-radius: 3px;
  //         background-color: #FFF;
  //         color: ${colors.LIGHT_NAVY_BRIGHT}
  //       };
  //     `
  //         : ''}
  //     ${props => props.customStyle}
  //   `
  // },
  table: {
    header: {
      extend: css`
        ${tableCellBorder}
        ${props => {
          if (_.get(props, 'children.props.sticky')) {
            return 'position: sticky !important; left: 0px;'
          }
        }}
        font-size: 10px;
        color: ${colors.ANOTHER_GREY};
        border-collapse: collapse;
      `
    },
    body: {
      // border: 'all',
      extend: css`
        padding: 0px;
        ${tableCellBorder}
        ${props => {
          if (_.get(props, 'children.props.sticky')) {
            return 'position: sticky !important; left: 0px;'
          }
        }}
        font-size: 12px;
        color: ${colors.TEXT_PRIMARY};
      `
    },
    extend: css`
      ${props =>
        props.rowHover &&
        `tbody > tr:hover:not(.alt-row) {
          background-color: ${colors.TABLE_HOVER};
          #delete_line_item_icon svg path {
            fill: ${colors.CORAL_TWO};
          }
        }`}
      /* tbody > .alt-row * td, tbody > .alt-row * th {
        border-top: none;
      }
      .alt-row > td {
        border: none;
      }
      .alt-row-last * {
        border-bottom: none;
      } */
      /* tbody > .alt-row * td,
      tbody > .alt-row * th:first-child,
      tbody * .line_item_name {
        border-left: solid 1px transparent;
      } */

      /* tbody > tr {
        border-bottom: solid 1px transparent;
      } */

      tbody * .error_comment {
        border-color: ${colors.CORAL_TWO};
      }

      .selected_row {
        background-color: ${colors.AQUA_MARINE_LIGHT} !important;
      }
      .selected_cell {
        border-color: ${colors.AQUA_MARINE} !important;
      }
    `
  }
}

export default theme
