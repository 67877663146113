import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'

// import colors from 'shared/constants/colors'
import { Layer, ModalHeader, Box, Button, TextInput, FormField } from 'shared/components'
import { createContactsGroup } from 'controllers/contacts'
import contactsPageMode from 'constants/contactsPageMode'

const SaveFilterGroupModal = ({ modalRef }) => {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [params, setParams] = useState()
  const [, setSearchParams] = useSearchParams()

  const close = () => setShow(false)

  const open = (filters, operator) => {
    setName('')
    setParams({ filters, operator })
    setShow(true)
  }

  useImperativeHandle(modalRef, () => ({
    open
  }))

  const saveGroup = async () => {
    const groupId = await createContactsGroup(name, params.filters, params.operator)
    setSearchParams({ mode: contactsPageMode.GROUP, groupId })
    close()
  }

  if (show) {
    return (
      <Layer>
        <Box width='medium'>
          <ModalHeader title='Save Group' onClose={close} />
          {/* <Text
            size='large'
            margin={{ top: 'large', horizontal: 'medium' }}
            color={colors.LIGHT_NAVY_BRIGHT}
            textAlign='center'
          >
            Enter name for the group
          </Text> */}
          <Box pad={{ horizontal: 'medium', top: 'large', bottom: 'medium' }}>
            <FormField label='Save group as'>
              <TextInput value={name} onChange={e => setName(e.target.value)} />
            </FormField>
          </Box>
          <Box direction='row' align='center' justify='between' pad='medium'>
            <Button label='Cancel' secondary onClick={close} />
            <Button label='Save' primary onClick={saveGroup} disabled={_.isEmpty(name)} />
          </Box>
        </Box>
      </Layer>
    )
  } else {
    return null
  }
}

export default SaveFilterGroupModal
