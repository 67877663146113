import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, MaskedInput, Keyboard } from 'grommet'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlus } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'

const Input = ({ value, mask, onChange, onBlur }) => {
  const inputRef = useRef()

  useEffect(() => {
    if (_.isEmpty(_.toString(value))) {
      inputRef.current.focus()
    }
  }, [value])

  const onEnter = () => inputRef.current.blur()
  return (
    <Keyboard onEnter={onEnter}>
      <MaskedInput plain mask={mask} value={value} onChange={onChange} onBlur={onBlur} ref={inputRef} />
    </Keyboard>
  )
}

const InputsList = ({ values, mask, isValid, setValues, buttonLabel, formatOnChange }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (_.isEmpty(values)) {
      setData([...values, ''])
    } else {
      setData(values)
    }
  }, [values])

  const save = newData => {
    const withoutEmpty = _.filter(newData, v => !_.isEmpty(_.toString(v)))
    setValues(withoutEmpty)
  }

  const removeItem = index => {
    const newData = [...data]
    newData.splice(index, 1)
    save(newData)
  }

  const addValue = () => {
    setData([...data, ''])
  }

  const renderRow = (v, index) => {
    const onBlur = () => {
      if (!isValid(v)) {
        removeItem(index)
      } else {
        save(data)
      }
    }

    const onChange = e => {
      const newData = [...data]
      const v = formatOnChange(e.target.value)
      _.set(newData, index, v)
      setData(newData)
    }

    return (
      <Box
        fill='horizontal'
        direction='row'
        key={`email_${index}`}
        border={{ color: colors.VERY_LIGHT_GREY_TWO, side: 'all' }}
        round='xsmall'
        align='center'
        customStyle={`
          button {
            display: none;
          }
          :hover {
            button {
              display: block;
            }
          }
        `}
      >
        <Input mask={mask} value={v} onChange={onChange} onBlur={onBlur} />
        <Box align='center' justify='center' pad='small'>
          {!_.isEmpty(_.toString(v)) && (
            <Button plain icon={<FontAwesomeIcon icon={faTrashAlt} />} onClick={() => removeItem(index)} />
          )}
        </Box>
      </Box>
    )
  }

  const hasEmptyValue = _.size(_.filter(data, v => v.length === 0)) > 0
  const valuesValid = _.isEmpty(_.filter(data, v => !isValid(v)))

  return (
    <Box gap='xsmall'>
      {_.map(data, renderRow)}
      <Box direction='row'>
        <Button
          icon={<FontAwesomeIcon icon={faPlus} />}
          label={buttonLabel}
          onClick={addValue}
          disabled={hasEmptyValue || !valuesValid}
        />
      </Box>
    </Box>
  )
}

InputsList.defaultProps = {
  values: [],
  isValid: () => true,
  setValues: () => null,
  formatOnChange: v => v
}

InputsList.propTypes = {
  values: PropTypes.array,
  mask: PropTypes.array,
  isValid: PropTypes.func,
  setValues: PropTypes.func,
  buttonLabel: PropTypes.string
}

export default InputsList

export { InputsList }
