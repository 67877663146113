import React, { useState, useRef, useMemo, useEffect, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Box, Button, Text, TextInput, List, Layer, RadioButtonGroup, Tip } from 'shared/components'
import { faCircle, faPlus, faPlusCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import Tip from 'shared/components/Tip'
import colors from 'shared/constants/colors'
import CreateContactModal from 'modals/inviteSubDrop/CreateContactModal'
import { cutString, generateId, getName } from 'shared/utils/stringUtils'
import { getUsersProfilesByAccountId } from 'model/selectors/profiles'
import { formatNational } from 'shared/utils/phone'
import { updateAccountContact, deleteAccountContact, updateAccountContactCompany } from 'controllers/contacts'
import { inviteSub } from 'controllers/workOrder'
import sendTypes from 'shared/constants/inviteStatus'

const SUGGESTIONS_LIMIT = 12

const InviteSubPanel = ({
  account,
  accountsProfiles,
  usersProfilesByAccountId,
  profiles,
  contacts,
  companies,
  inviteSubPanelRef
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [manualMode, setManualMode] = useState(false)
  const [value, setValue] = useState('')
  const [selectedOption, setSelectedOption] = useState()
  const [workOrderId, setWorkOrderId] = useState()
  const [sendTo, setSendTo] = useState()
  const [contactModalVisible, setContactModalVisible] = useState(false)
  const contactModalRef = useRef()
  const [companyId, setCompanyId] = useState()
  const [sendType, setSendType] = useState(sendTypes.IMMEDIATELY)
  const panelRef = useRef()

  useImperativeHandle(inviteSubPanelRef, () => ({
    open: (params, woId, value) => {
      console.log('%c open', 'color: red;', params, woId, value)
      setIsOpened(true)
      setValue(value)
      setSelectedOption(null)
      setSendTo(null)
      setCompanyId(null)
      setManualMode(false)
      setWorkOrderId(woId)
      onSuggestionSelect(params, value)
    },
    close: () => panelRef.current.close()
  }))

  useEffect(() => {
    const newSendAR = _.map(sendTo, c => {
      return { ...c, ..._.get(contacts, c.id, {}) }
    })
    setSendTo(_.keyBy(newSendAR, 'id'))
  }, [contacts])

  const close = () => {
    setIsOpened(false)
  }

  const accountId = _.get(account, 'id')

  const selectCompanyMode = _.isEmpty(selectedOption) && !manualMode

  // console.log('%c selected option', 'color: lightgreen', selectedOption)

  const renderHeader = () => {
    return (
      <Box direction='row' align='center' justify='between' pad={{ horizontal: 'medium' }} flex={{ shrink: 0 }}>
        <Text size='large' weight={600}>
          Invite sub to bid
        </Text>
      </Box>
    )
  }

  const createSendToByContactId = contactId => {
    const companyId = _.get(contacts, [contactId, 'companyId'])
    const res = {}
    _.forEach(contacts, c => {
      if (c.companyId === companyId) {
        const sendTouUser = _.pick(c, ['id', 'name', 'phones', 'emails'])
        _.set(res, c.id, { ...sendTouUser, checked: true })
      }
    })
    setCompanyId(companyId)
    return res
  }

  const createSendToByAccountId = accountId => {
    const profiles = _.get(usersProfilesByAccountId, accountId)
    const res = {}
    _.forEach(profiles, p => {
      const name = getName(p)
      const sendToUser = _.pick(p, ['id', 'phones', 'emails'])
      if (!_.isEmpty(p.phone) && _.isArray(p.phones) && !_.includes(p.phones, p.phone)) {
        sendToUser.phones.push(p.phone)
      }
      if (!_.isEmpty(p.email) && _.isArray(p.emails) && !_.includes(p.emails, p.email)) {
        sendToUser.emails.push(p.email)
      }
      _.set(res, p.id, { ...sendToUser, name, checked: true })
    })
    setCompanyId(null)
    return res
  }

  const onSuggestionSelect = (event, name) => {
    const sugValue = _.get(event, 'suggestion.value')
    console.log('onSuggestionSelect', sugValue)
    const contactId = _.get(sugValue, 'contactId')
    const accountProfile = _.get(sugValue, 'accountProfile')
    if (_.isNil(sugValue)) {
      setManualMode(true)
      const id = generateId()
      setCompanyId(id)
      updateAccountContactCompany(id, { name })
    } else {
      if (contactId) {
        const companyId = _.get(contacts, [contactId, 'companyId'])
        setValue(_.get(companies, [companyId, 'name']))
        setSendTo(createSendToByContactId(contactId))
      } else if (accountProfile) {
        setValue(getName(accountProfile))
        setSendTo(createSendToByAccountId(accountProfile.id))
      }
      setSelectedOption(sugValue)
    }
  }

  const renderCompanyOptionLabel = (accName, userName, isActive) => {
    return (
      <Box
        // border='top'
        direction='row'
        align='center'
        justify='between'
        flex
        pad={{ vertical: 'small', horizontal: 'medium' }}
        gap='small'
        width={{ min: '450px' }}
      >
        <Box direction='row' align='end' gap='xsmall'>
          <Text weight={500} color={colors.TEXT}>
            {accName}
          </Text>
          {isActive && (
            <Text size='small' color={colors.ANOTHER_GREY}>
              <i>Active</i>
            </Text>
          )}
        </Box>
        <Text weight={500} color={colors.LIGHT_NAVY_BRIGHT} textAlign='end' truncate>
          {userName}
        </Text>
      </Box>
    )
  }

  const suggestions = useMemo(() => {
    const createCompanyButton = (
      <Box
        border='top'
        direction='row'
        align='center'
        flex
        pad={{ vertical: 'small', horizontal: 'medium' }}
        gap='xsmall'
      >
        <FontAwesomeIcon icon={faPlusCircle} fontSize={20} color={colors.CLEAR_BLUE} />
        <Text weight={500} color={colors.CLEAR_BLUE}>
          Create a Company <Text color={colors.ALMOST_BLACK}>{value}</Text>
        </Text>
      </Box>
    )
    if (_.isEmpty(value) || !_.isEmpty(selectedOption) || manualMode) return []
    let res = []
    const reg = new RegExp(_.escapeRegExp(value), 'gi')

    // console.log('contacts', contacts)
    _.forEach(accountsProfiles, (accountProfile, accId) => {
      const accName = getName(accountProfile)
      const profiles = _.get(usersProfilesByAccountId, accId)
      _.forEach(profiles, p => {
        const userName = getName(p)
        if (accId !== accountId && (accName.match(reg) || userName.match(reg))) {
          res.push({
            label: renderCompanyOptionLabel(getName(accountProfile), getName(p), true),
            value: { accId, userId: p.id, accountProfile }
          })
        }
      })
    })
    if (_.size(res) < SUGGESTIONS_LIMIT) {
      _.forEach(contacts, c => {
        const companyName = _.get(companies, [c.companyId, 'name'])
        const companyNameLower = _.toLower(companyName)
        const phones = _.get(c, 'phones', [])
        const emails = _.get(c, 'emails', [])
        const str = [c.name, companyNameLower, ...phones, ...emails].join(', ')
        if (str.match(reg) && !_.isEmpty(companyName) && !_.isEmpty(c.name)) {
          res.push({
            label: renderCompanyOptionLabel(companyName, c.name, false),
            value: { contactId: c.id }
          })
        }
      })
    }
    res = _.take(res, SUGGESTIONS_LIMIT)
    res.push({
      label: createCompanyButton,
      value: null
    })
    return res
  }, [accountId, contacts, value, accountsProfiles, usersProfilesByAccountId, selectedOption, manualMode, companies])

  const onChangeValue = e => {
    const v = e.target.value
    setValue(v)
    if (manualMode && _.isEmpty(v)) {
      setManualMode(false)
      setSelectedOption(null)
      setSendTo(null)
    } else if (!manualMode) {
      setSelectedOption(null)
      setSendTo(null)
    }
  }

  const onInputEnd = () => {
    if (manualMode && !_.isEmpty(value)) {
      updateAccountContactCompany(companyId, { name: value })
    }
  }

  const renderInput = () => {
    const inputLabel = selectCompanyMode ? 'To' : 'Company'
    return (
      <Box margin={{ horizontal: 'medium', top: 'medium' }} flex={{ shrink: 0 }}>
        <Text margin={{ bottom: 'xsmall' }}>{inputLabel}</Text>
        <TextInput
          value={value}
          onChange={onChangeValue}
          suggestions={suggestions}
          onSuggestionSelect={onSuggestionSelect}
          onBlur={onInputEnd}
        />
        {selectCompanyMode && (
          <Text margin={{ top: 'xsmall' }} size='xsmall' color={colors.ASANA_GRAY_TEXT_HOVERED}>
            Search by company or user name or enter a phone number or email
          </Text>
        )}
      </Box>
    )
  }

  const renderUsersHeader = () => {
    if (!selectCompanyMode) {
      return (
        <Box pad={{ top: 'medium', left: 'medium', bottom: 'small' }} flex={{ shrink: 0 }}>
          <Text color={colors.ASANA_GRAY_TEXT_HOVERED}>{`People at ${value}`}</Text>
        </Box>
      )
    }
  }

  const toggleUserId = userId => e => {
    // console.log('toggleUserId', userId, e)
    e.stopPropagation()
    const newSendTo = { ...sendTo }
    const curValue = _.get(newSendTo, [userId, 'checked'])
    _.set(newSendTo, [userId, 'checked'], !curValue)
    setSendTo(newSendTo)
  }

  const renderRadio = (userId, checked) => {
    const size = 24
    const faIcon = checked ? faCheckCircle : faCircle
    return (
      <Box onClick={toggleUserId(userId)} flex={{ shrink: 0 }}>
        <FontAwesomeIcon icon={faIcon} fontSize={size} color={colors.AQUA_MARINE} />
      </Box>
    )
  }

  const editContact = cId => () => {
    console.log('editContact', cId)
    const c = _.get(contacts, cId, _.get(sendTo, cId))
    openCreateContactModal(c)
  }

  const renderContactRow = c => {
    const emails = _.get(c, 'emails', [])
    const phones = _.map(_.get(c, 'phones', []), formatNational)
    const desc = [...emails, ...phones].join(', ')
    const checked = _.get(c, 'checked', false)
    const onClick = _.has(profiles, c.id) ? undefined : editContact(c.id)
    return (
      <Box
        direction='row'
        justify='between'
        align='center'
        pad={{ vertical: 'small', horizontal: 'medium' }}
        gap='medium'
        hoverIndicator
        onClick={onClick}
      >
        <Box gap='xxsmall' width={{ max: '300px' }}>
          <Text color={colors.TEXT_PRIMARY}>{getName(c)}</Text>
          <Text size='small' color={colors.VERY_LIGHT_PINK} truncate customStyle='white-space: nowrap;'>
            {desc}
          </Text>
        </Box>
        {renderRadio(c.id, checked)}
      </Box>
    )
  }

  const renderUsers = () => {
    // console.log('sendTo', sendTo)
    return (
      <Box overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
        <List children={renderContactRow} data={_.values(sendTo)} pad='none' />
      </Box>
    )
  }

  const openCreateContactModal = c => {
    console.log('openCreateContactModal')
    setContactModalVisible(true)
    // the code is not good, but finally the way is easier than passing params to props
    contactModalRef.current.init(c)
  }

  const closeContactModal = () => {
    setContactModalVisible(false)
  }

  const renderAddContactButton = () => {
    if (!selectCompanyMode && !_.has(selectedOption, 'accId')) {
      return (
        <Box direction='row' pad={{ horizontal: 'medium', vertical: 'small' }} flex={{ shrink: 0 }}>
          <Button
            label={cutString(`Add a contact to ${value}`, 40)}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => openCreateContactModal()}
          />
        </Box>
      )
    }
  }

  const renderSendOptions = () => {
    if (!selectCompanyMode) {
      return (
        <Box gap='small' pad='medium'>
          <Text>When to send</Text>
          <RadioButtonGroup
            name='send-type'
            options={[
              { label: 'Send immediately', value: sendTypes.IMMEDIATELY },
              { label: 'Send Later', value: sendTypes.LATER },
              {
                label: (
                  <Box gap='small' alignContent='between' direction='row'>
                    <Text>Send Silently</Text>
                    <Tip content='Add sub to bid invitation without notifying them'>
                      <Box>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Box>
                    </Tip>
                  </Box>
                ),
                value: sendTypes.SILENT
              },
              { label: <Tip content='Coming soon'>Scheduled Send</Tip>, value: sendTypes.SCHEDULE, disabled: true }
            ]}
            value={sendType}
            onChange={event => setSendType(event.target.value)}
          />
        </Box>
      )
    }
  }

  const send = () => {
    const subAccountId = _.get(selectedOption, 'accId', null)
    const dbSendTo = {}
    _.forEach(sendTo, (c, cId) => {
      if (c.checked) {
        _.set(dbSendTo, cId, _.pick(c, 'id', 'name', 'emails', 'phones'))
      }
    })
    inviteSub(subAccountId, companyId, dbSendTo, workOrderId, sendType)
    close()
  }

  const renderLabel = type => {
    switch (type) {
      case sendTypes.IMMEDIATELY:
        return 'Send Invitation'
      case sendTypes.LATER:
        return 'Send Later'
      case sendTypes.SILENT:
        return 'Silent Invitation'
      default:
        return 'Send Invitation'
    }
  }

  const renderFooter = () => {
    if (!selectCompanyMode) {
      const canSend = !_.isEmpty(_.filter(sendTo, c => c.checked)) || _.isEqual(sendType, sendTypes.SILENT)
      return (
        <Box
          direction='column'
          pad={{ top: 'large', bottom: 'small', horizontal: 'medium' }}
          margin={{ top: 'auto' }}
          gap='small'
          flex={{ shrink: 0 }}
        >
          <Button primary label={renderLabel(sendType)} color={colors.AQUA_MARINE} disabled={!canSend} onClick={send} />
          <Button color={colors.CLEAR_BLUE} label='Cancel' onClick={close} />
        </Box>
      )
    }
  }

  const addContact = p => {
    console.log('addContact', p)
    const checked = _.get(sendTo, p.id, true)
    setSendTo({ ...sendTo, [p.id]: { ...p, checked } })
    updateAccountContact({ ...p, companyId })
    if (manualMode) {
      updateAccountContactCompany(companyId, { name: value })
    }
  }

  const removeContact = pId => {
    console.log('removeContact', pId)
    const newSendTo = { ...sendTo }
    _.unset(newSendTo, pId)
    setSendTo(newSendTo)
    if (!manualMode) {
      deleteAccountContact(pId)
    }
  }

  const dropContent = (
    <Box width={{ min: '400px' }} pad={{ top: 'medium' }} flex={{ shrink: 0 }} fill>
      {renderHeader()}
      <Box flex={{ shrink: 0 }} customStyle='flex: 1' overflow={{ horizontal: 'hidden', vertical: 'auto' }} fill>
        {renderInput()}
        {renderUsersHeader()}
        {renderUsers()}
        {renderAddContactButton()}
        {renderSendOptions()}
      </Box>
      {renderFooter()}
    </Box>
  )

  const onClickOutside = contactModalVisible ? closeContactModal : close
  const onEsc = contactModalVisible ? closeContactModal : close

  if (!isOpened) return null

  return (
    <Layer modal position='right' full='vertical' onEsc={onEsc} onClickOutside={onClickOutside}>
      {dropContent}
      <CreateContactModal
        ref={contactModalRef}
        visible={contactModalVisible}
        onClose={closeContactModal}
        companyName={value}
        addContact={addContact}
        removeContact={removeContact}
      />
    </Layer>
  )
}

InviteSubPanel.defaultProps = {}

InviteSubPanel.propTypes = {
  dropButtonLabel: PropTypes.node,
  workOrderId: PropTypes.string,
  dropButtonProps: PropTypes.object
}

const mapsStateToProps = state => ({
  account: state.account,
  accountsProfiles: state.accountsProfiles,
  usersProfilesByAccountId: getUsersProfilesByAccountId(state),
  profiles: state.profiles,
  contacts: _.get(state, 'contacts.contacts'),
  companies: _.get(state, 'contacts.companies')
})

export default connect(mapsStateToProps)(InviteSubPanel)
