import _ from 'lodash'

export const condition = {
  IS: 'is',
  IS_NOT: 'isNot',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesNotContain',
  MORE: 'more',
  MORE_EQUAL: 'moreEqual',
  LESS: 'less',
  LESS_EQUAL: 'lessEquall'
}

const conditionsList = _.values(condition)

const conditionTitles = {
  [condition.IS]: 'is',
  [condition.IS_NOT]: 'is not',
  [condition.STARTS_WITH]: 'starts with',
  [condition.ENDS_WITH]: 'ends with',
  [condition.CONTAINS]: 'contains',
  [condition.DOES_NOT_CONTAIN]: 'does not contain',
  [condition.MORE]: 'more',
  [condition.MORE_EQUAL]: 'more equal',
  [condition.LESS]: 'less',
  [condition.LESS_EQUAL]: 'less equall'
}

const conditionsByType = {
  number: [condition.IS, condition.IS_NOT, condition.MORE, condition.MORE_EQUAL, condition.LESS, condition.LESS_EQUAL],
  string: [
    condition.IS,
    condition.IS_NOT,
    condition.STARTS_WITH,
    condition.ENDS_WITH,
    condition.CONTAINS,
    condition.DOES_NOT_CONTAIN
  ]
}

export default {
  condition,
  conditionTitles,
  conditionsByType,
  conditionsList
}
