import { createStore, compose } from 'redux'

import rootReducer from 'model/rootReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = initialState => {
  const enhancer = composeEnhancers()
  return createStore(rootReducer, initialState, enhancer)
}

const store = configureStore()
export default store
