import React from 'react'
import { Box, Text } from 'shared/components'
import PropTypes from 'prop-types'

import Tip from 'shared/components/Tip'
import colors from 'shared/constants/colors'

const TabTitle = ({ label, active, disabled }) => {
  const color = active ? colors.AQUA_MARINE : colors.ANOTHER_GREY
  if (disabled) {
    return (
      <Tip message='Coming Soon'>
        <Box width='xsmall'>
          <Text textAlign='center' color={color}>
            {label}
          </Text>
        </Box>
      </Tip>
    )
  } else {
    return (
      <Box width='xsmall'>
        <Text textAlign='center' color={color}>
          {label}
        </Text>
      </Box>
    )
  }
}

TabTitle.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool
}

export default TabTitle
