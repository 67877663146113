import React from 'react'
import { Box, Text } from 'shared/components'
import colors from 'shared/constants/colors'

const StatusRemovalRequested = ({ isDeclined }) => {
  return (
    <Box background={colors.WHITE_GREY} width='108px' align='center' justify='center'>
      <Text color={colors.VERY_LIGHT_GREY} size='xsmall' weight={600} italic>
        {isDeclined ? 'Removal Declined' : 'Removal requested'}
      </Text>
    </Box>
  )
}

export default React.memo(StatusRemovalRequested)
