import React, { Component } from 'react'
import { Layer, Text, CardBody, CardFooter, Button, CardHeader, Box, Stack } from 'shared/components'
import _ from 'lodash'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'
import colors from 'shared/constants/colors'

const StyledIcon = styled(FontAwesomeIcon)`
  display: flex;
`
class MergingModal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  open = ({ invId, itemsAvailableForMerging }) => {
    this.setState({
      visible: true,
      invId,
      itemsAvailableForMerging,
      selectedItemId: null
    })
  }

  close = () => {
    this.setState({ visible: false })
  }

  onItemClick = id => () => {
    const { selectedItemId } = this.state
    if (selectedItemId !== id) {
      this.setState({ selectedItemId: id })
    } else {
      this.setState({ selectedItemId: null })
    }
  }

  checkmarkInCircle = (
    <Box align='center' justify='center' background={colors.AQUA_MARINE} round='full' width='32px' height='32px'>
      <FontAwesomeIcon icon={faCheck} color={colors.WHITE} fontSize={12} />
    </Box>
  )

  onMerge = () => {
    const { selectedItemId } = this.state
    this.props.merge(selectedItemId)
    this.close()
  }

  renderNumIcon = num => (
    <Stack anchor='center' height='fit-content' alignSelf='center'>
      <StyledIcon icon={faCircle} color={colors.VERY_LIGHT_PINK} fontSize={36} />
      <Text color={colors.VERY_LIGHT_PINK}>{num}</Text>
    </Stack>
  )

  renderItemRow = item => {
    const { selectedItemId } = this.state
    const selected = selectedItemId === item.id
    return (
      <Box
        key={item.id}
        pad={{ horizontal: 'small', vertical: 'small' }}
        round='none'
        onClick={this.onItemClick(item.id)}
        border={{ side: 'bottom' }}
        hoverIndicator
      >
        <Box direction='row' align='center' gap='xsmall' pad='xsmall' width='100%'>
          {selected ? this.checkmarkInCircle : this.renderNumIcon(item.num)}
          <Text size='medium' color={selected ? colors.LIGHT_NAVY : undefined} weight={selected ? 600 : 400}>
            {item.name}
          </Text>
        </Box>
      </Box>
    )
  }

  render () {
    const { visible, itemsAvailableForMerging, selectedItemId } = this.state
    // console.log('itemsAvailableForMerging', itemsAvailableForMerging)
    if (visible) {
      return (
        <Layer background='transparent' onClickOutside={this.close} onEsc={this.close}>
          <CardHeader pad='small' border={{ side: 'bottom' }}>
            <Text size='large' weight={600}>
              Merge line item with...
            </Text>
          </CardHeader>
          <CardBody width='medium' height={{ min: '400px' }}>
            {_.map(itemsAvailableForMerging, this.renderItemRow)}
          </CardBody>
          <CardFooter
            background={colors.VERY_LIGHT_PINK_TWO}
            pad='small'
            justify='end'
            gap='small'
            round={{ corner: 'bottom', size: 'xsmall' }}
          >
            <Button secondary label='Cancel' onClick={this.close} customStyle='padding: 6px 24px;' />
            <Button
              primary
              label='Merge'
              onClick={this.onMerge}
              color={colors.AQUA_MARINE}
              disabled={_.isEmpty(selectedItemId)}
            />
          </CardFooter>
        </Layer>
      )
    } else {
      return null
    }
  }
}

MergingModal.propTypes = {
  merge: PropTypes.func
}

export default MergingModal
